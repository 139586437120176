import React, { useState, useRef, useEffect } from 'react';
import winterbornKnightImage from '../../assets/images/subclasses/Winterborn/winterbornknight.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesWinterbornKnight = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={winterbornKnightImage} 
          alt="Winterborn Knight" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Winterborn Knight</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Description</h2>
          <p>
            Knights sworn to the Frost Monarch serve the ruler of the coldest, most forbidding realms of the fey world. These warriors harness the biting frost, fierce blizzards, and silent deadliness of the harshest winters.<br/><br/>As enforcers of their icy patron's will, they manipulate the cold to vanquish their foes and protect the balance of life that winter brings.
          </p>
        </div>

        <div className="content-block">
          <h2>Expanded Spell List</h2>
          <div className="level-indicator">Level 1st</div>
          <p>The Winterborn Knight lets you choose from an expanded list of spells when you learn a warlock spell. The following spells are added to the warlock spell list for you.</p>
          <div className="trait">
            <div className="trait-header">
              <h3>3rd Level</h3>
            </div>
            <p>Command, Ice Knife</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>5th Level</h3>
            </div>
            <p>Rime's Binding Ice, Hold Person</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>7th Level</h3>
            </div>
            <p>Sleet Storm, Tidal Wave</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>9th Level</h3>
            </div>
            <p>Ice Storm, Otiluke's Resilient Sphere</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Frostforged Weapon</h2>
          <div className="level-indicator">Level 1st</div>
          <p>
            As an action, you can summon any melee weapon made of pure ice that deals 1d12 damage on a hit, using your spell attack modifier for attack rolls. The weapon remains as long as you hold it but melts away if it leaves your hand for more than 1 minute. It cannot be ranged or have any special properties beyond its cold damage type.
          </p>
        </div>

        <div className="content-block">
          <h2>Icy Thorns</h2>
          <div className="level-indicator">Level 5th</div>
          <p>
            Whenever you hit a creature with the Frostforged Weapon, the target takes additional cold damage equal to your Charisma modifier.
          </p>
        </div>

        <div className="content-block">
          <h2>Frost Armor and Winter's Cloak</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            Activate this ability as an action to envelop yourself in shimmering frost armor. This sets your Armor Class to 16 (if not higher), grants resistance to cold damage, dims light by one level for a 10-foot radius around you, and provides darkvision up to 60 feet, including the ability to see in magical darkness. Additionally, you can cast darkness a number of times per day equal to your proficiency bonus without needing material components.
          </p>
          <p>
            While in darkness or dim light, you can use your reaction to become invisible until the start of your next turn or until you attack, cast a spell, or turn on a light. This ability can be used a number of times equal to your proficiency bonus, resetting after a long rest.
          </p>
        </div>

        <div className="content-block">
          <h2>Ice Shield</h2>
          <div className="level-indicator">Level 10th</div>
          <p>
            You can conjure a shield of solid ice at a moment's notice with a reaction or bonus action. This shield not only grants a +2 bonus to AC but also impedes the freedom of movement, allowing you to glide across the battlefield unimpeded. The shield dims light by one level for a 20-foot radius around you and casts an aura of deeper darkness in a 10-foot radius around you, which can be dismissed at your will.
          </p>
        </div>

        <div className="content-block">
          <h2>Winter's Grasp</h2>
          <div className="level-indicator">Level 11th</div>
          <p>
            Hits with the Frostforged Weapon now slow the target, reducing their speed by 10 feet until the end of their next turn. If you hit the same target with your Frostforged Weapon in two consecutive rounds, the target becomes frozen (restrained). The target remains frozen as long as it is hit by your weapon each round. The target can make a Strength saving throw at the end of each of its turns to break free from the frozen condition.
          </p>
        </div>

        <div className="content-block">
          <h2>Winter's Dominion</h2>
          <div className="level-indicator">Level 14th</div>
          <p>
            Once per long rest, as an action, you can invoke a powerful blizzard centered on yourself that extends in a 60-foot radius. This blizzard moves with you and lasts for 1 minute, creating heavily obscured and difficult terrain. It deafens all within its range and forces creatures to make a Constitution saving throw at the start of their turns; on a failed save, they take 4d6 cold damage (this damage bypasses cold resistance and immunity). At the start of the blizzard, choose a number of creatures up to your Charisma modifier to be unaffected by its effects.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={winterbornKnightImage} 
                alt="Winterborn Knight"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={winterbornKnightImage}
                download="WinterbornKnight.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesWinterbornKnight; 