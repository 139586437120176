import React, { useState, useRef, useEffect } from 'react';
import dronewrightImage from '../../assets/images/subclasses/Dronewright/dronewright.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesDronewright = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={dronewrightImage} 
          alt="Dronewright" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Dronewright</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Artificers who follow the path of the Dronewright are masters of integrating technology with magic. They craft specialized drones that serve as extensions of their abilities—enhancing their physical prowess, acting as formidable weapons, or providing versatile utility functions.
          </p>
        </div>

        <div className="content-block">
          <h2>Drone Specialization</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>At 3rd level, you choose a specialization for your drone. This choice determines the capabilities and progression of your drone:</p>
          <p>Path of the Exoskeleton (Body Enhancement Drone)</p>
          <p>Path of the Weaponized Drone</p>
          <p>Path of the Toolkit Drone</p>
        </div>

        {/* Path of the Exoskeleton */}
        <div className="path-section">
          <h2 className="path-title">Path of the Exoskeleton</h2>
          
          <div className="content-block">
            <h2>Path of the Exoskeleton</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>Your drone integrates with your body, forming an exoskeleton that enhances your physical capabilities...</p>
          </div>

          <div className="content-block">
            <h2>Exoskeleton Activation</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>
              As an action, you activate your body enhancement drone, which assembles around you to form an exoskeleton. The exoskeleton remains active for 1 hour per level, after which it requires a long rest to recharge.
            </p>
            <p>Enhanced Armor: Your Armor Class (AC) becomes 13 + your Intelligence modifier + your proficiency bonus (you can still benefit from a shield).</p>
            <p>Augmented Strength: You gain advantage on Strength checks and Strength saving throws.</p>
            <p>Powered Movement: Your walking speed increases by 10 feet.</p>
            <p>Integrated Weaponry: You can use your Intelligence modifier instead of Strength or Dexterity for attack and damage rolls with unarmed strikes and melee weapons.</p>
          </div>

          <div className="content-block">
            <h2>Exoskeleton Upgrades</h2>
            <div className="level-indicator">Level 5th</div>
            <p>Choose one of the following upgrades for your exoskeleton:</p>
            <p>Flight Module: You integrate thrusters into your exoskeleton, granting you a flying speed of 30 feet for up to 10 minutes (usable in 1-minute increments). You regain this ability after a short or long rest.</p>
            <p>Energy Shielding: Your exoskeleton is equipped with energy shields, granting you resistance to one of the following: acid, cold, fire, lightning, or thunder damage. You can change the selected resistance during a long rest.</p>
            <p>Enhanced Mobility: Difficult terrain doesn't cost you extra movement, and you can take the Dash action as a bonus action.</p>
          </div>

          <div className="content-block">
            <h2>Adaptive Systems</h2>
            <div className="level-indicator">Level 9th</div>
            <ul>
              <li>Adaptive Defense: Use your reaction to gain resistance to damage from a single source (limited uses per long rest equal to your Intelligence modifier, minimum of once).</li>
              <li>Overdrive Mode: Activate Overdrive Mode for 1 minute, gaining an additional action each turn that can be used for the Attack (one weapon attack only), Dash, Disengage, Hide, or Use an Object action. Your movement speed also increases by an additional 10 feet. Once used, you cannot activate Overdrive Mode again until you finish a long rest. After Overdrive Mode ends, you must succeed on a DC 15 Constitution saving throw or gain one level of exhaustion.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Integrated Arsenal</h2>
            <div className="level-indicator">Level 15th</div>
            <ul>
              <li>Extra Attack: Attack twice, instead of once, whenever you take the Attack action on your turn.</li>
              <li>Energy Blast: Gain a ranged spell attack dealing 2d10 force damage. You can use this feature a number of times equal to your Intelligence modifier (minimum of once), and you regain all expended uses after a short or long rest.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Masterful Integration</h2>
            <div className="level-indicator">Level 18th</div>
            <ul>
              <li>Permanent Integration: Your exoskeleton is now permanently integrated, and its benefits are always active without the need for activation.</li>
              <li>Superior Defenses: Gain resistance to bludgeoning, piercing, and slashing damage from nonmagical weapons.</li>
              <li>Ultimate Overdrive: When you activate Overdrive Mode, you also gain immunity to one damage type of your choice (acid, cold, fire, lightning, or thunder) for the duration. Once used, you cannot use Overdrive Mode again until you finish a long rest. After it ends, you suffer two levels of exhaustion.</li>
            </ul>
          </div>
        </div>

        {/* Path of the Weaponized Drone */}
        <div className="path-section">
          <h2 className="path-title">Path of the Weaponized Drone</h2>
          
          <div className="content-block">
            <h2>Path of the Weaponized Drone</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>
              You have crafted a drone that transforms into powerful weaponry, either ranged or melee, channeling energy to defeat your foes.
            </p>
          </div>

          <div className="content-block">
            <h2>Weaponized Drone Creation</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>
              Choose between Ranged Weapon Form (60 ft, 1d10 energy damage) or Melee Weapon Form (1d10 energy damage, 5 ft). The drone deals energy damage that bypasses resistance to nonmagical weapons. The drone acts as a bonus action to either make an attack or give you advantage on your next attack.
            </p>
          </div>

          <div className="content-block">
            <h2>Energy Discharge</h2>
            <div className="level-indicator">Level 5th</div>
            <p>Ranged Discharge: You can channel your weaponized drone's energy into a powerful ranged blast. As an action, make a ranged attack dealing 2d6 energy damage. If it hits, you can apply a special effect of your choice (such as pushing the target or blinding it for a turn).</p>
            <p>Melee Discharge: As a bonus action after making a melee attack, release a burst of energy in a 5-foot radius, forcing creatures within range to make a Dexterity saving throw or take 1d6 energy damage.</p>
          </div>

          <div className="content-block">
            <h2>Reconfigurable Assault</h2>
            <div className="level-indicator">Level 9th</div>
            <p>Dual Mode: The drone can switch between Ranged or Melee Weapon Form as a bonus action.</p>
            <p>Energy Surge: When you use your action to make an attack, you can choose to deal an additional 2d6 damage of the same type as the weapon's damage.</p>
          </div>

          <div className="content-block">
            <h2>Lethal Precision</h2>
            <div className="level-indicator">Level 15th</div>
            <p>Critical Strikes: When you hit a creature with your weaponized drone, you can choose to score a critical hit.</p>
            <p>Guided Blast: Your ranged attacks ignore resistance to energy damage.</p>
          </div>

          <div className="content-block">
            <h2>Tactical Overload</h2>
            <div className="level-indicator">Level 18th</div>
            <p>Unstoppable Assault: You can activate Tactical Overload as a bonus action, increasing the damage of your weaponized drone attacks by 1d10 for the next 1 minute. Once you use this feature, you cannot do so again until you finish a long rest.</p>
            <p>Full Recalibration: Once per long rest, you can recalibrate your drone to provide either healing (restoring 3d10 HP to you) or energy (gaining 1d4 additional spell slots of 1st level).</p>
          </div>
        </div>

        {/* Path of the Toolkit Drone */}
        <div className="path-section">
          <h2 className="path-title">Path of the Toolkit Drone</h2>
          
          <div className="content-block">
            <h2>Path of the Toolkit Drone</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>
              You have crafted a drone designed for utility, providing support to your allies, repairing and enhancing their capabilities, and adapting to any situation.
            </p>
          </div>

          <div className="content-block">
            <h2>Toolkit Drone Creation</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>Choose from 3 tools your drone can provide:</p>
            <p>Healing Module: Allows the drone to heal a target for 1d8 + your Intelligence modifier as an action.</p>
            <p>Utility Module: The drone grants you advantage on checks related to tool use or crafting.</p>
            <p>Protective Module: The drone grants you or an ally a +2 bonus to AC for 1 minute (usable once per long rest).</p>
          </div>

          <div className="content-block">
            <h2>Adaptive Assistance</h2>
            <div className="level-indicator">Level 5th</div>
            <p>Enhanced Healing: Your drone's Healing Module can now heal 2d8 + Intelligence modifier.</p>
            <p>Sustaining Shields: Your drone can activate a temporary shield that grants 10 temporary hit points to an ally within 30 feet.</p>
          </div>

          <div className="content-block">
            <h2>Field Deployment</h2>
            <div className="level-indicator">Level 9th</div>
            <p>Rapid Repair: You can use your bonus action to repair a damaged object or ally (restores 1d10 + Intelligence modifier to an ally, or restores an item to full working order).</p>
            <p>Resourceful Burst: The drone can provide an emergency spell-like ability, casting aid once per day without expending a spell slot.</p>
          </div>

          <div className="content-block">
            <h2>Superior Tools</h2>
            <div className="level-indicator">Level 15th</div>
            <p>Masterwork Tools: You can use the drone to grant yourself or an ally advantage on any ability check involving tools or repairs.</p>
            <p>Recharge Mechanism: The drone can recharge one expended spell slot of 3rd level or lower per day.</p>
          </div>

          <div className="content-block">
            <h2>Master Technician</h2>
            <div className="level-indicator">Level 18th</div>
            <p>
              Multi-Function Support: The drone can use its modules for two different functions simultaneously (e.g., Healing and Protecting). Once per day, the drone can restore full hit points to one target within 30 feet.
            </p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={dronewrightImage} 
                alt="Dronewright"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={dronewrightImage}
                download="Dronewright.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesDronewright; 