import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import sacrathorImage from '../../assets/images/deities/Sacrathor.jpg';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SacrathorDominion = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={sacrathorImage} 
          alt="Sacrathor: Dominion Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Sacrathor: The Path of Dominion</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/nebula/mistwalker" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Nebula: Mistwalker
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="race-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Establish Humanity's dominance over all other races, guiding them to assert their rightful place as the unchallenged rulers of the Shattered Realm.</li>
              <li>Embrace and utilize technology as a tool of domination, wielding its power to forge a new era of prosperity and order under Humanity's unyielding rule.</li>
              <li>Inspire unwavering loyalty and obedience among Humanity's factions, uniting them under a single banner to achieve their shared destiny of absolute dominion.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>Must be of the Humanity race.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <p>
            To walk the Path of Dominion is to claim humanity's rightful place as the pinnacle of creation. Through a fusion of advanced technology and commanding charisma, you reshape the world in your image. Sacrathor's chosen stand as the vanguard of human evolution, radiating authority that inspires devotion and demands submission. With nanite miracles and the sheer force of their will, they forge a new era where humanity reigns supreme.
          </p>
        </div>

        <div className="content-block">
          <h2>Sacrathor: The Architect of Perfection</h2>
          <p>
            Sacrathor, the god of divine authority and human perfection, exemplifies the seamless union of advanced technology and commanding charisma. His domain is a gleaming metropolis where every surface radiates his influence and every innovation showcases his unmatched brilliance. To his followers, Sacrathor represents the pinnacle of human achievement – a flawless synthesis of brilliance, charm, and technological advancement.
            His presence is both awe-inspiring and daunting. Where Sacrathor treads, the air hums with the power of nanites, and his every word carries the weight of undeniable truth. His followers strive to emulate his perfection, reshaping themselves and the world in his image, becoming instruments of a new age where humanity transcends all limitations and compels all others to kneel.
          </p>
        </div>

        <div className="content-block">
          <h2>Sacrathor's Domain</h2>
          <p>
            Sacrathor's domain is a dazzling metropolis of technological marvels. Towers of glass and steel ascend to impossible heights, their surfaces alive with glowing nanite circuitry that pulses like the lifeblood of the city. The streets below hum with energy, a seamless blend of human ingenuity and divine innovation, where every detail speaks to the perfection of Sacrathor's vision. Every facet of this domain reflects Sacrathor's genius, from the automated systems that ensure its flawless operation to the omnipresent holographic displays that broadcast his image.
            The very air is alive with nanotechnology, a constant reminder of Sacrathor's vigilance. Perfection is the unwavering standard, and any deviation from the established order is swiftly rectified. Within this domain, Sacrathor's chosen are exalted as paragons of humanity, their every action a testament to their divine purpose.
          </p>
        </div>

        <div className="content-block">
          <h2>Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Nanite Presence</h3>
            </div>
            <p>
              Swarms of microscopic nanites embedded in your body perform subtle but transformative functions. These ever-present particles enhance your presence and reinforce your superiority. In moments of diplomacy, the nanites adjust the light around you to cast a regal glow. In times of action, they project an aura of unshakable dominance, ensuring every gaze is drawn to your magnificence.
            </p>
            <ul className="specializations-list">
              <li>Your appearance is always immaculate, with attire and grooming maintained to perfection.</li>
              <li>Your form radiates an aura of unshakable authority, adorned with glowing insignias and regal enhancements that seem to emanate effortlessly.</li>
              <li>Minor effects mimic the capabilities of Prestidigitation, allowing you to clean, heat, cool, or create sensory enhancements at will.</li>
              <li>Effect: Gain a +2 bonus to Charisma as a manifestation of your enhanced presence.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Deceptive Radiance</h3>
            </div>
            <p>
              Once per long rest, you can project an overwhelming aura of divine light within a 30-foot radius for 1 minute. The light radiates from your form, casting you as a figure of ultimate authority.
              Creatures within the radius must succeed on a Wisdom saving throw (DC = 8 + your Charisma modifier + your proficiency bonus) or become charmed for the duration.
              Charmed creatures view you as a divinely sanctioned leader and are compelled to follow your commands, provided they do not cause direct harm to themselves.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Enforced Obedience</h3>
            </div>
            <p>
              As a reaction, your nanites swarm over a creature that defies your will, creating a visible shimmer of divine technology across their form.
              The creature must succeed on a Wisdom saving throw (DC = 8 + your Charisma modifier + your proficiency bonus) or be compelled to obey your command for 1 hour per level.
              This effect enforces absolute obedience and creates a sensory impression of your authority. Usable a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying the Path of Sacrathor</h2>
          <p>
            Followers of Sacrathor are icons of human excellence and technological progress. They move through the world with an air of absolute confidence, embodying innovation and unrelenting ambition. Each interaction reinforces their dominion, whether through their commanding presence or the seamless integration of technology into their very being. Their presence is magnetic, their authority unshakable, and their every action a testament to the belief that humanity is destined to dominate.
            Choosing this path is a declaration of supremacy. Practitioners of the Path of Sacrathor do not request obedience; they command it. They do not negotiate; they dictate. With nanotechnology enhancing their every step and charisma that reshapes the perceptions of all who meet their gaze, they are the architects of a new world order.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={sacrathorImage} 
              alt="Sacrathor: Dominion Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={sacrathorImage}
              download="Sacrathor.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default SacrathorDominion;