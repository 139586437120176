import React, { useState, useRef, useEffect } from 'react';
import lifedrinkerImage from '../../assets/images/subclasses/Lifedrinker/wayofthelifedrinker.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesWayOfTheLifedrinker = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={lifedrinkerImage} 
          alt="Way of the Lifedrinker" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Way of the Lifedrinker</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Monks who follow the Way of the Lifedrinker have learned to manipulate Ki, the vital energy that flows through all living beings. They use this power to defend themselves, disrupt their enemies, and draw vitality from others to sustain their own strength. Though some might find their methods unsettling, Lifedrinkers embrace their role as a force of balance, understanding that all life is part of an intricate energy exchange.
          </p>
        </div>

        <div className="content-block">
          <h2>Essence Drain</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>
            You have learned to drain the Ki from other beings to revitalize yourself. As a bonus action, you can expend 1 Ki point to make a special melee strike against a creature. On a hit, you deal damage equal to your martial arts die and regain hit points equal to the damage dealt. Additionally, choose one of the following effects:
          </p>
          <p>Ki Disrupt: The target has disadvantage on attack rolls until the start of your next turn.</p>
          <p>Weakened Spirit: The target's movement speed is reduced by 10 feet until the start of your next turn.</p>
        </div>

        <div className="content-block">
          <h2>Disrupting Strike</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>
            As a bonus action, you can expend 1 Ki point to unleash a blast of psychic force from your palm. Choose a target within 10 feet of you. The target must succeed on a Strength saving throw (DC = 8 + your proficiency bonus + your Wisdom modifier) or take psychic damage equal to your martial arts die and be knocked prone. The range of this ability increases as you gain levels in this class:
          </p>
          <p>3rd-4th Level: 10 ft</p>
          <p>5th-7th Level: 15 ft</p>
          <p>8th-11th Level: 20 ft</p>
          <p>12th-14th Level: 30 ft</p>
          <p>15th-17th Level: 40 ft</p>
          <p>18th-19th Level: 50 ft</p>
          <p>20th Level: 60 ft</p>
        </div>

        <div className="content-block">
          <h2>Ethereal Ward</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            You can expend 2 Ki points as a bonus action to envelop yourself in a protective aura of Ki for 1 minute. While the ward is active, you gain the following benefits:
          </p>
          <p>- Resistance to one of the following damage types of your choice: bludgeoning, piercing, slashing, fire, cold, acid, lightning, thunder, poison, or psychic.</p>
          <p>- A +2 bonus to AC.</p>
          <p>Lifeforce Shield: When you activate Ethereal Ward, you gain temporary hit points equal to your Wisdom modifier plus half your monk level.</p>
        </div>

        <div className="content-block">
          <h2>Ki Feedback</h2>
          <div className="level-indicator">Level 11th</div>
          <p>
            When you are hit by a melee attack, you can use your reaction to expend 1 Ki point to deal psychic damage to the attacker equal to your martial arts die plus your Wisdom modifier. The attacker must also make a Constitution saving throw (DC = 8 + your proficiency bonus + your Wisdom modifier). On a failure, the attacker has disadvantage on its next attack roll. This represents your ability to disrupt an opponent's Ki at the moment of contact, making their next strike less effective.
          </p>
        </div>

        <div className="content-block">
          <h2>Lifeforce Mimicry</h2>
          <div className="level-indicator">Level 17th</div>
          <p>
            When a creature within 5 feet of you uses a spell, special ability, or attack, you can use your reaction to make a special melee attack against that creature. On a successful hit, you deal necrotic damage equal to your martial arts die and drain part of the creature's life force or Ki. For 1 minute, you may replicate the ability you interrupted, but at half its original power or effectiveness. You must have the appropriate action type available (e.g., action, bonus action, reaction) to use the copied effect. This ability can be used a number of times equal to your Wisdom modifier per long rest.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={lifedrinkerImage} 
                alt="Way of the Lifedrinker"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={lifedrinkerImage}
                download="WayOfTheLifedrinker.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesWayOfTheLifedrinker; 