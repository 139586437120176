import React, { useState, useEffect, useRef } from 'react';
import queenMabImage from '../../assets/images/deities/queenmab.png';
import './PantheonPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const PantheonQueenMab = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img
          src={queenMabImage}
          alt="Queen Mab: The Frost Monarch"
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
        />
        <div className="pantheon-title">
          <h1>Queen Mab: The Frost Monarch</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/Pantheon" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCaelumbris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris
                </Link>
                <Link to="/deities/PantheonCalanthar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Calanthar
                </Link>
                <Link to="/deities/PantheonFirstborn" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonNebula" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Nebula
                </Link>
                <Link to="/deities/PantheonQueenTitania" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania
                </Link>
                <Link to="/deities/PantheonSacrathor" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor
                </Link>
                <Link to="/deities/PantheonValdis" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Valdís
                </Link>
                <Link to="/deities/PantheonWildscar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Wildscar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            Queen Mab and her twin sister Titania once ruled a vibrant, living world as the Winter Queen and the Summer Queen, embodiments of night and day, frost and growth, balance and life. Together, they maintained the delicate equilibrium that sustained their planet. Mab's domain covered the lands of winter, where the world could rest and renew. Titania ruled the lands of eternal summer, where warmth and light nurtured growth and prosperity.
          </p>
        </div>

        <div className="content-block">
          <h2>Origins and Mythos</h2>
          <p>
            For centuries, their partnership was harmonious. Each respected the other's power and purpose, and their people thrived under the balance of light and dark, warmth and cold. Winter culled excess and prepared the earth for rebirth, while summer brought abundance and growth. This cycle was the lifeblood of their world. But as ages passed, the people grew enamored with the beauty and bounty of Titania's realm. Winter, with its harsh truths and unyielding cold, became a symbol of cruelty and stagnation in their eyes. Titania, intoxicated by the adoration of her people, began to expand her lands into Mab's icy territory. Her summer fields encroached on winter's borders, upsetting the balance. Mab tried to reason with her sister, reminding her of their shared purpose and the consequences of unchecked growth. Titania dismissed her warnings, calling her cold-hearted and jealous. As the endless summer consumed more of the planet, winter retreated, weakening Mab's power. The once-thriving world began to suffocate under the unchecked weight of growth and life. Crops failed in the unending heat, and ecosystems collapsed without the reprieve of winter.
          </p>
        </div>

        <div className="content-block">
          <h2>The Shattering and Mab's Transformation</h2>
          <p>
            In desperation, Mab attempted to halt Titania's encroachment through force. She sent storms to reclaim her lands and brought glacial winds to freeze the creeping summer. But her efforts only deepened the people's hatred for her. To them, she was an aggressor, a villain disrupting their comfort. Her sister stoked these sentiments, branding Mab as the antagonist who sought to destroy their paradise. Eventally, the world teetered on the brink of destruction. With no winter to temper the relentless growth of summer, the planet was choking to death under an endless cycle of unchecked life. Unable to stop her sister and reviled by the people she sought to save, Mab withdrew to the shadows, her heart heavy with the knowledge that the balance they were meant to preserve had been broken beyond repair. The death of their planet came swiftly. Mab's last-ditch efforts to restore balance collided with Titania's unchecked power, creating a cascade of magical energy that flung them into the Shattering Universes. Both sisters were fundamentally changed by the destruction of their world. For Mab, the loss tempered her already cold resolve. She became the Frost Monarch, a being of icy determination and unrelenting duty, swearing never to let another world suffer the fate of her own. Mab claimed a mountainous region in the Shattered Realm, where three peaks rise like frozen sentinels above an endless tundra. From this domain, she began her role as the Guardian of Balance, a mantle she took upon herself to ensure that no force—whether of growth, decay, chaos, or stagnation—could ever again upset the natural cycles of life.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality and Influence</h2>
          <p>
            Mab's personality is shaped by the tragedy of her past. She is pragmatic and unyielding, driven by a sense of duty rather than emotion. She understands the necessity of balance, even when it demands harsh choices. Her followers revere her for her wisdom and resilience, seeing her as a figure who protects the greater good, even at great personal cost. Despite her cold demeanor, Mab is not without empathy. She carries the weight of her world's destruction as a constant reminder of her mission. To those who seek her counsel, she offers guidance grounded in reality, teaching that survival requires both strength and understanding of the natural cycles. Her influence spreads through subtle means. Where Titania commands with radiance and charm, Mab weaves her power through whispers and shadows, guiding events from afar. Her realm is a reflection of her personality—beautiful, harsh, and unyielding, a place of frozen truths and silent strength.
          </p>
        </div>

        <div className="content-block">
          <h2>Worship and Clergy</h2>
          <p>
            Mab's followers are drawn to her through a shared appreciation for discipline, strategy, and the acceptance of life's harsher realities. Her clergy consists of scholars, strategists, and those who seek to uncover hidden truths. They value balance above all and see the necessity of both creation and destruction in the cycle of life. Her rituals involve invoking the cold winds and shadows, symbolizing the necessity of rest and renewal. Offerings of frozen flowers, reflective crystals, and intricate ice sculptures are made at her altars, each representing the beauty of impermanence and the strength found in adversity.
          </p>
        </div>

        <div className="content-block">
          <h2>Role as Guardian of Balance</h2>
          <p>
            As the Guardian of Balance, Mab serves as a counterweight to the forces of chaos. She ensures that no power—be it growth, decay, or destruction—dominates the Shattered Realm. Her strategies are long-term and deliberate, reflecting the precision of a chessmaster. Her methods are often misunderstood as ruthless, but she understands that balance is rarely achieved without sacrifice.
          </p>
        </div>

        <div className="content-block">
          <h2>Powers and Abilities</h2>
          <p>
            Queen Mab's abilities reflect her mastery over ice, shadow, and the cycles of nature: Cryomancy: She wields ice and frost with unparalleled control, shaping storms, creating barriers, and freezing time itself in brief bursts. Shadow Manipulation: Mab can cloak her actions in secrecy, bend shadows to her will, and expose hidden truths. Temporal Frost: Her icy magic slows time in localized areas, giving her an edge in both battle and strategy. Unyielding Presence: Mab's diadem protects her mind and amplifies her will, making her immune to influence and deception.
          </p>
        </div>

        <div className="content-block">
          <p>
            Mab stands as a reminder that winter, though harsh, is necessary for the world's survival. Her story is a cautionary tale of imbalance, a legacy of resilience, and a testament to the enduring strength of those who bear the burden of maintaining order.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Frost Monarch, Winter Witch, Keeper of the Long Night</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Ice, Darkness, Strategy, Balance</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>Unseelie Court</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A crown entirely encased in ice</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={queenMabImage} 
                alt="Queen Mab: The Frost Monarch"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={queenMabImage}
                download="QueenMab.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonQueenMab; 