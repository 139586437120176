import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Winter Witch/winterwitch.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesWinterWitch = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Winter Witch" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Winter Witch</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>              
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Winter Witches first appeared during the first winter solstice after the Shattering, a time when a devastating winter storm swept across the Shattered Realm. This storm traveled with the changing seasons, leaving no part of the realm untouched. It brought icy destruction to lush lands and blanketed even the warmest regions in frost. In the coldest and harshest reaches, this unnatural storm gave birth to new beings—the Winter Witches. Emerging from the darkest caves and endless tundra, they were both natural protectors and predators, shaped by the storm's relentless power.
          </p>
        </div>

        <div className="content-block">
          <p>
            At first, Winter Witches lived as hermits, guarding frozen regions and ensuring the balance of winter's grip. Over time, their influence began to spread, their tendrils of frost reaching across the realm. Now, no winter or icy region has been untouched by the presence of a Winter Witch. Whether protecting glacial fortresses or preserving the sanctity of desolate tundras, they see themselves as stewards of the cold, ensuring winter's icy tendrils remain deeply embedded in the Shattered Realm.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <p>
            Winter Witches exude an unnerving aura, marked by cold, calculating beauty. Their movements are precise, and their presence evokes the stillness of a winter storm—a calm that hides potent power.
          </p>
          <p>
            Their skin is unnaturally pale, often with grey or blue undertones that highlight their unyielding connection to the cold. Subtle markings, resembling cracked ice or frostbite scars, shimmer as though a thin layer of frost covers their flesh. Their touch is tough and icy, far from delicate.
          </p>
          <p>
            Their hair is a tangled mass, appearing more like dark, frozen vines than typical strands. It writhes and twists with an eerie vitality, crackling faintly with blue energy. Colors range from ashen whites and icy blues to deep purples and midnight blacks. It is unkempt and unruly, its use valued over its appearance.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Basic Characteristics</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Ability Score Increase: Choose one ability score to increase by 2, and increase a different one by 1; or increase three different ability scores by 1.</p>
            <p>Age: Winter Witches mature at a slower pace than humans, reaching adulthood around the age of 25 and living for approximately 200 years.</p>
            <p>Alignment: They tend towards lawful neutral, driven by a need for order and strategy above all else.</p>
            <p>Size: Medium. Winter Witches vary in height and build, but most are between 5 feet and 6 feet tall.</p>
            <p>Speed: Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frigid Assault</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Your connection to the cold allows you to wield it with precision. You know the ray of frost cantrip. The range of this ability is 120 feet. After casting the spell, you can choose for the damage type to be piercing or cold.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frigid Assault Improvement</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>You can cast the frostbite spell once with this trait and regain the ability to do so after a long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frigid Assault Mastery</h3>
              <span className="level-tag">Level 5th</span>
            </div>
            <p>When you score a critical hit with this ability or the ice knife spell, the target's movement speed is reduced to 0 until the end of their next turn.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Prehensile Hair Mastery</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Your hair acts as an extension of your will. You can manipulate objects, open doors, or disarm traps with your hair as if using the mage hand cantrip. Your hair has a reach of 15 feet and can carry up to 5 pounds. As a bonus action, you can use your hair to make a number of melee spell attacks equal to your proficiency bonus against different targets, using your spellcasting modifier for attack and damage rolls. These attacks deal 1d4 cold damage on a hit. As an action, you can use your hair as if casting mage hand.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Prehensile Hair Mastery Improvement</h3>
              <span className="level-tag">Level 8th</span>
            </div>
            <p>Your hair attacks now deal an additional 1d4 necrotic damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Winter's Resilience</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You are resistant to cold damage. You are vulnerable to: fire. As a reaction, when you take damage, you can emit a blast of freezing air, inflicting your level in cold damage on the attacker and reducing their movement speed by 10 feet until the end of their next turn. You may use this reaction a number of times equal to your proficiency bonus per long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frostbound Perception</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You have proficiency in the Insight skill. Additionally, you have advantage on Wisdom (Insight) checks that rely on logic, pattern recognition, or inconsistencies in social interactions.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You can speak, read, and write Common, Sylvan, and one other language of your choice.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying Notes</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Solitary Pragmatists</h3>
            </div>
            <p>Winter Witches often lead lives of cold, quiet pragmatism, focusing on strategic planning and self-sufficiency. They have little interest in frivolous social interactions, prioritizing the task at hand.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Seekers of Hidden Truths</h3>
            </div>
            <p>Drawn to ancient ruins and forgotten libraries, they pursue forbidden knowledge, unraveling the intricate webs of power and manipulation.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Guardians of the Cycle</h3>
            </div>
            <p>Winter Witches believe in the necessity of decay and death as a natural part of life's cycle and will uphold this reality by any means necessary.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Practical Familiars</h3>
            </div>
            <p>Preferring utility over comfort, they bond with strategic creatures such as ravens, snow leopards, or ice mephits.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Winter Witch"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="WinterWitch.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesWinterWitch; 