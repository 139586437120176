import React, { useState, useEffect, useRef } from 'react';
import sacrathorImage from '../../assets/images/deities/Sacrathor.jpg';
import './PantheonPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const PantheonSacrathor = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img
          src={sacrathorImage}
          alt="Sacrathor, The Gilded Sovereign"
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
        />
        <div className="pantheon-title">
          <h1>Sacrathor: The Gilded Sovereign</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/Pantheon" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCaelumbris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris
                </Link>
                <Link to="/deities/PantheonCalanthar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Calanthar
                </Link>
                <Link to="/deities/PantheonFirstborn" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonQueenMab" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab
                </Link>
                <Link to="/deities/PantheonQueenTitania" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania
                </Link>
                <Link to="/deities/PantheonValdis" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Valdís
                </Link>
                <Link to="/deities/PantheonWildscar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Wildscar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            Sacrathor is revered by humanity within the Shattered Realm as the architect of their ascension. He is seen as the divine force behind their unprecedented technological advancements, promising order and prosperity in a chaotic world. Clad in magnificent robes that radiate an aura of divine authority, he preaches a doctrine of unwavering discipline, absolute obedience, and fervent loyalty. His opulent temples serve as both places of worship and centers of technological power, showcasing humanity's achievements.
          </p>
        </div>

        <div className="content-block">
          <h2>Description</h2>
          <p>
            Sacrathor's avatar is a fusion of ancient religious iconography and cutting-edge technology. Metallic robes with a complex, color-shifting hexagram pattern suggest his boundless intellect. A fluid, metallic crown, incorporating a mechanical visor, obscures his features, lending him an air of both authority and mystery. Glowing blue eyes, radiating an intense, almost unnerving light, fix upon his followers. His synthetic skin, seamlessly interwoven with circuitry, hints at his technological nature, and his voice resonates with the harmonic hum of a thousand perfectly calibrated machines. He inspires both awe and fear, demanding unquestioning devotion and enforcing his divine law with ruthless efficiency. He has led humanity to heights previously unimaginable, but the true cost of this progress, and the ultimate destination of his grand design, remains shrouded in the gleaming brilliance of his gilded presence.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Gilded Sovereign</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Technology, Law, War</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>Humanity</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A golden sun with a serpent coiled around it.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={sacrathorImage} 
                alt="Sacrathor, The Gilded Sovereign"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={sacrathorImage}
                download="Sacrathor.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonSacrathor; 