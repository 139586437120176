import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Seraphiend/seraphiend.png';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesSeraphiend = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Seraphiend" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Seraphiend</h1>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            The Seraphiends emerged in the aftermath of the Shattering, a cataclysmic event that fractured reality and scattered its fragments across countless realms. In this chaos, celestial and infernal forces collided, and from their union arose the Seraphiends. Forged by the deity Valdís, a being of balance and justice, they were created as emissaries of her will to embody the duality of light and darkness.
          </p>
        </div>

        <div className="content-block">
          <p>
            Each Seraphiend bore the mark of their creator—a form that reflected the beauty and terror of the realms that birthed them. Their horns gleamed like molten metal, their halos shone faintly above them, and their wings spanned wide with otherworldly grace. They descended from the cracks between the planes, stepping into a fractured world as arbiters, protectors, and symbols of a tenuous harmony.
          </p>
        </div>

        <div className="content-block">
          <p>
            For fifty years, they moved among mortals, their presence inspiring awe and fear in equal measure. Their words carried the weight of truth, for deception was a trait denied to them. Yet, their actions spoke louder than their voices—restoring what was broken, defending the weak, and guiding mortals toward a fragile peace.
          </p>
        </div>

        <div className="content-block">
          <p>
            Then, on the fiftieth anniversary of the Shattering, all the progenitor Seraphiends vanished. They walked back through the same celestial rifts that had delivered them to the world, leaving only their descendants, bearing faint echoes of their splendor, to carry their story forward.
          </p>
        </div>

        <div className="content-block">
          <h2>Description</h2>
          <p>
            Seraphiends are beings of striking contrast and otherworldly presence, their features both captivating and intimidating. Their skin ranges from radiant metallic hues of gold and silver to deep, shadowed tones of ashen gray and obsidian, often veined with a subtle luminescence that flickers like distant starlight. Their eyes burn with an inner fire—some glowing like molten metal, others shimmering like the edge of a storm cloud.
          </p>
        </div>

        <div className="content-block">
          <p>
            Crowning their heads are horns, unique in every individual, that arc in graceful curves or jagged spires. These horns gleam with metallic tones, from bright, reflective golds and silvers to the dark luster of bronze or iron. Floating just above their horns is a soft halo of light, its glow casting faint shadows across their features.
          </p>
        </div>

        <div className="content-block">
          <p>
            Their wings, powerful and commanding, stretch wide and take many forms. Some are draped in feathers that catch the light like polished steel, while others are sleek and leathery, textured like the hide of ancient beasts. Whether in repose or spread in motion, their wings create an imposing silhouette that lingers in the memory of all who see them.
          </p>
        </div>

        <div className="content-block">
          <h2>Physical Characteristics</h2>
          <p>
            Height: 5'8" to 7'2"<br/>
            Weight: 140–280 lbs<br/>
            Lifespan: Immortal, but they can be slain
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase one ability score by 2 and another by 1, or increase three different ability scores by 1 each.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Seraphiends are Medium or Small, chosen when you create your character.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flight</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You have a flying speed of 20 feet. To use this speed, you must not be carrying more than a light load as determined by your Strength score.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You can speak, read, and write Common, Celestial, and Infernal.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darklight Vision</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You can see in magical and non-magical darkness within 120 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Celestial Infernal Balance</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>
              As a bonus action, you can channel either your celestial or infernal essence. When you do so, choose one of the following effects:
              <br/><br/>
              <strong>Celestial Form:</strong> Radiates a healing aura. Allies within 10 feet regain hit points equal to 1d8 + your Charisma modifier at the start of their turn for a number of rounds equal to your level.
              <br/><br/>
              <strong>Infernal Form:</strong> Exudes a terrifying aura. Enemies within 30 feet must make a Wisdom saving throw (DC 8 + your Charisma modifier + your proficiency bonus) or be frightened for 1 minute.
              <br/><br/>
              You can use this ability a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Heavenly Wrath</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>
              As a bonus action, you can invoke divine or infernal energy to unleash powerful effects. Choose one of the following effects:
              <br/><br/>
              <strong>Celestial Form:</strong> Radiant energy surrounds you, granting allies within 10 feet the effects of the Sanctuary and Protection from Good and Evil spells for 1 minute or until they attack or take actions seen as hostile.
              <br/><br/>
              <strong>Infernal Form:</strong> A 10-foot diameter pillar of white flames erupts at a point you can see within 30 feet. The pillar lasts for a number of rounds equal to your level and requires concentration to maintain. As a bonus action, you can move the pillar up to 30 feet to a point you can see within 60 feet. Creatures within the pillar must make a Dexterity saving throw (DC = 8 + your Charisma modifier + your proficiency bonus). On a failure, they take 4d6 fire damage; on a success, they take half damage. Creatures that start their turn in the pillar or enter it for the first time during their turn must repeat the saving throw.
              <br/><br/>
              You can use this ability a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bound by Duality</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You have resistance to radiant and fire damage, but you are vulnerable to necrotic damage. This vulnerability reflects the corruption inherent in necrotic energy, which disrupts the delicate celestial-infernal balance sustaining your form.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Celestial Honesty</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You are incapable of lying or deceiving. This trait is intrinsic to your nature, and any attempts at deception automatically fail. This restriction also extends to indirect deception, such as omitting truths or making intentionally misleading statements.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Skill Proficiencies</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You are proficient in the Persuasion and Intimidation skills.</p>
          </div>
        </div>

        <div className="menu-container">
          <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Species
              <ChevronDown size={20} />
            </h2>
            <div className="speciespage-links">
              <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                ← Return to Species              
              </Link>
              <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Gravewalker
              </Link>
              <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Humanity
              </Link>
              <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Lifedrinker
              </Link>
              <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Manaforged
              </Link>
              <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Scytherai
              </Link>
              <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Starborn
              </Link>
              <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Vermis Corpus
              </Link>
              <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Sunseed
              </Link>
              <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Winter Witch
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Seraphiend"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Seraphiend.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesSeraphiend; 