import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Crown, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import './Pantheon.css';

const Pantheon = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const deityPaths = [
    { name: 'Caelumbris', path: '/deities/PantheonCaelumbris' },
    { name: 'Calanthar', path: '/deities/PantheonCalanthar' },
    { name: 'Firstborn', path: '/deities/PantheonFirstborn' },
    { name: 'L.I.B.R.I.S.', path: '/deities/PantheonLibris' },
    { name: 'Nebula', path: '/deities/PantheonNebula' },
    { name: 'Queen Mab', path: '/deities/PantheonQueenMab' },
    { name: 'Queen Titania', path: '/deities/PantheonQueen-Titania' },
    { name: 'Sacrathor', path: '/deities/PantheonSacrathor' },
    { name: 'Valdís', path: '/deities/PantheonValdis' },
    { name: 'Wildscar', path: '/deities/PantheonWildscar' }
  ];

  return (
    <div className="veiled-welcome" style={{ background: '#0a0a0a' }}>
      <motion.div 
        className="veiled-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Crown size={48} className="welcome-icon" style={{ color: '#4a9eff', marginBottom: '1rem' }} />
        <h1>The Veiled Divinity</h1>
        <div className="menu-container">
          <div className={`pantheons ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={() => setMenuOpen(!menuOpen)}>
              Pantheon
              <ChevronDown size={20} />
            </h2>
            <div className="pantheon-links">
              {deityPaths.map((deity, index) => (
                <Link 
                  key={index} 
                  to={deity.path}
                  className="pantheon-link"
                  onClick={() => setMenuOpen(false)}
                >
                  {deity.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="content-section">
          <p>
            The Shattering brought the end of the old gods, their eternal thrones shattered alongside the cosmos. In their absence, new deities emerged—some ancient entities awakened by the cataclysm, others mortals who ascended to godhood amidst the chaos. These divine beings are not the omnipotent rulers of old; instead, they wield their power to shape the fledgling realm, guiding its fractured fragments toward new destinies. Bound by their roles and driven by ambition, they use mortals as tools, champions, and pawns in their struggle to claim dominion over a realm rife with possibilities. The pantheon is young, their war for influence fresh, and their impact on the world profound. In this new age, divinity is not static—it is forged through action, alliances, and the indomitable will to rise.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Pantheon; 