import React from 'react';
import { motion } from 'framer-motion';
import manaforgedImage from '../../assets/images/races/Manaforged/manaforged.png';
import manaforgedData from '../../assets/data/manaforged.json';
import './RacePage.css';

const Manaforged = () => {
  return (
    <div className="race-page">
      <div className="race-hero">
        <img src={manaforgedImage} alt="Manaforged" className="race-background" />
        <div className="race-content">
          <h1>{manaforgedData.title}</h1>
          <p className="race-brief">{manaforgedData.brief}</p>
        </div>
      </div>

      <div className="race-details">
        {manaforgedData.content.map((section, index) => (
          <div key={index} className="content-section">
            <h2>{section.title}</h2>
            <p>{section.description}</p>
          </div>
        ))}

        <div className="features-section">
          {manaforgedData.features.map((feature, index) => (
            <div key={index} className="feature-group">
              {feature.traits && feature.traits.map((trait, idx) => (
                <div key={idx} className="trait">
                  <div className="trait-header">
                    <h3>{trait.title}</h3>
                    {trait.level && <span className="level">Level {trait.level}</span>}
                  </div>
                  <p>{trait.description}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Manaforged; 