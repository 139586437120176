import React, { useState, useRef, useEffect } from 'react';
import pathOfWrathImage from '../../assets/images/subclasses/Wrath/pathofwrath.png';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesPathOfWrath = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={pathOfWrathImage} 
          alt="Path of Wrath" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Path of Wrath</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Barbarians who walk the Path of Wrath are embodiments of untamed fury. They are consumed by a primal rage that transforms them into unstoppable forces of destruction. These barbarians often hail from war-torn societies or harsh environments where survival hinges on raw power and unwavering aggression. Some are even touched by the essence of Wildscar, the fickle and violent god of battle, their rage fueled by a divine spark of chaotic energy.
          </p>
        </div>

        <div className="content-block">
          <h2>Bonus Proficiencies</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>When you choose this path at 3rd level, you gain proficiency in Athletics and Intimidation.</p>
        </div>

        <div className="content-block">
          <h2>Fury of Wrath</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>Starting at 3rd level, your rage transforms you into a force of nature. However, to fully tap into the raw power of your rage, you must not be encumbered by heavy or medium armor. You gain the following benefits only while wearing light armor or no armor:</p>
          <p>Explosive Growth: You temporarily grow in size, enhancing your battlefield presence. At 3rd level, you grow to Medium size if you are smaller. At 6th level, you grow to Large size, and at 10th level, you grow to Huge size.</p>
          <p>Bloodfire Mist: While raging, you exude a swirling aura of blood-red mist. This aura initially surrounds your space. At 6th level, it expands to a 5-foot radius, and at 10th level, it expands to a 10-foot radius. While within this aura, you and your allies gain resistance to bludgeoning, piercing, and slashing damage. Additionally, you can choose one of the following damage types, gaining resistance to it:</p>
          <p>- 3rd level: Acid, cold, fire, lightning, or thunder</p>
          <p>- 6th level: Necrotic, poison, or radiant</p>
          <p>- 10th level: Force, psychic, or magical</p>
          <p>However, creatures within the aura also suffer a –1 penalty to AC, saving throws, and disadvantage on concentration checks. Additionally, whenever a creature within the aura attempts to cast a spell or perform an action requiring concentration, they must succeed on a concentration check with a DC of 8 + the spell level. Failure results in the spell or action failing, as the instinctual rage disrupts intellectual control.</p>
          <p>Unyielding Will: At 6th level, you gain immunity to the charmed and frightened conditions. At 10th level, you gain immunity to the paralyzed and stunned conditions.</p>
        </div>

        <div className="content-block">
          <h2>Wrath Unleashed</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            At 6th level, you can channel your inner fury into a destructive blast. Once per rage, when you hit a creature with a melee attack, you can choose to unleash a burst of energy. Each creature within 10 feet of you must make a Constitution saving throw (DC 8 + your proficiency bonus + your Constitution modifier). A creature takes 3d8 mixed damage (half fire, half bludgeoning, piercing, or slashing of your choice) on a failed save, or half as much on a successful one. This damage increases to 4d8 at 11th level and 5d8 at 16th level.
          </p>
        </div>

        <div className="content-block">
          <h2>Embodiment of Wrath</h2>
          <div className="level-indicator">Level 10th</div>
          <p>At 10th level, the essence of your rage flows through you even outside of battle. You gain the following benefits, even when not raging:</p>
          <p>- You have advantage on Strength checks and Strength saving throws.</p>
          <p>- When you make a melee weapon attack using Strength, you gain a bonus to the damage roll equal to +2. This increases to +3 at 14th level and +4 at 17th level.</p>
          <p>- You have resistance to bludgeoning, piercing, and slashing damage.</p>
        </div>

        <div className="content-block">
          <h2>Wrathful Incarnation</h2>
          <div className="level-indicator">Level 14th</div>
          <p>At 14th level, you can briefly become the avatar of pure wrath. As an action, you gain the following benefits for 1 minute:</p>
          <p>- You grow into a Huge creature, gaining the benefits of Explosive Growth if you are not already Huge.</p>
          <p>- Your unarmed strikes and melee weapon attacks deal an additional 2d6 fire damage.</p>
          <p>- You have advantage on all Strength checks and saving throws.</p>
          <p>- You are immune to all conditions except for exhaustion.</p>
          <p>Once you use this feature, you can't use it again until you finish a long rest.</p>
        </div>

        <div className="content-block">
          <h2>Avatar of Wildscar</h2>
          <div className="level-indicator">Level 20th</div>
          <p>Unstoppable Fury: While raging, you cannot be reduced to 0 hit points. If you take damage that would reduce you to 0 hit points, you automatically succeed on all death saving throws, and your rage ends.</p>
          <p>Wrathful Embodiment: Your Bloodfire Mist now expands to a 30-foot radius. The penalty incurred by creatures within this area increases to –2.</p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={pathOfWrathImage} 
                alt="Path of Wrath"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={pathOfWrathImage}
                download="PathOfWrath.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesPathOfWrath; 