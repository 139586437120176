import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Starborn/starborn.png';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesStarborn = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Starborn" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Starborn</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species              
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>s
                  Scytherai
                </Link>                            
                <Link to="/species/SpeciesSkinWalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Skinwalker
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Starborn are an ancient race hailing from a distant galaxy on the brink of collapse. Their elven heritage is evident in their graceful features and slender build, but their connection to the cosmos is intertwined with a unique, mana-infused nanogel technology. This nanogel is a substance that they strip-mined from their dying galaxy for centuries, viewing the life that came from the material as mere resources to fuel their society. Now, they desperately seek to reclaim that which they consider to be rightfully theirs from this fractured realm. Their pale skin and ethereal features are a result of the nanogel infusion, and they carry within them the very resource they now desperately seek. Their hair, typically shades of silver or white, flows like rivers of starlight, and their eyes, often a piercing blue, hold the depths of space within them. Their civilization, a marvel of technological and arcane brilliance, thrived on the edge of oblivion, but now desperately chases anything they believe may save it. They are a people born from the harmony of technology and magic, using these tools to extend their reach across the cosmos and preserve the power they believe to be rightfully theirs.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality</h2>
          <p>
            Starborn are contemplative, wise, and inherently arrogant, with a deep understanding of the universe's vastness and the fleeting nature of others' existence. They believe themselves to be superior to all other races. They are curious, seeking knowledge and understanding, not to be shared but to be added to their already vast libraries of cosmic and strategic knowledge. While they may appear diplomatic, they are fiercely protective of their heritage, their dwindling resources, and especially the nanogel they carry within them. They view others as tools, obstacles, or lesser beings.
          </p>
        </div>

        <div className="content-block">
          <h2>Society</h2>
          <p>
            Before the Shattering, the Starborn lived in a highly advanced, spacefaring society. They valued knowledge, exploration, and the preservation of their culture and resources – for themselves. Their society was structured around merit and expertise, with individuals contributing their unique talents to ensure the prosperity and expansion of their empire.
          </p>
        </div>

        <div className="content-block">
          <h2>Claim to the Moon</h2>
          <p>
            Upon arriving in the Shattered Realm, the Starborn claimed a moon as their own, naming it "All Father" - a symbol of their perceived authority and superiority. This base serves as their outpost, a staging ground for their operations, and a reminder of the empire they intend to rebuild. They send envoys to the planet's surface but rarely stay, considering the inhabitants beneath them. The planet's surface is seen as unworthy of their touch, and its inhabitants as dangerous and unpredictable, with few capable of understanding or respecting their ways. They have been known to wipe entire cities off the face of the planet from orbit if an envoy is insulted. Their first envoys to Velum met with insult. The night after they left, the "All Father" appeared to open its eyes, and rays of fiery white light descended, incinerating the kingdom that had offended them. Their ventures planet-side are solely in search of a being known as "Proto-Type Alpha" and any Manaforged, showing no interest in anything else.
          </p>
        </div>

        <div className="content-block">
          <h2>Physical Description</h2>
          <p><strong>Graceful Build:</strong> Starborn are typically slender and graceful, with an ethereal beauty. They are slightly taller than humans, averaging between 5'8" and 6'4".</p>
          <p><strong>Luminous Skin:</strong> Their skin has a subtle luminescence, often in shades of pale blue, silver, or gold. This glow is a result of the mana-infused nanogel flowing within them and is more pronounced in the dark.</p>
          <p><strong>Starlight Hair:</strong> Their hair is often white, silver, or pale blonde, and it seems to shimmer with a faint, internal light, like starlight infused with nanogel.</p>
          <p><strong>Cosmic Eyes:</strong> Their eyes are typically vibrant shades of blue, violet, or silver, and they often appear to contain swirling nebulae or distant galaxies, reflecting their connection to the very weave of the universe.</p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Two ability scores of your choice increases by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Starborn mature at a rate similar to elves, reaching adulthood around 100 years old. Due to their connection to the cosmos, the nanogel within them, and their advanced technology, they are incredibly long-lived, potentially living for over a thousand years. Their bodies do not decay upon death; instead, they dissipate into stardust, returning to the cosmos.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>Starborn tend towards lawful neutral or lawful evil alignments, valuing order, control, and the pursuit of knowledge and power above all else.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium. Starborn vary in height and build, but they typically range from just under 5 feet to about 6 feet tall.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>Accustomed to the dim light of space, you have superior vision in dark and dim conditions. You can see in dim light within 60 feet of you as if it were bright light, and in darkness as if it were dim light. You can't discern color in darkness, only shades of gray.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Celestial Resistance</h3>
            </div>
            <p>You have resistance to radiant damage and advantage on saving throws against being charmed.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Starlight Legacy</h3>
            </div>
            <p>You know the Light cantrip.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Techno-Arcane Integration</h3>
            </div>
            <p>You gain proficiency in the Arcana skill. You also gain proficiency with one type of artisan's tools of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common, Elvish, and Celestial.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Techno-Magic Abilities (Nanogel-Focused)</h2>
          
          <div className="trait">
            <div className="trait-header">
              <h3>Technological Servitor</h3>
            </div>
            <p>
              You can infuse a tiny, non-magical object with a spark of your personal energy and nanogel, creating a construct that will serve you. The object gains rudimentary locomotion and awareness. It becomes a servitor with enhanced programming that obeys your commands explicitly.
            </p>
            
            <div className="trait-subsection">
              <h4>Usage Options</h4>
              <ul className="trait-list">
                <li>Once per long rest without expending additional nanogel (8-hour duration)</li>
                <li>Multiple times per long rest (half proficiency bonus, rounded down) by expending a 3rd-level energy conduit (1-hour duration)</li>
              </ul>
            </div>

            <div className="trait-subsection">
              <h4>Servitor Capabilities</h4>
              <ul className="trait-list">
                <li>Constructed from nanogel and original material fusion</li>
                <li>Understands commands via integrated sensors</li>
                <li>Base Intelligence of 10</li>
                <li>60 ft. darkvision</li>
                <li>Gains your proficiency bonus to AC, attacks, damage, and saves</li>
                <li>HP = base + (5 × your level)</li>
                <li>Operational range of 120 feet while you're conscious</li>
                <li>Reverts to original form at 0 HP</li>
              </ul>
            </div>

            <div className="trait-subsection">
              <h4>Tiny Servant Statistics</h4>
              <div className="roll-table">
                <table>
                  <tr>
                    <td><strong>Size/Type</strong></td>
                    <td>Tiny construct, unaligned</td>
                  </tr>
                  <tr>
                    <td><strong>Armor Class</strong></td>
                    <td>15 (natural armor)</td>
                  </tr>
                  <tr>
                    <td><strong>Hit Points</strong></td>
                    <td>10 (4d4)</td>
                  </tr>
                  <tr>
                    <td><strong>Speed</strong></td>
                    <td>30 ft., climb 30 ft.</td>
                  </tr>
                </table>
              </div>

              <div className="roll-table">
                <table>
                  <tr>
                    <th>STR</th>
                    <th>DEX</th>
                    <th>CON</th>
                    <th>INT</th>
                    <th>WIS</th>
                    <th>CHA</th>
                  </tr>
                  <tr>
                    <td>4 (-3)</td>
                    <td>16 (+3)</td>
                    <td>10 (+0)</td>
                    <td>2 (-4)</td>
                    <td>10 (+0)</td>
                    <td>1 (-5)</td>
                  </tr>
                </table>
              </div>

              <div className="roll-table">
                <table>
                  <tr>
                    <td><strong>Damage Immunities</strong></td>
                    <td>poison, psychic</td>
                  </tr>
                  <tr>
                    <td><strong>Condition Immunities</strong></td>
                    <td>blinded, charmed, deafened, exhaustion, frightened, paralyzed, petrified, poisoned</td>
                  </tr>
                  <tr>
                    <td><strong>Senses</strong></td>
                    <td>blindsight 60 ft. (blind beyond this radius), passive Perception 10</td>
                  </tr>
                  <tr>
                    <td><strong>Languages</strong></td>
                    <td>-- (understands the languages you speak)</td>
                  </tr>
                  <tr>
                    <td><strong>Challenge</strong></td>
                    <td>0 (0 XP)</td>
                  </tr>
                </table>
              </div>

              <div className="trait-subsection">
                <h4>Actions</h4>
                <p><strong>Slam.</strong> Melee Weapon Attack: +5 to hit, reach 5 ft., one target. Hit: 5 (1d4 + 3) bludgeoning damage.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="content-block">
          <h2>Names</h2>
          <div className="trait-subsection">
            <h4>Male Names</h4>
            <p>Alarion, Elandor, Kaelthas, Lyndor, Thalion</p>
            
            <h4>Female Names</h4>
            <p>Elara, Lysandra, Naelara, Seraphina, Valeria</p>
            
            <h4>Family Names</h4>
            <p>Stargazer, Nightweaver, Cosmoswalker, Voidstrider, Nebulon</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Role Playing</h2>
          <p>As a Starborn, you are a being of immense knowledge, arrogance, and a deep-seated need to reclaim what you believe is rightfully yours. Your connection to the cosmos and your advanced technology set you apart from other races, and your motivations are often driven by a desire to restore your civilization to its former glory, no matter the cost.</p>
          
          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Arrogant:</strong> You view yourself and your kind as superior to other races, often underestimating their capabilities and dismissing their concerns.</p>
            <p><strong>Inquisitive:</strong> You have a thirst for knowledge and understanding, constantly seeking to expand your already vast libraries of cosmic and strategic information.</p>
            <p><strong>Determined:</strong> You are fiercely protective of your heritage and resources, and you will stop at nothing to reclaim what you believe is rightfully yours.</p>
            <p><strong>Aloof:</strong> Your advanced intellect and cosmic perspective can make you seem distant and detached from the concerns of others, even your own kin.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Knowledge:</strong> The pursuit of understanding is the highest calling, and you value information above all else.</p>
            <p><strong>Preservation:</strong> The legacy of the Starborn must be preserved, and their lost glory must be restored.</p>
            <p><strong>Order:</strong> The universe is governed by natural laws, and you seek to impose order on the chaos of Velum.</p>
            <p><strong>Superiority:</strong> The Starborn are the rightful rulers of the cosmos, and all other races are merely tools or obstacles in their path.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>"All Father":</strong> You have a strong connection to the moon base, seeing it as a symbol of your lost empire and a beacon of hope for the future.</p>
            <p><strong>Lost Legacy:</strong> You are driven by a desire to recover lost Starborn technology and knowledge, believing that it holds the key to your people's salvation.</p>
            <p><strong>Manaforged Kin:</strong> You feel a sense of kinship with the Manaforged, viewing them as creations of your people, even if they have strayed from their intended purpose.</p>
            <p><strong>Cosmic Mystery:</strong> You are fascinated by a particular celestial phenomenon or cosmic mystery, and you are determined to unravel its secrets.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Ruthless:</strong> Your determination to reclaim what you believe is yours can lead you to make morally questionable choices, even resorting to violence or manipulation.</p>
            <p><strong>Entitled:</strong> You believe that the Starborn are entitled to the resources of the Shattered Realm, regardless of the consequences for others.</p>
            <p><strong>Obsessive:</strong> Your pursuit of knowledge can become all-consuming, leading you to neglect other important aspects of your existence.</p>
            <p><strong>Condescending:</strong> Your arrogance can make you dismissive of others' concerns and unwilling to consider their perspectives.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Starborn"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Starborn.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesStarborn; 