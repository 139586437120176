import React from 'react';
import { motion } from 'framer-motion';
import sovereignZealotData from '../../assets/data/sovereignzealot.json';
import sovereignZealotImage from '../../assets/images/subclasses/Sovereign/sovereignzealot.jpg';
import '../races/RacePage.css';

const SovereignZealot = () => {
  return (
    <div className="race-page">
      <div className="race-hero">
        <img 
          src={sovereignZealotImage} 
          alt="Sovereign Zealot" 
          className="race-background" 
        />
        <div className="race-content">
          <h1>{sovereignZealotData.title}</h1>
        </div>
      </div>

      <div className="race-details">
        <div className="content-section">
          <p>{sovereignZealotData.content}</p>
        </div>

        <div className="features-section">
          {sovereignZealotData.features.map((feature, index) => (
            <div key={index} className="feature-group">
              <div className="trait">
                <div className="trait-header">
                  <h3>{feature.title}</h3>
                  {feature.level && <span className="level">Level {feature.level}</span>}
                </div>
                {Array.isArray(feature.description) ? (
                  feature.description.map((desc, idx) => (
                    <p key={idx}>{desc}</p>
                  ))
                ) : (
                  <p>{feature.description}</p>
                )}
                {feature.spells && (
                  <div className="spell-list">
                    {feature.spells.map((levelSpells, idx) => (
                      <div key={idx} className="spell-level">
                        <span className="level-number">{levelSpells.level}:</span> {levelSpells.spells.join(", ")}
                      </div>
                    ))}
                  </div>
                )}
                {feature.options && feature.options.map((option, idx) => (
                  <div key={idx} className="trait">
                    <h3>{option.title}</h3>
                    {Array.isArray(option.description) ? (
                      option.description.map((desc, descIdx) => (
                        <p key={descIdx}>{desc}</p>
                      ))
                    ) : (
                      <p>{option.description}</p>
                    )}
                  </div>
                ))}
                {Array.isArray(feature.additional) ? (
                  feature.additional.map((desc, idx) => (
                    <p key={idx} className="additional">{desc}</p>
                  ))
                ) : feature.additional ? (
                  <p className="additional">{feature.additional}</p>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SovereignZealot; 