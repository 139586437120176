import React, { useState, useEffect, useRef } from 'react';
import wildscarImage from '../../assets/images/deities/wildscar.png';
import './PantheonPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const PantheonWildscar = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img
          src={wildscarImage}
          alt="Wildscar, The Primal Fury"
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
        />
        <div className="pantheon-title">
          <h1>Wildscar, The Primal Fury</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/Pantheon" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCaelumbris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris
                </Link>
                <Link to="/deities/PantheonCalanthar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Calanthar
                </Link>
                <Link to="/deities/PantheonFirstborn" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonQueenMab" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab
                </Link>
                <Link to="/deities/PantheonQueenTitania" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania
                </Link>
                <Link to="/deities/PantheonSacrathor" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor
                </Link>
                <Link to="/deities/PantheonValdis" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Valdís
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            The Shattering ripped through the fabric of reality, unleashing forces beyond comprehension. Among these, Wildscar emerged, not merely a phenomenon but a primal fury incarnate. For decades, a formless red mist, it haunted the Shattered Realm, unseen yet deeply felt. Villages woke to days of madness, warriors turned their blades on allies, and tranquil havens became slaughterhouses—all swathed in its crimson embrace.
          </p>
        </div>

        <div className="content-block">
          <h2>Description</h2>
          <p>
            The Shattering ripped through the fabric of reality, unleashing forces beyond comprehension. Among these, Wildscar emerged, not merely a phenomenon but a primal fury incarnate. For decades, a formless red mist, it haunted the Shattered Realm, unseen yet deeply felt. Villages woke to days of madness, warriors turned their blades on allies, and tranquil havens became slaughterhouses—all swathed in its crimson embrace.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Crimson Mist, The Unseen Fury</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Chaos, Madness, War</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>All</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A formless red mist.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={wildscarImage} 
                alt="Wildscar, The Primal Fury"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={wildscarImage}
                download="Wildscar.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonWildscar; 