import React from 'react';
import { motion } from 'framer-motion';
import './HouseRules.css';

const HouseRules = () => {
  return (
    <motion.div 
      className="houserules-container"
      initial={{ opacity: 0, x: '100%' }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: '-100%' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <h1>Change your Fate</h1>
      
      <div className="houserules-content">
        <section className="rule-section">
          <h2>Fate Point</h2>
          <p>You have a special connection to the forces of fate, which allows you to alter reality in your favor once in a while. You start the game with one fate point, and you can never have more than one at a time. You can use your fate point to do one of the following things:</p>
          
          <ul>
            <li>After you roll a d20 for an attack roll, an ability check, or a saving throw, you can treat the d20 roll as a natural 20.</li>
            <li>After another creature rolls a d20 for an attack roll, an ability check, or a saving throw, you can force that creature to reroll the d20 and use the new roll.</li>
            <li>You can use your fate point to automatically succeed on a skill check that you are proficient in, regardless of the difficulty or the roll.</li>
            <li>You can use your fate point to cast a spell of 5th level or lower without expending a spell slot or material components (under 500 gold), as long as you know the spell and have it prepared (if applicable).</li>
            <li>You can use your fate point to reroll any number of dice that you have rolled, such as damage dice, hit dice, or dice from a magic item.</li>
            <li>You can use your fate point to create a twist of fate that benefits you or your allies, such as finding a hidden passage, discovering a useful clue, or escaping a deadly trap. The DM has the final say on what constitutes a twist of fate.</li>
          </ul>

          <p>You can use your fate point before or after the outcome of the roll is revealed. Once you use your fate point, you lose it. You can regain your fate point in the following ways:</p>
          
          <ul>
            <li>The DM can award you a fate point for completing a major quest, performing a heroic deed, or achieving a personal goal.</li>
            <li>You can draw a fate point from a deck of many things, if you are lucky enough to find one.</li>
            <li>You can bargain with a powerful entity, such as a deity, a fiend, or a fey, who can grant you a fate point in exchange for a favor or a service.</li>
          </ul>

          <p className="warning">However, using your fate point comes with a risk. Some creatures, such as dragons, liches, or archdevils, have legendary actions that allow them to counter your fate point. When you use your fate point within 120 feet of such a creature, it can spend one of its legendary actions to cancel your fate point and make you roll normally. The creature must choose to do so before the outcome of your fate point is revealed.</p>
        </section>

        <section className="rule-section">
          <h2>GM Inspiration 1d4</h2>
          <p>The player can roll the GM Inspiration 1d4 and add the number rolled to one ability check, attack roll, or saving throw it makes. The player can wait until after the results of the d20 before deciding to use the GM Inspiration die, but must decide before the DM says whether the roll succeeds or fails. Once the GM Inspiration die is rolled, it is lost. A creature can have only one Bardic Inspiration die at a time.</p>
        </section>

        <section className="rule-section">
          <h2>Potions</h2>
          <p>Drinking an equipped potion is a bonus action, not an action. Administering a potion is an action still.</p>
        </section>

        <section className="rule-section">
          <h2>Spell Exhaustion (Warning this can KILL you)</h2>
          <p>When a spellcaster runs out of spell slots, they can still cast more spells at the risk of gaining exhaustion. If a caster tries to cast a spell, that they are able to cast and meet all other requirements but do not have the required spell slot that is needed, they must make a (DC 10 + the level of the spell they are casting) constitution saving throw. On a fail, the spell fails to cast, they lose their concentration on any spells they are maintaining and they take a level of exhaustion equal to the level of the spell they tried to cast. On a success, they cast the spell successfully and gain exhaustion equal to half the spell level rounded up.</p>
        </section>
      </div>
    </motion.div>
  );
};

export default HouseRules; 