import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Gravewalker/gravewalker.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesGravewalker = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Gravewalker" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Gravewalker</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species              
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            Gravewalkers are not born but made, brought into existence by the unyielding power of the Firstborn, the God of Death and Pacts. Their unlife is no act of mercy, no boon freely given; it is a consequence of the divine balance the Firstborn enforces. Some are remnants of shattered pacts, souls tethered to their bones as punishment for breaking oaths. Others are raised deliberately by his clergy, formed into tools of divine judgment and unflinching guardians of sacred places. There are also those who fell victim to the Firstborn's aura, their mortal forms snuffed out in the presence of his overwhelming power and twisted into unlife by the necromantic forces that radiate from him. Whatever their origin, Gravewalkers are eternal reminders of the cost of defying the Firstborn or failing to uphold one's obligations.
          </p>
        </div>

        <div className="content-block">
          <p>
            Once raised, Gravewalkers find themselves at a crossroads. Many remain bound to the Church of the Firstborn, serving as steadfast guardians of sacred sites, enforcers of divine will, or scholars of forbidden lore. Their dedication is a quiet testament to the divine authority that animates them. Others, however, choose to walk a different path, venturing into the wider world. These Gravewalkers often act as wandering emissaries, seeking to right ancient wrongs, fulfill unkept pacts, or confront those who disrupt the balance of life and death. Regardless of their choice, every Gravewalker carries with them the unshakable weight of their creation and the knowledge that their existence is tied to a higher purpose. The living may fear or revere them, but the Gravewalkers themselves remain resolute, driven by the echoes of the Firstborn's will.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <p>
            Held together by arcane tendrils of necromantic energy, their skeletal forms are both fragile and indomitable. These tendrils shimmer with a cold, ghostly light, alternating between hues of icy blue and pale green, weaving through their bones like living chains of energy. Their surfaces bear the texture of ancient ivory, with cracks and engravings that glow faintly as if inscribed by unseen hands. The energy pulses rhythmically, as though mimicking the heartbeat they no longer possess, and when they move, faint wisps of mist trail behind, adding an eerie grace to their undead form.
          </p>
        </div>

        <div className="content-block">
          <p>
            Gravewalkers present themselves with a macabre dignity, their skeletal forms often adorned in well-maintained attire that blends practicality with a somber elegance. Their clothing and equipment, tailored or reclaimed, are carefully chosen to reflect their unique identities, whether as wandering scholars, guardians, or emissaries of the Firstborn. Intricate patterns of runes or etchings on their bones speak not of despair but of purpose, proclaiming their role as agents of the divine balance.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Creature Type</h3>
            </div>
            <p>Undead</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium or Small (your choice, reflective of your form in life)</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Gravewalkers do not age and cannot die of natural causes. Their existence endures until their purpose is fulfilled, their punishment ends, or their bodies are destroyed.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>Gravewalkers often lean toward neutrality, driven by the demands of their creation. However, remnants of mortal personalities may pull them toward other alignments, influenced by the echoes of their former selves.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>The ghostly fire in your eye sockets grants you sight in darkness, glowing with an ever-shifting hue of azure and emerald. It flickers like a dying ember, casting faint shadows that seem alive in their own right, yet burns steadily when your focus sharpens, illuminating the world with an eerie, spectral clarity. You can see in dim light within 60 feet as if it were bright light, and in darkness as if it were dim light. You cannot discern color in darkness, only shades of gray.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Undying Resilience</h3>
            </div>
            <p>The necromantic magic sustaining you renders you resistant to poison damage and immune to disease. You do not require air, food, or water.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Unyielding Frame</h3>
            </div>
            <p>Your skeletal body is hardened by the Firstborn's power. You have resistance to piercing damage from nonmagical sources. Additionally, when not wearing armor, your natural AC is 13 + your Dexterity modifier.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bone Collapse</h3>
            </div>
            <p>When struck by a deadly blow, you can allow your skeletal form to disassemble momentarily. As a reaction to being hit by a critical strike, you can collapse into a pile of bones, taking only normal damage instead of the extra damage from the critical hit. Afterward, you fall prone. You can use this ability a number of times equal to your Dexterity modifier (minimum of once), regaining all uses after a long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pactbound Essence</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>The Firstborn's claim on your soul grants you a spectral authority over death. You can cast Speak with Dead a number of times equal to your proficiency bonus per long rest without requiring material components. Charisma is your spellcasting ability for this spell.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pactbound Essence: Spirit Guardians</h3>
              <span className="level-tag">Level 5th</span>
            </div>
            <p>Your connection to the Firstborn deepens, allowing you to summon spectral figures bound by his will to guard your path. You can cast Spirit Guardians a number of times equal to half your proficiency bonus (minimum of 1) per long rest without requiring material components. Charisma is your spellcasting ability for this spell.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Wailing Souls</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>As an action, you release the anguished cries of those bound to your soul. Each creature of your choice within 15 feet must succeed on a Wisdom saving throw (DC = 8 + your proficiency bonus + your Charisma modifier) or become frightened until the end of your next turn. Creatures that succeed on their saving throw are immune to this effect for 24 hours. At 3rd level, the cries begin to inflict physical pain. Creatures that fail their saving throw take 1d8 necrotic damage in addition to being frightened. This damage increases to 2d8 at 5th level and 3d8 at 10th level. You can use this ability a number of times equal to your proficiency bonus per long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Anchored Soul</h3>
            </div>
            <p>Your connection to the Firstborn protects your essence. You have advantage on saving throws against effects that would turn, banish, or sever your connection to the material plane.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Gravewalker Intuition</h3>
            </div>
            <p>You gain proficiency in Intimidation or History (your choice). You also have advantage on checks to recall information about death-related lore, necromancy, or the undead.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common and one additional language of your choice. This may reflect the culture of your mortal life or the rituals of the Firstborn's clergy.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Gravewalker"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Gravewalker.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesGravewalker; 