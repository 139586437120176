import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import backgroundImage from '../assets/images/background.png';
import './Home.css';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mainContainer">
      <div 
        className="contentContainer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="contentOverlay">
          <div className="sectionContent" style={{ background: 'rgba(0, 0, 0, 0.7)', padding: '1rem', borderRadius: '8px' }}>
            <h1 style={{ textAlign: 'center' }}>Welcome to The Shattered Realm</h1>
            <div className={`veiled-paths ${menuOpen ? 'active' : ''}`} ref={menuRef}>
              <h2 onClick={toggleMenu}>
                Menu
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/history" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  History
                </Link>
                <Link to="/house-rules" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  House Rules
                </Link>
                <Link to="/prologue" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Prologue
                </Link>
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Veiled Ascension
                </Link>
              </div>
            </div>
            <p>
              In the vast expanse of the Shattered Realm, a universe birthed from a cosmic event of unparalleled magnitude, the boundaries between the arcane and the technological blur, crafting a symphony of worlds where the extraordinary is the norm. This domain, where dimensions intertwine, serves as a crucible for the melding of ancient magic with the remnants of advanced, forgotten civilizations. It's a realm of endless mysteries and untold stories, each fragment of reality hiding legacies of the past and secrets yet to be unveiled.
            </p>
            <p>
              The Shattered Realm is a tapestry woven from the threads of time and space, a place where the laws of nature bend to the will of the mystical and the mechanical. Here, the echoes of ancient sorceries resonate through the air, intermingling with the hum of forgotten machinery. The landscape is a patchwork of diverse biomes, each a testament to the realm's rich history and the myriad forces that have shaped it over eons.
            </p>
            <p>
              From the towering spires of the Crystal Peaks, where the air is thin and the magic potent, to the shadowy depths of the Abyssal Chasms, where darkness reigns and whispers of ancient horrors linger, the Shattered Realm is a place of stark contrasts and boundless wonder. The forests of the Elderwood teem with life, both familiar and fantastical, while the desolate wastelands of the Blighted Sands bear the scars of wars long past.
            </p>
            <p>
              In this realm, heroes are forged in the fires of adversity, and legends are born from the ashes of conflict. The Shattered Realm is a crucible for the brave and the bold, a place where destiny is not a predetermined path but a journey shaped by the choices and actions of its inhabitants. Whether you are a seasoned adventurer seeking glory and treasure, or a scholar delving into the mysteries of the arcane, the Shattered Realm offers a wealth of opportunities and challenges.
            </p>
            <p>
              Welcome to the Shattered Realm, where magic and technology intertwine, and the extraordinary is the norm. Embark on a journey of discovery and adventure, and uncover the secrets that lie hidden within its vast and wondrous expanse.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;