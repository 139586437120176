import React, { useState, useEffect, useRef } from 'react';
import firstbornImage from '../../assets/images/deities/firstborn.png';
import './PantheonPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const PantheonFirstborn = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img
          src={firstbornImage}
          alt="The Firstborn: God of Death and Pacts"
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
        />
        <div className="pantheon-title">
          <h1>The Firstborn: God of Death and Pacts</h1>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            The Firstborn, revered as The Eternal Wanderer and The Cursed One, represents the fragile balance between life and death, as well as the sanctity of binding agreements. Born as the first of his race in a long-lost reality, he was tasked with guiding his people for countless ages. His stewardship was marked by wisdom and stability, but the Gods, perceiving his rising influence as a threat, conspired against him. Unable to destroy him, they created an artifact from the void—a shard of pure nothingness, an artifact older than time itself. This fragment was imbued with the power of entropy and fused with his essence, irreversibly transforming him.
          </p>
        </div>

        <div className="content-block">
          <h2>Description</h2>
          <p>
            The void shard unleashed an aura of undeath, draining life and animating the dead in a ceaseless cycle. Anything entering its range is reduced to lifelessness, its energy reformed into unholy animation. This relentless force claimed his wife and daughter, leaving him to an eternity of isolation. Cast adrift on a 400-mile-wide island filled with undead, the Firstborn wandered the void for eons after the Gods destroyed their celestial realm. It was Calanthar, the living embodiment of existence, who eventually found him at the Shattered Realm's edges. Recognizing the Firstborn's immense burden and potential, Calanthar forged a pact, empowering him as the gatekeeper between life and death. Elevated to godhood, the Firstborn now governs the delicate cycles of existence, serving as a cosmic arbiter in a fragmented universe.
          </p>
        </div>

        <div className="content-block">
          <h2>Origins and Mythos</h2>
          <p>
            The Firstborn's origins are steeped in tragedy and perseverance. As the progenitor of his kind, he led his people with unmatched devotion until the Gods betrayed him. The void shard, a manifestation of nothingness, turned him into a living harbinger of undeath and entropy. Over time, he became a symbol of death's inevitability and the resilience found in enduring purpose. Drifting with his undead entourage, the Firstborn became a quiet testament to the unbreakable link between life and death. When discovered by Calanthar, his existence was reshaped into one of balance and renewal, elevating his tragic history into a divine mission.
          </p>
        </div>

        <div className="content-block">
          <h2>Influence and Teachings</h2>
          <p>
            The Firstborn's teachings revolve around balance and the unyielding importance of keeping one's word. His followers—necromancers, mediators, and scholars—seek to understand the intricate cycles of life and death. Undeath, in his doctrine, is seen not as a curse but as a continuation of one's service to the greater order. Daily rituals include tending to the undead, who serve vital roles in maintaining societal functions. These practices uphold the Firstborn's philosophy that all forms of existence, even in death, contribute to the cosmos. Followers plant seeds over graves as a symbolic gesture of renewal, signifying the connection between decay and growth. Mediators inspired by his teachings often invoke the sanctity of pacts to resolve disputes, ensuring fairness and harmony.
          </p>
        </div>

        <div className="content-block">
          <h2>Worship and Rituals</h2>
          <p>
            Temples dedicated to the Firstborn are solemn spaces, their dim interiors illuminated by pale, flickering flames. Central to his worship is the ritual of binding, where written agreements are burned in ceremonial braziers to signify their eternal nature. Another key practice involves offerings of memory. Personal relics or keepsakes of the deceased are placed on altars to honor their legacy and acknowledge life's impermanence. These rituals reinforce the Firstborn's teachings that the actions of the living resonate far beyond their mortal span.
          </p>
        </div>

        <div className="content-block">
          <h2>Powers and Abilities</h2>
          <p>
            Aura of Undeath: An ever-present field extending 100 feet, draining life and animating the dead. Void Infusion: The void shard grants mastery over entropy, enabling the nullification of magical effects and the unraveling of creation. Master of Pacts: Contracts forged in his name carry unbreakable cosmic bindings, with severe consequences for betrayal. Gatekeeper of Life and Death: Oversees the passage of souls, determining their fates according to the cosmic balance.
          </p>
        </div>

        <div className="content-block">
          <h2>Realm and Society</h2>
          <p>
            The Firstborn's island, known as The Eternal Wanderer, now floats through the skies, a marvel of commerce and unyielding governance. Suspended by ancient magics and divine authority, it serves as a hub of trade, diplomacy, and divine oversight. Its ability to traverse the Shattered Realm fosters economic alliances and ensures the enforcement of fairness and honor in all dealings. Within its borders, the living and undead coexist harmoniously, their contributions interwoven into a thriving society. Towering obsidian structures, veined with pulsating red light, form the core of its cities, while bustling marketplaces brim with life and innovation. Undead laborers tirelessly maintain infrastructure, their presence a constant reminder of the Firstborn's dominion, while living artisans and merchants bring creativity and vibrancy to the economy. The Firstborn's presence on the island is an unwavering force, ensuring every pact is upheld under the gaze of a divine arbiter. His aura of undeath influences the culture profoundly, fostering a unique blend of reverence for death's inevitability and celebration of life's creative potential. This balance defines the essence of the Eternal Wanderer's society, where each action and agreement holds the weight of divine accountability.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Eternal Wanderer, The Cursed One</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Death, Pacts, Undeath</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>The Shattered Realm</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A skeletal hand holding a scroll, balanced by scales on either end</p>
          </div>
        </div>

        <div className="menu-container">
          <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Divine Paths
              <ChevronDown size={20} />
            </h2>
            <div className="pantheon-links">
              <Link to="/Pantheon" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                ← Return to Veiled Pantheon
              </Link>
              <Link to="/deities/PantheonCaelumbris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Caelumbris
              </Link>
              <Link to="/deities/PantheonCalanthar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Calanthar
              </Link>
              <Link to="/deities/PantheonLibris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                L.I.B.R.I.S.
              </Link>
              <Link to="/deities/PantheonQueenMab" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Queen Mab
              </Link>
              <Link to="/deities/PantheonQueenTitania" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Queen Titania
              </Link>
              <Link to="/deities/PantheonSacrathor" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Sacrathor
              </Link>
              <Link to="/deities/PantheonValdis" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Valdís
              </Link>
              <Link to="/deities/PantheonWildscar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Wildscar
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={firstbornImage} 
                alt="The Firstborn: God of Death and Pacts"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={firstbornImage}
                download="Firstborn.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonFirstborn; 