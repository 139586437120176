import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import firstbornImage from '../../assets/images/deities/TheFirstborn.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const FirstbornSoulbound = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={firstbornImage} 
          alt="Firstborn: Soulbound Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Firstborn: The Soulbound Path</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="deity-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Uphold all oaths, contracts, and agreements—even verbal ones—without exception.</li>
              <li>Respect the natural order of life and death, intervening only when the balance is disrupted; destroy mindless undead.</li>
              <li>Create or arbitrate pacts that serve mutual growth or balance.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>All agreements made by the follower must be honored, even at great personal cost.</li>
              <li>Seek opportunities to mediate disputes and ensure fairness in agreements.</li>
              <li>Never raise undead creatures except to serve as guardians of sacred sites or to fulfill the terms of a pact.</li>
            </ul>
          </div>
        </div>

        <div className="content-block dark-block">
          <p>
            Following the path of the Soulbound grants the ability to forge divine pacts that bind the souls of participants to a shared purpose. Practitioners of this path become arbiters of cooperation and discipline, wielding their oaths to unify others under an unbreakable bond. The Firstborn's adherents wield their power not as tyrants but as resolute stewards of balance, enforcing the sanctity of their pacts with an unshakable sense of duty.
          </p>
        </div>

        <div className="content-block dark-block">
          <h2 className="blue-text">Firstborn: Soulbound Covenant</h2>
          <p>
            In the depths of mortality's end and the silence beyond, the Firstborn reigns as an impartial arbiter of oaths and balance. As the God of Death and Pacts, the Firstborn ensures that every promise, every solemn vow, and every shared bond is honored without fail. To those who walk the path of the Firstborn, there is no act more sacred than a pact—an immutable covenant that binds souls together with divine precision. Each agreement, no matter its purpose, carries the weight of inevitability, for breaking such a vow invites dire and unyielding consequences.
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>The Firstborn's Domain</h3>
          </div>
          <p>
            The Firstborn's realm lies at the nexus of life and death, a stark expanse where the weight of every promise ever made presses upon the soul. Towering monoliths etched with the pacts of countless beings stretch endlessly into the void, their inscriptions glowing faintly with divine energy. The air thrums with the resonance of countless vows, whispered eternally in the silence of this solemn place.
            To traverse the Firstborn's domain is to feel the inescapable gravity of accountability. Every shadow carries the echo of broken oaths, and every step seems to test the resolve of those who dare to walk this path. In this place, the Firstborn's presence is absolute, and their gaze pierces all deceptions, ensuring that every pact is upheld with unwavering finality.
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>The Soulbound Pact</h3>
          </div>
          <p>
            You can forge a Soulbound Pact with up to a number of creatures equal to your proficiency bonus. Each pact lasts for 8 hours and requires mutual consent from all participants. The Firstborn enforces each agreement with impartiality, and breaking a pact incurs immediate and severe consequences for violators.
          </p>
          <h4>Breaking the Pact</h4>
          <p>
            Penalties for All Violators: Breaking the terms of a pact results in a temporary reduction to maximum hit points equal to the violator's level + 2 × proficiency bonus. This penalty lasts for a number of days equal to their level.
          </p>
          <p>
            Additional Penalties for Initiators:
            If the initiator is the one that breaks the pact, they lose the ability to forge new pacts and temporarily lose one character level and all associated benefits for a number of days equal to their level.
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Types of Pacts</h3>
          </div>
          <p>
            Each Soulbound Pact confers a divine bonus equal to the initiator's proficiency bonus, applied to the chosen benefit. Below are the available pacts, their benefits, and their requirements:
          </p>
          <ul className="specializations-list">
            <li>
              <strong>Pact of Combat</strong>
              <p>Benefit: Each participant gains a divine bonus to attack and damage rolls.</p>
              <p>Requirement: All members must actively contribute to combat, whether through direct attacks, buffs, or other meaningful actions.</p>
            </li>
            <li>
              <strong>Pact of Defense</strong>
              <p>Benefit: Each participant gains a divine bonus to AC and resistance to piercing, slashing, and bludgeoning damage.</p>
              <p>Requirement: Participants must prioritize defending one another, such as casting protective spells, using abilities to shield others, or forming defensive positions.</p>
            </li>
            <li>
              <strong>Pact of Resolve</strong>
              <p>Benefit: Each participant gains a divine bonus to a saving throw of their choice. The available options are Strength, Dexterity, Constitution, Intelligence, Wisdom, and Charisma.</p>
              <p>Requirement: Participants must actively work together to overcome hazards, such as sharing resources, combining abilities, or supporting one another in challenges.</p>
            </li>
            <li>
              <strong>Pact of Mastery</strong>
              <p>Benefit: Each participant gains a divine bonus to skill checks.</p>
              <p>Requirement: Participants must collaborate on skill challenges, ensuring no individual attempts tasks alone unless absolutely necessary.</p>
            </li>
            <li>
              <strong>Pact of Community</strong>
              <p>Benefit: Each participant gains temporary hit points equal to twice the initiator's proficiency bonus + their level and advantage on death saving throws.</p>
              <p>Requirement: Participants must foster teamwork, honesty, and mutual support, refraining from selfish actions that undermine group cohesion.</p>
            </li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Roleplaying the Path of the Firstborn</h3>
          </div>
          <p>
            Followers of the Firstborn embody the unwavering principles of duty, balance, and accountability. They are not mere enforcers but architects of unity, forging bonds that elevate the strength of the collective. Their presence demands respect, for they bring an air of inevitability—every pact made in their presence feels as unshakable as the grave itself. Whether guiding a fractured group toward a shared goal or holding others accountable to their promises, they are resolute stewards of trust and discipline.
            To walk this path is to carry the burden of judgment and guardianship, ensuring that oaths are not taken lightly and that every bond forged strengthens those who share it. The Firstborn's power flows through their adherents as an unyielding reminder that to break a promise is to sever a sacred thread of fate itself.
          </p>
        </div>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={firstbornImage} 
              alt="Firstborn: Soulbound Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={firstbornImage}
              download="Firstborn.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default FirstbornSoulbound;