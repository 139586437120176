import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Sunseed/sunseed.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesSunseed = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Sunseed" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Sunseed</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>
                <Link to="/species/SpeciesSkinWalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Skinwalker
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>              
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Sunseed are fey entities born at the peak of summer's ferocity, embodying the untamed vitality and fiery chaos of the season. Their existence is as fleeting as it is vibrant; their lives burn with unparalleled intensity, but their vitality wanes quickly compared to other fey. This rapid lifecycle stems from the blazing energy that sustains them, consuming their life force as swiftly as a wildfire devours a dry forest.
          </p>
          <p>
            Despite their short lives, the Sunseed are among the most prolific fey in the Shattered Realm. They spring into existence at the heart of summer's heat, driven by raw, unrestrained passion. Their appearances are so common that even children have grown indifferent to the sight of one. Yet, wherever a Sunseed treads, they leave an undeniable impact. Entire forests may bloom in their wake, only to wither into ash depending on their mercurial moods. Their presence reshapes communities and environments, like a whirlwind that disrupts and transforms everything it touches.
          </p>
          <p>
            Born of the Summer Court's primal power, the Sunseed embody the duality of their origin: at once dazzlingly beautiful and inherently dangerous. They are creatures of spontaneity, driven by fleeting passions and a relentless need to experience everything the world has to offer before their flames are extinguished. Their existence mirrors the season they spring from—unpredictable, intense, and unforgettable.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <p>
            The Sunseed's appearance reflects their fiery and volatile nature. Their skin shimmers with a constant heat haze, as though tiny embers smolder just beneath the surface. Their hues range from molten gold and searing orange to deep, charred blacks and vivid reds, embodying the essence of a roaring wildfire.
          </p>
          <p>
            Their bodies are adorned with living vines, flowers, and leaves that shift and grow with their emotions. When calm, these organic features bloom with vibrant beauty, captivating all who behold them. However, in moments of anger, their plant features transform into thorny, jagged shapes, radiating an aura of danger. This duality—at once alluring and intimidating—is central to their nature.
          </p>
          <p>
            A subtle warmth emanates from the Sunseed, causing the air around them to shimmer with heat and the occasional flicker of sparks. Their forms also reflect their moods; anger ignites their embers and intensifies their presence, while tranquility softens their glow and fosters lush, verdant growth. Even their attire—crafted from woven leaves, hardened bark, and living flowers—shifts to match their ever-changing energy.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality</h2>
          <p>
            Sunseed are creatures of passion, embodying emotions as intense and fleeting as a summer storm. They are impulsive and unpredictable, pursuing their desires with reckless abandon. Their love for novelty and shiny, new things borders on obsession, and their childlike enthusiasm often leaves others bewildered. However, their fleeting attention spans ensure that they rarely linger long enough to form deep bonds.
          </p>
          <p>
            Despite their chaotic tendencies, Sunseed leave a lasting mark wherever they go. Their fiery spirits can inspire or devastate, reshaping the world around them in profound and unexpected ways. They value freedom and individuality above all else, thriving in environments that allow them to express their boundless energy. However, their volatility and intensity can make them difficult companions, as their emotions often burn too brightly to be contained. Loyal to those who earn their trust, their devotion is as fierce as their fury.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two different ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium or Small (player's choice)</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Base walking speed is 30 feet</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Features</h3>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Fiery Resilience</h3>
            </div>
            <p>You have resistance to fire damage. However, you also have vulnerability to cold damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ember Burst</h3>
            </div>
            <p>As an action, you can release a burst of fiery energy, creating a 10-foot radius sphere centered on yourself. Each creature in the area must make a Dexterity saving throw (DC = 8 + your proficiency bonus + your Charisma modifier). On a failed save, the creature takes 1d6 fire damage (increasing to 2d6 at 5th level, 3d6 at 10th level, and 4d6 at 15th level) and becomes scorched. Scorched creatures have disadvantage on Strength saving throws until the end of their next turn. On a successful save, the creature takes half damage and is not scorched. You can use this ability a number of times equal to your proficiency bonus per long rest (minimum of once).</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Wild Growth</h3>
            </div>
            <p>As a bonus action, you can command the plant life around you to surge. In a 10-foot radius centered on yourself, you create difficult terrain with dense vines, brambles, and thorny plants that last for 1 minute. Each creature within the area must make a Wisdom saving throw (DC = 8 + your proficiency bonus + your Charisma modifier). On a failed save, the creature is affected by a random effect from the table below. You can use this ability a number of times equal to your proficiency bonus per long rest (minimum of once).</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Living Embers</h3>
            </div>
            <p>Your natural warmth harms those who strike you. When a creature hits you with a melee attack, they take 1d4 fire damage. This damage increases to 1d6 at 5th level, 1d8 at 10th level, and 1d10 at 15th level. This damage can only be dealt once per turn.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Adaptive Nature</h3>
            </div>
            <p>You gain advantage on Wisdom (Survival) checks made to adapt to new environments or situations. This reflects your innate ability to thrive in various conditions and your quick adaptability.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Skill Proficiency</h3>
            </div>
            <p>You gain proficiency in either Performance or Athletics (your choice) and Nature.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common, Sylvan, and one additional language of your choice.</p>
          </div>

          <div className="content-block">
            <h2>Wild Growth Random Effects Table</h2>
            <table className="roll-table">
              <thead>
                <tr>
                  <th className="table-header">D10</th>
                  <th className="table-header">Effect</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Teleportation Chaos: Each time the creature moves 5 feet, it teleports to a random adjacent square. It must make a Dexterity saving throw (DC = 8 + your proficiency bonus + your Charisma modifier) to remain upright; otherwise, it falls prone.</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Mirror Image Madness: The creature perceives all other creatures as copies of itself. It must use its action to attack the nearest "duplicate" on its next turn. The effect ends after one attack or at the end of the turn.</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Fearful Flight: The creature must succeed on a Wisdom saving throw (DC = 8 + your proficiency bonus + your Charisma modifier) or see its greatest fear and spend its turn moving as far away from it as possible. It cannot willingly move closer to the origin of the fear until the start of its next turn.</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Blinded by Beauty: The creature is blinded by a sudden eruption of radiant blooms around them. The creature is blinded until the end of its next turn.</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Thorny Embrace: The creature perceives a nearby hostile target as its true love. The creature must spend its action trying to move adjacent to the "loved one" and embracing them. This results in the creature grappling the target (DC = 8 + your proficiency bonus + your Charisma modifier to escape). The effect ends at the start of the creature's next turn.</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Web Burst: At the start of their turn, the creature releases a 20-foot radius burst of sticky webbing centered on itself. Each creature within the area must succeed on a Dexterity saving throw (DC = 8 + your proficiency bonus + your Charisma modifier) or be restrained. Restrained creatures may use their action to make a Strength check to free themselves (DC = 8 + your proficiency bonus + your Charisma modifier). The webbing is flammable, burning away if exposed to fire, dealing 2d4 fire damage to restrained creatures.</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Rooted in Place: The creature's feet sink into the ground and become entwined with roots. It cannot move for 1 minute unless it uses an action to make a Strength check (DC = 8 + your proficiency bonus + your Charisma modifier) to free itself.</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Haunting Whispers: The creature is surrounded by a 20-foot radius of supernatural silence for 1 minute. It cannot cast spells requiring verbal components while within this radius.</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Grease Burst: A 20-foot radius of slippery grease appears centered on the creature, turning the area into difficult terrain. Creatures within the radius must make a Dexterity saving throw (DC = 8 + your proficiency bonus + your Charisma modifier) or fall prone.</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Foggy Frenzy: The creature is enveloped in a 20-foot radius of dense fog, heavily obscuring the area for 1 minute. All creatures in the area are blinded while inside the fog.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h2>Role Playing</h2>
            </div>
          </div>

          <div className="trait">
            <p>As a Sunseed, you are a radiant beacon of vitality and hope, a living extension of solar energy imbued with the divine essence of life itself. Your presence invigorates those around you, but your light also casts harsh shadows, revealing uncomfortable truths and exposing hidden flaws. While you are deeply connected to the cycles of the natural world, your solar essence drives you to seek growth, healing, and renewal wherever you go. However, your radiance can be both a blessing and a burden, as your brilliance often isolates you from others.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Optimistic:</strong> You exude a natural confidence and belief in the potential for growth and improvement in all things.</p>
            <p><strong>Empathic:</strong> Your connection to life allows you to sense and respond to the emotional and physical needs of others.</p>
            <p><strong>Unyielding:</strong> Like the sun itself, you are steadfast in your convictions and difficult to sway once you have chosen a path.</p>
            <p><strong>Illuminating:</strong> Your presence brings clarity, whether by uncovering hidden truths or inspiring others to see the world in a new light.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Growth:</strong> Life is an ever-expanding journey, and you seek to nurture progress in yourself and those around you.</p>
            <p><strong>Renewal:</strong> Just as the sun rises each day, you believe in the power of second chances and fresh beginnings.</p>
            <p><strong>Harmony:</strong> You strive to bring balance to the world, ensuring that light and life prevail over darkness and decay.</p>
            <p><strong>Sacrifice:</strong> Your brilliance comes with a cost, and you are willing to bear burdens to ensure the well-being of others.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>The Eternal Cycle:</strong> You are deeply attuned to the natural cycles of the sun, seasons, and life itself, and you feel compelled to uphold them.</p>
            <p><strong>The Light Within:</strong> Your solar essence is a source of pride and responsibility, driving you to live up to its divine potential.</p>
            <p><strong>Kindred Spirits:</strong> You are drawn to those who share your commitment to growth and renewal, forming deep connections with like-minded individuals.</p>
            <p><strong>Shadowed Past:</strong> You are haunted by a moment when your light failed to save someone or something dear to you, driving your current efforts.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Overbearing:</strong> Your desire to bring light and clarity to every situation can come across as intrusive or overzealous.</p>
            <p><strong>Exhausting Radiance:</strong> Your constant optimism and energy can be overwhelming, making it difficult for others to relate to you.</p>
            <p><strong>Fear of Darkness:</strong> You are deeply uncomfortable with failure, loss, or situations that your light cannot fix or illuminate.</p>
            <p><strong>Isolated Brilliance:</strong> Your radiant nature can make it hard for others to see you as anything but a symbol, rather than a person with your own struggles.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Sunseed"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Sunseed.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesSunseed; 