import React from 'react';
import { motion } from 'framer-motion';
import leviathanData from '../../assets/data/leviathan.json';
import leviathanImage from '../../assets/images/deities/leviathan.png';
import './DeityPage.css';
import '../races/RacePage.css';

const Leviathan = () => {
  return (
    <div className="race-page">
      <div className="race-hero">
        <img 
          src={leviathanImage} 
          alt="The Leviathan, The Veiled Devourer" 
          className="race-background" 
        />
        <div className="race-content">
          <h1>{leviathanData.title}</h1>
        </div>
      </div>

      <div className="race-details">
        <div className="content-section">
          {leviathanData.content.map((section, index) => (
            <div key={index} className="content-block">
              {section.title && <h2>{section.title}</h2>}
              <p>{section.description}</p>
            </div>
          ))}
        </div>

        <div className="features-section">
          {leviathanData.features.map((feature, index) => (
            <div key={index} className="feature-group">
              <h2>{feature.title}</h2>
              <div className="traits-grid">
                {feature.traits.map((trait, idx) => (
                  <div key={idx} className="trait">
                    <div className="trait-header">
                      <h3>{trait.title}</h3>
                    </div>
                    <p>{trait.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Leviathan; 