import React from 'react';
import { motion } from 'framer-motion';
import dronewrightImage from '../../assets/images/subclasses/Dronewright/dronewright.jpg';
import dronewrightData from '../../assets/data/dronewright.json';
import '../races/RacePage.css';

const Dronewright = () => {
  return (
    <div className="race-page">
      <div className="race-hero">
        <img src={dronewrightImage} alt="Dronewright" className="race-background" />
        <div className="race-content">
          <h1>{dronewrightData.title}</h1>
        </div>
      </div>

      <div className="race-details">
        <div className="content-section">
          <p>{dronewrightData.content}</p>
        </div>

        <div className="features-section">
          {dronewrightData.features.map((feature, index) => (
            <div key={index} className="feature-group">
              <div className="trait">
                <div className="trait-header">
                  <h3>{feature.title}</h3>
                  {feature.level && <span className="level">Level {feature.level}</span>}
                </div>
                <p>{feature.description}</p>
                {Array.isArray(feature.description) && feature.description.map((desc, idx) => (
                  <p key={idx}>{desc}</p>
                ))}
                {feature.specializations && (
                  <ul className="specializations-list">
                    {feature.specializations.map((spec, idx) => (
                      <li key={idx}>{spec}</li>
                    ))}
                  </ul>
                )}
                {feature.benefits && (
                  <ul className="benefits-list">
                    {feature.benefits.map((benefit, idx) => (
                      <li key={idx}>{benefit}</li>
                    ))}
                  </ul>
                )}
                {feature.upgrades && (
                  <ul className="upgrades-list">
                    {feature.upgrades.map((upgrade, idx) => (
                      <li key={idx}>{upgrade}</li>
                    ))}
                  </ul>
                )}
                {feature.tools && (
                  <ul className="tools-list">
                    {feature.tools.map((tool, idx) => (
                      <li key={idx}>{tool}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dronewright; 