import React, { useState, useRef, useEffect } from 'react';
import pathOfWrathImage from '../../assets/images/subclasses/Wrath/pathofwrath.png';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesPathOfWrath = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={pathOfWrathImage} 
          alt="Path of Wrath" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Path of Wrath</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            The Path of Wrath calls to those who embrace fury not as a fleeting emotion but as an indomitable force that shapes their existence. These barbarians are vessels for chaos, avatars of destruction whose very presence stirs the air with anticipation of violence. Whether forged by the relentless trials of war or imbued with the essence of Wildscar, the capricious god of battle, their rage transcends mortal understanding. It is a living storm, a force of nature bound only by their will.
          </p>
          <p>
            Path of Wrath barbarians are not mere brutes; they are engines of destruction with a purpose. Their fury consumes all hesitation, turning them into unstoppable juggernauts. In battle, their wrath manifests as a tangible force, reshaping their very forms and enveloping the battlefield in an aura of primal power. Enemies falter under their oppressive presence, while allies draw strength from their untamed spirit.
          </p>
        </div>

        <div className="content-block">
          <h2>Bonus Proficiencies</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>When you choose this path at 3rd level, you gain proficiency in Athletics and Intimidation.</p>
          <p>You can rage a number of times per day equal to your proficiency bonus.</p>
        </div>

        <div className="content-block">
          <h2>Fury of Wrath</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>Starting at 3rd level, your rage transforms you into a force of nature. To fully tap into the raw power of your rage, you must not be encumbered by heavy or medium armor. You gain the following benefits only while wearing light armor or no armor:</p>
          
          <h3>Explosive Growth</h3>
          <p>You temporarily grow in size, enhancing your battlefield presence and raw physical strength.</p>
          <ul>
            <li>At 3rd level, you grow to Medium size if you are smaller and gain a +2 bonus to Strength.</li>
            <li>At 6th level, you grow to Large size and your Strength bonus increases to +4.</li>
            <li>At 10th level, you grow to Huge size and your Strength bonus increases to +6.</li>
          </ul>

          <h3>Bloodfire Mist</h3>
          <p>While raging, you exude a swirling aura of blood-red mist. This aura initially surrounds your space and a 5-foot radius around you.</p>
          <ul>
            <li>At 6th level, it expands to a 10-foot radius.</li>
            <li>At 10th level, it expands to a 15-foot radius.</li>
            <li>At 20th level, it expands to a 30-foot radius.</li>
          </ul>
          
          <p>While within this aura, you and your allies gain resistance to bludgeoning, piercing, and slashing damage. Additionally, you can choose one of the following damage types, gaining resistance to it:</p>
          <ul>
            <li>3rd level: Acid, cold, fire, lightning, or thunder</li>
            <li>6th level: Necrotic, poison, or radiant</li>
            <li>10th level: Force, psychic, or magical</li>
          </ul>
          
          <p>However, creatures within the aura also suffer a –1 penalty to AC, saving throws, and make all concentration checks at disadvantage. At 20th level, the penalty increases to –2. Additionally, whenever a creature within the aura attempts to cast a spell or perform an action requiring concentration, they must succeed on a concentration check with a DC of 8 + the spell level. Failure results in the spell or action failing, as the instinctual rage disrupts intellectual control.</p>
        </div>

        <div className="content-block">
          <h2>Unyielding Will</h2>
          <div className="level-indicator">Level 6th</div>
          <ul>
            <li>At 6th level, you gain immunity to the charmed and frightened conditions.</li>
            <li>At 10th level, you gain immunity to the stunned condition.</li>
            <li>At 14th level, you gain resistance to psychic damage.</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Wrath Unleashed</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            At 6th level, you can channel your inner fury into a destructive blast. When you hit a creature with a melee attack, you can choose to unleash a burst of energy. Each creature within 10 feet of you must make a Constitution saving throw (DC 8 + your proficiency bonus + your Constitution modifier). A creature takes 3d8 mixed damage (half fire, half bludgeoning, piercing, or slashing of your choice) on a failed save, or half as much on a successful one. This damage increases to 4d8 at 11th level and 5d8 at 16th level. Additionally, affected creatures take 1d6 fire damage at the start of their next turn. You can use this feature a number of times equal to your proficiency bonus per long rest.
          </p>
        </div>

        <div className="content-block">
          <h2>Embodiment of Wrath</h2>
          <div className="level-indicator">Level 10th</div>
          <p>At 10th level, the essence of your rage flows through you even outside of battle. You gain the following benefits, even when not raging:</p>
          <ul>
            <li>You have advantage on Strength checks and Strength saving throws.</li>
            <li>When you make a melee weapon attack using Strength, you gain a bonus to the damage roll equal to +2. This increases to +3 at 14th level and +4 at 17th level.</li>
            <li>You have resistance to bludgeoning, piercing, and slashing damage.</li>
            <li>At the start of each of your turns, you gain temporary hit points equal to your Constitution modifier (minimum of 1).</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Wrathful Incarnation</h2>
          <div className="level-indicator">Level 14th</div>
          <p>At 14th level, you can briefly become the avatar of pure wrath. As a bonus action, you gain the following benefits for 1 minute:</p>
          <ul>
            <li>You grow into a Huge creature, gaining the benefits of Explosive Growth if you are not already Huge.</li>
            <li>Your unarmed strikes and melee weapon attacks deal an additional 2d6 fire damage.</li>
            <li>You have advantage on all Strength checks and saving throws.</li>
            <li>You are immune to all conditions except for exhaustion, paralysis, and petrification.</li>
            <li>You regenerate 5 hit points at the start of each of your turns if you have at least 1 hit point.</li>
          </ul>
          <p>Once you use this feature, you can't use it again until you finish a long rest.</p>
        </div>

        <div className="content-block">
          <h2>Avatar of Wildscar</h2>
          <div className="level-indicator">Level 20th</div>
          <p>At level 20, you become an avatar of Wildscar when you rage. Your connection to the god of battle is so profound that you embody his chaotic and violent nature.</p>
          <p>Unstoppable Fury: Once per day, while raging, you cannot be reduced to 0 hit points. If you take damage that would reduce you to 0 hit points, you remain standing with 1 hit point and automatically succeed on all death saving throws for 1 minute.</p>
          <p>Wrathful Embodiment: Your Bloodfire Mist now expands to a 30-foot radius. The penalty incurred by creatures within this area increases to –2.</p>
          <p>Limitless Rage: You are always considered to be raging. To end your rage voluntarily, you must spend 1 minute concentrating (as if concentrating on a spell).</p>
        </div>

        <div className="content-block">
          <h2>Roleplaying a Path of Wrath Barbarian</h2>
          <p>Barbarians who walk the Path of Wrath are embodiments of chaos and destruction, driven by unrelenting fury. Their connection to Wildscar defines their worldview: might makes right, and the strong dominate the weak. They are feared and respected for their overwhelming presence, leaving a trail of devastation in their wake.</p>
          
          <h3>Personality Traits</h3>
          <ul>
            <li>Ruthless: You see mercy as weakness and strive to dominate every situation.</li>
            <li>Savage: Your instincts drive your actions, often leading to brutal solutions.</li>
            <li>Unyielding: You refuse to compromise or back down, no matter the cost.</li>
            <li>Instinctive: You act on gut feelings rather than reason, trusting your primal nature.</li>
          </ul>

          <h3>Ideals</h3>
          <ul>
            <li>Chaos: Destruction is the natural order; through chaos, the world is reshaped.</li>
            <li>Power: Strength is the ultimate truth, and you seek to prove your might.</li>
            <li>Domination: The strong must rule the weak; it is the way of Wildscar.</li>
            <li>Revenge: Your fury is a tool to settle scores and destroy those who wronged you.</li>
          </ul>

          <h3>Bonds</h3>
          <ul>
            <li>Wildscar's Call: You feel bound to Wildscar's chaotic essence, serving their will through unrelenting destruction.</li>
            <li>Scars of Wrath: A battle, loss, or betrayal fuels your rage, and you carry its memory into every fight.</li>
            <li>Symbol of Fury: You wield an artifact or totem that represents your bond to Wildscar's destructive power.</li>
            <li>Tribal Bond: Your connection to a savage tribe or warband shapes your loyalty and actions.</li>
          </ul>

          <h3>Flaws</h3>
          <ul>
            <li>Bloodthirsty: You struggle to restrain yourself, often seeking violence when it isn't necessary.</li>
            <li>Destructive: Your inability to hold back leaves ruin in your wake, even when it harms allies.</li>
            <li>Uncontrollable: Your rage blinds you, causing reckless decisions and collateral damage.</li>
            <li>Cruel: You enjoy the suffering of those weaker than you, taking pride in their despair.</li>
          </ul>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={pathOfWrathImage} 
                alt="Path of Wrath"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={pathOfWrathImage}
                download="PathOfWrath.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesPathOfWrath; 