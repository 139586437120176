import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import queenMabImage from '../../assets/images/deities/queenmab.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const QueenMabFrozenVeil = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={queenMabImage} 
          alt="Queen Mab: Frozen Veil Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Queen Mab: The Frozen Veil Path</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="race-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Protect and control the cycles of winter, ensuring the world is prepared for renewal.</li>
              <li>Use cold and ice strategically as both weapons and shields in your endeavors.</li>
              <li>Value strategic planning and calculated action, recognizing that every move has consequences.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>They may not touch, use, or cast flames under any circumstances.</li>
              <li>Always consider the long-term implications of your actions and prioritize the preservation of balance.</li>
              <li>Seek to uncover hidden truths and expose deceptions that threaten the stability of the realm.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <p>
            Following the Frozen Veil grants mastery over icy resilience and battlefield control. Practitioners embody the duality of winter, wielding its beauty and lethality to manipulate their surroundings and protect their allies. By channeling Queen Mab's wintry dominion, they become paragons of strategy, wielding frost to outlast and punish their adversaries.
          </p>
        </div>

        <div className="content-block">
          <h2>Queen Mab: The Winter Monarch</h2>
          <p>
            Queen Mab stands as a counterbalance to her sister Titania's chaotic growth, embodying the calculated precision of winter. Her domain, a crystalline expanse of beauty and cold, mirrors her unyielding resolve. Those who follow her channel her strength, wielding frost and stillness as extensions of her indomitable will.
            Mab's rule is characterized by strategy and resilience. Her court of frost and shadows mirrors her secretive and tactical nature, a place where every flurry and frostbitten gale serves a purpose. Legends tell of her as a deity of necessity, teaching her followers that winter is not an end but a time of preparation and endurance. Through her guidance, they learn to survive and adapt, embodying the stillness and power of frost.
          </p>
        </div>

        <div className="content-block">
          <h2>Queen Mab's Domain</h2>
          <p>
            Queen Mab's realm is a frozen expanse of breathtaking beauty and perilous challenges. Towering glaciers and endless snowfields stretch beneath a pale, eternal sky, while hidden caverns of shimmering ice conceal secrets of ages past. Frost-laden winds sweep across the landscape, carrying with them whispers of her icy court's secrets.
            Shadows and frost intertwine in Mab's domain, creating a land of stark contrasts. Snowflakes hang suspended in the air, glinting like stars in the faint light, while frost creeps along every surface, freezing all it touches. The landscape embodies winter's necessity—a season of stillness, reflection, and preparation for the rebirth to come.
          </p>
        </div>

        <div className="content-block">
          <h2>Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Frozen Ground</h3>
            </div>
            <p>
              You can cause the ground in a 15-foot radius around you to become coated in ice. Creatures of your choice must succeed on a Dexterity saving throw (DC = 8 + your proficiency bonus + your Constitution modifier) or fall prone. The ice lasts for 1 minute, creating difficult terrain for all creatures except you. This ability can be used a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Icebound Wall</h3>
            </div>
            <p>
              As an action, you can create a wall of ice 5 feet long per level. The wall is 5 feet high and 1 foot thick and lasts for 1 minute or until destroyed. Each 5-foot section has AC 10 and 10 hit points. The wall provides three quarters cover cover and creates difficult terrain on either side. This ability can be used a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying the Path of Frost</h2>
          <p>
            Followers of Queen Mab are defined by their resilience and tactical precision. They value endurance and control, using the power of winter to shape the battlefield and protect those they hold dear. As living manifestations of Queen Mab's icy dominion, they bring the beauty and lethality of winter to bear in all they do.
            Choosing this path is a commitment to survival and strategy, embodying the stillness and power of frost to endure even the harshest challenges. Practitioners of the Frosted Path serve as defenders, tacticians, and symbols of unyielding strength, mirroring the indomitable spirit of Queen Mab herself.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={queenMabImage} 
              alt="Queen Mab: Frozen Veil Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={queenMabImage}
              download="QueenMab.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueenMabFrozenVeil;
