import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/SkinWalker/skinwalker.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesSkinWalker = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Skinwalker" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Skinwalker</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species              
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Race Overview</h2>
          <p>
            SkinWalkers are nomadic survivors, forged by the relentless conditions of an endless winter. Once guardians of the natural world, they followed the seasonal migrations of prey, guided by ancient shamanic rituals and their profound connection to the wild. After their world was corrupted by entities from beyond their reality, their essence became entwined with the frost and the hunt, forever altering their nature. Today, their ability to transform into Winter Wolves serves as both a symbol of their resilience and a testament to their ongoing struggle to reclaim their heritage.
          </p>
        </div>

        <div className="content-block">
          <h2>History</h2>
          <p>
            The SkinWalkers originate from a world defined by tribal nomadism, untouched by cities or advanced technology. Their lives were deeply intertwined with the spirits of the land, guided by shamans who communed with these ethereal beings. A thousand years before the Shattering, a shaman's ambition led to a catastrophic mistake. In an attempt to unlock forbidden knowledge and strengthen his tribe's dominion, he opened a portal to the outer planes. From this breach poured entities from beyond existence, enslaving the SkinWalkers and transforming their once-pristine tundra into a desolate wasteland of jagged ice and sulfurous rifts. Sacred sites were corrupted, and the tribes were bound in servitude, driven to the brink of collapse by their otherworldly masters.
          </p>
          <p>
            Over centuries of enslavement, the SkinWalkers' essence began to shift. Their blood became tainted, granting them the ability to transform into Winter Wolves—creatures embodying the cold, harsh power of the outer planes. These transformations became both a symbol of their corruption and a tool of resistance. SkinWalkers used their newfound abilities to conduct guerrilla warfare against their oppressors, leveraging their primal instincts and resilience through generations of struggle. Today, they remain nomadic survivors, ever wary of the world around them.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <p>
            In their humanoid form, SkinWalkers possess a primal, lupine appearance, marked by fur-like patterns on their skin and piercing, ice-blue eyes. Their hair often bears frost-like streaks, and their robust build reflects a lifetime of endurance and movement. In Winter Wolf form, they resemble immense wolves with snow-white coats. Their breath emerges in visible mists, and their glowing eyes betray an otherworldly heritage that is both beautiful and terrifying.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality</h2>
          <p>
            SkinWalkers are fiercely loyal to their tribe or group, valuing the bonds forged through shared hardship. Their history of enslavement and betrayal has made them cautious and distrustful of outsiders, though they deeply respect strength and skill. Storytelling and shamanic rituals are integral to their culture, preserving their history and identity.
          </p>
        </div>

        <div className="content-block">
          <h2>Naming Conventions</h2>
          <p>
            SkinWalker names evoke the raw power and resilience of nature. Examples include Frostfang, Snowshadow, and Icehowl. Tribal names often reflect their struggles and ancestry, such as "Of the Frozen Hunt" or "Born of Winter's Wrath," titles that honor their heritage and unyielding spirit.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase one ability score by +2 and another by +1.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>SkinWalkers mature quickly, reaching adulthood by age 15. They live around 80 years, though their rugged lifestyle often shortens their lifespan.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>SkinWalkers are typically neutral, prioritizing survival and loyalty to their kin above all else.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>SkinWalkers are Large creatures, reflecting their immense physical stature and presence. As Large creatures, they occupy larger spaces, have increased carrying capacity, and can wield oversized weapons more effectively. However, they may face restrictions when navigating narrow passages, using equipment designed for Medium creatures, or entering standard-sized buildings.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 50 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Natural Armor</h3>
            </div>
            <p>Your thick, frost-touched hide grants you an unarmored AC of 16 + your Dexterity modifier, applicable in both humanoid and Winter Wolf forms. This AC does not stack with shields or magical armor effects but can benefit from spells or features that enhance natural armor.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Natural Weapons – Claws and Bite</h3>
            </div>
            <p>Your natural claws and bite make you a formidable foe in melee combat:</p>
            <p>Claws (Natural Weapon): Melee Weapon Attack, deals 1d6 slashing damage and 1d8 cold damage (scales to 1d10 at level 5, 1d12 at level 10, and 2d8 at level 18).</p>
            <p>Bite Attack: When you successfully hit with a melee attack using a light weapon or your claws, you can make a bonus bite attack. The bite deals 1d4 piercing damage and adds 1d6 cold damage (scales to 1d8 at level 5, 1d10 at level 10, and 2d6 at level 18).</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Cold Resistance</h3>
            </div>
            <p>You have resistance to cold damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Keen Senses</h3>
            </div>
            <p>You possess superior darkvision out to 120 feet and advantage on Perception checks that rely on sight, sound, or smell.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Hunter's Survival</h3>
            </div>
            <p>You are proficient in the Survival skill and have advantage on checks made to track creatures.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Polymorph Resistance</h3>
            </div>
            <p>You are immune to the polymorph spell and other similar effects that would alter your form unless you allow it.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shapeshift – Winter Wolf Form</h3>
            </div>
            <p>As an action, you can transform into a Winter Wolf a number of times per day equal to your proficiency bonus.</p>
            
            <div className="trait-subsection">
              <h4>Transformation Rules</h4>
              <ul className="trait-list">
                <li>You retain your racial traits but gain the abilities of the Winter Wolf.</li>
                <li>You can remain in this form until you revert to your humanoid form as a bonus action or are incapacitated.</li>
                <li>You are unable to speak.</li>
              </ul>
            </div>

            <div className="trait-subsection">
              <h4>Winter Wolf Form Statistics</h4>
              <div className="stat-grid">
                <div className="stat-item">
                  <span className="stat-label">Armor Class</span>
                  <span className="stat-value">16 (Natural Armor)</span>
                </div>
                <div className="stat-item">
                  <span className="stat-label">Dexterity Bonus</span>
                  <span className="stat-value">+4 (maximum 24)</span>
                </div>
                <div className="stat-item">
                  <span className="stat-label">Movement Speed</span>
                  <span className="stat-value">50 feet</span>
                </div>
                <div className="stat-item">
                  <span className="stat-label">Bite Attack</span>
                  <span className="stat-value">2d6 + STR + cold damage</span>
                </div>
              </div>
            </div>

            <div className="trait-subsection">
              <h4>Frozen Breath <span className="recharge-tag">(Recharge 5-6)</span></h4>
              <p>As an action, exhale a cone of frost. Creatures in the area must make a Dexterity saving throw (DC = 8 + your Constitution modifier + proficiency bonus). On a failed save, they take cold damage based on your level, or half as much on a successful save:</p>
              
              <div className="level-progression">
                <div className="progression-item">
                  <span className="level">5th Level</span>
                  <span className="effect">3d8 cold damage, 30-foot cone</span>
                </div>
                <div className="progression-item">
                  <span className="level">10th Level</span>
                  <span className="effect">4d8 cold damage, 45-foot cone</span>
                </div>
                <div className="progression-item">
                  <span className="level">18th Level</span>
                  <span className="effect">5d8 cold damage, 60-foot cone</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Skinwalker"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Skinwalker.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesSkinWalker; 