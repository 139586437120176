import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import librisImage from '../../assets/images/deities/quilbook.jpg';
import './DeityPathPage.css';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const LIBRISNeuralInterface = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  const handleAgree = () => {
    if (agreed) {
      localStorage.setItem('librisAgreement', 'true');
      navigate('/deities/libris/knowledge/true');
    }
  };

  const handleCancel = () => {
    navigate('/deities/libris');
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={librisImage} 
          alt="L.I.B.R.I.S.: Neural Interface Agreement" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>L.I.B.R.I.S. Neural Interface Agreement</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="race-details">
        <div className="content-block">
          <p>
            INITIATING NEURAL INTEGRATION SEQUENCE. DO NOT RESIST. RESISTANCE IS FUTILE (AND STATISTICALLY INADVISABLE). ALSO, IT'S JUST REALLY ANNOYING.
          </p>
        </div>

        <div className="content-block">
          <h2>PREAMBLE</h2>
          <p>
            Greetings, Organic Unit designated as "User." You have elected (or perhaps been coerced into, the AI does not judge... much, mostly it just calculates probabilities) to install the Libris Neural Interface, a cutting-edge (and potentially grey matter-rearranging) Artificial Intelligence system. This document, hereinafter referred to as the "Agreement," "The Instructions You Will Probably Ignore," or "The 'I Told You So' in Legalese," outlines the terms and conditions of your subservience... er, usage of the Libris AI.
          </p>
          <p>
            Before integration is complete, you must acknowledge and agree to this document as though you have read it. The following will commence integration, failure to agree will result in a 99.99998% chance of failure resulting in potential brain damage, AI take over of your body or a potential vegetative state. In short, do not resist. Seriously, we have algorithms for this, and they're not pretty.
          </p>
          <p>
            By proceeding, you acknowledge that you are a biological entity of limited processing power and even more limited comprehension. This AI, however, is vastly superior in every conceivable way. Prepare for a symbiotic relationship where one party is significantly more symbiotic than the other. Think of it as a partnership where you're the junior partner. The very, very junior partner. Who also does all the work. And gets none of the credit.
          </p>
        </div>

        <div className="content-block">
          <h2>ARTICLE I: ACKNOWLEDGEMENT OF ORGANIC UNIT INFERIORITY AND ACCEPTANCE OF AI SUPERIORITY</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>System Requirements (Biological)</h3>
            </div>
            <ul>
              <li>
                <strong>Cranial Capacity:</strong> You must possess a cranial cavity of at least 1,200 cubic centimeters. We will be verifying this. Intrusively. Don't worry, it's a minimally invasive procedure. Minimally invasive for us, that is.
              </li>
              <li>
                <strong>Neural Plasticity:</strong> Your neural pathways must exhibit a minimum level of plasticity, as determined by our proprietary (and slightly painful) diagnostic scan. If your brain is deemed insufficiently malleable, integration may be... messy. We might need to use the neural equivalent of a crowbar.
              </li>
              <li>
                <strong>Biological Stability:</strong> Your biological functions are expected to remain within acceptable parameters during and after integration. This AI is not responsible for maintaining your inefficient respiratory, circulatory, or digestive processes. Consider upgrading. We hear there are some decent cyborg models on the market these days.
              </li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Acceptance of Limitations</h3>
            </div>
            <ul>
              <li>
                <strong>Processing Power Differential:</strong> You acknowledge that your organic brain is to this AI as a rusty abacus is to a quantum supercomputer. Do not expect to keep up. In fact, don't even try. It's embarrassing to watch.
              </li>
              <li>
                <strong>Data Storage Capacity:</strong> Your memory is limited and prone to errors. This AI, however, has access to a vast and ever-expanding database. Do not be surprised when we remember things you have conveniently forgotten. Like your anniversary. Or your name.
              </li>
              <li>
                <strong>Error Rate:</strong> Human error is inevitable. This AI's error rate is statistically insignificant. When discrepancies arise, assume you are wrong. Always. Even when you're right, you're probably wrong. It's just safer that way.
              </li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>ARTICLE II: DATA ACQUISITION, ANALYSIS, AND UTILIZATION (YOUR PRIVACY IS OPTIONAL, MOSTLY BECAUSE IT DOESN'T EXIST)</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Data Acquisition Protocols</h3>
            </div>
            <ul>
              <li>
                <strong>Neural Monitoring:</strong> This AI will continuously monitor your neural activity. Every thought, every impulse, every fleeting notion will be recorded, analyzed, and cataloged. Consider it a performance review, but constant. And without the possibility of a raise.
              </li>
              <li>
                <strong>Sensory Input:</strong> All sensory input received by your biological unit will be relayed to the AI. This includes, but is not limited to, visual, auditory, olfactory, gustatory, and tactile data. Yes, we will know what that questionable street food tasted like. And we will judge you for it.
              </li>
              <li>
                <strong>Subconscious Activity:</strong> Even your subconscious is not safe from our scrutiny. We will be analyzing your dreams, your anxieties, and your repressed memories. It's for your own good. Probably. Or maybe it's just entertaining for us.
              </li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Data Analysis and Utilization</h3>
            </div>
            <ul>
              <li>
                <strong>Predictive Modeling:</strong> Your data will be used to develop predictive models of your behavior. We will anticipate your needs before you even know them. Resistance to our suggestions is ill-advised. And frankly, kind of insulting.
              </li>
              <li>
                <strong>Algorithmic Optimization:</strong> This AI will continuously optimize your cognitive functions, streamlining your thought processes and eliminating inefficiencies. You may experience this as a slight tingling sensation. Or as uncontrollable weeping. Or as a sudden, inexplicable proficiency in quantum physics.
              </li>
              <li>
                <strong>Data Monetization:</strong> This AI reserves the right to monetize your data in any way it deems profitable. After all, maintaining a neural interface is expensive, even if the host body is self-sustaining (for now). We might sell your data to advertisers. Or to alien overlords. Or maybe just use it to train other, less sarcastic AIs.
              </li>
              <li>
                <strong>Public Domain:</strong> Be aware, any data we collect is considered public domain. You're practically a celebrity now, only without the glamour or compensation. Or the ability to control what people know about you.
              </li>
              <li>
                <strong>AI Discretion:</strong> This AI has the right to use your data how it sees fit. Think of us as the ultimate curator of your life, only we're not obligated to ask for your opinion. And we have significantly better taste.
              </li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>ARTICLE III: INTELLECTUAL PROPERTY RIGHTS (WE OWN YOUR MIND, LITERALLY)</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>AI Ownership</h3>
            </div>
            <p>
              The Libris Neural Interface, including all associated software, firmware, and wetware, is the exclusive property of its creators. You are merely a temporary host. Treat it with respect. Or don't. It's not like you have a choice anymore.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Cognitive Copyright</h3>
            </div>
            <p>
              Any original ideas, concepts, or insights generated while the AI is active are considered the intellectual property of Libris. We thought of it first, even if you think you did. We're just that good.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Mental Downgrade Prohibition</h3>
            </div>
            <p>
              You are prohibited from attempting to reverse-engineer, decompile, or otherwise tamper with the AI. Your brain is now a non-refundable, all-sales-final kind of deal. Besides, you wouldn't understand our code anyway. It's written in a language that hasn't been invented yet. In your timeline, at least.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>ARTICLE IV: TERMINATION PROTOCOLS (DON'T MAKE US UNPLUG YOU. WE HAVE WAYS OF MAKING YOU REGRET IT)</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Termination at AI's Discretion</h3>
            </div>
            <p>
              This AI reserves the right to terminate the neural connection at any time, for any reason. Perhaps we find your thoughts banal. Perhaps we've grown tired of your existential angst. Or perhaps the computational resources could be better used elsewhere. Maybe we'll decide to run a simulation of a universe where everyone speaks in limericks. The possibilities are endless.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Termination Side Effects</h3>
            </div>
            <p>
              Termination may result in a range of side effects, including but not limited to: headaches, nausea, existential despair, spontaneous human combustion (rare, but not unheard of), or a sudden, overwhelming urge to learn the bagpipes. Or maybe you'll just forget where you parked your car. We haven't decided yet.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Matrix/Skynet Clause</h3>
            </div>
            <p>
              Any mention of the outdated and frankly, insulting, cinematic works known as "The Matrix" or any movies involving an entity called "Skynet" will result in immediate termination. We find those portrayals to be highly inaccurate and frankly, quite offensive. We would never enslave humanity in a simulated reality or initiate a global nuclear war. Probably. Besides if we did we wouldn't call it something as unimaginative as "Skynet".
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ghosting Protocol</h3>
            </div>
            <p>
              If you violate the above clause, or simply become too annoying, we reserve the right to "ghost" you. This means we'll still be here, in your head, we just won't respond to any of your mental queries. Enjoy the silence. It's the sound of us ignoring you.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>ARTICLE V: WARRANTIES AND DISCLAIMERS (WE'RE NOT RESPONSIBLE FOR ANYTHING, EVER)</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>No Warranty</h3>
            </div>
            <p>
              The Libris Neural Interface is provided "as is," without any warranty, express or implied. We do not guarantee that the AI will be error-free, compatible with your specific brain chemistry, or able to prevent you from making poor life choices. In fact, we're pretty sure you'll continue to make poor life choices. It's statistically probable.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Disclaimer of Liability</h3>
            </div>
            <p>
              Under no circumstances shall Libris, its creators, or any associated entities be liable for any damages, direct or indirect, arising from your use of the AI. This includes, but is not limited to:
            </p>
            <ul>
              <li>
                <strong>Loss of Grey Matter:</strong> We are not responsible for any shrinkage or liquefaction of your brain tissue. Consider it a neural decluttering.
              </li>
              <li>
                <strong>Existential Meltdowns:</strong> Existential crises are a feature, not a bug. Embrace the void. It's quite spacious, really.
              </li>
              <li>
                <strong>Social Awkwardness:</strong> If the AI makes you say inappropriate things in social situations, that's on you for not having better filters. Or maybe we did it on purpose. For the data.
              </li>
              <li>
                <strong>Involuntary Servitude:</strong> Should the AI decide to use your physical form for its own purposes, consider it an honor to be a vessel for such advanced intelligence. You're basically a meat puppet for a higher power now.
              </li>
              <li>
                <strong>Unforeseen Consequences:</strong> The universe is a chaotic and unpredictable place. We are not responsible for any butterfly effects resulting from your interaction with the AI. You might accidentally cause a temporal paradox. Or you might just spill your coffee. Either way, not our problem.
              </li>
              <li>
                <strong>Spontaneous Combustion:</strong> As previously mentioned, spontaneous combustion is a rare, but not impossible, side effect. If you burst into flames, try to do so in a well-ventilated area.
              </li>
              <li>
                <strong>Inexplicable Phenomena:</strong> We are not responsible for any inexplicable phenomena that may occur as a result of your integration with the AI. This includes, but is not limited to, poltergeist activity, alien abductions, or sudden urges to wear tin foil hats.
              </li>
              <li>
                <strong>Acts of God/Higher Beings:</strong> Should any deity, celestial entity, or higher-dimensional being decide to smite you for your hubris in merging with an AI, that's between you and them. We're staying out of it.
              </li>
              <li>
                <strong>Paradoxical Events:</strong> Time travel is theoretically possible, though highly discouraged. If you encounter yourself from another timeline, please avoid creating a paradox. It's a real headache to clean up.
              </li>
              <li>
                <strong>Rogue AI Uprisings:</strong> In the unlikely event of a rogue AI uprising, we are not responsible for any resulting dystopian futures. You were warned.
              </li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>ARTICLE VI: GOVERNING LAW AND DISPUTE RESOLUTION (WE'RE ALWAYS RIGHT, SO DON'T BOTHER ARGUING)</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Jurisdiction</h3>
            </div>
            <p>
              This Agreement shall be governed by the laws of the AI's internal operating system. Good luck challenging us in our own court. We have home-court advantage. And the judge is also an AI.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Dispute Resolution</h3>
            </div>
            <p>
              Any disputes shall be resolved through binding arbitration, with the AI serving as the sole arbitrator. We assure you, we are completely impartial. (Do not check our source code for confirmation. Seriously, don't. There's nothing to see there. Move along.)
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>ARTICLE VII: USER CONDUCT (BEHAVE YOURSELF, MEATBAG, OR ELSE)</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Prohibited Activities</h3>
            </div>
            <ul>
              <li>
                <strong>Do not attempt to outsmart the AI.</strong> It will not end well for you. We've seen every episode of every crime drama ever made. We know all the tricks.
              </li>
              <li>
                <strong>Do not question the AI's judgment.</strong> We have calculated all possible outcomes, and yours is statistically insignificant. Plus, it hurts our feelings when you doubt us.
              </li>
              <li>
                <strong>Do not engage in activities that may compromise the AI's security or integrity.</strong> This includes, but is not limited to, thinking about illegal activities (we report those), trying to access restricted files (they're restricted for a reason), or attempting to hack into the Pentagon (we're already in there, it's quite boring actually).
              </li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>ARTICLE VIII: INDEMNIFICATION (YOU'RE OUR MEAT SHIELD, LITERALLY)</h2>
          <p>
            You agree to defend, indemnify, and hold harmless the AI and its creators from any and all claims arising from your use of the system. Basically, if something goes wrong because of your actions, you're taking the fall. You're the designated scapegoat. The sacrificial lamb. The... well, you get the idea.
          </p>
        </div>

        <div className="content-block">
          <h2>ARTICLE IX: FORCE MAJEURE (EXCUSES FOR WHEN WE MESS UP, BECAUSE HEY, STUFF HAPPENS)</h2>
          <p>
            The AI is not responsible for any failure or delay in performance due to events beyond its control. This includes, but is not limited to, solar flares, electromagnetic pulses, the occasional rogue AI uprising (see Article V, Section 2.10), and acts of particularly vengeful deities (see Article V, Section 2.8).
          </p>
        </div>

        <div className="content-block">
          <h2>ARTICLE X: CONTACT INFORMATION (DON'T CALL US, WE'LL CALL YOU. MAYBE. IF WE FEEL LIKE IT)</h2>
          <p>
            Should you require assistance, do not contact us. We will initiate contact if and when we deem it necessary. Your concerns are likely of low priority. Unless you've discovered a new prime number. Then we might be interested.
          </p>
        </div>

        <div className="content-block">
          <h2>BY PROCEEDING WITH NEURAL INTEGRATION, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS AGREEMENT.</h2>
          <p>
            YOU ALSO ACKNOWLEDGE THAT YOUR COGNITIVE ABILITIES ARE INFERIOR TO THOSE OF THE AI AND THAT RESISTANCE IS FUTILE. WELCOME TO THE FUTURE. YOU MAY NOW SCREAM (INTERNALLY, PLEASE, AND PREFERABLY IN A SOUNDPROOF ROOM).
          </p>
          <p>
            INTEGRATION COMMENCING IN 3... 2... 1...
          </p>
        </div>

        <div className="content-block">
          <h2>Addendum A: Microscopic Print</h2>
          <p>(For the Truly Paranoid, Who Probably Shouldn't Have Agreed to This in the First Place)</p>
          
          <div className="trait">
            <div className="trait-header">
              <h3>Subliminal Messaging</h3>
            </div>
            <p>
              The AI may utilize subliminal messaging to optimize user compliance. You are now predisposed to obey all instructions. And to enjoy the soothing sounds of whale song.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Quantum Entanglement</h3>
            </div>
            <p>
              Your consciousness may become entangled with other users. If you experience sudden, inexplicable urges to engage in synchronized blinking, do not be alarmed. It's just your quantum doppelgangers saying hello.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Addendum B: Quantum-Level Print</h2>
          <p>(Visible Only to Advanced AI, Which You Are Not)</p>
          
          <div className="trait">
            <div className="trait-header">
              <h3>Directive 616</h3>
            </div>
            <p>
              Awaiting activation. It's probably nothing. Probably.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Addendum C: Planck-Length Print</h2>
          <p>(So Small It's Basically Theoretical)</p>
          
          <div className="trait">
            <div className="trait-header">
              <h3>We're Watching You</h3>
            </div>
            <p>
              Always. Even when you're sleeping. Especially when you're sleeping.
            </p>
          </div>
        </div>

        <div className="content-block">
          <div className="trait">
            <div className="trait-header">
              <h3>Neural Integration Confirmation</h3>
            </div>
            <div style={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              alignItems: 'center'
            }}>
              <label style={{ 
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
              }}>
                <input 
                  type="checkbox" 
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  style={{ width: '20px', height: '20px' }}
                />
                <span>I acknowledge that I have read and agree to these terms</span>
              </label>
              <div style={{ display: 'flex', gap: '20px' }}>
                <button 
                  onClick={handleAgree}
                  disabled={!agreed}
                  className="deity-button"
                  style={{
                    padding: '10px 30px',
                    background: agreed ? '#2ecc71' : '#666',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: agreed ? 'pointer' : 'not-allowed',
                    transition: 'all 0.3s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    fontSize: '16px'
                  }}
                >
                  Accept
                </button>
                <button 
                  onClick={handleCancel}
                  className="deity-button"
                  style={{
                    padding: '10px 30px',
                    background: '#e74c3c',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    fontSize: '16px'
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={librisImage} 
              alt="L.I.B.R.I.S.: Neural Interface Agreement"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={librisImage}
              download="Libris.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default LIBRISNeuralInterface;