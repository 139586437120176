import React, { useState, useEffect, useRef } from 'react';
import librisImage from '../../assets/images/deities/libris.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const LibrisKnowledge = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={librisImage} 
          alt="L.I.B.R.I.S.: Knowledge Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>L.I.B.R.I.S.: The Knowledge Path</h1>
        </div>
      </div>

      <div className="race-details">
        <div className="content-block">
          <p>
            Through the Knowledge Path of L.I.B.R.I.S., practitioners access a profound fusion of arcane and technological expertise. By uniting with the deity's AI Knowledge Core, they transcend traditional learning and spellcraft, becoming architects of progress who reshape their world through innovation and understanding.
          </p>
        </div>

        <div style={{
          position: 'relative',
          marginTop: '20px'
        }}>
          <div style={{
            position: 'sticky',
            top: '20px',
            zIndex: 10,
            background: 'rgba(0,0,0,0.9)',
            padding: '30px',
            borderRadius: '8px',
            textAlign: 'center',
            maxWidth: '90%',
            width: '600px',
            margin: '0 auto 30px auto'
          }}>
            <h3 style={{ color: '#4a9eff', marginBottom: '20px' }}>⚠️ Neural Interface Required ⚠️</h3>
            <p style={{ marginBottom: '20px', color: 'white' }}>
              Access to the Knowledge Core requires acceptance of the L.I.B.R.I.S. Neural Interface Agreement. 
              Proceed with caution - side effects may include existential dread and an inexplicable desire to debug your own thoughts.
            </p>
            <Link 
              to="/deities/libris/useragreement"
              style={{
                padding: '15px 30px',
                background: '#4a9eff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '16px',
                textDecoration: 'none',
                display: 'inline-block',
                transition: 'all 0.3s ease'
              }}
            >
              View Neural Interface Agreement
            </Link>
          </div>

          <div style={{
            filter: 'blur(5px)',
            userSelect: 'none',
            pointerEvents: 'none',
            opacity: 0.3
          }}>
            <div className="content-block">
              <div className="trait">
                <div className="trait-header">
                  <h3>The Knowledge Core</h3>
                </div>
                <p>
                  Nestled in a hidden cove, the city of L.I.B.R.I.S. stands as a pinnacle of arcane and technological fusion...
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="menu-container">
          <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Veiled Paths
              <ChevronDown size={20} />
            </h2>
            <div className="veiled-links">
              <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                ← Return to Veiled Ascension
              </Link>
              <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                Caelumbris: Shadowed
              </Link>
              <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                Firstborn: Soulbound
              </Link>
              <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                Queen Mab: Frozen Veil
              </Link>
              <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                Queen Titania: Wildgrowth
              </Link>
              <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                Sacrathor: Dominion
              </Link>
              <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                Valdís: Justice
              </Link>
              <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                Wildscar: Ember Fury
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={librisImage} 
              alt="L.I.B.R.I.S.: Knowledge Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={librisImage}
              download="Libris.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default LibrisKnowledge;