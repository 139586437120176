import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/subclasses/Umbral/umbralinfiltrator.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesUmbralInfiltrator = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Umbral Infiltrator" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Umbral Infiltrator</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/classes" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes              
                </Link>
                <Link to="/classes/ClassesBurningSeed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Umbral Infiltrator Rogue Subclass</h2>
          <h3>Overview</h3>
          <p>
            Rogues who follow the path of the Umbral Infiltrator are masters of stealth and deception, using the shadows as both a means of concealment and a weapon. They are adept at navigating the darkest corners of the world, striking from the shadows and vanishing without a trace. Their connection to the deity Caelumbris grants them unique abilities to manipulate and control shadows, making them formidable adversaries and elusive allies.
          </p>
        </div>

        <div className="content-block">
          <h2>Features</h2>
          <h3>Shadow Abilities</h3>

          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Familiar</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>
              You gain the ability to form a familiar using your own shadow. You can cast the <em>find familiar</em> spell, but it does not require material components. The familiar is a manifestation of your shadow. You can communicate telepathically with your familiar and perceive through its senses as long as it is within 100 feet of you. The familiar can be summoned or dismissed as a bonus action.
            </p>
            <p>
              Your shadow familiar is an extension of yourself and cannot be more than 100 feet away from you. It can perform tasks, deliver messages, and assist in minor activities. The familiar can take the Attack action using your proficiency bonus for attack and damage rolls. If your familiar is killed or dispelled, it reforms from your shadow after you complete a short rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Step</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>
              As a bonus action, you can teleport up to 60 feet to an unoccupied space, provided that the space is not occupied by any solid object or creature. If you teleport within 5 feet of a creature, you have advantage on your next attack roll and deal an additional 1d8 psychic damage against that creature before the end of your turn. You can use this ability a number of times equal to your proficiency bonus, regaining all uses on a long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Coordinated Assault</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>
              When you use Shadow Step to teleport beside an enemy adjacent to an ally, your ally can use their reaction to make a melee attack against the same enemy with advantage.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Swap</h3>
              <span className="level-tag">Level 5th</span>
            </div>
            <p>
              You can swap positions with your shadow familiar as a bonus action, provided it is within 100 feet of you and in line of sight. You can use this ability a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shadow's Veil</h3>
              <span className="level-tag">Level 6th</span>
            </div>
            <p>
              Your command over shadows enhances your evasion and strategic combat capabilities. As a bonus action, you can use Cunning Action to meld with the shadows, gaining the effects of the <em>invisibility</em> spell until the end of your next turn. You can use this ability a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Tactical Flanking</h3>
              <span className="level-tag">Level 6th</span>
            </div>
            <p>
              When you use Shadow Step or Shadow Swap to position yourself to flank an enemy with an ally, you gain a +2 bonus to your AC until the start of your next turn. Additionally, your ally gains advantage on their next attack roll against the flanked enemy.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Clone</h3>
              <span className="level-tag">Level 9th</span>
            </div>
            <p>
              You gain the power to conjure a shadowy duplicate of yourself, offering strategic advantages in combat and infiltration. As a bonus action, you can create a Shadow Clone adjacent to you. The clone has AC equal to yours but only 1 hit point. It can move, act independently, and take any action you can take except it can only use limited use abilities once. It must stay within 30 feet of you. The clone disappears if it takes any damage or if you dismiss it as a bonus action. The clone has a movement speed equal to yours. You can use this ability a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Attack from the Shadows</h3>
              <span className="level-tag">Level 9th</span>
            </div>
            <p>
              When you make a melee attack against an enemy while your Shadow Clone is within 5 feet of that enemy, you gain advantage on the attack roll. Additionally, if your Shadow Clone is within 5 feet of an enemy, that enemy has disadvantage on attack rolls against you.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shade's Guise</h3>
              <span className="level-tag">Level 13th</span>
            </div>
            <p>
              You gain the ability to transform into a living shadow. As an action, you can transform into a shadow for up to 1 round per character level. While in this form, you gain the following benefits:
            </p>
            <ul>
              <li>You can move through a space as narrow as 1 inch without squeezing.</li>
              <li>You gain resistance to necrotic damage.</li>
              <li>You gain immunity to the frightened condition.</li>
              <li>You can move through other creatures and objects as if they were difficult terrain. If you pass through a creature while in this form, they take 1d10 psychic damage. If you end your turn inside an object or creature, you are shunted to the nearest unoccupied space and take 1d10 force damage.</li>
              <li>While you are in dim light or darkness, you gain a flying speed of 30 feet.</li>
            </ul>
            <p>
              This transformation ends early if you take any damage or if you dismiss it as a bonus action. You can use this ability a number of times equal to your proficiency bonus, regaining all uses on a long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Domain</h3>
              <span className="level-tag">Level 17th</span>
            </div>
            <p>
              You gain the ability to create a localized area of darkness, enhancing your stealth and evasion capabilities. As an action, you can create a 30-foot radius of magical darkness centered on you. This domain lasts for up to 1 minute and requires concentration, or until you dismiss it as a bonus action. While within this domain, you and up to a number of allies equal to your Intelligence modifier of your choice gain the following benefits:
            </p>
            <ul>
              <li>You and your allies within the domain gain advantage on Dexterity (Stealth) checks.</li>
              <li>Enemies within the domain have disadvantage on attack rolls against you and your allies.</li>
              <li>You can teleport to any location within the darkness as a bonus action once per turn per person in the darkness.</li>
            </ul>
            <p>
              You can use this ability a number of times equal to your proficiency bonus, regaining all uses on a long rest.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying an Umbral Infiltrator</h2>
          <p>
            As an Umbral Infiltrator, you are a master of stealth and deception, utilizing the shadows as both a means of concealment and a weapon. Your connection to the deity Caelumbris grants you unique abilities to manipulate and control shadows, making you a formidable adversary and an elusive ally. You may find yourself drawn to the darker corners of the world, where the shadows are deepest and the secrets are most closely guarded. Your abilities make you an excellent spy, assassin, or thief, and your connection to Caelumbris may lead you to uncover hidden truths and ancient mysteries.
          </p>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Mysterious:</strong> You have an enigmatic aura that makes others curious about your true nature and intentions.</p>
            <p><strong>Elusive:</strong> You are difficult to pin down, always seeming to slip away just as others think they have you figured out.</p>
            <p><strong>Cunning:</strong> You have a sharp mind and a talent for deception, always thinking several steps ahead.</p>
            <p><strong>Resourceful:</strong> You are adept at finding creative solutions to problems, using the shadows and your surroundings to your advantage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Secrecy:</strong> Knowledge is power, and the secrets you uncover should be guarded closely.</p>
            <p><strong>Freedom:</strong> The shadows offer a refuge from the prying eyes of the world, and you strive to protect that freedom for yourself and others.</p>
            <p><strong>Balance:</strong> The light and the dark are two sides of the same coin, and you seek to maintain the equilibrium between them.</p>
            <p><strong>Knowledge:</strong> The pursuit of hidden truths and ancient mysteries is a worthy endeavor, and you are driven to uncover the secrets of the world.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>The Shadow Network:</strong> You are part of a secretive organization of shadow manipulators, and you value the bonds and information shared among your allies.</p>
            <p><strong>A Hidden Truth:</strong> You are driven by the desire to uncover a specific secret or hidden truth, and you will stop at nothing to reveal it.</p>
            <p><strong>A Mentor's Legacy:</strong> You were trained by a master of shadows, and you strive to uphold their teachings and carry on their legacy.</p>
            <p><strong>A Debt Repaid:</strong> You owe a debt to someone who helped you in the past, and you are determined to repay that debt, no matter the cost.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Overconfident:</strong> Your mastery of the shadows can make you overestimate your abilities, leading you to take unnecessary risks.</p>
            <p><strong>Secretive:</strong> You guard your secrets closely, even from those you trust, which can strain your relationships.</p>
            <p><strong>Impulsive:</strong> Your desire for knowledge and secrets can lead you to act recklessly, without fully considering the consequences.</p>
            <p><strong>Isolated:</strong> Your connection to the shadows can make you feel disconnected from the world around you, leading to a sense of isolation and loneliness.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Equipment</h2>
          <p>As an Umbral Infiltrator, your equipment reflects your stealthy and deceptive nature. You might carry:</p>
          <ul>
            <li>A set of dark, nondescript clothing that blends into the shadows.</li>
            <li>A cloak or hood that helps conceal your features and movements.</li>
            <li>A pouch containing a variety of small, useful items, such as lockpicks, a small mirror, and a set of caltrops.</li>
            <li>A dagger or short sword, preferably one with a dark or shadowy appearance.</li>
            <li>A backpack containing a bedroll, a mess kit, 10 torches, a tinderbox, and a waterskin.</li>
            <li>A belt pouch containing 15 gold pieces.</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Multiclassing</h2>
          <p>If you choose to multiclass, you can combine your rogue abilities with those of another class to create a unique and versatile character. Some multiclassing options might include:</p>
          <div className="trait">
            <p><strong>Sorcerer (Shadow Magic):</strong> The sorcerer's innate magical abilities can enhance your shadow manipulation, granting you access to powerful spells that complement your stealthy nature.</p>
            <p><strong>Warlock (The Shadow):</strong> The warlock's pact with a shadowy patron can grant you additional shadow-based abilities and invocations, further enhancing your control over the darkness.</p>
            <p><strong>Ranger (Gloom Stalker):</strong> The ranger's expertise in stealth and survival can complement your shadowy abilities, making you a formidable hunter in the darkest corners of the world.</p>
            <p><strong>Monk (Way of Shadow):</strong> The monk's mastery of ki and shadowy abilities can enhance your stealth and combat prowess, making you a deadly and elusive warrior.</p>
          </div>
          <p>
            By embracing the path of the Umbral Infiltrator, you become a master of the shadows, using your unique abilities to navigate the darkest corners of the world and uncover its hidden secrets. Your connection to Caelumbris grants you the power to manipulate and control the shadows, making you a formidable adversary and an elusive ally. Whether you choose to operate in the shadows as a spy, an assassin, or a thief, your abilities make you a valuable asset to any team and a force to be reckoned with in the world of Velum.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Gravewalker"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Gravewalker.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesUmbralInfiltrator; 