import React from 'react';
import { motion } from 'framer-motion';
import { Swords } from 'lucide-react';
import './Subclasses.css';

const Subclasses = () => {
  return (
    <div className="subclasses-welcome" style={{ background: '#0a0a0a' }}>
      <motion.div 
        className="subclasses-welcome-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Swords size={48} className="welcome-icon" style={{ color: '#4a9eff', marginBottom: '1rem' }} />
        <h1>Fragments of Forgotten Paths</h1>
        <p className="welcome-subtitle">
          Ancient traditions reborn
        </p>
      </motion.div>
    </div>
  );
};

export default Subclasses;