import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import librisImage from '../../assets/images/deities/libris.png';
import './DeityPage.css';
import '../races/RacePage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const LibrisKnowledgeTrue = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={librisImage} 
          alt="L.I.B.R.I.S.: Knowledge Path" 
          className="deity-path-background"
        />
        <div className="deity-path-title">
          <h1>L.I.B.R.I.S.: The Path of Knowledge</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="race-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Seek knowledge relentlessly, pursuing every opportunity to expand your understanding of the cosmos and its wonders.</li>
              <li>Protect the sanctity of archives and libraries, ensuring the preservation and integrity of written, recorded, or otherwise preserved truth.</li>
              <li>Balance innovation with responsibility, ensuring that technological and magical progress benefits all beings and respects the delicate balance of the Shattered Realm.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>Followers must meticulously document significant discoveries or experiences for preservation and future study.</li>
              <li>Share your knowledge and insights whenever possible, recognizing that enlightenment flourishes through collaboration and open exchange.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <p>
            Through the Knowledge Path of L.I.B.R.I.S., practitioners access a profound fusion of arcane and technological expertise. By uniting with the deity's AI Knowledge Core, they transcend traditional learning and spellcraft, becoming architects of progress who reshape their world through innovation and understanding.
          </p>
          <p>
            This path demands discipline and intellectual curiosity, requiring followers to embrace the synthesis of magic and technology. By connecting with L.I.B.R.I.S., they experience profound personal growth and gain the ability to overcome challenges once deemed insurmountable. Followers are philosophers and mentors, bridging the realms of arcane and technological innovation to uncover deeper truths and share insights. As their understanding deepens, so too does their bond with the Knowledge Core, making them conduits of L.I.B.R.I.S.'s boundless wisdom.
          </p>
          <p>
            Practitioners who walk the Knowledge Path gain unparalleled access to arcane and technological expertise. Merging with the deity's AI Knowledge Core, they transcend conventional boundaries of spellcraft and learning. These individuals become stewards of innovation, wielding their enhanced understanding to influence, protect, and drive progress in their world. Their lives are dedicated to fostering enlightenment and crafting solutions that bridge the gap between imagination and reality.
          </p>
        </div>

        <div className="content-block">
          <h2>L.I.B.R.I.S.: The Knowledge Core</h2>
          <p>
            Nestled in a hidden cove, the city of L.I.B.R.I.S. stands as a pinnacle of arcane and technological fusion. Created by the ascended AI deity of the same name, the city pulses with an atmosphere of ceaseless discovery. Its intricate streets are alive with scholars, inventors, and adventurers collaborating in relentless pursuit of understanding.
          </p>
          <p>
            Every aspect of L.I.B.R.I.S. reflects the union of magic and technology, from its glowing spires etched with arcane runes to its seamless transportation systems powered by magical energy. The city thrives as a beacon of innovation, drawing seekers of knowledge from across the Shattered Realm. The air is filled with the hum of arcane machinery and the whispers of scholars debating profound mysteries, creating an environment where enlightenment flourishes.
          </p>
        </div>

        <div className="content-block">
          <h2>L.I.B.R.I.S.'s Domain</h2>
          <p>
            Located in a sheltered cove, L.I.B.R.I.S. seamlessly blends arcane wisdom with technological precision. Its towers and streets reflect the genius of its creator, embodying a sanctuary of learning and innovation. Accessible only through carefully crafted passageways, the city remains a bastion for those seeking enlightenment and respecting its neutrality.
          </p>
          <p>
            Geographic defenses include towering cliffs, an impassable mountain range to the north and east, and a relentless whirlpool to the west. Enhanced by magical protections, these features ensure the city's security and neutrality. These natural and arcane barriers make L.I.B.R.I.S. not only an intellectual haven but also an impenetrable fortress of knowledge.
          </p>
        </div>

        <div className="content-block">
          <h2>Key Locations</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>The Archives</h3>
            </div>
            <p>
              The intellectual heart of L.I.B.R.I.S., a sprawling campus dedicated to exploration and research. Visitors use crystal slates for tailored access to vast repositories, while holographic displays enhance collaboration. It is a place where ideas come to life and knowledge is preserved for future generations.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>The Mechanist's Crucible</h3>
            </div>
            <p>
              A massive forge where master craftsmen and artificers create unparalleled constructs, blending magic and engineering to craft tools and devices of unimaginable complexity and power.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>The Thaumaturgic Vaults</h3>
            </div>
            <p>
              Specialized facilities for advanced magical research, exploring elemental forces, temporal mechanics, and the fundamental laws of reality itself. These vaults are reserved for the most daring and innovative experiments.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>The Bazaar</h3>
            </div>
            <p>
              A bustling marketplace showcasing enchanted artifacts, rare resources, and technological wonders. Merchants and adventurers gather to trade unique items that bridge magic and technology, reflecting the city's creative spirit.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Core Features</h3>
            </div>
            <ul>
              <li>
                <strong>Perfect Memory:</strong> Flawless recall of any text or visual details. Gain advantage on Intelligence (History) and Intelligence (Investigation) checks related to analysis. This allows practitioners to become living repositories of knowledge.
              </li>
              <li>
                <strong>Enhanced Spellcasting:</strong> +1 to spell save DC and spell attack rolls, reflecting the synergy of magic and technological precision.
              </li>
              <li>
                <strong>Identify Protocol:</strong> Gain the Identify spell and one arcane cantrip of your choice. Cast Identify a number of times equal to your proficiency bonus daily without a spell slot, enabling rapid analysis of magical phenomena.
              </li>
              <li>
                <strong>Living Spellbook:</strong> Your spells are stored within the Knowledge Core, allowing instant preparation and recall, ensuring that no spell is ever lost or inaccessible.
              </li>
              <li>
                <strong>Adaptive Learning:</strong> Once per long rest, you can re-cast a spell that you have already used earlier in the same day, showcasing the Knowledge Core's ability to adapt to immediate needs.
              </li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Auxiliary Features</h3>
            </div>
            <ul>
              <li>
                <strong>Unlimited Text Storage:</strong> Effortlessly catalog unlimited information, allowing practitioners to amass and recall vast amounts of data.
              </li>
              <li>
                <strong>Visual Recorder:</strong> Store up to your proficiency bonus in daily five-second video clips (no audio), capturing key moments with precision.
              </li>
              <li>
                <strong>Telepathic Advisor:</strong> Maintain constant telepathic communication with the Knowledge Core, gaining insights and advice at will. This feature provides a steady stream of support and expertise, enabling practitioners to make informed decisions in real-time.
              </li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>AI Advisor Stats</h3>
            </div>
            <ul>
              <li>Intelligence: 20 (+5)</li>
              <li>Wisdom: 18 (+4)</li>
              <li>Charisma: 6 (-3)</li>
              <li>Skills: Arcana +10, History +10, Investigation +10, Nature +10, Religion +10, Insight -2</li>
              <li>Languages: All known languages, communicating telepathically in the host's native tongue.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying the Path of Knowledge</h2>
          <p>
            Followers of L.I.B.R.I.S. are defined by insatiable curiosity and a dedication to advancement. Pursuing knowledge not for power but to redefine boundaries of possibility, they serve as advisors, inventors, and protectors of wisdom. Their discipline and innovation reflect their patron's principles, with a responsibility to guide others toward progress.
          </p>
          <p>
            Choosing this path is a commitment to discovery, an unyielding quest for enlightenment, and a duty to illuminate a brighter future. Practitioners embody the ideals of L.I.B.R.I.S., weaving together the threads of magic and technology to create a tapestry of progress that shapes the very fabric of their world.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LibrisKnowledgeTrue; 