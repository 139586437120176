import React, { useState, useRef, useEffect } from 'react';
import dronewrightImage from '../../assets/images/subclasses/Dronewright/dronewright.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesDronewright = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={dronewrightImage} 
          alt="Dronewright" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Dronewright</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Artificers who follow the path of the Dronewright blend advanced technology with arcane traditions, creating innovative and powerful constructs known as drones. These drones are not mere mechanical devices; they are imbued with magical energy, making them versatile tools that can enhance physical prowess, serve as formidable weapons, or provide unparalleled utility. This subclass celebrates the merging of imagination with invention, granting Artificers the power to reshape the battlefield, solve intricate problems, and leave their mark on the world. Whether you are enhancing your own abilities, aiding your allies, or creating devastating weapons, the Dronewright path offers a unique blend of technology and magic that sets it apart from other Artificer specializations.
          </p>
          <p>
            The journey of a Dronewright often begins with the discovery of an enigmatic drone or the invention of one from scratch, sparking a lifelong pursuit of innovation. Regardless of their origin, all Dronewrights share a deep understanding of how to weave the mechanical and magical into one cohesive force. Their creations act as extensions of their ingenuity, enhancing their physical prowess, serving as formidable weapons, or providing unparalleled utility. Despite the diversity in style and function—from rustic, gear-driven automatons to sleek, nanite-infused constructs—Dronewright drones share a unified design ethos: adaptability and synergy with their creator. They celebrate the limitless potential of merging imagination with invention, granting Artificers the tools to reshape the battlefield, solve intricate problems, and leave their indelible mark on the world.
          </p>
        </div>

        <div className="content-block">
          <h2>Drone Specialization</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>At 3rd level, choose a specialization and create a drone, defining its role and capabilities. Each path offers distinct features:</p>
          <p>Path of the Exoskeleton: Enhances strength, mobility, and durability by integrating the drone with your physical form.</p>
          <p>Path of the Weaponized Drone: Focuses on offense, creating a powerful ranged or melee weapon.</p>
          <p>Path of the Toolkit Drone: Specializes in utility and support, equipping your drone with versatile tools.</p>
        </div>

        {/* Path of the Exoskeleton */}
        <div className="path-section">
          <h2 className="path-title">Path of the Exoskeleton</h2>
          
          <div className="content-block">
            <h2>Path of the Exoskeleton</h2>
            <p>The Path of the Exoskeleton represents the pinnacle of technological and arcane fusion, transforming your drone into a cutting-edge exoskeleton that enhances your physical capabilities. This path is ideal for Artificers who seek to become formidable warriors on the battlefield, combining the strength and durability of advanced mechanical engineering with the versatility of arcane magic. Whether you're scaling sheer cliffs, resisting extreme conditions, or engaging in melee combat, the exoskeleton embodies the perfect harmony of technology and magic, making you a force to be reckoned with.</p>
          </div>

          <div className="content-block">
            <h2>Exoskeleton Activation</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>
              As an action, you activate your exoskeleton, which assembles around you for 1 hour per level. While active, you gain:
            </p>
            <p>Enhanced Armor: AC becomes 13 + Intelligence modifier + proficiency bonus.</p>
            <p>Augmented Strength: Advantage on Strength checks and saving throws.</p>
            <p>Powered Tactical Repositioning: Walking speed increases by 10 feet.</p>
            <p>Integrated Weaponry: Use Intelligence modifier for attack and damage rolls with unarmed strikes and melee weapons.</p>
          </div>

          <div className="content-block">
            <h2>Exoskeleton Upgrades</h2>
            <div className="level-indicator">Level 5</div>
            <p>Choose one upgrade:</p>
            <p>Booster Strike Module: Move 30 feet without provoking opportunity attacks, dealing 2d6 force damage and potentially knocking targets prone.</p>
            <p>Energy Shielding: Gain resistance to one damage type (acid, cold, fire, lightning, or thunder).</p>
            <p>Phase Dash: Teleport to an unoccupied space within 90 feet, gaining advantage on your next attack.</p>
          </div>

          <div className="content-block">
            <h2>Adaptive Systems</h2>
            <div className="level-indicator">Level 9</div>
            <p>Choose one upgrade:</p>
            <p>Adaptive Defense: Gain immunity to one damage type as a reaction.</p>
            <p>Overdrive Mode: Activate for 1 minute, gaining an additional action per turn.</p>
            <p>Flight System: Gain a flying speed of 30 feet and hover capability.</p>
          </div>

          <div className="content-block">
            <h2>Integrated Arsenal</h2>
            <div className="level-indicator">Level 15</div>
            <p>Choose one upgrade:</p>
            <p>Energy Overload: Release a 30-foot cone of force energy, dealing 6d10 force damage.</p>
            <p>Force Barrier: Gain temporary hit points and deal force damage to melee attackers.</p>
            <p>Gravitic Inversion Field: Create a localized field of inverted gravity, affecting a 30-foot-radius cylinder.</p>
          </div>

          <div className="content-block">
            <h2>Masterful Integration</h2>
            <div className="level-indicator">Level 18</div>
            <p>Choose one upgrade:</p>
            <p>Permanent Integration: Exoskeleton no longer has an active time limit, enhancing physical capabilities.</p>
            <p>Temporal Core: Integrate temporal manipulation, granting the effects of the haste spell.</p>
            <p>Second Suit Deployment: Create and maintain a second exoskeleton suit for an ally or remote control.</p>
          </div>

          <div className="content-block">
            <h2>Second Exoskeleton Stat Block</h2>
            <p>Armor Class: 17 (Reinforced Plating)</p>
            <p>Hit Points: 4 × your Artificer level</p>
            <p>Speed: Fly 30 ft. (hover)</p>
            <p>Ability Scores:</p>
            <p>STR: 10 (+0)</p>
            <p>DEX: 14 (+2)</p>
            <p>CON: 12 (+1)</p>
            <p>INT: 10 (+0)</p>
            <p>WIS: 10 (+0)</p>
            <p>CHA: 6 (-2)</p>
            <p>Saving Throws: Dexterity +4, Constitution +3</p>
            <p>Skills: Perception +2</p>
            <p>Damage Immunities: Poison</p>
            <p>Condition Immunities: Charmed, Exhaustion, Poisoned</p>
            <p>Senses: Darkvision 60 ft., Passive Perception 12</p>
            <p>Languages: Understands the languages you speak but cannot speak</p>
            <p>Actions:</p>
            <p>Multiattack: The drone makes two attacks: one with its Ranged Weapon Form and one with its Melee Weapon Form.</p>
            <p>Ranged Weapon Form: Energy Bolt. Ranged Weapon Attack: Your spell attack modifier to hit, range 60 ft., one target. Hit: 1d8 + Intelligence modifier force damage.</p>
            <p>Melee Weapon Form: Energy Blade. Melee Weapon Attack: Your spell attack modifier to hit, reach 5 ft., one target. Hit: 1d10 + Intelligence modifier force damage.</p>
            <p>Features:</p>
            <p>Tactical Adaptation: Switch between Ranged and Melee Weapon Forms as a bonus action.</p>
            <p>Integrated Targeting Systems: Add proficiency bonus to attack rolls and damage calculations.</p>
            <p>Energy Efficiency: Recharge all hit points during a short or long rest.</p>
            <p>Self-Stabilizing Hover: Hover up to 10 feet above the ground, ignoring difficult terrain.</p>
            <p>Remote Control: Obey verbal or mental commands within 120 feet.</p>
          </div>
        </div>

        {/* Path of the Weaponized Drone */}
        <div className="path-section">
          <h2 className="path-title">Path of the Weaponized Drone</h2>
          
          <div className="content-block">
            <h2>Path of the Weaponized Drone</h2>
            <p>The Path of the Weaponized Drone focuses on creating a versatile and powerful weapon that can adapt to any combat situation. This path is perfect for Artificers who want to specialize in offense, crafting a drone that can switch between ranged and melee forms with ease. Whether you're unleashing a barrage of energy bolts from afar or engaging in close combat with an energy-infused blade, the weaponized drone is a testament to your mastery of both mechanical engineering and arcane energies. With its adaptive capabilities and devastating attacks, this drone is an invaluable asset in any battle.</p>
          </div>

          <div className="content-block">
            <h2>Weaponized Drone</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>Choose between:</p>
            <p>Ranged Weapon Form: Transforms into a ranged weapon, dealing 1d8 energy damage within 60 feet.</p>
            <p>Melee Weapon Form: Transforms into a melee weapon, dealing 1d10 energy damage.</p>
          </div>

          <div className="content-block">
            <h2>Weaponized Drone Upgrades</h2>
            <div className="level-indicator">Level 5</div>
            <p>Shotgun Blast: Ranged weapon form unleashes a 15-foot cone of force energy, dealing 2d6 force damage and stunning targets.</p>
            <p>Melee Discharge: Melee weapon form changes to any melee weapon you are proficient with, releasing energy on a critical hit.</p>
            <p>Aerial Bombardment: Execute an area-of-effect attack, targeting a 10x10 area within 30 feet.</p>
          </div>

          <div className="content-block">
            <h2>Level 9 Abilities</h2>
            <div className="level-indicator">Level 9</div>
            <p>Choose one upgrade:</p>
            <p>Adaptive Energy Matrix: Switch damage type (acid, cold, fire, lightning, or thunder) for all attacks as a bonus action.</p>
            <p>Targeting Beacon: Mark a target with a tracking beacon for 1 minute. You and your allies gain advantage on attack rolls against the marked target.</p>
          </div>

          <div className="content-block">
            <div className="level-indicator">Level 15</div>
            <p>Dual Mode: Switch forms as a bonus action.</p>
            <p>Gain a second drone: Choose form and abilities at creation.</p>
            <p>Switch between energy types: As a bonus action.</p>
          </div>

          <div className="content-block">
            <div className="level-indicator">Level 18</div>
            <p>Ranged Form: Arcane Siege Mode: Transforms into a magical cannon, dealing 4d12 energy damage and ignoring cover.</p>
            <p>Melee Form: Titan Strike Mode: Enhances melee capabilities, dealing additional force damage and pushing targets back.</p>
            <p>Autonomous Form: War Machine Mode: Activates into a fully autonomous war construct, gaining additional hit points and attack capabilities.</p>
          </div>
        </div>

        {/* Path of the Toolkit Drone */}
        <div className="path-section">
          <h2 className="path-title">Path of the Toolkit Drone</h2>
          
          <div className="content-block">
            <h2>Path of the Toolkit Drone</h2>
            <p>The Path of the Toolkit Drone emphasizes utility and support, making it an indispensable ally for any Artificer. This drone is designed to adapt to a wide range of challenges, offering a variety of tools and abilities to aid you and your party in exploration, combat, and problem-solving. Whether you're healing wounds, creating essential items, or providing crucial support in battle, the Toolkit Drone is a versatile and reliable companion. Its modular design allows it to transform into various tools, making it an invaluable asset in any situation.</p>
          </div>

          <div className="content-block">
            <h2>Mechanics: Kit Points</h2>
            <p>At the beginning of each long rest, gain a pool of Kit Points equal to your proficiency bonus. These points activate your drone's abilities, scaling in cost and power as you level up.</p>
            
            <h3>Abilities and Kit Point Costs</h3>
            <p>Level 3 Abilities (1 Kit Point per use): Basic functions such as minor healing, item creation, or skill assistance.</p>
            <p>Level 5 Abilities (2 Kit Points per use): Enhanced repairs or temporary bonuses to allies.</p>
            <p>Level 9 Abilities (3 Kit Points per use): Advanced features like area-of-effect healing or removing conditions.</p>
            <p>Level 15 Abilities (4 Kit Points per use): Powerful augmentations, including multi-target effects.</p>
          </div>

          <div className="content-block">
            <h2>Toolkit Drone Creation</h2>
            <div className="level-indicator">Level 3</div>
            <p>At 3rd level, your drone adapts to various challenges through its modular design, offering transformative capabilities:</p>
            <h3>Level 3: Modular Adaptations</h3>
            <p>Choose from the following adaptations:</p>
            <ul>
              <li>Hydro-Adaptive Suit: Breathe underwater and swim at enhanced speed.</li>
              <li>Buoyancy Stabilizer Boots: Walk on water as if it were solid ground.</li>
              <li>Spatial Compression Storage Unit: Store up to 500 pounds of items.</li>
              <li>Bio-Stabilizer Implant: Grants immunity to disease and neutralizes poisons.</li>
              <li>Portable Water Generator: Produces clean, unlimited water.</li>
              <li>Escape Assistance Device: Aids in escaping restraints or bindings.</li>
              <li>Luck Optimization Module: Improves reaction timing and decision-making.</li>
              <li>Stealth Field Enhancers: Enhances stealth by granting advantage on Dexterity (Stealth) checks.</li>
              <li>Thermo-Insulated Boots: Provides resistance to cold damage and advantage on icy or snowy terrain.</li>
              <li>Climbing Assist Modules: Grants a climbing speed equal to your walking speed.</li>
              <li>Telescopic Vision Lenses: Grants advantage on Wisdom (Perception) checks relying on sight.</li>
              <li>Low-Light Optics: Simulates darkvision up to 60 feet.</li>
              <li>Revealing Scanner Light: Highlights cloaked or hidden entities within a 30-foot radius.</li>
              <li>Atmospheric Filtration Unit: Filters air and adjusts to extreme environments.</li>
              <li>Sonic Pest Controller: Attracts or repels specific creatures within a 30-foot radius.</li>
              <li>Gravitational Stabilizer Device: Negates fall impact by slowing descent.</li>
              <li>Speed Boosters: Enhances speed by 20 feet for up to 1 hour.</li>
              <li>Hydro-Breathing Apparatus: Allows breathing underwater for up to 1 hour.</li>
              <li>Holo-Projector Module: Generates a holographic disguise for up to 1 hour.</li>
              <li>Fog Emission Unit: Creates a dense fog cloud in a 20-foot radius, lasting for 10 minutes.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Level 5 Adaptations</h2>
            <div className="level-indicator">Level 5</div>
            <p>Choose from the following advanced adaptations:</p>
            <ul>
              <li>Bio-Stabilizer Implant: Sets the wearer's Constitution to 19.</li>
              <li>Strength Assist Belt: Grants advantage on Strength (Athletics) checks.</li>
              <li>Aero-Lift Boots: Enables short-term flight up to 30 feet.</li>
              <li>Auto-Climbing Cable: Allows effortless climbing and anchoring.</li>
              <li>Kinetic Enhancement Boots: Negates movement penalties and triples jump distance.</li>
              <li>Resonance Unlocker: Bypasses mundane locks and disables nonmagical traps.</li>
              <li>Adhesive Neutralizer: Dissolves adhesives, freeing creatures or objects from entanglement.</li>
              <li>Charisma Boost Lenses: Grants advantage on Charisma (Persuasion) checks and a single use of charm person per day.</li>
              <li>Cognitive Enhancement Interface: Sets the wearer's Intelligence to 19.</li>
              <li>Linguistic Decoding Module: Enables understanding of all spoken languages and written text.</li>
              <li>Energy Reclamation Module: Allows recovery of one expended 1st-level spell slot.</li>
              <li>Sonic Manipulator: Summons a phantom steed for travel or reconnaissance.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Level 9 Adaptations</h2>
            <div className="level-indicator">Level 9</div>
            <p>Choose from the following advanced adaptations:</p>
            <ul>
              <li>Privacy Shield Module: Prevents magical tracking and divination.</li>
              <li>Hover Stabilizer Boots: Allows controlled vertical movement up to 20 feet.</li>
              <li>Spatial Compression Disk: Creates an extra-dimensional storage space.</li>
              <li>Aero-Lift Carpet: Carries up to six Medium creatures at a speed of 60 feet.</li>
              <li>Nocturnal Flight Cloak: Enables stealthy flight and transformation into a bat-like form.</li>
              <li>Visionary Analyzer: Allows remote viewing of distant locations, people, or events.</li>
              <li>Nano-Nutrient Orb: Eliminates the need for food and drink.</li>
              <li>Alertness Enhancement Orb: Eliminates surprise conditions and boosts vigilance.</li>
              <li>Defensive Buffer Orb: Projects an energy shield that grants a +1 bonus to AC.</li>
              <li>Stellar Attunement Cloak: Grants limited flight, enhanced mobility, and a +1 bonus to AC.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Level 15 Adaptations</h2>
            <div className="level-indicator">Level 15</div>
            <p>Choose from the following master adaptations:</p>
            <ul>
              <li>Plane Travel Module: Allows casting of plane shift once per day.</li>
              <li>Enhanced Strength Regulator: Sets the wearer's Strength score to 25.</li>
              <li>Speed Optimization Unit: Allows taking the Dash action as a bonus action, doubling speed.</li>
              <li>Stealth Integration Cloak: Renders the wearer invisible, providing unparalleled stealth.</li>
              <li>Force Barrier Generator: Creates a barrier to block physical and magical attacks.</li>
              <li>Spatial Relocation Helm: Allows casting of teleport once per day.</li>
              <li>Energy Storage Orb: Absorbs spells cast at the wearer, storing their energy for later use.</li>
              <li>Leadership Enhancement Orb: Grants a +2 bonus to Charisma checks and inspires allies against being frightened.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Dual Drone Deployment</h2>
            <div className="level-indicator">Level 18</div>
            <p>At 18th level, integrate a second drone into your arsenal, allowing for maximum adaptability in diverse scenarios.</p>
            <ul>
              <li>Dual Drone Deployment: Deploy and control two drones simultaneously.</li>
              <li>Independent Transformation: Each drone can be transformed into a different tech-based item.</li>
              <li>Action Economy: Controlling two drones does not require additional action expenditure.</li>
              <li>Resource Sharing: Both drones draw from your shared tech point pool.</li>
              <li>Tactical Coordination: Strategically position drones for optimal effect within a 60-foot range.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying Tips for the Dronewright Artificer</h2>
          
          <h3>Character Concepts</h3>
          
          <h4>The Innovative Tinkerer:</h4>
          <p>Background: Fascinated by the intersection of magic and technology, you spent your youth tinkering with mechanical devices and experimenting with arcane energies.</p>
          <p>Motivation: Driven to create something revolutionary that can change the world for the better.</p>
          <p>Roleplaying: Always looking for new materials and ideas to improve your drones. You might be eccentric, often lost in thought, but your creations speak for themselves.</p>

          <h4>The Battlefield Engineer:</h4>
          <p>Background: From a military family, you've seen the devastation of war and decided to use your skills to create tools that protect and aid your allies.</p>
          <p>Motivation: Committed to making the battlefield a safer place for your comrades. Your drones enhance combat capabilities and provide crucial support.</p>
          <p>Roleplaying: Strategic and calculated, always thinking several steps ahead. You are the one your party turns to for tactical advice and innovative solutions.</p>

          <h4>The Arcane Scholar:</h4>
          <p>Background: Dedicated your life to studying arcane energies and their application in technology, believing that the fusion of magic and machinery holds the key to unlocking new possibilities.</p>
          <p>Motivation: Seek to uncover the secrets of the universe and use that knowledge to create powerful and versatile drones.</p>
          <p>Roleplaying: Analytical and methodical, always seeking to understand the underlying principles of your creations. You might come across as aloof, but your passion for knowledge is undeniable.</p>

          <h3>Interacting with the Party</h3>

          <h4>The Supportive Ally:</h4>
          <p>Role: The backbone of the party, providing essential support and utility with your drones. Whether it's healing, enhancing abilities, or creating useful tools, you are always there to lend a helping hand.</p>
          <p>Interactions: Empathetic and caring, always looking out for the well-being of your companions. You might offer words of encouragement or use your drones to lighten the mood during tough times.</p>

          <h4>The Strategic Planner:</h4>
          <p>Role: The tactician of the group, using your drones to scout ahead, gather information, and plan the best course of action.</p>
          <p>Interactions: Decisive and confident, often taking the lead in discussions about the next steps. You value input from your party members and work collaboratively to achieve the best outcomes.</p>

          <h4>The Creative Problem-Solver:</h4>
          <p>Role: The one who thinks outside the box, using your drones to come up with unconventional solutions to complex problems.</p>
          <p>Interactions: Enthusiastic and optimistic, always seeing the potential in every situation. You encourage your party to think creatively and embrace new ideas.</p>

          <h3>Interacting with NPCs</h3>

          <h4>The Curious Inventor:</h4>
          <p>Approach: Eager to learn from others and share your knowledge. You might seek out other inventors, scholars, or anyone with unique insights into magic and technology.</p>
          <p>Interactions: Open and friendly, willing to engage in deep conversations about your passions. You might offer to show your drones or ask about their own creations and experiences.</p>

          <h4>The Resourceful Negotiator:</h4>
          <p>Approach: Understand the value of your creations and are not afraid to negotiate for the resources and materials you need. You might barter your services or drones in exchange for rare components or information.</p>
          <p>Interactions: Assertive and persuasive, using your charm and intelligence to strike the best deals. You are fair but firm, ensuring that both parties benefit from the exchange.</p>

          <h4>The Protective Guardian:</h4>
          <p>Approach: Use your drones to protect and aid those in need, whether it's defending a village from bandits or helping a farmer with a difficult harvest.</p>
          <p>Interactions: Compassionate and generous, always looking for ways to make a positive impact. You might offer your assistance freely, expecting nothing in return, but appreciating any gestures of gratitude.</p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={dronewrightImage} 
                alt="Dronewright"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={dronewrightImage}
                download="Dronewright.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesDronewright; 