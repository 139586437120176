import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Humanity/humanity.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesHumanity = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Humanity: The Anointed Remnant" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Humanity: The Anointed Remnant</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>              
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>              
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            In the Shattered Realm, Humanity stands as a testament to unity and resilience, a people forged in the crucible of a harsh and unforgiving world. Their history speaks of a singular dedication to survival and a drive to master their environment. They received a message from the cosmos, a cryptic communication that they believed was from their god, warning of an approaching leviathan. In response, they poured their resources into a dimensional net to ensnare the entity. It was in their attempt to stop the leviathan that the Shattering occurred, ripping their world and countless others apart.
          </p>
        </div>

        <div className="content-block">
          <p>
            Amidst this cataclysmic upheaval, a figure emerged from the void, his presence a symphony of power and promise. He called himself Sacrator, and he declared himself to be the god they had long worshipped without truly knowing his form. He declared them his chosen, the Anointed Remnant, and, as a symbol of his divine selection, he enacted a transformative ritual that changed them at a fundamental level.
          </p>
        </div>

        <div className="content-block">
          <p>
            He infused their blood with microscopic nanites, turning their lifeblood a vibrant, luminous blue. This was not a curse but a divine endowment, a biological bond to their technology that made them unlike any other race in the Shattered Realm. It was a mark of their status, the chosen inheritors of a destiny written by the hand of their god.
          </p>
        </div>

        <div className="content-block">
          <p>
            Under Sacrator's influence, Humanity embraced their new purpose with a zealous fervor. Their cities, rebuilt from the ruins of the old, became testaments to their ingenuity, powered by the energy of their blue blood and the strength of their resolve. Their beliefs were galvanized, and a singular ideology took hold: they were the inheritors of the Shattered Realm, tasked with imposing their technologically driven, divinely sanctioned order on a world in chaos.
          </p>
        </div>

        <div className="content-block">
          <h2>Physiology</h2>
          <p>
            Blue Blood: The most distinctive feature of Humanity is their vibrant blue blood, coursing with microscopic nanites. This grants them an innate connection to their technology and is a visible reminder of their unique heritage. The nanites are not a universal key but allow for their technology to be personalized to their bloodline.
          </p>
          <p>
            Enhanced Physique: Their history of facing a harsh environment and Sacrator's influence, have cultivated a physique that is both robust and adaptable.
          </p>
          <p>
            Technological Affinity: They have an intuitive understanding of machinery and can often manipulate it with greater ease than others. They cannot use magic and any external magic is neutralized by their nanites.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Humans reach adulthood in their late teens and live less than a century.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>Humanity's alignment varies, though their rigid societal structure and belief in their destiny often steer them towards lawful alignments.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Humans vary widely in height and build, from barely 5 feet to well over 6 feet tall. Regardless of your position in that range, your size is Medium.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shocking Grasp</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You can channel the electrical energy within your body to deliver a powerful shock. You can use your action to make a melee attack against a creature within 5 feet. On a hit, the target takes 1d8 lightning damage, and it can't take reactions until the start of its next turn.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shocking Grasp Improvement</h3>
              <span className="level-tag">Level 5th</span>
            </div>
            <p>Your Shocking Grasp now deals 2d8 lightning damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shocking Grasp Improvement</h3>
              <span className="level-tag">Level 11th</span>
            </div>
            <p>Your Shocking Grasp now deals 3d8 lightning damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shocking Grasp Improvement</h3>
              <span className="level-tag">Level 17th</span>
            </div>
            <p>Your Shocking Grasp now deals 4d8 lightning damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Lightning Burst</h3>
              <span className="level-tag">Level 5th</span>
            </div>
            <p>You can unleash a burst of electrical energy in a 5-foot-wide, 30-foot-long line as an action. Each creature in the line must make a Dexterity saving throw (DC 8 + your Constitution modifier + your proficiency bonus). A creature takes 3d6 lightning damage on a failed save, or half as much damage on a successful one. The lightning ignites flammable objects in the area that aren't being worn or carried. You can use this ability a number of times per day equal to your proficiency bonus.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Lightning Burst Improvement</h3>
              <span className="level-tag">Level 11th</span>
            </div>
            <p>Your Lightning Burst now deals 5d6 lightning damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Lightning Burst Improvement</h3>
              <span className="level-tag">Level 17th</span>
            </div>
            <p>Your Lightning Burst now deals 8d6 lightning damage.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Humanity: The Anointed Remnant"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Humanity.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesHumanity; 