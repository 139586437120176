import React, { useState, useEffect, useRef } from 'react';
import caelumbrisImage from '../../assets/images/deities/Caelumbris.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const CaelumbrisShadowed = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={caelumbrisImage} 
          alt="Caelumbris: Shadowed Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Caelumbris: The Shadowed Path</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="deity-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Acquire and strategically leverage secrets to manipulate events and influence outcomes.</li>
              <li>Embrace deception and misdirection as tools for personal gain and the advancement of Caelumbris's hidden agenda.</li>
              <li>Infiltrate positions of power to control the flow of information and subtly guide others toward your desired outcomes.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li className="requirement-warning">You are a member of the Keepers and must follow their orders. Members may never speak about the Keepers to a non-member and are magically and physically prohibited from doing so, with this pact enforced by the Firstborn.</li>
              <li>Always maintain a hidden identity or alias to conceal your true allegiance to Caelumbris.</li>
              <li>Seek to acquire compromising information about individuals in positions of power to use as leverage for your schemes.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <p>
            Following the Shadowed Path grants mastery over the living shadows,
            providing unparalleled control over light and darkness. Followers of
            this path become agents of secrecy and manipulation, merging with the
            shadows to become indistinguishable from the forces they command.
            This journey requires introspection and adaptation, as practitioners
            learn to harness the boundless potential of shadow to control,
            conceal, and manipulate their surroundings.
          </p>
        </div>

        <div className="content-block">
          <h2>Caelumbris: The Shrouded Catalyst</h2>
          <p>
            Deep within the Shattered Realm, where light falters and shadows
            dominate, Caelumbris, the Shrouded Catalyst, reigns supreme. Every
            shadow and unlit corner of the world forms a vast, intricate web that
            serves as the foundation of Caelumbris's domain. This living shadow
            transcends mere darkness; it is a sentient force that observes,
            manipulates, and controls, weaving secrets and whispers into a
            tapestry of unparalleled power. Legends tell of a primordial pact
            forged between Caelumbris and the twilight, granting it dominion over
            every hidden place untouched by light. Those who align themselves with
            Caelumbris gain mastery over these shadows, wielding them as tools of
            influence and subtlety.
          </p>
        </div>

        <div className="content-block">
          <h2>Caelumbris's Domain</h2>
          <p>
            Caelumbris's realm is a perfect shadow replica of the material world,
            drawn from every cast shadow and hidden place light cannot touch. The
            architecture of this living shadow is ever-shifting, a fluid tapestry
            that watches and remembers all. Every step taken here feels as if it
            is scrutinized by an omnipresent force, one that exists in the
            periphery yet never reveals itself fully. The air hums with latent
            awareness, and the boundary between observer and observed blurs
            entirely. To traverse this realm is to walk among the whispers of
            secrets and the silent movements of an all-encompassing shadow that
            may very well be Caelumbris itself.
          </p>
        </div>

        <div className="content-block">
          <h2>Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Shadow Duplicates</h3>
            </div>
            <p>
              Summon manifestations of shadow that mirror your form, emerging
              seamlessly from your own shadow. These duplicates serve as both tools
              of misdirection and extensions of your will, shifting and reforming
              fluidly in response to your commands.
            </p>
            <ul className="specializations-list">
              <li><strong>Clones:</strong> You can summon a single duplicate directly from your position as a bonus action. At any time, you can have up to your proficiency bonus in active duplicates. You can command your duplicates to move up to 30 feet per round using a bonus action and mental commands. Each duplicate must remain within your line of sight.</li>
              <li><strong>Duration:</strong> Duplicates persist until dismissed or exposed to bright light or direct sunlight, which causes them to dissolve into mist.</li>
              <li><strong>Interaction:</strong> You can speak and hear through your duplicates, allowing them to act as extensions of yourself in conversation or deception. Their presence is marked by a faint, whispering resonance.</li>
              <li><strong>Spellcasting Through Shadows:</strong> Cast spells through your duplicates as if you were in their location. When a spell originates from a duplicate, its shadowy form twists and expands before seamlessly reforming. These duplicates cannot deal direct damage or attack.</li>
              <li><strong>Clone Shift:</strong> As a reaction, you can instantaneously swap places with one of your duplicates within your line of sight. The transition is imperceptible to observers. You can use this ability a number of times per day equal to your proficiency bonus, and all uses reset after a long rest.</li>
              <li><strong>Shadowed Aura:</strong> Light dims within a radius of 1 foot per level around you, as if consumed by the shadows, creating an atmosphere where darkness thrives.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shrouded Insight</h3>
            </div>
            <p>Caelumbris sharpens your ability to perceive hidden truths and subtle details:</p>
            <ul className="specializations-list">
              <li>Gain proficiency in Perception and Insight. If you are already proficient in either skill, gain expertise instead.</li>
              <li>You have advantage on checks to detect hidden creatures or decipher concealed information.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Cloak of Deception</h3>
            </div>
            <p>
              Your shadow duplicates are masters of evasion. Enemies targeting a duplicate must succeed on an Intelligence (Investigation) check against your spell save DC to recognize it as a fake. If they fail, the duplicate evades the attack effortlessly, appearing to dodge or deflect the blow, preserving the illusion of its authenticity.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying the Path of Caelumbris</h2>
          <p>
            Followers of Caelumbris embrace the shadows, becoming extensions of its
            omnipresent will. They operate as unseen architects of schemes, spies,
            and manipulators, orchestrating events from the safety of darkness.
            Their actions are deliberate, guided by the knowledge gleaned from the
            shadows they command. Whether swaying the decisions of leaders or
            uncovering buried secrets, these adherents wield the power of the
            unseen to shape the world to their design. To walk this path is to
            become a silent observer, a master puppeteer whose strings are never
            seen.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={caelumbrisImage} 
              alt="Caelumbris: Shadowed Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={caelumbrisImage}
              download="Caelumbris.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaelumbrisShadowed;
