import React from 'react';
import { motion } from 'framer-motion';
import glacialHeartData from '../../assets/data/glacialheart.json';
import glacialHeartImage from '../../assets/images/subclasses/Glacial/circleoftheglacialheart.png';
import '../races/RacePage.css';

const GlacialHeart = () => {
  return (
    <div className="race-page">
      <div className="race-hero">
        <img 
          src={glacialHeartImage} 
          alt="Circle of the Glacial Heart" 
          className="race-background" 
        />
        <div className="race-content">
          <h1>{glacialHeartData.title}</h1>
        </div>
      </div>

      <div className="race-details">
        <div className="content-section">
          <p>{glacialHeartData.content}</p>
        </div>

        <div className="features-section">
          {glacialHeartData.features.map((feature, index) => (
            <div key={index} className="feature-group">
              <div className="trait">
                <div className="trait-header">
                  <h3>{feature.title}</h3>
                  {feature.level && <span className="level">Level {feature.level}</span>}
                </div>
                <p>{feature.description}</p>
                {feature.spells && (
                  <div className="spell-list">
                    {feature.spells.map((levelSpells, idx) => (
                      <div key={idx} className="spell-level">
                        <span className="level-number">{levelSpells.level}:</span> {levelSpells.spells.join(", ")}
                      </div>
                    ))}
                  </div>
                )}
                {feature.additional && <p className="additional">{feature.additional}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GlacialHeart; 