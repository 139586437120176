import React from 'react';
import { motion } from 'framer-motion';
import backgroundImage from '../assets/images/background.png';
import './Home.css';

const Home = () => {
  return (
    <div className="mainContainer">
      <div 
        className="contentContainer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="contentOverlay">
          <div className="sectionContent" style={{ background: 'rgba(0, 0, 0, 0.7)', padding: '2rem', borderRadius: '8px' }}>
            <p>In the far reaches of the Shattered Realm, where the boundaries of reality blur and magic entwines with technology...</p>
            <p>This is a world where dreams come to life, where the impossible becomes possible, and where heroes rise from the ashes of fallen empires.</p>
            <p>This domain, where dimensions intertwine, is a melting pot of ancient magic and cutting-edge technology, where the echoes of forgotten civilizations dance with the sparks of innovation.</p>
            <p>The fragments of countless shattered worlds hang in the void, each one a story waiting to be discovered.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;