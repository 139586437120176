import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Sunseed/sunseed.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesSunseed = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Sunseed" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Sunseed</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>              
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>              
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Sunseed are fey entities born at the peak of summer's ferocity, embodying the untamed vitality and fiery chaos of the season. Their existence is as fleeting as it is vibrant; their lives burn with unparalleled intensity, but their vitality wanes quickly compared to other fey. This rapid lifecycle stems from the blazing energy that sustains them, consuming their life force as swiftly as a wildfire devours a dry forest.
          </p>
        </div>

        <div className="content-block">
          <p>
            A subtle warmth emanates from the Sunseed, causing the air around them to shimmer with heat and the occasional flicker of sparks. Their forms also reflect their moods; anger ignites their embers and intensifies their presence, while tranquility softens their glow and fosters lush, verdant growth. Even their attire—crafted from woven leaves, hardened bark, and living flowers—shifts to match their ever-changing energy.
          </p>
        </div>

        <div className="content-block">
          <p>
            Despite their chaotic tendencies, Sunseed leave a lasting mark wherever they go. Their fiery spirits can inspire or devastate, reshaping the world around them in profound and unexpected ways. They value freedom and individuality above all else, thriving in environments that allow them to express their boundless energy. However, their volatility and intensity can make them difficult companions, as their emotions often burn too brightly to be contained. Loyal to those who earn their trust, their devotion is as fierce as their fury.
          </p>
        </div>

        <div className="content-block">
          <p>
            Born of the Summer Court's primal power, the Sunseed embody the duality of their origin: at once dazzlingly beautiful and inherently dangerous. They are creatures of spontaneity, driven by fleeting passions and a relentless need to experience everything the world has to offer before their flames are extinguished. Their existence mirrors the season they spring from—unpredictable, intense, and unforgettable.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <p>
            The Sunseed's appearance reflects their fiery and volatile nature. Their skin shimmers with a constant heat haze, as though tiny embers smolder just beneath the surface. Their hues range from molten gold and searing orange to deep, charred blacks and vivid reds, embodying the essence of a roaring wildfire.
          </p>
        </div>

        <div className="content-block">
          <p>
            Their bodies are adorned with living vines, flowers, and leaves that shift and grow with their emotions. When calm, these organic features bloom with vibrant beauty, captivating all who behold them. However, in moments of anger, their plant features transform into thorny, jagged shapes, radiating an aura of danger. This duality—at once alluring and intimidating—is central to their nature.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality</h2>
          <p>
            Sunseed are creatures of passion, embodying emotions as intense and fleeting as a summer storm. They are impulsive and unpredictable, pursuing their desires with reckless abandon. Their love for novelty and shiny, new things borders on obsession, and their childlike enthusiasm often leaves others bewildered. However, their fleeting attention spans ensure that they rarely linger long enough to form deep bonds.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>+2 to one ability score of your choice, and +1 to another ability score of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium or Small (player's choice)</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Base walking speed is 30 feet</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Fiery Resilience</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You have resistance to fire damage. However, you also have vulnerability to cold damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ember Burst</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>As an action, you can release a burst of fiery energy, creating a 10-foot radius sphere centered on yourself. Each creature in the area must make a Dexterity saving throw (DC = 8 + your proficiency bonus + your Charisma modifier). On a failed save, the creature takes 1d6 fire damage and becomes scorched. Scorched creatures have disadvantage on Strength saving throws until the end of their next turn. On a successful save, the creature takes half damage and is not scorched. You can use this ability a number of times equal to half your proficiency bonus per long rest (minimum of once).</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ember Burst Improvement</h3>
              <span className="level-tag">Level 5th</span>
            </div>
            <p>Your Ember Burst now deals 2d6 fire damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ember Burst Improvement</h3>
              <span className="level-tag">Level 10th</span>
            </div>
            <p>Your Ember Burst now deals 3d6 fire damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Wild Growth</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>As a bonus action, you can command the plant life around you to surge. In a 10-foot radius centered on yourself, you create difficult terrain with dense vines, brambles, and thorny plants that last for 1 minute. You can also target one creature in the area, which must succeed on a Strength saving throw (DC = 8 + your proficiency bonus + your Constitution modifier) or become restrained until the end of its next turn. You can use this ability a number of times equal to half your proficiency bonus per long rest (minimum of once).</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Living Embers</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Your natural warmth harms those who strike you. Any creature within 5 feet of you that deals melee damage takes 1d4 fire damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Living Embers Improvement</h3>
              <span className="level-tag">Level 5th</span>
            </div>
            <p>Your Living Embers now deals 1d6 fire damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Living Embers Improvement</h3>
              <span className="level-tag">Level 10th</span>
            </div>
            <p>Your Living Embers now deals 1d8 fire damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Living Embers Improvement</h3>
              <span className="level-tag">Level 15th</span>
            </div>
            <p>Your Living Embers now deals 1d10 fire damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Skill Proficiency</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You gain proficiency in either Performance or Athletics (your choice) and Nature.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You can speak, read, and write Common, Sylvan, and one additional language of your choice.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying Notes</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Wild Hearts</h3>
            </div>
            <p>Sunseed are driven by raw emotion and instinct, embracing their fleeting existence with unrelenting passion.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Natural Guardians</h3>
            </div>
            <p>While they often protect natural environments, their methods are as unpredictable as their moods.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Volatile Nature</h3>
            </div>
            <p>Their fiery intensity makes them both inspiring and challenging companions, capable of reshaping the world—for better or worse.</p>
          </div>

          <div className="trait">
            <p>The Sunseed are a living embodiment of summer's extremes: creation and destruction, beauty and danger, growth and decay. Their presence burns brightly, leaving the world forever changed in their wake.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Sunseed"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Sunseed.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesSunseed; 