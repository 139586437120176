import React from 'react';
import { motion } from 'framer-motion';
import { Swords } from 'lucide-react';
import './Classes.css';

const Classes = () => {
  return (
    <div className="classes-welcome" style={{ background: '#0a0a0a' }}>
      <motion.div 
        className="classes-welcome-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Swords size={48} className="welcome-icon" style={{ color: '#4a9eff', marginBottom: '1rem' }} />
        <h1>Echoes of Ancient Power</h1>
        <p className="welcome-subtitle">
          Choose your destined path
        </p>
      </motion.div>
    </div>
  );
};

export default Classes; 