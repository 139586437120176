import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Vermis Corpus/vermiscorpus.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesVermisCorpus = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Vermis Corpus" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Vermis Corpus</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species              
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>                            
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Vermis Corpus are an unsettling and enigmatic race, feared and misunderstood by most other inhabitants of the Shattered Realm. Their first appearance occurred in the aftermath of a massive battlefield following the Great Sundering, a catastrophic event that reshaped the very fabric of the world. Crows swarmed overhead but refused to touch the bodies below, as ethereal, glowing, snake-like worms began to phase in and out of existence around the corpses. Slowly, every single ethereal worm would enter a corpse, with each body housing dozens.
          </p>
        </div>

        <div className="content-block">
          <p>
            Those who attempted to interfere or attack these risen bodies faced swift retaliation, as the newly risen moved faster and fought with deadly precision, far surpassing their capabilities in life. Each new corpse added to their ranks, creating an ever-growing force of unstoppable resolve.
          </p>
        </div>

        <div className="content-block">
          <p>
            Once fully inhabited, the corpses all stood as one, turned southward, and marched toward the central wastelands. A toxic and desolate expanse of deserts and floating landmasses scattered around the equator, largely devoid of life due to their harsh environment. This phenomenon repeated countless times across the war-torn lands.
          </p>
        </div>

        <div className="content-block">
          <h2>A Return to the World</h2>
          <p>
            Fifty years after the Great Sundering, the Vermis Corpus opened their borders, venturing into the broader world. Their initial forays were met with trepidation and outright fear; tales of "body-snatchers" still lingered in the hearts of many. However, through diplomacy, strategic alliances, and vital assistance in repelling a great extraplanar threat, they proved their worth. Notably, they played a crucial role in sealing a breach to the Far Realm, demonstrating their unique abilities and unwavering resolve. Yet, they remain an unnerving presence. Even with their best efforts, the reality of their worm-infested forms cannot be entirely hidden. While some have come to see them as allies, others still whisper of their unsettling origin and ponder the cost of trusting beings that embody both life and death. Their continued survival is a testament to their adaptability and resilience.
          </p>
        </div>

        <div className="content-block">
          <h2>Society and Culture</h2>
          <p>
            To an outsider, the cities of the Vermis Corpus are unsettlingly quiet. No laughter echoes in the streets, no shouting vendors hawk wares, and no footsteps disturb the calm—only the rustle of wind or the distant scrape of movement. Yet, to the Vermis Corpus, their cities are deafening. Constant telepathic communication pulses through their shared consciousness, a cacophony of thoughts, decisions, and communal deliberations that harmonize into a singular, collective existence.
          </p>
        </div>

        <div className="content-block">
          <h2>Communal Harmony and Leadership</h2>
          <p>
            The Vermis Corpus live in highly communal societies, where the needs of the collective far outweigh individual desires. Leadership is vested in a single figure known as The Broodhearth—an elected individual, often the eldest and wisest among them. The Broodhearth acts as a nexus for the hive mind, merging their consciousness with the collective to become a single, unified entity during times of crisis or crucial decision-making. This transformation is awe-inspiring and terrifying: an entire city's population acting as one, capable of overwhelming coordination and unparalleled strategy, able to adapt to rapidly changing circumstances.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <p>
            The Vermis Corpus's appearance is dictated largely by the host body they inhabit. However, subtle signs betray their true nature. Their host bodies often display faint, unnatural movements, as if something shifts just beneath the surface of their skin, particularly around the joints. The eyes of a Vermis Corpus host tend to have an unsettling, iridescent sheen, hinting at the collective intelligence within. In some cases, the snake-like worms themselves may briefly emerge from wounds or orifices, a chilling reminder of their unconventional existence.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality</h2>
          <p>
            Vermis Corpus are shaped by the memories and experiences of their hosts, giving them an uncanny and disjointed perspective on the world. While some embrace the knowledge and personalities of past hosts, others strive to forge a distinct identity, rejecting these fragments of memory. This duality often makes their behavior unpredictable, as they might exhibit personality traits or mannerisms from a multitude of previous hosts.
          </p>
          <p>
            As a collective, they value strategy and adaptability, leveraging their shared consciousness to excel in problem-solving and tactical thinking. They are highly pragmatic, viewing life and death through an impersonal lens. While their actions may seem cold or calculating, they are motivated by survival and the preservation of their collective. Their ability to inhabit hosts makes them naturally curious, often seeking new experiences and knowledge through the lives they inhabit. They are also deeply curious about the world around them, seeking to understand the cultures and societies they encounter.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Basic Characteristics</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Ability Score Increase: +2 to one ability score of your choice, and +1 to another ability score of your choice.</p>
            <p>Age: Vermis Corpus do not age so long as the worms remain alive. The host's body does not decay further once inhabited.</p>
            <p>Alignment: Vermis Corpus can embody any alignment, reflecting the diverse nature of their collective consciousness.</p>
            <p>Size: Small or Medium (player's choice).</p>
            <p>Speed: Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Core Abilities</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Darkvision: Accustomed to operating in dark environments, you can see in dim light within 60 feet as if it were bright light, and in darkness as if it were dim light. You can't discern color in darkness, only shades of gray.</p>
            <p>Collective Mind: Your shared consciousness grants you resistance to psychic damage and advantage on saving throws against fear. Additionally, you can telepathically communicate with other Vermis Corpus within 100 feet, sharing thoughts and strategies instantly.</p>
            <p>Undead Nature: You are classified as undead for the purposes of spells and effects. You do not need to eat, drink, breathe, or sleep, and you are immune to disease. However, you can still benefit from resting to recover hit points and abilities.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Host Body Mechanics</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Worm Regeneration: As long as at least one worm survives and the body is intact enough to support the worms and remain functional, there is a 5% chance per Vermis Corpus level that the host will revive at the next dawn with 1 hit point. This chance requires GM approval to ensure the body's condition meets the necessary criteria for reanimation.</p>
            <p>Corpse Adaptability: You can adapt the physical abilities of your host. For example, a host with claws or gills allows you to climb or swim. This does not grant magical or supernatural abilities. Hosts from non-playable races require GM approval.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Host Transfer System</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Time Limit: Corpses up to 1 day old per Vermis Corpus level can be inhabited.</p>
            <p>Corpse Viability: The body must be intact enough to support the worms and maintain basic functionality.</p>
            <p>Transfer Phase: You migrate to a new body through natural openings or wounds, a process that takes 24 hours during which you are vulnerable.</p>
            <p>Integration Phase: Once fully integrated into a new host, the body no longer decays.</p>
            <p>Cooldown: You can transfer to a new host once every seven days.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Damage and Degradation</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <div className="trait-description">
              <p>The condition of the host body affects its usability. Damage levels and their effects are:</p>
              
              <div className="damage-stages">
                <div className="damage-stage">
                  <h4>20% - Minor Impairment</h4>
                  <div className="damage-effects">
                    <p>One missing or severely injured limb</p>
                    <p>Disadvantage on one physical skill check</p>
                    <p>Movement speed reduced by 15%</p>
                  </div>
                </div>

                <div className="damage-stage">
                  <h4>40% - Moderate Impairment</h4>
                  <div className="damage-effects">
                    <p>Two missing limbs or significant torso damage</p>
                    <p>Disadvantage on all physical skill checks</p>
                    <p>Movement speed reduced by 30%</p>
                  </div>
                </div>

                <div className="damage-stage">
                  <h4>60% - Severe Impairment</h4>
                  <div className="damage-effects">
                    <p>Partial collapse of torso or multiple broken bones</p>
                    <p>Disadvantage on all physical skill checks</p>
                    <p>Disadvantage on Strength or Dexterity saving throws</p>
                    <p>Movement speed reduced by 45%</p>
                  </div>
                </div>

                <div className="damage-stage">
                  <h4>80% - Critical Impairment</h4>
                  <div className="damage-effects">
                    <p>Severe internal damage or loss of structural integrity</p>
                    <p>Disadvantage on all physical actions</p>
                    <p>Movement speed reduced by 60%</p>
                  </div>
                </div>

                <div className="damage-stage">
                  <h4>100% - Terminal</h4>
                  <div className="damage-effects">
                    <p>Body is uninhabitable due to complete destruction of vital areas</p>
                  </div>
                </div>
              </div>

              <div className="damage-note">
                <h4>Degradation</h4>
                <p>Damaged bodies progress to the next damage level each full day</p>
              </div>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Overdrive Assimilation</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <div className="trait-description">
              <p>As an action, you can activate Overdrive Assimilation a number of times per day equal to your proficiency bonus. While active (1 minute):</p>
              
              <div className="overdrive-benefits">
                <h4>Benefits</h4>
                <div className="benefit">
                  <strong>Enhanced Speed</strong>
                  <p>Your movement speed is doubled</p>
                </div>
                <div className="benefit">
                  <strong>Enhanced Proficiency</strong>
                  <p>Your proficiency bonus is doubled for attacks, saving throws, and skill checks</p>
                </div>
              </div>

              <div className="overdrive-consequences">
                <h4>Consequences</h4>
                <div className="consequence">
                  <strong>Immediate</strong>
                  <p>Lose any remaining temporary hit points when the effect ends</p>
                </div>
                <div className="consequence">
                  <strong>Short Term</strong>
                  <p>Gain one level of exhaustion for (10 - Proficiency bonus) minutes</p>
                </div>
                <div className="consequence">
                  <strong>Extended</strong>
                  <p>Gain an additional level of exhaustion until completing a short rest</p>
                </div>
              </div>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You can speak, read, and write Common and one additional language based on your host's background.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying Notes</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Social Stigma</h3>
            </div>
            <p>Vermis Corpus are often feared and mistrusted due to their nature, facing prejudice and suspicion wherever they go.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pragmatic Survivors</h3>
            </div>
            <p>Their focus on survival and adaptability makes them highly practical, but their methods can seem callous to others.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Fragmented Identity</h3>
            </div>
            <p>Balancing the memories and experiences of past hosts with their own individuality can create internal conflict and unique roleplaying opportunities.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Tactical Geniuses</h3>
            </div>
            <p>Their collective mind allows them to excel in planning and strategy, making them invaluable in complex situations.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Vermis Corpus"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="VermisCorpus.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesVermisCorpus; 