import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import backgroundImage from '../assets/images/background.png';
import './Home.css';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mainContainer">
      <div 
        className="contentContainer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="contentOverlay">
          <div className="sectionContent" style={{ background: 'rgba(0, 0, 0, 0.7)', padding: '1rem', borderRadius: '8px' }}>
            <h1 style={{ textAlign: 'center' }}>Welcome to The Shattered Veil</h1>
            <div className={`veiled-paths ${menuOpen ? 'active' : ''}`} ref={menuRef}>
              <h2 onClick={toggleMenu}>
                Menu
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/history" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  History
                </Link>
                <Link to="/house-rules" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  House Rules
                </Link>
                <Link to="/campaignintro" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Campaign Intro
                </Link>
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Veiled Ascension
                </Link>
              </div>
            </div>
            <p>
              In the vast expanse of the Shattered Veil, a universe birthed from a cosmic event of unparalleled magnitude, the boundaries between the arcane and the technological blur. This fusion crafts a symphony of worlds where the extraordinary is the norm. This domain, where dimensions intertwine, serves as a crucible for the melding of ancient magic with the remnants of advanced, forgotten civilizations. It's a realm of endless mysteries and untold stories, each fragment of reality hiding legacies of the past and secrets yet to be unveiled.
            </p>
            <p>
              Velum is now a world formed from the remnants of countless shattered realities, where fragments of existence—from the floating cities of aetheric empires to the smoldering ruins of once-great mechanized civilizations—have collided and fused into a single, chaotic domain. Here, the echoes of ancient sorceries resonate through the air, intermingling with the hum of forgotten machinery. The landscape is a patchwork of diverse biomes, each piece a surviving relic of a different world, a testament to the fragmented realities that now coexist and shape Velum's unique history.
            </p>
            <p>
              From the towering spires of the Crystal Peaks, where magic suffuses the thin air, to the shadowy depths of the Abyssal Chasms, where whispers of ancient horrors linger, the Shattered Realm is a place of contrasts and wonder. The forests of the Elderwood teem with life, both familiar and fantastical, while the desolate wastelands of the Blighted Sands bear the scars of wars long past.
            </p>
            <p>
              In this realm, heroes are forged in the fires of adversity, and legends are born from the ashes of conflict. The Shattered Realm is a crucible for the brave and the bold, a place where destiny is not a predetermined path but a journey shaped by the choices and actions of its inhabitants. Whether you are a seasoned adventurer seeking glory and treasure, or a scholar delving into the mysteries of the arcane, the Shattered Realm offers a wealth of opportunities and challenges.
            </p>
            <p>
              Welcome to Velum, where fragments of countless realities intertwine, magic and technology clash, and the extraordinary emerges from the chaos of a shattered multiverse. Embark on a journey of discovery and adventure, and uncover the secrets that lie hidden within its vast and wondrous expanse.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;