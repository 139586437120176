import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import backgroundImage from '../assets/images/intro.jpg';
import './Home.css';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mainContainer">
      <div 
        className="contentContainer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="contentOverlay">
          <div className="sectionContent" style={{ background: 'rgba(0, 0, 0, 0.7)', padding: '1rem', borderRadius: '8px' }}>
            <h1 style={{ textAlign: 'center' }}>Welcome to The Shattered Realm</h1>
            <div className={`veiled-paths ${menuOpen ? 'active' : ''}`} ref={menuRef}>
              <h2 onClick={toggleMenu}>
                Menu
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Home
                </Link>
                <Link to="/house-rules" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  House Rules
                </Link>
                <Link to="/prologue" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Prologue
                </Link>
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Veiled Ascension
                </Link>
              </div>
            </div>
            <p>
              In the beginning, there was Calanthar, a being of immeasurable intellect and power, who embarked on a journey to transcend the limitations of his mortal form by connecting with every iteration of himself across the multiverse. This pursuit of omniscience led to his transformation into something beyond human, a nexus of infinite possibilities. However, this monumental achievement came at a great cost, as the weight of countless lives and realities began to press upon him, driving him into isolation and madness.
            </p>
            
            <p>Wandering through an unrecognizable world, Calanthar observed as humanity, unaware of his guiding influence, flourished under his silent guardianship. For centuries, he shaped the course of human advancement from the shadows, his interventions steering them through technological and societal leaps. Despite his contributions, Calanthar remained a spectral figure, disconnected from the very humanity he sought to protect.</p>
            
            <p>The arrival of a cryptic signal from the cosmos marked the beginning of an unprecedented challenge. Interpreted as a divine test, this message ignited a fervor among humanity, leading them to the brink of fanaticism. Calanthar's attempt to translate the signal and uncover its true meaning only fueled the flames of zealotry, threatening to unravel the fragile balance he had maintained.</p>
            
            <p>In a desperate act to counter the looming threat, Calanthar initiated the construction of a dimensional net, a masterwork of multiversal engineering designed to ensnare the approaching leviathan. However, the activation of the net resulted in the "Shattering," a cataclysm that fragmented the fabric of reality itself, birthing a new universe composed of countless shattered realms, each a reflection of an alternate possibility.</p>
            
            <p>In this new reality, Calanthar ascended beyond the confines of mortality, becoming the guardian of a realm where the boundaries between worlds were irrevocably blurred. To maintain the balance within this fractured universe, he created a pantheon of deities, each embodying aspects of the world's newfound complexity. Among these were Queen Mab, the Goddess of the Winter Court, and Queen Titania of the Seelie Court, deities whose influence shaped the evolving landscape of the shattered realms.</p>
            
            <p>The Firstborn, a being of profound loneliness cursed with a connection to Calanthar's essence, was elevated to godhood, tasked with overseeing the cycle of life and death, and the intricate web of contracts and pacts that bound the fate of all beings within this new world.</p>
            
            <p>As the world recovered from the Shattering, the Firstborn's floating island emerged as a symbol of unity and progress, facilitating trade and communication among the disparate realms. Humanity, now a part of a larger, more diverse community, sought to assert its dominance, declaring war on those who dared to use their technology or magic without permission.</p>
            
            <p>Fifty years after the Shattering, the world stands at a crossroads, its destiny shaped by the actions of gods and mortals alike. In this realm of infinite possibilities, the legacy of Calanthar and the deities he created continues to unfold, their stories interwoven with the fabric of a universe forever changed by the ambition of one man's quest for knowledge and power.</p>
            
            <p>As the dawn of a new era rises from the ashes of the Shattered Realm, the fabric of existence itself is being rewoven. Proverbial lines in the sand are drawn as the world stands on the precipice of a monumental shift. Gods and goddesses, kings and queens, burgeoning kingdoms, and disputed territories emerge from the chaos, each vying for dominance in a landscape forever altered by cosmic forces. Battlefields bristle with the promise of glory and the threat of oblivion, as demi-gods and heroes carve their names into the annals of history. Amidst this tumultuous backdrop, the question that echoes through the cosmos is clear: Will you rise as a beacon of power, shaping the destiny of this new world, or will you fade into the tapestry of the realm, a mere whisper lost in the clamor of legends forged? The choice is yours, and the time to decide is now. Will you be the architect of your own fate or just another background character in the saga of the Shattered Realm?</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;