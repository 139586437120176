import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import wildscarImage from '../../assets/images/deities/wildscar.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const WildscarEmberFury = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={wildscarImage} 
          alt="Wildscar: Ember Fury Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Wildscar: The Path of Ember Fury</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/nebula/mistwalker" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Nebula: Mistwalker
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="race-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Embrace the primal fury within, giving in to its destructive urges and reveling in the chaos it unleashes.</li>
              <li>Express your wrath without restraint, becoming a force of pure destruction that obliterates anything in its path.</li>
              <li>Savor the thrill of the fight, reveling in the bloodlust and the raw power that surges through your veins as you unleash your fury.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>Engage in combat or acts of wanton destruction whenever possible, allowing your fury to flow freely and annihilate anything that stands in your way.</li>
              <li>Never back down from a fight, no matter the odds or the consequences. Embrace every opportunity to unleash your unbridled rage.</li>
              <li>Surrender to the bloodlust, reveling in the visceral thrill of combat and the raw power that surges through your being as you unleash your fury.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <p>
            Following the path of Ember Fury imbues warriors with the raw, unrelenting energy of primal chaos. They channel this ferocity to unleash devastating power, becoming both destroyers and protectors on the battlefield. Wildscar's chosen embody the unbridled rage of the elements, a force of nature driven by instinct and passion.
          </p>
        </div>

        <div className="content-block">
          <h2>Wildscar: The Primal Fury</h2>
          <p>
            Wildscar represents the raw, untamed fury of primal chaos, a force as destructive as it is empowering. She manifests as the embodiment of rage, her presence a fiery catalyst that ignites conflict and passion in equal measure. Warriors who invoke Wildscar's name do not seek guidance; they seek power, embracing the primal instinct that drives them to glory or ruin.
          </p>
        </div>

        <div className="content-block">
          <h2>Wildscar's Domain: The Embered Heart</h2>
          <p>
            The realm of Wildscar is a roiling storm of fire and rage. Red mist curls through ashen plains, and rivers of molten rock carve paths through jagged landscapes. This domain is a crucible of fury where the strongest thrive, and the weak are consumed. Every ember and every flame is a reflection of Wildscar's unbridled passion, and the echoes of battle rage eternal across the land. The air hums with tension, vibrating with the cries of warriors who have embraced the chaos and those who fell to it.
          </p>
        </div>

        <div className="content-block">
          <h2>Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Rage Aura</h3>
            </div>
            <p>
              A 10-foot aura of fury emanates from you after you make an attack, are attacked, or take damage, affecting allies and enemies alike. All within the aura gain a bonus to melee attack rolls equal to your proficiency bonus but must make a Wisdom saving throw (DC = 10 + your proficiency bonus) at the start of their turn. On a failure, they must attack the nearest creature. The aura lasts for 1 minute per level.
            </p>
            <p>
              At the start of each day, choose one damage type—piercing, slashing, or bludgeoning—to gain resistance against until your next long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Rage Transformation</h3>
            </div>
            <p>
              Up to your proficiency bonus times per long rest, you can enter a rage as a bonus action, lasting for a number of rounds equal to your level. During this rage:
            </p>
            <ul className="specializations-list">
              <li>Your size increases by 1, and your Strength, Constitution, and Dexterity are increased by twice your proficiency bonus.</li>
              <li>Your movement speed increases by 5 feet per 2 levels.</li>
              <li>You gain resistance to piercing, slashing, bludgeoning, fire, cold, lightning, acid, radiant, necrotic, and psychic damage.</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Primal Eruption</h3>
            </div>
            <p>
              As an action, release a burst of red mist in a 15-foot radius. Creatures within the area must make a Wisdom saving throw (DC = 8 + your Constitution modifier + your proficiency bonus). On a failed save, they become enraged and must attack the nearest creature on their next turn. On a successful save, they still take half damage. The psychic damage dealt is 2d8. This ability can be used once per Rage Transformation.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ember's Endless Fury</h3>
            </div>
            <p>
              When you bring a creature to 0 hit points, an eruption of red mist bursts out in a 10-foot radius. All creatures within the area must make a Wisdom saving throw (DC = 8 + your Constitution modifier + your proficiency bonus) or be slowed until the start of your next turn. Additionally, you gain the effects of the Haste spell until the start of your next turn.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying the Path of Wildscar</h2>
          <p>
            Followers of Wildscar are not bound by tradition or law; they are driven by instinct and passion. They embody the duality of rage, wielding it as a weapon that can either protect their allies or destroy everything in their path. As champions of chaos, they view every battle as an opportunity to revel in their primal strength. To walk the Path of Wildscar is to burn brightly, whether as a beacon for your allies or as a consuming flame that leaves nothing behind.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={wildscarImage} 
              alt="Wildscar: Ember Fury Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={wildscarImage}
              download="Wildscar.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default WildscarEmberFury;