import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getAllImagesInFolder, getSubfolders, preloadImages } from '../utils/imageLoader';
import './GalleryFolder.css';

const GalleryFolder = () => {
  const { folder = '', subfolder } = useParams();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [contents, setContents] = useState({ folders: [], images: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

  useEffect(() => {
    const loadContents = async () => {
      setIsLoading(true);
      const path = subfolder ? `${folder}/${subfolder}` : folder;
      
      // Get subfolders if any
      const subfolders = getSubfolders(path);
      
      // Get images in current folder
      const images = getAllImagesInFolder(path);
      const imageList = Object.entries(images)
        .filter(([_, path]) => path !== null)
        .map(([fullPath, path]) => ({
          name: fullPath.split('/').pop().replace(/\.(png|jpe?g|webp)$/, ''),
          path: path
        }));

      setContents({
        folders: subfolders,
        images: imageList
      });

      // Preload images in the background
      if (imageList.length > 0) {
        preloadImages(imageList.map(img => img.path))
          .catch(error => console.error('Error preloading images:', error));
      }

      setIsLoading(false);
    };

    loadContents();
  }, [folder, subfolder]);

  const handleBack = () => {
    if (subfolder) {
      navigate(`/gallery/${folder}`);
    } else if (folder) {
      navigate('/gallery');
    }
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    document.body.style.overflow = 'unset';
  };

  const handleDownload = (e, imagePath) => {
    e.stopPropagation();
    
    if (isMobile) {
      window.open(imagePath, '_blank');
    } else {
      const link = document.createElement('a');
      link.href = imagePath;
      link.download = imagePath.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="gallery-folder-page">
      <div className="gallery-header">
        <div className="nav-links">
          <Link to="/gallery" className="nav-link">
            <svg viewBox="0 0 24 24" width="16" height="16" style={{marginRight: '4px'}}>
              <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="currentColor"/>
            </svg>
            Gallery Home
          </Link>
          {folder && (
            <button onClick={handleBack} className="nav-link">
              <svg viewBox="0 0 24 24" width="16" height="16" style={{marginRight: '4px'}}>
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="currentColor"/>
              </svg>
              Back
            </button>
          )}
          <a href="https://shatteredrealm.net" target="_blank" rel="noopener noreferrer" className="nav-link">
            <svg viewBox="0 0 24 24" width="16" height="16" style={{marginRight: '4px'}}>
              <path d="M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" fill="currentColor"/>
            </svg>
            ShatteredRealm.net
          </a>
        </div>
        <h1>{subfolder || folder || 'Gallery'}</h1>
      </div>
      
      <div className="gallery-folder-container">
        <div className="gallery-content">
          {isLoading ? (
            <div className="loading-spinner">Loading...</div>
          ) : (
            <>
              {contents.folders.length > 0 && (
                <div className="folder-grid">
                  {contents.folders.map((folderName) => (
                    <Link 
                      to={`/gallery/${folder}/${folderName}`} 
                      key={folderName}
                      className="folder-card"
                    >
                      <div className="folder-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path 
                            d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <span className="folder-name">{folderName}</span>
                    </Link>
                  ))}
                </div>
              )}

              {contents.images.length > 0 && (
                <div className="image-grid">
                  {contents.images.map(({ name, path }) => (
                    <div 
                      key={name}
                      className="image-card"
                      onClick={() => handleImageClick(path)}
                    >
                      <img 
                        src={path} 
                        alt={name}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <div className="image-overlay">
                        <span className="image-name">{name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>

        {selectedImage && (
          <div 
            className="image-modal"
            onClick={handleCloseModal}
          >
            <div style={{ position: 'relative' }}>
              <img 
                src={selectedImage}
                alt="Full size"
                onClick={(e) => e.stopPropagation()}
              />
              <button 
                className="close-button"
                onClick={handleCloseModal}
                aria-label="Close"
              >
                ×
              </button>
              <button 
                className="download-button"
                onClick={(e) => handleDownload(e, selectedImage)}
                aria-label="Download"
              >
                <svg viewBox="0 0 24 24" width="24" height="24">
                  <path
                    d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GalleryFolder; 