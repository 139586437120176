import React, { useState, useRef, useEffect } from 'react';
import glacialHeartImage from '../../assets/images/subclasses/Glacial/circleoftheglacialheart.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesGlacialHeart = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={glacialHeartImage} 
          alt="Circle of the Glacial Heart" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Circle of the Glacial Heart</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesUmbralInfiltrator" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Umbral Infiltrator
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Druids of the Circle of the Glacial Heart are masters of cold and control. They channel the power of winter to freeze their enemies, manipulate the battlefield, and strike from the shadows. Their magic is precise and calculated, reflecting a deep understanding of strategy and the natural order. These druids are often seen as stoic and reserved, but their hearts burn with a fierce determination to protect the balance of nature.
          </p>
          <p>
            These druids often dwell in regions dominated by snow and ice, such as tundras, glaciers, and mountain ranges. They revere the stillness and beauty of winter, understanding that its chill is a vital part of nature's cycle. Their rituals often include creating intricate patterns in the snow, summoning freezing winds to cleanse an area, and even carving ice sculptures as offerings to the spirits of winter.
          </p>
        </div>

        <div className="content-block">
          <h2>Circle Spells</h2>
          <div className="level-indicator">Level 2nd</div>
          <p>Your connection to the power of winter grants you access to certain spells. You always have the following spells prepared, and they don't count against the number of spells you can prepare each day.</p>
          <div className="trait">
            <div className="trait-header">
              <h3>3rd Level</h3>
            </div>
            <p>Snilloc's Snowball Swarm, Rime's Binding Ice</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>5th Level</h3>
            </div>
            <p>Sleet Storm, Hold Person</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>7th Level</h3>
            </div>
            <p>Ice Storm, Elemental Bane</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>9th Level</h3>
            </div>
            <p>Cone of Cold, Control Winds</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Glacial Burst</h2>
          <div className="level-indicator">Level 2nd</div>
          <p>
            Starting at 2nd level, while in your Wild Shape, you can expend a spell slot as a bonus action to unleash a burst of freezing energy. Each creature of your choice within 10 feet of you must make a Dexterity saving throw against your spell save DC. On a failed save, a creature takes 1d4 cold damage per level of the spell slot expended and falls prone. On a successful save, a creature takes half as much damage and does not fall prone.
          </p>
          <p>
            The area within 10 feet of you becomes slick with ice, turning it into difficult terrain for 1 minute per level of the spell slot expended. This ice affects creatures other than you and your allies.
          </p>
        </div>

        <div className="content-block">
          <h2>Frostbite</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            At 6th level, your mastery of winter enhances the lethality of your Wild Shape. While in Wild Shape, your melee weapon attacks deal an additional 1d4 cold damage and count as magical for the purpose of overcoming resistance and immunity to nonmagical attacks and damage. The cold damage increases to 1d6 when you reach 10th level and 1d8 when you reach 14th level in this class.
          </p>
          <p>
            Additionally, when you hit a creature with a melee weapon attack while in Wild Shape, it must succeed on a Constitution saving throw (DC = 8 + your proficiency bonus + your Wisdom modifier) or lose 5 feet of movement until the end of your next turn. If the creature is hit again before the effect ends, it must repeat the saving throw. On a failed save, it loses another 5 feet of movement, but a creature cannot have its movement reduced below 5 feet in this way. If the creature succeeds on a saving throw, all movement restrictions from this feature end immediately.
          </p>
        </div>

        <div className="content-block">
          <h2>Glacier Stance</h2>
          <div className="level-indicator">Level 10th</div>
          <p>
            At 10th level, as a reaction to being attacked while in Wild Shape, you can create a wall of ice around yourself, granting you a +4 bonus to AC until the end of your next turn. Additionally, enemies within 10 feet of you must make a Dexterity saving throw (DC = 8 + your proficiency bonus + your Wisdom modifier) or take 3d6 cold damage as ice shards erupt around you. The ground in this 10-foot radius becomes slick with frost, turning it into difficult terrain for 1 minute.
          </p>
          <p>
            You can use this feature a number of times equal to your proficiency bonus (minimum of once). You regain all expended uses when you finish a long rest.
          </p>
        </div>

        <div className="content-block">
          <h2>Freezing Step</h2>
          <div className="level-indicator">Level 10th</div>
          <p>
            Starting at 10th level, while in Wild Shape, the ground within 5 feet of you becomes slick with frost, turning it into difficult terrain for creatures other than you. You ignore difficult terrain caused by ice or snow.
          </p>
        </div>

        <div className="content-block">
          <h2>Heart of Winter</h2>
          <div className="level-indicator">Level 14th</div>
          <p>
            At 14th level, as an action while in Wild Shape, you can create a swirling globe of winter energy centered on yourself. The area within 15 feet of you becomes a harsh winter storm and remains covered with ice for 1 minute per level after you move away. While the storm persists, you gain the benefits of the Mirror Image spell, appearing as flickering images surrounded by falling snow.
          </p>
          <p>
            At the start of each of your turns, each enemy within the globe must make a Constitution saving throw against your spell save DC. On a failed save, they take 3d6 cold damage and are slowed until the end of their next turn. On a successful save, they are not slowed and take no damage.
          </p>
          <p>
            The area remains difficult terrain for 1 minute per level after you leave the area.
          </p>
          <p>
            You can use this feature a number of times equal to your proficiency bonus per long rest.
          </p>
        </div>

        <div className="content-block">
          <h2>Roleplaying a Circle of the Glacial Heart Druid</h2>
          <p>
            As a druid of the Circle of the Glacial Heart, you are deeply connected to the primal forces of cold and winter. Your stoic nature and icy demeanor make you a calculated protector of nature's balance, willing to harness the relentless power of frost to cleanse and renew the land. You might be drawn to areas where ice and snow dominate the landscape, such as frozen tundras or glacial peaks. Your rituals and practices often involve the stillness and reflection that winter demands, using the power of frost to preserve and maintain the natural order.
          </p>

          <h3>Personality Traits</h3>
          <ul>
            <li>Stoic: You act with calm and measured resolve, rarely displaying your emotions openly.</li>
            <li>Cold-Blooded: You approach problems with logical detachment, valuing reason over emotion.</li>
            <li>Calculating: You plan your actions carefully, ensuring each move serves a greater purpose.</li>
            <li>Resilient: Like ice itself, you endure hardships without breaking, adapting to survive.</li>
          </ul>

          <h3>Ideals</h3>
          <ul>
            <li>Preservation: Winter's stillness is necessary to protect and preserve the natural world.</li>
            <li>Discipline: True power lies in control and understanding, not recklessness.</li>
            <li>Balance: Life and death, warmth and cold—all must exist in harmony.</li>
            <li>Strength: The harshest environments forge the strongest wills.</li>
          </ul>

          <h3>Bonds</h3>
          <ul>
            <li>Frozen Lands: You feel a deep connection to a specific icy domain that you are sworn to protect.</li>
            <li>Winter's Legacy: You honor the memory of those who taught you the ways of the frost.</li>
            <li>Natural Cycles: You are dedicated to ensuring the natural cycles of decay and renewal continue unimpeded.</li>
            <li>Chilling Retribution: You have sworn vengeance against those who harm nature's balance.</li>
          </ul>

          <h3>Flaws</h3>
          <ul>
            <li>Aloofness: Your detached manner can alienate allies, making you difficult to trust.</li>
            <li>Rigidity: You struggle to adapt to unexpected changes or break from established plans.</li>
            <li>Relentless: You can become fixated on eliminating threats, even at great personal cost.</li>
            <li>Overconfidence: Your mastery of winter leads you to underestimate opponents or dismiss alternative approaches.</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Equipment</h2>
          <p>As a druid of the Circle of the Glacial Heart, you carry tools and items that help you harness the power of winter and thrive in frozen landscapes. Your equipment might include:</p>
          <ul>
            <li>A druidic focus, such as a staff or totem, carved from frost-covered wood or adorned with icy crystals.</li>
            <li>A set of warm, fur-lined clothes suitable for harsh winter environments.</li>
            <li>A backpack containing a bedroll, a mess kit, a set of flint and steel, and a waterskin.</li>
            <li>A pouch of frost-coated seeds or magical ice shards, which you use in your rituals and spells.</li>
            <li>A set of explorer's tools for navigating icy terrains and crafting shelters in the snow.</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Multiclassing</h2>
          <p>
            If you choose to multiclass, you can combine your druidic powers with the abilities of another class to create a unique and versatile character. Some multiclassing options might include:
          </p>
          <ul>
            <li>Barbarian: The rage of the barbarian complements your icy resilience, allowing you to endure and retaliate with ferocity.</li>
            <li>Sorcerer (Draconic or Storm Sorcery): The sorcerer's spellcasting flexibility enhances your ability to control the battlefield with cold-based magic.</li>
            <li>Ranger: The ranger's tracking and survival skills pair well with your mastery over frozen terrain, creating a cold and calculating hunter.</li>
            <li>Cleric (Nature Domain): The cleric's divine magic complements your focus on balance and preservation, strengthening your protective capabilities.</li>
          </ul>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={glacialHeartImage} 
                alt="Circle of the Glacial Heart"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={glacialHeartImage}
                download="GlacialHeart.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesGlacialHeart; 