import React, { useState, useRef, useEffect } from 'react';
import sovereignZealotImage from '../../assets/images/subclasses/Sovereign/sovereignzealot.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesSovereignZealot = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={sovereignZealotImage} 
          alt="Sovereign Zealot" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Sovereign Zealot</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesUmbralInfiltrator" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Umbral Infiltrator
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <div className="requirement">Requirement: Must be the Humanity race</div>
          <p>
            Sovereign Zealots are the unwavering vanguards of Humanity's resurgence, skilled in the arts of warfare and persuasion. Clad in heavy armor and wielding martial weapons, they stand as bulwarks against the chaos of the world of Velum. Their mastery of rhetoric and unwavering faith fuel their drive to elevate humanity above all others, securing their dominion over the realm.
          </p>
          <p>
            In the unforgiving realm of Velum, where the very existence of humanity teeters on the precipice of oblivion, the Sovereign Zealots emerge as unwavering beacons of hope. These are not mere warriors, but paragons of unwavering dedication to humanity's resurgence, their resolve as unyielding as the armor they wear.
          </p>
        </div>

        <div className="content-block">
          <h2>Features</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Domain Spells</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You gain domain spells at the cleric levels listed in the Sovereign Zealot Domain Spells table. See the Divine Domain class feature for how domain spells work.</p>
            <div className="trait">
              <div className="trait-header">
                <h3>1st Level</h3>
              </div>
              <p>Command, Heroism</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>3rd Level</h3>
              </div>
              <p>Enthrall, Branding Smite</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>5th Level</h3>
              </div>
              <p>Crusader's Mantle, Fear</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>7th Level</h3>
              </div>
              <p>Compulsion, Dominate Beast</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>9th Level</h3>
              </div>
              <p>Dominate Person, Geas</p>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Channel Divinity: Inspiring Conviction</h3>
              <span className="level-tag">Level 2nd</span>
            </div>
            <p>
              As an action, you can channel divine energy to bolster your words and ignite the flames of human exceptionalism. For the next 10 minutes, you gain advantage on all Charisma (Persuasion) and Charisma (Deception) checks when interacting with others to promote human unity, inspire loyalty, and strengthen their resolve to reclaim humanity's rightful place as the Shattered Realm's leaders.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Tactical Precision</h3>
              <span className="level-tag">Level 6th</span>
            </div>
            <p>
              Your combat training and unwavering focus grant you the ability to exploit enemy vulnerabilities with deadly accuracy. You gain the Sneak Attack feature, as a Rogue. You deal an extra 1d6 damage to a creature you hit with an attack if you have advantage on the attack roll. The attack must use a finesse or ranged weapon. The extra damage increases by 1d6 at 10th and 14th level.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Deflecting Maneuver</h3>
              <span className="level-tag">Level 8th</span>
            </div>
            <p>
              Your battlefield experience and understanding of combat tactics allow you to deftly avoid harm. When you are targeted by an attack, you can use your reaction to impose disadvantage on the attack roll. You can use this feature a number of times equal to your Wisdom modifier (minimum of once), and you regain all expended uses after a long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Paragon of Humanity</h3>
              <span className="level-tag">Level 17th</span>
            </div>
            <p>At 17th level, your commitment to humanity's cause elevates you to a living legend. You gain the following benefits:</p>
            <p>Humanity's Legacy: Your physical form embodies the ideals of human dominance. Your skin takes on a golden hue, and you grow a pair of pure white wings, granting you a flying speed of 30 feet.</p>
            <p>Voice of Authority: Your words carry the weight of human history and the promise of a glorious future. You gain advantage on Charisma (Persuasion), Charisma (Intimidation), and Charisma (Deception) checks.</p>
            <p>Unbreakable Will: Your mind is a fortress. You gain resistance to psychic damage and advantage on saving throws against being charmed or frightened.</p>
            <p>Dominion Mandate: You can issue a command that compels obedience a number of times equal to your proficiency bonus. Any creature that hears your command must make a Wisdom saving throw against your Cleric spell save DC (8 + your proficiency bonus + your Wisdom modifier) or follow the command for the next minute, provided the command is not self-harmful.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Role Playing</h2>
          <p>
            As a Sovereign Zealot, you are a beacon of humanity's resurgence, a champion of their supremacy, and a skilled warrior and orator. Your unwavering faith in humanity's potential and your dedication to Sacrator's teachings drive you to lead and inspire, to forge a new era where humanity stands above all others.
          </p>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Disciplined:</strong> You embody the order and structure that Sacrator demands.</p>
            <p><strong>Zealous:</strong> Your belief in humanity's superiority is absolute.</p>
            <p><strong>Charismatic:</strong> You inspire others with your words and actions.</p>
            <p><strong>Ambitious:</strong> You strive for greatness, both for yourself and for humanity.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Order:</strong> You believe in the importance of structure and hierarchy.</p>
            <p><strong>Dominion:</strong> Humanity is the pinnacle of creation, destined to rule over all others.</p>
            <p><strong>Progress:</strong> Technological advancement is the key to humanity's future.</p>
            <p><strong>Purity:</strong> You uphold the sanctity of humanity's bloodline.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>Sacerdotian Devotion:</strong> You are deeply devoted to Sacrator and his divine mandate.</p>
            <p><strong>Humanity's Advocate:</strong> You see yourself as a champion of humanity's rights.</p>
            <p><strong>Technological Legacy:</strong> You are proud of humanity's technological achievements.</p>
            <p><strong>Community Leader:</strong> You inspire and guide your community.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Fanaticism:</strong> Your unwavering belief in humanity's supremacy can blind you to their flaws.</p>
            <p><strong>Intolerance:</strong> You have little patience for those who oppose humanity's rule.</p>
            <p><strong>Arrogance:</strong> Your belief in humanity's superiority can make you dismissive of other races.</p>
            <p><strong>Ruthlessness:</strong> You are willing to use any means necessary to achieve your goals.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Equipment</h2>
          <p>As a Sovereign Zealot, your equipment reflects your role as a warrior and leader:</p>
          <ul>
            <li>A holy symbol of Sacrator, crafted from gold or platinum</li>
            <li>A set of masterfully crafted armor</li>
            <li>A backpack containing a bedroll, mess kit, and armor repair tools</li>
            <li>A collection of Sacrator's teachings</li>
            <li>A banner bearing the symbol of Sacrator and humanity's colors</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Multiclassing</h2>
          <p>To enhance your capabilities as a Sovereign Zealot, consider multiclassing with:</p>
          <div className="trait">
            <p><strong>Fighter:</strong> Further enhance your martial prowess with combat maneuvers and additional fighting styles.</p>
            <p><strong>Rogue:</strong> Gain stealth and cunning abilities to complement your tactical skills.</p>
            <p><strong>Ranger:</strong> Add wilderness expertise and enhanced combat abilities to your repertoire.</p>
            <p><strong>Paladin:</strong> Combine divine power with your zealous dedication to humanity.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={sovereignZealotImage} 
                alt="Sovereign Zealot"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={sovereignZealotImage}
                download="SovereignZealot.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesSovereignZealot; 