import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { User, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import './species.css';

const Species = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  const deityPaths = [
    { name: 'Gravewalker', path: '/species/SpeciesGravewalker' },
    { name: 'Humanity', path: '/species/SpeciesHumanity' },
    { name: 'Lifedrinker', path: '/species/SpeciesLifedrinker' },
    { name: 'Manaforged', path: '/species/SpeciesManaforged' },
    { name: 'Seraphiend', path: '/species/SpeciesSeraphiend' },
    { name: 'Scytherai', path: '/species/SpeciesScytherai' },
    { name: 'Skinwalker', path: '/species/SpeciesSkinWalker' },
    { name: 'Starborn', path: '/species/SpeciesStarborn' },
    { name: 'Vermis Corpus', path: '/species/SpeciesVermisCorpus' },
    { name: 'Sunseed', path: '/species/SpeciesSunseed' },
    { name: 'Winter Witch', path: '/species/SpeciesWinterWitch' }
  ];

  return (
    <div className="species-welcome" style={{ background: '#0a0a0a' }}>
      <motion.div 
        className="species-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <User size={48} className="welcome-icon" style={{ color: '#4a9eff', marginBottom: '1rem' }} />
        <h1>Fractured Paths of the Lost</h1>
        <div className="menu-container">
          <div ref={menuRef} className={`species-paths ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Species
              <ChevronDown size={20} />
            </h2>
            <div className="species-links">
              {deityPaths.map((deity, index) => (
                <Link 
                  key={index} 
                  to={deity.path}
                  className="species-link"
                  onClick={() => setMenuOpen(false)}
                >
                  {deity.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="content-section">
        <p>
            In the Shattered Realm, the diversity of life is as profound as the fractures of reality itself. The races that inhabit this realm are not merely survivors; they are testaments to the resilience, adaptation, and chaos born from the Shattering. From the ancient and enigmatic Starborn, who seek to reclaim their lost legacy, to the Manaforged, living constructs born of magic and technology, each race tells a story of triumph, loss, and transformation.
          </p>
          <p>
            The Gravewalkers, animated by the Firstborn's will, bear the weight of unfulfilled pacts, while the Scytherai struggle with the echoes of their hive-mind past. Humanity, now marked by divine nanites, believes itself chosen to impose order upon the chaos, while the Vermis Corpus embody the unsettling union of death and purpose. Whether born of celestial intent, mortal ambition, or the chaotic forces of the Shattering, each race brings unique strengths, histories, and ambitions to this reshaped world.
          </p>
          <p>
            The inhabitants of the Shattered Realm are more than wanderers in a broken world; they are key players in the unfolding saga of a cosmos reborn, each carving their legacy into the fabric of a reality still finding its balance
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Species; 