import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/deities/Caelumbris.png';
import './PantheonPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const PantheonCaelumbris = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={caelumbrisImage} 
          alt="Caelumbris: The Shrouded Catalyst" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Caelumbris: The Shrouded Catalyst</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/Pantheon" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCalanthar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Calanthar
                </Link>
                <Link to="/deities/PantheonFirstborn" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonQueenMab" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab
                </Link>
                <Link to="/deities/PantheonQueenTitania" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania
                </Link>
                <Link to="/deities/PantheonSacrathor" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor
                </Link>
                <Link to="/deities/PantheonValdis" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Valdís
                </Link>
                <Link to="/deities/PantheonWildscar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Wildscar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2></h2>
          <p>
            Caelumbris reigns unseen within the deepest shadows, a subtle manipulator of fate. His influence is a whisper in the dark, a chill down the spine, a presence felt rather than seen. He is the embodiment of secrets and the unseen forces that shape destiny. Those who dwell in the grey areas of morality—thieves, rogues, spies, and especially the umbral infiltrators—find kinship with Caelumbris. He is the patron of those who understand the power of hidden knowledge and the strategic deployment of chaos.
          </p>
        </div>

        <div className="content-block">
          <p>
            Unlike many deities who demand outright worship, Caelumbris prefers subtle acknowledgment through actions rather than words. His followers believe that every secret kept, every shadow embraced, and every moment of chaos carefully orchestrated is a prayer to their patron. They operate in networks of information brokers, each one holding pieces of greater truths but never the complete picture.
          </p>
        </div>

        <div className="content-block">
          <h2>Sacred Places</h2>
          <p>
            The temples of Caelumbris, if they can be called such, are hidden spaces within ordinary buildings - secret rooms behind revolving bookcases, underground chambers beneath busy marketplaces, or abandoned warehouses that serve as meeting spots for his faithful. These locations are known only to initiates and are frequently changed to maintain their secrecy.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Whisper in the Dark</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Shadows, Secrets, Survival</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>The Shattered Realm</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A clouded mirror reflecting a distorted image.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Caelumbris: The Shrouded Catalyst"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Caelumbris.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonCaelumbris; 