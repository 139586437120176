import React, { useState, useEffect, useRef } from 'react';
import calantharImage from '../../assets/images/deities/Calanthar.png';
import './PantheonPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const PantheonCalanthar = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img
          src={calantharImage}
          alt="Calanthar: The All-Encapsulating Reality"
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
        />
        <div className="pantheon-title">
          <h1>Calanthar: The All-Encapsulating Reality</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/Pantheon" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCaelumbris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris
                </Link>
                <Link to="/deities/PantheonFirstborn" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonNebula" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Nebula
                </Link>
                <Link to="/deities/PantheonQueenMab" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab
                </Link>
                <Link to="/deities/PantheonQueenTitania" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania
                </Link>
                <Link to="/deities/PantheonSacrathor" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor
                </Link>
                <Link to="/deities/PantheonValdis" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Valdís
                </Link>
                <Link to="/deities/PantheonWildscar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Wildscar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            Calanthar, within the lore of the Shattered Realm, stands as a unique and deeply intricate entity. Not just a god but the very embodiment of reality within this fractured cosmos, Calanthar's story weaves through the fabric of existence itself. His narrative is a confluence of omnipotence, isolation, and the quest for balance in a universe born from chaos.
          </p>
        </div>

        <div className="content-block">
          <h2>Origins and Ascension</h2>
          <p>
            Calanthar is not merely a god; he is the embodiment of reality within the Shattered Realm, a singular universe created from the collapse of countless others. This unique cosmos is characterized by its unstable and intertwined nature, where fragments of disparate worlds blend into a complex tapestry. Once a mortal driven by an insatiable desire for ultimate understanding, Calanthar's transformation into a being fused with the essence of existence itself granted him immeasurable power—but also profound isolation and madness, as the weight of infinite realities bore down upon his consciousness. For millennia, he subtly influenced humanity's technological progress, acting as an unseen architect of their destiny.
          </p>
        </div>

        <div className="content-block">
          <h2>The Multiversal Awakening</h2>
          <p>
            Amidst the endless cosmos, where stars whispered secrets of eternity, Calanthar found himself facing a monumental decision. His eyes, reflecting infinite galaxies, glowed with a power that transcended time and space as he prepared to expand his consciousness. With a deep breath, he connected his mind to every version of himself across the multiverse, weaving an intricate network of possibilities. In this transformative moment, Calanthar ceased to be merely mortal; he became a nexus of infinite selves, each contributing its knowledge and experiences to his being. The connection, however, brought both enlightenment and an overwhelming burden. The joys, sorrows, triumphs, and failures of countless lives bore down on him, threatening his sanity. In his pursuit of omniscience, Calanthar had entered uncharted realms, unaware of the cost to his soul.
          </p>
        </div>

        <div className="content-block">
          <h2>A Fragmented Existence</h2>
          <p>
            Calanthar awoke in a strange and unfamiliar world, the ground beneath him cold and unyielding. The warm memories of loved ones had become distant echoes. Rising as a figure shrouded in confusion and loss, he wandered like a ghost through landscapes he had never seen. His mind, a tempest of ceaseless voices and visions from a thousand realities, was a cacophony of alternate selves clashing for dominance. Decades, perhaps centuries, passed as he roamed, his resolve and sense of identity crumbling. This prolonged period of disorientation ultimately sharpened his awareness, forcing him to adapt to the onslaught of sensory chaos. From this crucible, Calanthar emerged fundamentally changed. No longer entirely human, he had become an entity straddling the line between man and myth, tethered to a world that felt both familiar and alien.
          </p>
        </div>

        <div className="content-block">
          <h2>The Silent Architect</h2>
          <p>
            For nearly a millennium, Calanthar remained a hidden force, guiding humanity through epochs of technological evolution. Operating from the shadows, he whispered insights to leaders and innovators, sowing the seeds of progress. Yet, for all his influence, Calanthar lived a solitary existence, guarding his immortality and vast knowledge as fiercely as the wisdom he imparted. He observed humanity's ascension with both pride and sorrow, increasingly alienated from those he uplifted. While his multiversal perspective provided unparalleled insight, it also rendered him an outsider, eternally gazing at lives he could never truly be part of. This dichotomy haunted him, and he often questioned whether he was still a participant in the grand drama of existence or merely a spectator.
          </p>
        </div>

        <div className="content-block">
          <h2>The Leviathan's Approach</h2>
          <p>
            The cosmos's tranquility shattered with the arrival of a cryptic signal, heralding the approach of a leviathan—a massive entity dwarfing their moon. Calanthar had not foreseen this event, and its implications unsettled him. Among the halls of power, where his counsel had long been revered, chaos erupted. The signal, interpreted as a divine challenge, ignited fervent zealotry. The doctrines Calanthar had instilled to guide humanity toward progress had twisted into a weapon of fanaticism. His once-puppet council transformed into fervent zealots, interpreting the message as a decree from their god to wage a holy war. The savior of humanity had inadvertently become the architect of its potential downfall. Even as Calanthar worked to decipher the signal's ancient, cryptic language, its misinterpretation spread like wildfire. It became the foundation for a new scripture and a rallying cry for cosmic crusade. Calanthar's influence waned as his council moved with zeal that defied his manipulation. As the leviathan loomed ever closer, the very beliefs Calanthar had nurtured became an obstacle. The leviathan, he discerned, was not a divine adversary but a cosmic seedling—a colossal organism that roamed the stars, feeding and growing. Though massive and powerful, it was not invincible. Convincing his council to grant him oversight of the dimensional net project, a device designed to halt the leviathan's advance, was a small victory. Yet, time was short, and the project would be his final chance to steer events back on course.
          </p>
        </div>

        <div className="content-block">
          <h2>The Dimensional Net and Sacrifice</h2>
          <p>
            The dimensional net, Calanthar's magnum opus, was a convergence of science and sorcery. It represented the culmination of his millennia of preparation. As the leviathan approached, anticipation and dread filled the air. Calanthar activated the net, and in an instant, the fabric of reality shifted. The leviathan was ensnared, but the consequences rippled across the multiverse. Realities began collapsing, folding into the pocket dimension created by the net. Calanthar, intertwined with the device, felt his essence stretch to encompass these unraveling worlds. He encountered a cosmic entity—the universe personified—appearing as a feminine form cloaked in galaxies. In her vast presence, Calanthar felt his insignificance yet defied her, channeling all his strength to stabilize the fragmenting universe. 'Not them!' he cried, his voice resonating through the collapsing void. With an unyielding determination, Calanthar channeled the unraveling realities, integrating them into his essence to safeguard the last fragments of his people and home. In this act of defiance and sacrifice, he transcended the role of a mere guardian, becoming a creator and the custodian of a fragile, precious new existence.
          </p>
        </div>

        <div className="content-block">
          <h2>The Shattered Realm</h2>
          <p>
            In the aftermath, Calanthar found himself within the Shattered Realm—a mosaic of fragmented worlds bound together in a precarious equilibrium. No longer a man or wizard, he had transcended into a living embodiment of the realm itself. Every star, every planet, and every creature within this new reality was a fragment of his consciousness. The burden of his role weighed heavily, but within the solitude, Calanthar found a glimmer of hope. Here was an opportunity to reshape a world free from the shadows of fanaticism and fear. As the custodian of this reality, his journey was far from over; it was a chance to create a legacy that would echo through eternity.
          </p>
        </div>

        <div className="content-block">
          <h2>Presence and Representation</h2>
          <p>
            In his ascended form, Calanthar manifests as a venerable figure, draped in humble, patchwork robes. His eyes, reflecting the cosmos, radiate an unearthly light, and his staff—fashioned from the remnants of collapsed realities—serves as both a weapon and a symbol of his cosmic authority. Every star, planet, and creature within the Shattered Realm is a fragment of his consciousness, a testament to his omnipresence and eternal vigilance.
          </p>
        </div>

        <div className="content-block">
          <h2>Dogma and Worship</h2>
          <p>
            Calanthar's teachings emphasize the interconnectedness of all things and the pursuit of understanding. His followers, often scholars, mages, and philosophers, seek to unravel the mysteries of the cosmos while respecting its delicate balance. Temples dedicated to Calanthar are rare; his worship is expressed through the pursuit of knowledge and harmony with the universe.
          </p>
        </div>

        <div className="content-block">
          <h2>Eternal Vigilance and Legacy</h2>
          <p>
            Calanthar's story is one of sacrifice, transformation, and eternal duty. As the Shattered Realm's custodian, he balances its myriad realities, ensuring that its infinite possibilities do not unravel into chaos. His existence serves as both a warning and an inspiration, a reminder of the cost of ambition and the boundless potential of understanding.
          </p>
        </div>

        <div className="content-block">
          <p>
            In the Shattered Realm, Calanthar is not merely a deity but the universe's soul—a figure of unparalleled complexity, whose story is interwoven with the very fabric of existence.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Universal Embodiment, The Weaver of Realities</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Cosmos, Knowledge, Creation</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>All</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A web intertwined with stars and fragmented realities.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={calantharImage} 
                alt="Calanthar: The All-Encapsulating Reality"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={calantharImage}
                download="Calanthar.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonCalanthar; 