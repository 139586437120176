import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Scytherai/scytherai.png';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesScytherai = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Scytherai: Echoes of the Hive" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Scytherai</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species              
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>
                <Link to="/species/SpeciesSkinWalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Skinwalker
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            The Scytherai are a haunting paradox: a race of insectoid humanoids born from a collective consciousness, now fractured into a mosaic of individual beings. Once extensions of the Leviathan, a malevolent entity dwelling within the Shadow Dimension, they were severed from the hive mind during a cataclysmic event known as the বিচ্ছিন্নতা (bisshinnota - 'separation'). This abrupt sundering shattered their shared consciousness, casting them adrift in the Shattered Realm as individuals for the first time. Their past, a swirling vortex of fragmented memories, paints a disturbing picture of existence within the hive: a ceaseless, যন্ত্রবত (jantrobot - 'mechanical' or 'robotic') servitude to the Leviathan's will, devoid of individual thought or emotion. These fragmented recollections, more akin to nightmares than memories, fuel a deep-seated internal conflict. Some Scytherai are repulsed by these visions, embracing their newfound individuality as a liberation from a horrific past. Others, however, feel a disturbing pull towards the lost unity of the hive, viewing their current state as incomplete and weak.
          </p>
        </div>

        <div className="content-block">
          <h2>Description</h2>
          <p>
            Scytherai are imposing figures, their bodies a testament to their engineered purpose within the hive.
          </p>
        </div>

        <div className="content-block">
          <h2>Exoskeleton</h2>
          <p>
            A durable, segmented exoskeleton provides natural protection, its color ranging from earthy browns and greens to deep blacks, sometimes with subtle, iridescent sheens.
          </p>
        </div>

        <div className="content-block">
          <h2>Limbs</h2>
          <p>
            Two pairs of arms – a primary humanoid pair for delicate manipulation and a secondary pair, often larger and more powerful, tipped with sharp, blade-like protrusions or specialized tools, depending on their caste.
          </p>
        </div>

        <div className="content-block">
          <h2>Heads</h2>
          <p>
            Insectile heads feature large, multifaceted eyes that grant exceptional vision, sensitive antennae, and powerful mandibles.
          </p>
        </div>

        <div className="content-block">
          <h2>Wings</h2>
          <p>
            All Scytherai possess a set of wings. While typically not large enough to grant true flight in their natural forms, they can be enhanced through magic or specialized training, reflecting the potential within their genetic code.
          </p>
        </div>

        <div className="content-block">
          <h2>A World of Whispers and Fear</h2>
          <p>
            The Scytherai's physical forms, a stark reminder of their otherworldly origins, often evoke fear and mistrust. Their chitinous exoskeletons, multifaceted eyes, and insectoid limbs are viewed with suspicion by most other races. This prejudice, coupled with the Scytherai's own internal struggles, has led many to adopt solitary lives on the fringes of society. The Wall of Death, an imposing barrier erected by powerful magic, stands as a constant, agonizing reminder of their severance from the hive, an uncrossable boundary between their present and the life they vaguely remember.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two different ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Scytherai mature rapidly, reaching adulthood by the age of 5. Their lifespan is relatively short, averaging around 50 years.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>The shock of separation from the hive mind has left many Scytherai struggling to define their own moral compass. Most tend toward neutral alignments as they adapt to their newfound individuality, but exceptions exist in all directions.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium. Scytherai typically stand between 5 and 6 feet tall.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Exoskeletal Armor</h3>
            </div>
            <p>Your chitinous exoskeleton is naturally resilient. When you aren't wearing armor, your AC is 16 + your Dexterity modifier. You can use your natural armor to determine your AC if the armor you wear would leave you with a lower AC. A shield's benefits apply as normal while you use your natural armor.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>You can see in dim light within 120 feet of you as if it were bright light, and in darkness as if it were dim light. You can't discern color in darkness, only shades of gray.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Multifaceted Eyes</h3>
            </div>
            <p>Your compound eyes grant you superior visual acuity. You have proficiency in the Perception skill, and you have advantage on saving throws against being blinded.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can understand and communicate in Scytherai, a complex language of clicks, chirps, and subtle body movements. Due to the unique structure of their mouthparts, Scytherai are physically incapable of speaking other languages, though they can learn to understand them.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Hive Mind Echoes</h3>
            </div>
            <p>You can communicate telepathically with any creature within 60 feet that you can see and that understands at least one language. This telepathy allows you to convey basic messages, emotions, or images, but it is not suitable for complex or nuanced communication. It is a vestige of the hive mind, a faint echo of their former connection. This telepathic communication is often accompanied by faint clicking or chirping sounds audible only to those receiving the message. You also have advantage on saving throws against effects that would read your thoughts or control you telepathically.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Subraces</h2>
          <p>The echoes of the hive's caste system still resonate within the Scytherai, influencing their physical attributes and innate talents.</p>
          
          <div className="trait">
            <div className="trait-header">
              <h3>Builder Caste</h3>
            </div>
            <p>Builders were the architects and engineers of the hive, responsible for constructing and maintaining its intricate structures. They possess a natural aptitude for crafting and problem-solving.</p>

            <div className="trait">
              <div className="trait-header">
                <h3>Ability Score Increase</h3>
              </div>
              <p>Your Intelligence score increases by 1.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Artisan's Expertise</h3>
              </div>
              <p>You gain proficiency with two sets of artisan's tools of your choice. You also gain expertise with those sets of artisan tools, you now double your proficiency bonus.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Extra Limb Utility</h3>
              </div>
              <p>Your four arms allow for exceptional dexterity and multitasking. You can use the bonus action to take the Help action. You also gain advantage on ability checks made using a set of artisan tools that you are proficient with.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Analytical Mind</h3>
              </div>
              <p>You have advantage on Intelligence checks made to appraise the structural integrity of buildings, traps, or objects. In addition, you have advantage on investigation checks.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Enhanced Flight</h3>
              </div>
              <p>You can use your bonus action to gain a flying speed of 30 feet until the end of your turn. You can use this feature a number of times equal to your proficiency bonus, regaining all expended uses after a long rest.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Bio-Fabrication</h3>
              </div>
              <p>As an action, you can secrete a specialized, quick-hardening resin from your lower pair of hands. This resin can be molded and shaped into simple, non-mechanical objects no larger than 5 cubic feet (e.g., a tool, a weapon, a shield, or a piece of furniture). The object remains solid for 24 hours, or until you dismiss it as a bonus action. The object has an AC of 10 and hit points equal to your level plus your Intelligence modifier. You can use this ability a number of times equal to your proficiency bonus, regaining all expended uses after a long rest.</p>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Soldier Caste</h3>
            </div>
            <p>Soldiers were the defenders of the hive, bred for strength, resilience, and unwavering aggression.</p>

            <div className="trait">
              <div className="trait-header">
                <h3>Ability Score Increase</h3>
              </div>
              <p>Your Strength score increases by 1.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Enhanced Natural Weaponry</h3>
              </div>
              <p>Your secondary arms end in sharp, blade-like protrusions. These are natural weapons, which you can use to make unarmed strikes. If you hit with them, you deal slashing damage equal to 1d8 + your Strength modifier, instead of the bludgeoning damage normal for an unarmed strike. You are proficient with these natural weapons.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Bonus Attack</h3>
              </div>
              <p>When you take the Attack action on your turn and make an attack using your natural weapon, you can immediately make two additional attacks using your natural weapon as a bonus action.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Imposing Presence</h3>
              </div>
              <p>You gain proficiency in the Intimidation skill. Also, you have advantage on any strength checks made to grapple or shove.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Enhanced Flight</h3>
              </div>
              <p>You can use your bonus action to gain a flying speed of 30 feet until the end of your turn. You can use this feature a number of times equal to your proficiency bonus, regaining all expended uses after a long rest.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Warrior's Resolve</h3>
              </div>
              <p>You have resistance to piercing damage. Additionally, a number of times equal to your proficiency bonus per long rest, you can use your reaction when you take damage to gain resistance to all damage from that attack or effect until the end of your turn. You can also use this reaction to gain advantage on a saving throw against being frightened or charmed. You regain all expended uses after a long rest.</p>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Drone Caste</h3>
            </div>
            <p>Drones were the scouts and messengers of the hive, possessing exceptional speed, agility, and sensory acuity.</p>

            <div className="trait">
              <div className="trait-header">
                <h3>Ability Score Increase</h3>
              </div>
              <p>Your Wisdom score increases by 1.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Advanced Winged Flight</h3>
              </div>
              <p>You have a pair of thin, insect-like wings that grant you a flying speed of 60 feet. To use this speed, you can't be wearing medium or heavy armor. You can also hover in place.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Evasive Flight</h3>
              </div>
              <p>While flying, you have advantage on Dexterity saving throws, and other creatures have disadvantage on opportunity attacks against you.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Keen Senses</h3>
              </div>
              <p>You have advantage on Wisdom (Perception) checks that rely on hearing or smell. Also, once per long rest, you can cast the spell Clairvoyance without expending a spell slot or requiring material components.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Nimble Form</h3>
              </div>
              <p>You gain proficiency in the Acrobatics skill. Also, you can take the Dash action a number of times equal to your proficiency bonus per long rest.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Skilled Flyer</h3>
              </div>
              <p>You can use the Dash, Disengage, or Hide action as a bonus action while flying.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Drone's Celerity</h3>
              </div>
              <p>You can use your bonus action to gain one of the following benefits of your choice, which lasts until the start of your next turn: Your movement speed increases by 30 feet. You gain an additional action on your turn. This action can be used only to take the Attack (one weapon attack only), Dash, Disengage, Hide, or Use an Object action. You gain an additional bonus action on your turn. You can use this feature a number of times equal to your proficiency bonus, regaining all expended uses after a long rest.</p>
            </div>
          </div>
        </div>

        <div className="content-block">
          <h2>Role Playing</h2>
          <p>As a Scytherai, you are a living weapon, a creature designed for survival and precision. Your blade-like limbs and predatory instincts are integral to your identity, but you are far more than a mere killer. To you, life is a series of calculated movements, each one an opportunity to refine your craft and prove your worth. Your existence is a balance between the savage and the sophisticated—a predator bound by the elegance of its nature.</p>
          <p>Despite your efficiency in battle, you are not without depth. Your instincts may be sharp, but they are guided by a strong sense of pride and culture. Every cut of your blade is purposeful, every movement deliberate. The Scytherai live by an internal code of honor and discipline, and you carry this philosophy into every interaction and decision you make.</p>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Disciplined:</strong> You approach life with precision and control, treating every action as part of a greater strategy.</p>
            <p><strong>Proud:</strong> Your Scytherai heritage is a point of pride, and you carry yourself with confidence and poise.</p>
            <p><strong>Pragmatic:</strong> You prioritize efficiency and effectiveness, cutting through unnecessary complications to achieve your goals.</p>
            <p><strong>Aesthetic:</strong> You see beauty in the symmetry of motion and form, even in the chaos of combat.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Perfection:</strong> Strive to hone your skills and become the best version of yourself, physically and mentally.</p>
            <p><strong>Balance:</strong> You believe that destruction and creation are two sides of the same coin and aim to maintain that equilibrium.</p>
            <p><strong>Honor:</strong> Combat is sacred, and you respect both prey and predator in the natural order.</p>
            <p><strong>Adaptation:</strong> Survival means evolving to meet new challenges; stagnation is death.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>The Blade Dance:</strong> The combat styles and traditions of your people are sacred to you, a reminder of your heritage.</p>
            <p><strong>Fellow Scytherai:</strong> The shared experience of being a Scytherai forges a bond with others of your kind, even if they walk different paths.</p>
            <p><strong>The Hunt:</strong> You revere the thrill of the hunt and are drawn to others who share your respect for its elegance.</p>
            <p><strong>The Forge of Origins:</strong> Legends of the creation of your race inspire you, and you seek the truth of your makers and their intent.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Arrogance:</strong> Your precision and skill can lead to overconfidence, making you underestimate others or take unnecessary risks.</p>
            <p><strong>Isolation:</strong> Your pride can make it difficult to trust or rely on others, even when cooperation is the wisest course.</p>
            <p><strong>Bloodthirst:</strong> The instinct to hunt and kill sometimes overwhelms your better judgment, driving you to pursue targets relentlessly.</p>
            <p><strong>Detached:</strong> Your calculated, pragmatic nature can alienate those who value emotional or impulsive connections.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Scytherai: Echoes of the Hive"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Scytherai.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesScytherai; 