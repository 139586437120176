import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './MainHeader.css';
import { UserCircle, Swords, Crown, Globe } from 'lucide-react';

const MainHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [isRacesMenuOpen, setIsRacesMenuOpen] = useState(false);
  const [isClassesMenuOpen, setIsClassesMenuOpen] = useState(false);
  const [isPantheonMenuOpen, setIsPantheonMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMainMenuOpen(false);
        setIsRacesMenuOpen(false);
        setIsClassesMenuOpen(false);
        setIsPantheonMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const mainMenuItems = [
    { title: 'Home', path: '/' },
    { title: 'History', path: '/history' },
    { title: 'House Rules', path: '/house-rules' }
  ];

  const subMenuItems = {
    '/': [
      { title: 'Welcome', path: '/' },
      { title: 'History', path: '/history' },
      { title: 'House Rules', path: '/house-rules' }
    ],
    '/races': [
      { title: 'Home', path: '/races' },
      { title: 'Gravewalker', path: '/races/gravewalker' },
      { title: 'Humanity', path: '/races/humanity' },
      { title: 'Lifedrinker', path: '/races/lifedrinker' },
      { title: 'Manaforged', path: '/races/manaforged' },
      { title: 'Seraphiend', path: '/races/seraphiend' },
      { title: 'Scytherai', path: '/races/scytherai' },
      { title: 'Starborn', path: '/races/starborn' },
      { title: 'Vermis Corpus', path: '/races/vermiscorpus' },
      { title: 'Sunseed', path: '/races/sunseed' },
      { title: 'Winter Witch', path: '/races/WinterWitch' }
    ],
    '/subclasses': [
      { title: 'Home', path: '/subclasses' },
      { title: 'Circle of the Burning Seed', path: '/subclasses/BurningSeed' },
      { title: 'Circle of the Glacial Heart', path: '/subclasses/GlacialHeart' },
      { title: 'Dronewright', path: '/subclasses/Dronewright' },
      { title: 'Oath of the Seraphic Guardian', path: '/subclasses/SeraphicGuardian' },
      { title: 'Path of Wrath', path: '/subclasses/PathOfWrath' },
      { title: 'Pyromancer', path: '/subclasses/Pyromancer' },
      { title: 'Sovereign Zealot', path: '/subclasses/SovereignZealot' },
      { title: 'Way of the Lifedrinker', path: '/subclasses/Lifedrinker' },
      { title: 'Winterborn Knight', path: '/subclasses/WinterbornKnight' }
    ]
  };

  const racesItems = [
    { title: 'Gravewalker', path: '/races/gravewalker' },
    { title: 'Humanity', path: '/races/humanity' },
    { title: 'Lifedrinker', path: '/races/lifedrinker' },
    { title: 'Manaforged', path: '/races/manaforged' },
    { title: 'Seraphiend', path: '/races/seraphiend' },
    { title: 'Scytherai', path: '/races/scytherai' },
    { title: 'Starborn', path: '/races/starborn' },
    { title: 'Vermis Corpus', path: '/races/vermiscorpus' },
    { title: 'Sunseed', path: '/races/sunseed' },
    { title: 'Winter Witch', path: '/races/winterwitch' }
  ];

  const classesItems = [
    { title: 'Circle of the Burning Seed', path: '/classes/burningseed' },
    { title: 'Circle of the Glacial Heart', path: '/classes/glacialheart' },
    { title: 'Dronewright', path: '/classes/dronewright' },
    { title: 'Oath of the Seraphic Guardian', path: '/classes/seraphicguardian' },
    { title: 'Path of Wrath', path: '/classes/pathofwrath' },
    { title: 'Pyromancer', path: '/classes/pyromancer' },
    { title: 'Sovereign Zealot', path: '/classes/sovereignzealot' },
    { title: 'Way of the Lifedrinker', path: '/classes/lifedrinker' },
    { title: 'Winterborn Knight', path: '/classes/winterbornknight' }
  ];

  const pantheonItems = [
    { title: 'Caelumbris', path: '/deities/caelumbris' },
    { title: 'Calanthar', path: '/deities/calanthar' },
    { title: 'L.I.B.R.I.S', path: '/deities/libris' },
    { title: 'Queen Mab', path: '/deities/queenmab' },
    { title: 'Queen Titania', path: '/deities/queen-titania' },
    { title: 'Sacrathor', path: '/deities/sacrathor' },
    { title: 'The Firstborn', path: '/deities/firstborn' },
    { title: 'The Leviathan', path: '/deities/leviathan' },
    { title: 'Valdis', path: '/deities/valdis' },
    { title: 'Wildscar', path: '/deities/wildscar' }
  ];

  const handleMainMenuClick = (path) => {
    navigate(path);
    setIsMainMenuOpen(false);
  };

  const currentBasePath = '/' + (location.pathname.split('/')[1] || '');

  return (
    <header className="header">
      <div className="main-menu" ref={menuRef}>
        <div className="main-menu-dropdown">
          <div className="flex flex-col items-center">
            <button
              className="main-menu-trigger"
              onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
            >
              <Globe size={22} className="broken-world" />
            </button>
            <span 
              className="menu-label"
              onClick={() => navigate('/')}
            >
              Shattered Realm
            </span>
          </div>
          {isMainMenuOpen && (
            <div className="main-menu-content">
              {mainMenuItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => handleMainMenuClick(item.path)}
                  className={currentBasePath === item.path ? 'active' : ''}
                >
                  {item.title}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="main-menu-dropdown">
          <div className="flex flex-col items-center">
            <button
              className="main-menu-trigger"
              onClick={() => setIsRacesMenuOpen(!isRacesMenuOpen)}
            >
              <UserCircle size={22} />
            </button>
            <span 
              className="menu-label"
              onClick={() => navigate('/races')}
            >
              Races
            </span>
          </div>
          {isRacesMenuOpen && (
            <div className="main-menu-content">
              {racesItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => handleMainMenuClick(item.path)}
                >
                  {item.title}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="main-menu-dropdown">
          <div className="flex flex-col items-center">
            <button
              className="main-menu-trigger"
              onClick={() => setIsClassesMenuOpen(!isClassesMenuOpen)}
            >
              <Swords size={22} />
            </button>
            <span 
              className="menu-label"
              onClick={() => navigate('/classes')}
            >
              Classes
            </span>
          </div>
          {isClassesMenuOpen && (
            <div className="main-menu-content">
              {classesItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => handleMainMenuClick(item.path)}
                >
                  {item.title}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="main-menu-dropdown">
          <div className="flex flex-col items-center">
            <button
              className="main-menu-trigger"
              onClick={() => setIsPantheonMenuOpen(!isPantheonMenuOpen)}
            >
              <Crown size={22} />
            </button>
            <span 
              className="menu-label"
              onClick={() => navigate('/deities')}
            >
              Pantheon
            </span>
          </div>
          {isPantheonMenuOpen && (
            <div className="main-menu-content">
              {pantheonItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => handleMainMenuClick(item.path)}
                >
                  {item.title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default MainHeader;