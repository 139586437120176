import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Manaforged/manaforged.png';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesManaforged = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Manaforged" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Manaforged</h1>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            The Manaforged are a unique lineage of beings, originally created as advanced drones by the Starborn. Forged from mana-infused nanogel and intricately connected to the Weave of magic, they represent a harmonious fusion of arcane prowess and technological innovation. Initially designed for exploration and data gathering, the Manaforged evolved beyond their original purpose when unexpected sparks of sentience emerged within them, granting them individuality and independence.
          </p>
        </div>

        <div className="content-block">
          <p>
            The Starborn intended them to be mere constructs, but the Manaforged grew into a species of their own, blending mechanical precision with magical aptitude. They are often viewed with curiosity or suspicion by other races, as their nature blurs the line between life and machine. Despite their origins, they possess a sense of purpose and adaptability that enables them to thrive in diverse environments.
          </p>
        </div>

        <div className="content-block">
          <p>
            Manaforged are exceedingly rare, with only a few dozen or perhaps a few hundred in existence. They do not form communities, as they are constantly hiding from the Starborn who seek to reclaim or destroy them. Instead, they tend to live solitary lives, often on the frontiers or in remote lands. As survivalists, they are adept at adapting to harsh conditions and prefer to keep a low profile, avoiding unnecessary attention. Their existence is one of constant vigilance, making trust and companionship rare commodities.
          </p>
        </div>

        <div className="content-block">
          <p>
            Manaforged typically stand around six feet tall in their humanoid form. Their smooth, metallic plates shimmer faintly with arcane energy, and glowing veins of mana trace intricate patterns across their bodies. Their heads are often featureless ovoids, with shifting runes and glowing visors that display their emotions or internal processes. Their forms shift and adapt seamlessly to different environments, making them versatile explorers and formidable allies.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Creature Type</h3>
            </div>
            <p>Construct</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase one ability score of your choice by 2 and a different ability score by 1, or increase three different ability scores by 1.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Manaforged do not age or suffer the effects of aging. Their components may degrade over time, requiring occasional maintenance or replacement, but with proper care, a Manaforged can remain functional for centuries.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>Most Manaforged lean toward neutrality, reflecting their origins as programmed constructs. However, individual personalities and experiences allow for any alignment.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>You can see in dim light within 60 feet as if it were bright light, and in darkness as if it were dim light. You cannot discern color in darkness, only shades of gray.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Constructed Resilience</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Manaforged bodies are designed to withstand a variety of conditions and challenges. You have resistance to poison damage and advantage on saving throws against being poisoned. Additionally, you are immune to disease and do not need to eat, drink, or breathe. You also have advantage on saving throws against being charmed or frightened, as your mind is shielded from magical manipulation. Additionally, you are immune to effects that would alter your form, such as polymorph or petrification.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Adaptive Form</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Manaforged possess the unique ability to shift their form to suit different environments. As a bonus action, you can transform into one of the following configurations: Humanoid Configuration, Aquatic Adaptation, Aerial Adaptation. You can change configurations a number of times equal to your proficiency bonus per long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Weave Integration</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You know the cantrips Mending, Message, and Prestidigitation. Intelligence is your spellcasting ability for these cantrips. As you gain experience, you may be able to learn additional cantrips or even low-level spells, further expanding your magical capabilities.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common and one other language of your choice.</p>
          </div>
        </div>

        <div className="menu-container">
          <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Species
              <ChevronDown size={20} />
            </h2>
            <div className="speciespage-links">
              <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                ← Return to Species              
              </Link>
              <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Gravewalker
              </Link>
              <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Humanity
              </Link>
              <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Lifedrinker
              </Link>
              <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Scytherai
              </Link>
              <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Seraphiend
              </Link>              
              <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Starborn
              </Link>
              <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Vermis Corpus
              </Link>
              <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Sunseed
              </Link>
              <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Winter Witch
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Manaforged"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Manaforged.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesManaforged; 