import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Lifedrinker/lifedrinker.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesLifedrinker = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Lifedrinker" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Lifedrinker</h1>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            Lifedrinkers are the result of a rare and dangerous fusion of Ki manipulation and vampiric essence. Their creation traces back to the Protiengier, a wandering monk whose mastery over Ki was unparalleled. According to legend, the Protiengier encountered a parasitic creature that fed on his life force. Desperate to survive, he turned his mastery inward, fusing his Ki with the predator's essence. The fusion transformed them both into something unnatural. When the process ended, the monk was gone, leaving behind a single new being: the first Lifedrinker.
          </p>
        </div>

        <div className="content-block">
          <p>
            Though the Protiengier vanished, his legacy endured. Lifedrinkers began appearing sporadically, their existence echoing the original transformation. Over time, these isolated occurrences coalesced into a distinct race. Unlike a plague, their condition does not spread like a virus, nor can it be willingly transmitted. However, their emergence remains a mystery, and their otherworldly nature inspires fear and mistrust.
          </p>
        </div>

        <div className="content-block">
          <p>
            Lifedrinkers walk the world as enigmatic wanderers, balancing their mastery of Ki with an insatiable hunger for life energy. Their presence is haunting yet magnetic, their striking beauty masking the predator within.
          </p>
        </div>

        <div className="content-block">
          <p>
            Lifedrinkers have pale, marble-like skin and sharp, elegant features. Their eyes glimmer faintly in dim light, hinting at the supernatural power that lies beneath.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Creature Type</h3>
            </div>
            <p>Humanoid</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium or Small (your choice)</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase one ability score of your choice by 2 and a different ability score by 1, or increase three different ability scores by 1.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>You can see in dim light within 60 feet as if it were bright light and in darkness as if it were dim light. You cannot discern color in darkness, only shades of gray.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Deathless Nature</h3>
            </div>
            <p>You do not need to breathe.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Spider Climb</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You have a climbing speed equal to your walking speed. Starting at 3rd level, you can move across vertical surfaces and ceilings without using your hands.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ki Sensitivity</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You can sense the Ki of creatures within 30 feet of you. You are aware of their emotional state and whether they are injured, frightened, or charmed. Starting at 5th level, you can sense invisible or hidden creatures within 10 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Proficient Predator</h3>
            </div>
            <p>You gain proficiency in one of the following skills: Athletics, Acrobatics, Insight, or Perception. This reflects the heightened instincts of a hunter attuned to their surroundings and prey.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ki Feeding Technique</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>As an action, you can siphon life force from a creature within 5 feet of you. The target takes 1d4 necrotic damage, and you heal for the same amount. At 5th level, this damage increases to 2d4, at 10th level to 3d4, and at 15th level, you can use this ability on creatures within 15 feet of you.</p>
            <p>When you feed, your predatory nature is unleashed: your eyes blaze a deep red, black veins writhe visibly under your skin, and dark, red-black vapor rises from your body. This display lasts until the end of your next turn.</p>
            <p>You can use this ability a number of times equal to half your proficiency bonus (rounded down, minimum of 1), regaining all uses after a long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Hungering Empowerment</h3>
            </div>
            <p>Each time you use Ki Feeding Technique, you enter a heightened state of power that lasts for 1 hour. During this time, your body undergoes a dramatic transformation, reflecting the energy you've absorbed. Your eyes burn a vivid red, black veins writhe beneath your skin, and a red-black vapor surrounds you.</p>
            <p>While under the effects of Hungering Empowerment, you gain the following benefits:</p>
            <p>Your walking speed increases by 10 feet.</p>
            <p>You gain advantage on all Strength (Athletics), Dexterity (Acrobatics), and Constitution saving throws.</p>
            <p>You gain a bonus to all attack rolls and damage rolls with melee or unarmed strikes equal to half your proficiency bonus (rounded down, minimum of 1).</p>
            <p>These benefits refresh after each successful use of Ki Feeding Technique, allowing you to sustain the empowered state as long as you continue to feed.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Overindulgence</h3>
            </div>
            <p>If you feed beyond your allowed uses of Ki Feeding Technique, you experience escalating backlashes after Hungering Empowerment ends. These effects occur automatically and cannot be avoided:</p>
            <p><strong>Stage 1:</strong> After the first extra feeding, you gain one level of exhaustion.</p>
            <p><strong>Stage 2:</strong> After the second extra feeding, you gain two levels of exhaustion and are poisoned for 24 hours.</p>
            <p><strong>Stage 3:</strong> After the third extra feeding, you lose control for 1 hour. During this time, you are compelled to feed on any living creature nearby, doubling all bonuses from Hungering Empowerment. During the period of uncontrolled feeding, you ignore the effects of exhaustion and poisoning. Once the hour ends, all exhaustion and poison effects return, and you gain two additional levels of exhaustion.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common and one other language of your choice.</p>
          </div>
        </div>

        <div className="menu-container">
          <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Species
              <ChevronDown size={20} />
            </h2>
            <div className="speciespage-links">
              <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                ← Return to Species              
              </Link>
              <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Gravewalker
              </Link>
              <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Humanity
              </Link>              
              <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Manaforged
              </Link>
              <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Scytherai
              </Link>
              <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Seraphiend
              </Link>              
              <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Starborn
              </Link>
              <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Vermis Corpus
              </Link>
              <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Sunseed
              </Link>
              <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Winter Witch
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Lifedrinker"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Lifedrinker.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesLifedrinker; 