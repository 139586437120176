import React from 'react';
import { motion } from 'framer-motion';
import { Users } from 'lucide-react';
import './Races.css';

const Races = () => {
  return (
    <div className="races-welcome" style={{ background: '#0a0a0a' }}>
      <motion.div 
        className="races-welcome-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Users size={48} className="welcome-icon" style={{ color: '#4a9eff', marginBottom: '1rem' }} />
        <h1>Slivers of Lost Bloodlines</h1>
        <p className="welcome-subtitle">
          Embrace your heritage
        </p>
      </motion.div>
    </div>
  );
};

export default Races;