import React, { useState, useRef, useEffect } from 'react';
import pyromancerImage from '../../assets/images/subclasses/Pyromancer/pyromancer.png';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesPyromancer = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={pyromancerImage} 
          alt="Pyromancer" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Pyromancer</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesUmbralInfiltrator" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Umbral Infiltrator
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Warlocks who pledge themselves to the Summer Court and to the Radiant Sovereign embody the chaotic beauty and destructive power of an eternal summer. They are conduits of unyielding heat, wild growth, and untamed passion, wielding magic that reflects the capricious and unpredictable nature of their patron. These warlocks often exhibit a charismatic and flamboyant personality, their actions driven by a desire to experience life to its fullest, even if it means embracing a touch of madness.
          </p>
        </div>

        <div className="content-block">
          <h2>Features</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Expanded Spell List</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>The Pyromancer subclass gains additional spells at the warlock levels listed. These spells are added to the warlock spell list for you.</p>
            <p><strong>3rd Level:</strong> Burning Hands, Command</p>
            <p><strong>5th Level:</strong> Scorching Ray, Suggestion</p>
            <p><strong>7th Level:</strong> Fireball, Stinking Cloud</p>
            <p><strong>9th Level:</strong> Fire Shield, Wall of Fire</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Blaze of the Summer Court</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>
              As an action, you can unleash a beam of searing light from your hand at a creature within 120 feet. Make a ranged spell attack against the target. On a hit, the target takes 1d12 fire damage. The spell creates more than one beam when you reach higher levels: two beams at 5th level, three beams at 11th level, and four beams at 17th level. You can direct the beams at the same target or at different ones. Make a separate attack roll for each beam.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Crown of Solar Fury</h3>
              <span className="level-tag">Level 6th</span>
            </div>
            <p>
              Once per day, as a bonus action, you can manifest a crown of swirling solar fury that hovers over your head. This crown remains for up to 1 hour per level or until dismissed, and if all charges are used, it vanishes. You can resummon the crown at the next dawn. The crown manifests as a tempestuous vortex of molten fire and incandescent daylight, with a number of incandescent spikes equal to your proficiency bonus. Each spike bears a burning red gem, which pulses with destructive potential. The crown grants you resistance to fire damage, and the area within 10 feet of you becomes difficult terrain as plants grow and twist unnaturally.
            </p>
            <p>
              Each red gem embedded at the base of the spikes can be used to unleash a fiery explosion as an action. This explosion functions as the fireball spell, originating from you and filling a 10-foot-radius sphere, but you are immune to its effects. Each use consumes one gem, which regenerates at dawn. If all gems are consumed, the crown vanishes and cannot be used again until it is resummoned at the next dawn.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Crown of Command</h3>
              <span className="level-tag">Level 10th</span>
            </div>
            <p>
              Each spike now bears an additional burning orange gem midway up its length. These gems, blazing with an intensity that rivals the summer sun, crackle with chaotic energy, each one allowing you to use the Crown of Command ability once per day. As an action, choose one creature that you can see within 30 feet of you. That creature must make a Wisdom saving throw. On a failed save, the creature is charmed by you for 1 minute, as per the charm monster spell. On a successful save, the creature is immune to this effect for 24 hours. Each use consumes one gem, which regenerates at dawn.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Eclipse of the Summer Court</h3>
              <span className="level-tag">Level 14th</span>
            </div>
            <p>
              Once per day, you can trigger the cataclysmic self-destruction of the Crown of Solar Fury. As an action, initiate this fearsome display by causing the white-hot gems at the tips of each spike to flare with an ominous, pulsating glow. The brilliance of these gems intensifies, signaling the chaos to come, while a low, eerie cackle—echoes of a madness that once devoured a goddess—rises ominously in the background. This spectral laughter escalates into a manic frenzy, becoming all-consuming as it fills the air.
            </p>
            <p>
              As the crescendo of madness peaks, everyone within 30 feet feels the presence of a vast, dark emptiness watching them, a chilling sense of being observed by an unfathomable void. This eerie feeling intensifies just as the crown unleashes a colossal solar explosion. The air around you shimmers with heat as the explosion sends shockwaves throughout the area, and the ground itself seems to crack under the supernatural force. All creatures within this 30-foot radius, except those you choose to spare up to a number equal to your Charisma modifier (including or excluding yourself at your discretion), must make a Dexterity saving throw. On a failed save, a creature takes 8d6 fire damage and 8d6 psychic damage, and is stunned, immobilized by the overwhelming force of the eruption, until the beginning of your next turn. On a successful save, a creature takes half damage and avoids being stunned. This apocalyptic spectacle resets at dawn.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Role Playing</h2>
          <p>As a Pyromancer, you embody the raw, chaotic beauty of fire and the sun's radiant fury. Your connection to the Summer Court manifests in your passionate nature and your willingness to embrace both creation and destruction. Whether inspiring others with your brilliance or leaving ruin in your wake, your fiery essence makes you an unpredictable force in the world. You may feel drawn to sunlit deserts, volcanic landscapes, or places where fire has shaped the land and its people.</p>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Impulsive:</strong> You act on instinct, often rushing into action without thinking of the consequences.</p>
            <p><strong>Charismatic:</strong> Your energy and charm attract others, but your intensity can be overwhelming.</p>
            <p><strong>Unpredictable:</strong> Like a wildfire, your actions and emotions can shift suddenly, keeping everyone on edge.</p>
            <p><strong>Resilient:</strong> You bounce back from hardship with the same stubborn persistence as a flame refusing to be extinguished.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Freedom:</strong> Fire cannot be contained, and neither can I. (Chaotic)</p>
            <p><strong>Renewal:</strong> Destruction clears the way for growth and change. (Neutral)</p>
            <p><strong>Balance:</strong> Fire is both a tool and a weapon; it must be wielded wisely. (Good)</p>
            <p><strong>Power:</strong> Those who control fire control life and death. (Evil)</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>Radiant Sovereign:</strong> My patron's light guides me, and I am devoted to their cause.</p>
            <p><strong>Scorched Past:</strong> Fire took something from me, and I carry its memory in every flame I summon.</p>
            <p><strong>Burning Legacy:</strong> I want my name to burn bright in history, never to be forgotten.</p>
            <p><strong>Kindred Spirits:</strong> I am drawn to those who understand the beauty and danger of fire.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Reckless:</strong> My impulsiveness often puts myself and others in danger.</p>
            <p><strong>Temperamental:</strong> My fiery personality makes me prone to outbursts of anger or frustration.</p>
            <p><strong>Destructive:</strong> Even with the best intentions, my actions often leave unintended destruction behind.</p>
            <p><strong>Stubborn:</strong> I struggle to accept help or admit when I'm wrong, trusting only in my own fire.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Equipment</h2>
          <p>As a Pyromancer, your equipment reflects your fiery magic and connection to the Summer Court.</p>
          <p><strong>Starting Equipment:</strong></p>
          <ul>
            <li>A druidic focus or arcane implement shaped like a sunburst, flame, or glowing crystal.</li>
            <li>A set of traveler's clothes in warm, fiery colors, often enchanted to resist heat.</li>
            <li>A backpack containing a bedroll, a mess kit, 10 torches, a tinderbox, and a waterskin.</li>
            <li>A small pouch of glowing embers or ash, used in rituals to channel your fiery power</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Multiclassing</h2>
          <p>If you choose to multiclass, you can combine your fiery warlock abilities with those of another class to create a unique and versatile character. Some multiclassing options might include:</p>
          <div className="trait">
            <p><strong>Sorcerer (Draconic Bloodline):</strong> The draconic affinity for fire complements your fiery magic, increasing damage output and granting fire resistance, while Metamagic lets you reshape your spells to suit any situation.</p>
            <p><strong>Paladin (Oath of the Ancients):</strong> Channel divine radiance alongside your fire magic, bolstering your survivability and giving you access to smite abilities that pair perfectly with your theme.</p>
            <p><strong>Bard (College of Valor):</strong> Use your charisma and flamboyant personality to inspire allies and enhance your fire magic with support and utility spells.</p>
            <p><strong>Fighter (Eldritch Knight):</strong> Combine melee prowess with fire-infused magic, creating a character who can hold the front line while unleashing devastating fiery spells.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={pyromancerImage} 
                alt="Pyromancer"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={pyromancerImage}
                download="Pyromancer.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesPyromancer; 