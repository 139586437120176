import React, { useState, useRef, useEffect } from 'react';
import nebulaImage from '../../assets/images/deities/Nebula.jpg';
import './PantheonPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const PantheonNebula = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={nebulaImage} 
          alt="Nebula: The Veiled One" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Nebula: The Veiled One</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/Pantheon" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCalanthar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Calanthar
                </Link>
                <Link to="/deities/PantheonCaelumbris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris
                </Link>
                <Link to="/deities/PantheonFirstborn" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonQueenMab" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab
                </Link>
                <Link to="/deities/PantheonQueenTitania" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania
                </Link>
                <Link to="/deities/PantheonSacrathor" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor
                </Link>
                <Link to="/deities/PantheonValdis" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Valdís
                </Link>
                <Link to="/deities/PantheonWildscar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Wildscar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            Nebula, the Veiled One, is the enigmatic deity of magic in Velum. Their presence, a subtle veil of mist visible only at night, cloaks the realm, subtly influencing the ebb and flow of magical energies. This mist, an ethereal presence that interacts with the world's elements without disrupting them, collects and channels ambient energies, weaving them into the very essence of magic that permeates Velum.
          </p>
          <p>
            Nebula's power is immense, drawn from the subtle energies that permeate the world. Some speculate that their very essence is intertwined with the Weave of magic, allowing them to shape and channel its power in ways that other deities cannot.
          </p>
        </div>

        <div className="content-block">
          <h2>The First Veil</h2>
          <p>
            Nebula is credited with the creation of the First Veil, a colossal wall that safeguards Velum from a devastating threat to the south. This barrier is guarded by Nebula's clergy, figures clad in crimson robes who wield formidable magic, ensuring the safety of those who dwell north of the wall.
          </p>
        </div>

        <div className="content-block">
          <h2>Magic</h2>
          <p>
            Nebula, the architect of magic, shapes the realm from the shadows, their power vast and enigmatic. Unlike deities who revel in worship, Nebula's influence is subtle, their motives often inscrutable.
          </p>
          <p>
            It is believed that Nebula's magic is cyclical, waxing and waning with the phases of the moon. When the moon is full, their power is at its zenith, and magic surges through the land. As the moon wanes, so too does their influence, creating a natural ebb and flow to the arcane energies that permeate Velum.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Veiled One, The Night Mist</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domain</h3>
            </div>
            <p>Magic</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A crescent moon veiled in swirling mist</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={nebulaImage} 
                alt="Nebula: The Veiled One"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={nebulaImage}
                download="Nebula.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonNebula; 