import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import backgroundImage from '../assets/images/background.png';
import './Home.css';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mainContainer">
      <div 
        className="contentContainer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="contentOverlay">
          <div className="sectionContent" style={{ background: 'rgba(0, 0, 0, 0.7)', padding: '1rem', borderRadius: '8px' }}>
            <h1 style={{ textAlign: 'center' }}>Welcome to The First Veil</h1>
            <div className={`veiled-paths ${menuOpen ? 'active' : ''}`} ref={menuRef}>
              <h2 onClick={toggleMenu}>
                Menu
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/history" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  History
                </Link>
                <Link to="/house-rules" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  House Rules
                </Link>
                <Link to="/prologue" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Prologue
                </Link>
                <Link to="/players-guide" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Player's Guide
                </Link>
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Veiled Ascension
                </Link>
              </div>
            </div>
            <p>
              The First Veil, a universe formed by the convergence of shattered realities, blends arcane magic and advanced technology into a singular, extraordinary realm. This is a realm where ancient enchantments coalesce with the remnants of forgotten civilizations, creating a symphony of wonder and mystery. Each fragment of existence bears echoes of lost worlds and whispers of untold stories, waiting to be discovered.
            </p>
            <p>
              Velum is a world forged from the remnants of countless shattered realities. Entire dimensions—from the floating spires of aetheric kingdoms to the battle-scarred plains of mechanized empires—have fused into one chaotic existence. This convergence has created a landscape where magic hums alongside the distant thrum of forgotten machinery, forming a patchwork of unparalleled diversity. Each biome, from crystalline peaks to shadowed chasms, tells a unique story of the worlds that came before.
            </p>
            <p>
              Heroes rise from adversity, forging their destinies through choice and action. They face trials ranging from battling ancient, slumbering horrors to deciphering the cryptic remnants of forgotten civilizations. Legends unfold as bravery earns glory and curiosity unearths ancient mysteries. The patchwork landscape of Velum brims with opportunities for discovery and the pursuit of greatness.
            </p>
            <p>
              Will you step into Velum, a realm where magic and technology intertwine, creating a tapestry of wonders and challenges? Are you willing to discover the remnants of shattered realms, face untold dangers, and uncover secrets hidden within its ever-shifting expanse?
            </p>
            <p>
              Then <Link to="/prologue" style={{ color: '#4a9eff', textDecoration: 'none', cursor: 'pointer' }}>follow me...</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;