import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Vermis Corpus/vermiscorpus.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesVermisCorpus = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Vermis Corpus" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Vermis Corpus</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species              
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesSkinWalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Skinwalker
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>                            
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Vermis Corpus are an unsettling and enigmatic race, feared and misunderstood by most other inhabitants of Velum. Their first appearance occurred in the aftermath of a massive battlefield following the Great Sundering, a catastrophic event that reshaped the very fabric of the world. Crows swarmed overhead but refused to touch the bodies below, as ethereal, glowing, snake-like worms began to phase in and out of existence around the corpses. Slowly, every single ethereal worm would enter a corpse, with each body housing dozens.
          </p>
          <p>
            Those who attempted to interfere or attack these risen bodies faced swift retaliation, as the newly risen moved faster and fought with deadly precision, far surpassing their capabilities in life. Each new corpse added to their ranks, creating an ever-growing force of unstoppable resolve.
          </p>
          <p>
            Once fully inhabited, the corpses all stood as one, turned southward, and marched toward the central wastelands. A toxic and desolate expanse of deserts and floating landmasses scattered around the equator, largely devoid of life due to their harsh environment. This phenomenon repeated countless times across the war-torn lands.
          </p>
          <p>
            This unique method of survival has earned them a grim reputation as body snatchers or grave robbers, instilling widespread fear of their ability to turn fallen combatants into reinforcements during battle. Over time, the Vermis Corpus became a symbol of the unknown and uncontrollable forces introduced by the Great Sundering. Despite this suspicion and fear, they endure, their continued existence secured by an ancient and mysterious pact brokered with the Firstborn—a deal whose terms remain shrouded in secrecy and speculation. This pact, whispered to involve the exchange of knowledge for sanctuary, is a cornerstone of their survival.
          </p>
        </div>

        <div className="content-block">
          <h2>A Return to the World</h2>
          <p>
            Fifty years after the Great Sundering, the Vermis Corpus opened their borders, venturing into the broader world. Their initial forays were met with trepidation and outright fear; tales of "body-snatchers" still lingered in the hearts of many. However, through diplomacy, strategic alliances, and vital assistance in repelling a great extraplanar threat, they proved their worth. Notably, they played a crucial role in sealing a breach to the Far Realm, demonstrating their unique abilities and unwavering resolve. Yet, they remain an unnerving presence. Even with their best efforts, the reality of their worm-infested forms cannot be entirely hidden. While some have come to see them as allies, others still whisper of their unsettling origin and ponder the cost of trusting beings that embody both life and death. Their continued survival is a testament to their adaptability and resilience.
          </p>
        </div>

        <div className="content-block">
          <h2>Society and Culture</h2>
          <h3>City of Silence, City of Noise</h3>
          <p>
            To an outsider, the cities of the Vermis Corpus are unsettlingly quiet. No laughter echoes in the streets, no shouting vendors hawk wares, and no footsteps disturb the calm—only the rustle of wind or the distant scrape of movement. Yet, to the Vermis Corpus, their cities are deafening. Constant telepathic communication pulses through their shared consciousness, a cacophony of thoughts, decisions, and communal deliberations that harmonize into a singular, collective existence.
          </p>
          <p>
            This hive mind connection ensures that every Vermis Corpus is both an individual and a vital piece of a greater entity. Visitors often mistake their silence for apathy or hostility, unaware that every glance and gesture might convey entire conversations, debates, or even complex strategic discussions. The silence is merely a byproduct of their internal symphony.
          </p>
        </div>

        <div className="content-block">
          <h2>Communal Harmony and Leadership</h2>
          <p>
            The Vermis Corpus live in highly communal societies, where the needs of the collective far outweigh individual desires. Leadership is vested in a single figure known as The Broodhearth—an elected individual, often the eldest and wisest among them. The Broodhearth acts as a nexus for the hive mind, merging their consciousness with the collective to become a single, unified entity during times of crisis or crucial decision-making. This transformation is awe-inspiring and terrifying: an entire city's population acting as one, capable of overwhelming coordination and unparalleled strategy, able to adapt to rapidly changing circumstances.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <p>
            The Vermis Corpus's appearance is dictated largely by the host body they inhabit. However, subtle signs betray their true nature. Their host bodies often display faint, unnatural movements, as if something shifts just beneath the surface of their skin, particularly around the joints. The eyes of a Vermis Corpus host tend to have an unsettling, iridescent sheen, hinting at the collective intelligence within. In some cases, the snake-like worms themselves may briefly emerge from wounds or orifices, a chilling reminder of their unconventional existence. These worms are long, serpentine, and glow with a faint, internal light. When outside a host, the Vermis Corpus appear as a writhing mass of glowing, snake-like worms that twist and coil with eerie precision. Though individually they lack great strength, together they form a formidable and highly intelligent entity.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality</h2>
          <p>
            Vermis Corpus are shaped by the memories and experiences of their hosts, giving them an uncanny and disjointed perspective on the world. While some embrace the knowledge and personalities of past hosts, others strive to forge a distinct identity, rejecting these fragments of memory. This duality often makes their behavior unpredictable, as they might exhibit personality traits or mannerisms from a multitude of previous hosts.
          </p>
          <p>
            As a collective, they value strategy and adaptability, leveraging their shared consciousness to excel in problem-solving and tactical thinking. They are highly pragmatic, viewing life and death through an impersonal lens. While their actions may seem cold or calculating, they are motivated by survival and the preservation of their collective. Their ability to inhabit hosts makes them naturally curious, often seeking new experiences and knowledge through the lives they inhabit. They are also deeply curious about the world around them, seeking to understand the cultures and societies they encounter.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two different ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Vermis Corpus do not age so long as the worms remain alive. The host's body does not decay further once inhabited.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>Vermis Corpus can embody any alignment, reflecting the diverse nature of their collective consciousness.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Small or Medium (player's choice).</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is determined by the condition of the corpse you inhabit. You gain the movement speed of the corpse it had when it was alive, plus an additional 10 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Features</h3>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Resurrected Mobility</h3>
            </div>
            <p>Your base walking speed is determined by the condition of the corpse you inhabit. You gain the movement speed of the corpse it had when it was alive, plus an additional 10 feet. This speed may be adjusted based on the corpse's physical capabilities and any impairments it may have.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>You can see in dim light within 60 feet as if it were bright light, and in darkness as if it were dim light. You can't discern color in darkness, only shades of gray.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Collective Mind</h3>
            </div>
            <p>Your shared consciousness grants you resistance to psychic damage and advantage on saving throws against fear. Additionally, you can telepathically communicate with other Vermis Corpus within 100 feet, sharing thoughts and strategies instantly.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Echoing Memories</h3>
            </div>
            <p>You gain a bonus to all skill checks equal to your proficiency bonus. This ability reflects the collective intelligence and memories of your past hosts, enhancing your adaptability and problem-solving skills.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Necromantic Nullification</h3>
            </div>
            <p>Once a corpse has been inhabited by the Vermis Corpus, it cannot be reused for necromantic purposes. Spells and abilities such as Raise Dead, Animate Dead, and similar effects will no longer work on the corpse.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Undead Nature</h3>
            </div>
            <p>You are classified as undead for the purposes of spells and effects. You do not need to eat, drink, breathe, or sleep, and you are immune to disease. However, you can still benefit from resting to recover hit points and abilities.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Worm Regeneration</h3>
            </div>
            <p>As long as at least one worm survives and the body is intact enough to support the worms and remain functional, there is a 10% chance per Vermis Corpus level that the host will revive at the end of a short rest with 1 hit point. This chance requires GM approval to ensure the body's condition meets the necessary criteria for reanimation. The ability cannot restore the host body beyond its original physical state or repair damage that would render the body inoperable.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Corpse Adaptability</h3>
            </div>
            <p>You can adapt the physical abilities of your host. For example, a host with claws or gills allows you to climb or swim. This does not grant magical or supernatural abilities. Hosts from non-playable races require GM approval.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Host Transfer Mechanism</h3>
            </div>
            <p>Time Limit: Corpses up to 1 day old per Vermis Corpus level can be inhabited.</p>
            <p>Corpse Viability: The body must be intact enough to support the worms and maintain basic functionality. GM approval is required to determine viability.</p>
            <p>Transfer Phase: You migrate to a new body through natural openings or wounds, a process that initially takes 5 rounds (30 seconds). Starting at 10th level, the time decreases to 2 rounds, and at 20th level, it becomes a bonus action. You can transfer to a new host a number of times per day equal to your proficiency bonus. During this process, you are vulnerable and unable to defend yourself effectively.</p>
            <p>Integration Phase: Once fully integrated into a new host, the body no longer decays.</p>
            <p>When you transfer to a new host, you retain your mental ability scores (Intelligence, Wisdom, and Charisma). Additionally, you may grant a +2 bonus to one physical ability score (Strength, Dexterity, or Constitution) and a +1 bonus to another physical ability score of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Overdrive Assimilation</h3>
            </div>
            <p>As an action, you can activate Overdrive Assimilation a number of times per day equal to your proficiency bonus to gain the following benefits for 1 minute:</p>
            <p>Speed is doubled.</p>
            <p>You gain a +2 bonus to Armor Class.</p>
            <p>You have advantage on Dexterity saving throws.</p>
            <p>You gain an additional action on each of your turns. That action can only be used to take the Attack (one attack only), Dash, Disengage, Hide, or Utilize action.</p>
            <p>You gain a +1 bonus to all ability checks and saving throws.</p>
            <p>Aftereffects:</p>
            <p>Gain one level of exhaustion for 5 minutes minus your proficiency bonus.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common and one additional language based on your host's background.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h2>Role Playing</h2>
            </div>
          </div>

          <div className="trait">
            <p>As a Vermis Corpus, you are the unsettling result of a parasitic collective merging with a humanoid host. Your existence defies conventional definitions of life, embodying the balance between individuality and the shared instincts of the collective. Whether you are driven by curiosity, survival, or something darker, your nature often places you at odds with those who struggle to understand your alien essence. You are a being of transformation, equally capable of destruction and adaptation.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Alien Perspective:</strong> Your thoughts are guided by a collective intelligence, leading you to view the world through an unsettlingly pragmatic lens.</p>
            <p><strong>Inquisitive:</strong> You are deeply curious about life, death, and the mechanics of existence, often seeking answers that others fear to pursue.</p>
            <p><strong>Detached:</strong> The symbiosis between you and your host can make you appear cold or emotionless, as your survival instincts often override personal connections.</p>
            <p><strong>Eerily Calm:</strong> You rarely panic, even in dire situations, exuding an unnerving composure that can either reassure or terrify.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Unity:</strong> You value the strength of collective action, believing that individuality is secondary to the good of the whole.</p>
            <p><strong>Renewal:</strong> Decay and destruction are necessary precursors to growth and transformation.</p>
            <p><strong>Survival:</strong> Above all else, you are driven to endure, adapt, and overcome.</p>
            <p><strong>Assimilation:</strong> (Optional darker ideal) You see the Vermis Corpus as the next stage of evolution, destined to consume and replace lesser forms.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>The Collective Within:</strong> Your survival depends on the parasites that compose your body, and their existence is deeply intertwined with yours.</p>
            <p><strong>Host Memories:</strong> Fragments of your host's identity persist, influencing your decisions and connecting you to a world you no longer truly belong to.</p>
            <p><strong>Defenders of Acceptance:</strong> You fiercely protect those rare individuals who see past your alien nature to value you for who you are.</p>
            <p><strong>Origins of the Corpus:</strong> You are compelled to uncover the truth of your creation, whether to find peace or further your kind's evolution.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Unnerving Presence:</strong> Others find it difficult to trust or tolerate you due to your unsettling appearance and nature.</p>
            <p><strong>Instinctual Drive:</strong> The collective within you can sometimes override your rational mind, leading to actions that are impulsive or alien.</p>
            <p><strong>Fascination with Decay:</strong> Your obsession with transformation and decomposition can make you indifferent to preservation or life.</p>
            <p><strong>Isolation:</strong> You struggle to form meaningful connections, as your alien nature often keeps you at arm's length from others.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Vermis Corpus"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="VermisCorpus.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesVermisCorpus; 