import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import backgroundImage from '../assets/images/intro.jpg';
import './Home.css';

const Prologue = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mainContainer">
      <div 
        className="contentContainer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="contentOverlay">
          <div className="sectionContent" style={{ background: 'rgba(0, 0, 0, 0.7)', padding: '2rem', borderRadius: '8px' }}>
            <h1 style={{ textAlign: 'center' }}>The Shattered Veil: Prologue</h1>
            <div className={`veiled-paths ${menuOpen ? 'active' : ''}`} ref={menuRef}>
              <h2 onClick={toggleMenu}>
                Menu
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Home
                </Link>
                <Link to="/history" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  History
                </Link>
                <Link to="/house-rules" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  House Rules
                </Link>
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Veiled Ascension
                </Link>
              </div>
            </div>

            <p>
              The world met its demise not with a cataclysmic roar, nor a pathetic whimper, but with a silent fissure in the very fabric of reality. One moment, there was the comforting familiarity of home; the next, an all-consuming pandemonium. The foundations of existence crumbled, and a fragment of your world—a sliver of land, an echo of life—was cast adrift.
            </p>

            <p>
              This fragment found its way to Velum, a planet seemingly untouched by the chaos. For a fleeting moment, it appeared salvation might be at hand. But Humanity was waiting. United under the zealous faith of Sacrathor, they proclaimed themselves divine inheritors of the Shattered Realms. With their nanite-infused, azure blood and advanced technology, they swept through the remnants of other worlds like an iron tide, capturing and subjugating all who dared to stand apart. Before you could even comprehend what had happened, you were hunted, captured, and bound aboard one of their prison vessels—the Dominion.
            </p>

            <h2>The Prison Cell</h2>
            <p>
              You awaken in a cramped, dimly lit cell. The acrid stench of sweat and fear permeates the stale air, made heavier by the faint hum of the ship's engines. Around you, the other prisoners stir. They are a menagerie of the Shattered Realms' diverse remnants, each bearing scars of survival in a universe now gone.
            </p>

            <p>
              A skeletal figure stands by the far wall, tendrils of ghostly necromantic energy threading through its ivory bones, faintly glowing with hues of icy blue and green. It is garbed in faded vestments, its hollow voice murmuring prayers to the Firstborn, its tone calm amidst the tension.
            </p>

            <p>
              Nearby, a radiant being scintillates faintly, their golden skin flickering with inner fire. Living flora and vines grow across their body, shifting and blooming in time with their turbulent emotions. The air around them feels warm, almost alive.
            </p>

            <p>
              A pale figure sits in unnerving stillness, their marble-like skin reflecting the dim light. Their sharp features betray an unsettling beauty, and their faintly glowing eyes suggest a power barely restrained. An air of icy control surrounds them, as if every movement is carefully measured.
            </p>

            <p>
              In one corner, two insect-like creatures huddle together. The larger, armored in scratched and dented chitin, angles its bladed limbs protectively around the smaller, frail figure. The smaller one chirps faintly, its delicate antennae brushing against its companion in a rhythmic, almost soothing gesture.
            </p>

            <p>
              Suspended against the far wall, her presence commanding even in chains, is a striking figure. Midnight-black skin shimmers faintly in the flickering light. Her large raven wings are bound tightly across her back. Golden horns curve proudly from her brow, and above them, a single radiant halo of glowing light hovers. Her eyes burn with defiance, unyielding even in her suffering.
            </p>

            <h2>The Guards</h2>
            <p>
              The cell door hisses open with a pneumatic groan. Two Humanity guards stride in, their full-body armor a dull metallic gray, pulsing faintly with intricate blue lines of nanite circuitry. Their visors are opaque, their faces hidden, their movements precise and inhumanly efficient.
            </p>

            <p>
              "On your feet, filth," one commands, his voice sharp and laced with disdain, distorted through a built-in vocalizer.
            </p>

            <p>
              A woman clutches a young girl to her chest. Her face is streaked with tears, her hands trembling. "Please," she begs, "leave us alone! She's sick—she needs help!"
            </p>

            <p>
              The guard steps forward and yanks the woman to her feet, his iron grip ignoring her pleas. "Sacrathor's justice demands sacrifice," he intones with cruel precision. "You creatures are lucky to even breathe our air."
            </p>

            <p>
              The child stares vacantly as the other guard seizes her. She doesn't struggle, doesn't cry. Her wide, empty eyes reflect the unyielding blue glow of the guards' visors. The woman screams, thrashing against the grip holding her, but her protests are ignored. Two more guards enter the cell and begin pulling additional prisoners from the group—a mother, a father, and another prisoner who tries to resist but is silenced with a sharp strike to the back of the head.
            </p>

            <p>
              "The rest of you," the first guard says coldly, "remember this. This is the price of defiance."
            </p>

            <p>
              As the guards turn to leave, one pauses, noticing a black, almost fluid humanoid sitting motionless in the corner. They approach it cautiously, their movements sharp with suspicion.
            </p>

            <p>
              "What do we have here?" one guard mutters. "A stolen suit? I thought we purged all you rogue synthetics." He kicks the figure hard, the sound of metal on metal reverberating through the cell. The figure remains inert, unreactive.
            </p>

            <p>
              "Looks derelict," the other guard says. "Probably malfunctioning." He pulls a gray, disc-shaped device from his belt and slaps it onto the figure's chest. It sparks and fizzles briefly before going dark. With a sneer, the guard hurls a bowl of foul-smelling gruel at the figure. The thick sludge splatters across its frame, dripping in viscous globs.
            </p>

            <p>
              "Ha! Maybe that'll fry what passes for a brain," he sneers, his laughter cutting through the cell like a blade.
            </p>

            <p>
              Satisfied, the guards move on to the chained figure. "A fallen angel?" one sneers, his voice dripping with derision. He reaches out and grabs one of her bound wings roughly, eliciting a sharp hiss of pain.
            </p>

            <p>
              "Careful with that one," the other guard cautions. "She's marked for the high courts. The council wants her to make an example for Sacrathor."
            </p>

            <p>
              As they pass you, one guard lashes out with the back of his gauntleted hand, striking you sharply across the face. The blow stings, leaving a metallic taste of blood in your mouth.
            </p>

            <p>
              "Keep your eyes down, wretch," he spits, his tone dripping with venom, before backhanding you sharply across the face. The sting burns as the metallic taste of blood fills your mouth. Without another word, he steps out and seals the door behind him with a mechanical hiss.
            </p>

            <h2>The Vision</h2>
            <p>
              The oppressive silence of the cell envelops you. The rhythmic hum of the engines fades into the background, overtaken by the sting of the guard's strike. Pain radiates across your face, sharp and pulsing, dragging your focus inward. Your head swims, the edges of your vision darkening. It feels like a heavy fog is rolling over your senses, pulling you down into a place where reality falters.
            </p>

            <p>
              The cell falls away completely, the hard edges of reality splintering and dissolving into shadow.
            </p>

            <p>
              You stand in a vast, shadowy expanse. The air hums with a deep, resonant energy that presses against your skin. Eight pedestals rise from the ground in a perfect circle, each bearing a glowing object. The light they emit casts faint, dancing shadows across the space.
            </p>

            <p>
              A clouded mirror, its surface shifting with indistinct images that seem to recoil from your gaze.
            </p>

            <p>
              A skeletal hand gripping a scroll, perfectly balanced by ornate scales on either end.
            </p>

            <p>
              An open tome, a quill suspended above it, writing endlessly in shifting ink.
            </p>

            <p>
              A crown of jagged ice, its edges razor-sharp, glittering coldly in the dim light.
            </p>

            <p>
              A sunburst entwined with blooming flora, radiating life and vitality.
            </p>

            <p>
              A phoenix rising from ashes, frozen in an eternal moment of fiery rebirth.
            </p>

            <p>
              A golden sun, a serpent coiled tightly around its center, emanating subtle menace.
            </p>

            <p>
              A blood-stained axe, crossed with a burning ember, its weight almost palpable.
            </p>

            <p>
              The energy grows, pressing harder, wrapping around you like an unseen force. The objects seem alive, calling out to you—not with words, but with an irresistible pull deep within your soul.
            </p>

            <p>
              The vision lasts only a heartbeat, a fleeting moment where reality falters. Then, the hum of the engines pulls you back, vibrating through the cold floor. The sting of the strike on your cheek sharpens, anchoring you in the present. Yet, the glimpse remains vivid in your mind—an unanswered question, its meaning just out of reach.
            </p>

            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
              <Link 
                to="/veiled-ascension" 
                style={{
                  color: '#4a9eff',
                  textDecoration: 'none',
                  fontSize: '1.2rem',
                  padding: '0.5rem 1rem',
                  border: '1px solid #4a9eff',
                  borderRadius: '4px',
                  transition: 'all 0.3s ease'
                }}
                onMouseOver={(e) => e.target.style.background = 'rgba(74, 158, 255, 0.2)'}
                onMouseOut={(e) => e.target.style.background = 'transparent'}
              >
                The Veiled Ascension →
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prologue;