import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Swords, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import './Pantheon.css';

const Classes = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const classLinks = [
    { name: 'Circle of the Burning Seed', path: '/classes/ClassesBurningSeed' },
    { name: 'Circle of the Glacial Heart', path: '/classes/ClassesGlacialHeart' },
    { name: 'Dronewright', path: '/classes/ClassesDronewright' },
    { name: 'Oath of the Seraphic Guardian', path: '/classes/ClassesSeraphicGuardian' },
    { name: 'Path of Wrath', path: '/classes/ClassesPathOfWrath' },
    { name: 'Pyromancer', path: '/classes/ClassesPyromancer' },
    { name: 'Sovereign Zealot', path: '/classes/ClassesSovereignZealot' },
    { name: 'Way of the Lifedrinker', path: '/classes/ClassesWayOfTheLifedrinker' },
    { name: 'Winterborn Knight', path: '/classes/ClassesWinterbornKnight' }
  ];

  return (
    <div className="veiled-welcome" style={{ background: '#0a0a0a' }}>
      <motion.div 
        className="veiled-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Swords size={48} className="welcome-icon" style={{ color: '#4a9eff', marginBottom: '1rem' }} />
        <h1>Fractured Paths of the Lost</h1>
        <div className="menu-container">
          <div className={`pantheons ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={() => setMenuOpen(!menuOpen)}>
              Classes
              <ChevronDown size={20} />
            </h2>
            <div className="pantheon-links">
              {classLinks.map((classLink, index) => (
                <Link 
                  key={index} 
                  to={classLink.path}
                  className="pantheon-link"
                  onClick={() => setMenuOpen(false)}
                >
                  {classLink.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="content-section">
          <p>
            The Shattered Realm demands more than survival—it calls for heroes, tacticians, and wielders of unmatched power to shape its destiny. The classes of this fractured world represent the pinnacle of mortal and divine potential, each embodying unique strengths and philosophies. From battle-hardened warriors and cunning rogues to those who channel the lingering echoes of divine might or weave the arcane forces of the Shattering itself, these champions rise to meet the challenges of a broken cosmos.
          </p>
          <p>
            Some tap into ancient traditions, mastering martial skill or spellcraft with discipline and focus. Others adapt to the world's chaos, drawing strength from forgotten gods, primal energies, or the latent power of the shattered Weave. Bound by no single destiny, each class offers countless paths to glory, redemption, or dominion in a realm still reeling from its creation. Together, they are the architects of a new order, fighting to define what it means to be a hero—or a villain—in a reality forever changed.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Classes; 