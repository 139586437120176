import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/subclasses/Burning/circleoftheburningseed.png';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesBurningSeed = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={caelumbrisImage} 
          alt="Circle of the Burning Seed" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Circle of the Burning Seed</h1>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Druids of the Circle of the Burning Seed are conduits of fiery chaos and untamed growth. They embrace the destructive and creative forces of fire, wielding its primal energy to burn away obstacles and fuel life's resurgence in unpredictable ways. These druids are passionate, often impulsive, but their hearts burn with an enduring desire to see life flourish, even if it means embracing the chaos of the flame. To them, fire is not just destruction—it is transformation, renewal, and the raw potential of nature itself.
          </p>
        </div>

        <div className="content-block">
          <h2>Circle Spells</h2>
          <div className="level-indicator">Level 2nd</div>
          <p>Your connection to fiery chaos grants you access to certain spells. You always have the following spells prepared, and they don't count against the number of spells you can prepare each day.</p>
          <div className="trait">
            <div className="trait-header">
              <h3>3rd Level</h3>
            </div>
            <p>Flame Blade, Flaming Sphere</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>5th Level</h3>
            </div>
            <p>Fireball, Plant Growth</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>7th Level</h3>
            </div>
            <p>Wall of Fire, Blight</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>9th Level</h3>
            </div>
            <p>Immolation, Tree Stride</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Fiery Growth</h2>
          <div className="level-indicator">Level 2nd</div>
          <p>
            While in your Wild Shape, you can channel the fiery chaos within you to fuel rapid growth in the land around you. As a bonus action, you can expend a spell slot to unleash a burst of fiery energy. Each creature of your choice within 10 feet of you must make a Dexterity saving throw against your spell save DC. On a failed save, a creature takes 1d4 fire damage for each level of the spell slot expended and is entangled by rapidly growing plants for 1 round per level of the spell slot expended. On a successful save, a creature takes half as much damage and is not entangled.
          </p>
          <p>
            The plants created by this ability are considered magical and burn away harmlessly after the effect ends.
          </p>
        </div>

        <div className="content-block">
          <h2>Burning Fury</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            Your fiery spirit enhances the destructive force of your Wild Shape. While in Wild Shape, your melee weapon attacks deal an additional 1d4 fire damage and count as magical for the purpose of overcoming resistance and immunity to nonmagical attacks and damage. The fire damage increases to 1d6 when you reach 10th level and 1d8 when you reach 14th level in this class.
          </p>
        </div>

        <div className="content-block">
          <h2>Chaotic Bloom</h2>
          <div className="level-indicator">Level 10th</div>
          <p>
            As an action while in Wild Shape, you can release a surge of chaotic plant growth. Choose a point you can see within 15 feet. Plants rapidly erupt in a 15-foot radius around that point, making the area difficult terrain for 1 minute. Each creature of your choice in the area must make a Dexterity saving throw against your spell save DC. On a failed save, a creature is restrained by the plants for 1 minute. A restrained creature can use its action to make a Strength (Athletics) check against your spell save DC, freeing itself on a success.
          </p>
          <p>
            You can use this feature a number of times equal to your proficiency bonus (minimum of once). You regain all expended uses when you finish a long rest.
          </p>
        </div>

        <div className="content-block">
          <h2>Sun's Fury</h2>
          <div className="level-indicator">Level 14th</div>
          <p>
            The blazing energy of the sun burns within you, allowing you to unleash its radiance in battle. As an action while in Wild Shape, you can release a wave of searing sunlight in a 30-foot cone. Each creature in the cone must make a Dexterity saving throw against your spell save DC. On a failed save, a creature takes 6d6 radiant damage and is blinded until the end of your next turn. On a successful save, a creature takes half damage and is not blinded.
          </p>
          <p>
            Once you use this feature, you can't do so again until you finish a long rest.
          </p>
        </div>

        <div className="menu-container">
          <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Classes
              <ChevronDown size={20} />
            </h2>
            <div className="pantheon-links">
              <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                ← Return to Classes
              </Link>    
              <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Circle of the Glacial Heart
              </Link>
              <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Dronewright
              </Link>
              <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Oath of the Seraphic Guardian
              </Link>
              <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Path of Wrath
              </Link>
              <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Pyromancer
              </Link>
              <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Sovereign Zealot
              </Link>
              <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Way of the Lifedrinker
              </Link>
              <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Winterborn Knight
              </Link>               
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Circle of the Burning Seed"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="BurningSeed.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesBurningSeed; 