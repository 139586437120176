import React, { useState, useRef, useEffect } from 'react';
import dronewrightImage from '../../assets/images/subclasses/Dronewright/dronewright.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesDronewright = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={dronewrightImage} 
          alt="Dronewright" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Dronewright</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Artificers who follow the path of the Dronewright pioneer the fusion of advanced technology with arcane traditions, their origins as diverse as the drones they construct. Some Dronewrights hail from steampunk worlds where clockwork mechanisms are imbued with magic, while others come from futuristic realities where technology and magic coexist seamlessly in space faring civilizations.
          </p>
          <p>
            Their journey often begins with the discovery of an enigmatic drone or the invention of one from scratch, sparking a lifelong pursuit of innovation. Regardless of origin, all Dronewrights share a deep understanding of how to weave the mechanical and magical into one cohesive force. Their creations act as extensions of their ingenuity, enhancing their physical prowess, serving as formidable weapons, or providing unparalleled utility.
          </p>
          <p>
            Despite the diversity in style and function—from rustic, gear-driven automatons to sleek, nanite-infused constructs—Dronewright drones share a unified design ethos: adaptability and synergy with their creator. This subclass celebrates the limitless potential of merging imagination with invention, granting Artificers the tools to reshape the battlefield, solve intricate problems, and leave their indelible mark on the world.
          </p>
        </div>

        <div className="content-block">
          <h2>Drone Specialization</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>At 3rd level, you choose a specialization and create a drone, marking the beginning of your mastery in technological innovation. This specialization defines the role and capabilities of your drone, tailoring it to your specific needs and playstyle. Each path offers distinct features, ensuring that your drone becomes a unique and indispensable tool in your adventures. The specialization options are as follows:</p>
          <p>Path of the Exoskeleton (Body Enhancement Drone): A path that integrates your drone with your physical form, enhancing your strength, mobility, and durability while providing tactical advantages.</p>
          <p>Path of the Weaponized Drone: Focuses on offense, crafting a drone that serves as a powerful ranged or melee weapon, capable of devastating attacks and precision strikes.</p>
          <p>Path of the Toolkit Drone: Specializes in utility and support, equipping your drone with versatile tools to heal, assist, and protect allies or solve complex problems.</p>
        </div>

        {/* Path of the Exoskeleton */}
        <div className="path-section">
          <h2 className="path-title">Path of the Exoskeleton</h2>
          
          <div className="content-block">
            <h2>Path of the Exoskeleton</h2>
            <p>Path of the Exoskeleton (Body Enhancement Drone): This specialization represents the seamless integration of your drone with your physical form, transforming it into a cutting-edge exoskeleton. Designed for both combat and utility, the exoskeleton enhances your strength, mobility, and durability, making you a formidable force on the battlefield. Equipped with servo-assisted limbs, kinetic energy conduits, and advanced plating, this path ensures tactical superiority and adaptability in various scenarios. Whether climbing sheer surfaces, resisting extreme conditions, or engaging in melee combat, the exoskeleton embodies the perfect harmony of arcane and technological innovation.</p>
          </div>

          <div className="content-block">
            <h2>Exoskeleton Activation</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>As an action, you activate your body enhancement drone, which assembles around you to form an exoskeleton. The exoskeleton remains active for 1 hour per level, after which it requires a long rest to recharge. You may also dismiss the exoskeleton as a reaction. While active, you gain the following effects:</p>
            <p>Enhanced Armor: Your Armor Class (AC) becomes 13 + your Intelligence modifier + your proficiency bonus (you can still benefit from a shield). This shimmering, mechanical plating covers your body, harmonizing arcane energy with durable alloys to enhance your defense. It is designed to deflect attacks with precision, embodying the pinnacle of arcane engineering. This benefit lasts for the duration of the exoskeleton's activation.</p>
            <p>Augmented Strength: Your exoskeleton's servo-assisted limbs radiate an aura of mechanical might. You gain advantage on Strength checks and Strength saving throws for the duration. This enhancement is visually represented by hydraulics and glowing energy conduits surging with power, making feats of strength appear effortless and commanding awe on the battlefield.</p>
            <p>Powered Tactical Repositioning: Your exoskeleton enhances your mobility through advanced kinetic systems. Your walking speed increases by 10 feet, with visible energy conduits glowing faintly as they power your steps. This enhancement allows you to move with precision and agility, making it easier to navigate the battlefield and maintain strategic positioning.</p>
            <p>Integrated Weaponry: While the exoskeleton is active, your melee capabilities are enhanced with precision-guided technology. You can use your Intelligence modifier instead of Strength or Dexterity for attack and damage rolls with unarmed strikes and melee weapons. Your strikes emit a faint hum of arcane energy, showcasing the seamless integration of magic and machine, and leaving a shimmering trail of light with each swing or thrust.</p>
          </div>

          <div className="content-block">
            <h2>Exoskeleton Upgrades</h2>
            <div className="level-indicator">Level 5</div>
            <p>Choose one of the following upgrades for your exoskeleton:</p>
            <p>Booster Strike Module: You integrate thrusters into your exoskeleton, enabling a powerful charging attack. As an action, you move in a straight 30-foot line, without provoking opportunity attacks. You may attack creatures along the path, dealing an additional 2d6 force damage and forcing them to succeed on a Strength saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) or be knocked prone. If interrupted, you take 1d6 force damage per 10 feet not completed.</p>
            <p>Energy Shielding: By channeling arcane energy through your exoskeleton, you gain resistance to one damage type of your choice (acid, cold, fire, lightning, or thunder). This shielding manifests as a faint, shimmering aura around your exoskeleton, deflecting incoming energy. The chosen resistance can be recalibrated during a long rest as you fine-tune your drone's energy field to match anticipated threats.</p>
            <p>Phase Dash: As a bonus action, teleport to an unoccupied space you can see within 90 feet. After teleporting, you gain advantage on your next melee or ranged attack before the end of your turn. You can use this feature a number of times equal to your proficiency bonus, and all expended uses recharge after a long rest.</p>
          </div>

          <div className="content-block">
            <h2>Adaptive Systems</h2>
            <div className="level-indicator">Level 9</div>
            <p>Choose one of the following upgrades for your exoskeleton:</p>
            <p>Adaptive Defense: Your exoskeleton's advanced sensors and shielding systems dynamically adjust to protect against immediate threats. As a reaction, you can gain immunity to one damage type of your choice until the start of your next turn. The applicable damage types are: acid, cold, fire, force, lightning, necrotic, poison, psychic, radiant, and thunder. When this ability is activated, the exoskeleton emits a brief flash of energy, visually displaying the adjustment in a shimmer of matching color (e.g., blue for cold, red for fire). You can use this feature a number of times equal to your proficiency bonus, recharging after a long rest.</p>
            <p>Overdrive Mode: As a bonus action, activate Overdrive Mode for 1 minute. Gain an additional action per turn for specific actions (Attack, Dash, Disengage, Hide, Use an Object). After Overdrive ends, succeed on a DC 15 Constitution saving throw or gain one level of exhaustion. Usable a number of times equal to your proficiency bonus, recharging after a long rest.</p>
            <p>Flight System: Your exoskeleton is now equipped with an advanced propulsion unit, granting you full flight capabilities. You gain a flying speed of 30 feet and can hover. This flight system has no time limit and is always active, allowing you to navigate difficult terrain and maintain a tactical advantage both in and out of combat. The propulsion system emits a faint, controlled hum of energy, adding an elegant, futuristic flair to your movements.</p>
          </div>

          <div className="content-block">
            <h2>Integrated Arsenal</h2>
            <div className="level-indicator">Level 15</div>
            <p>Choose one of the following upgrades for your exoskeleton:</p>
            <p>Energy Overload: Release a devastating force blast in a 30-foot cone of force energy. Each creature must make a Dexterity saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus), taking 6d10 force damage on a failed save or half on success. Usable a number of times equal to your proficiency bonus, recharging after a short or long rest.</p>
            <p>Force Barrier: As a bonus action, gain temporary hit points equal to twice your level for 1 minute. Creatures hitting you with melee attacks take force damage equal to your Intelligence modifier. Usable a number of times equal to your proficiency bonus, recharging after a long rest.</p>
            <p>Gravitic Inversion Field: As an action, your exoskeleton generates a localized field of inverted gravity, replicating the effects of the reverse gravity spell. This field affects a 30-foot-radius, 30-foot-high cylinder centered on you. While within the field, you can move and act normally, unaffected by the inverted gravity. All other creatures and objects in that area that aren't somehow anchored to the ground fall upward and reach the top of the cylinder. A creature can make a Dexterity saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) to grab a fixed object it can reach, thus avoiding the fall upward. If a ceiling or an anchored object is encountered in this upward fall, creatures and objects strike it just as they would during a normal downward fall. If an affected creature or object reaches the cylinder's top without striking anything, it hovers there for the duration, falling downward when the effect ends after 1 minute. You can use this feature a number of times equal to your proficiency bonus, recharging after a long rest.</p>
          </div>

          <div className="content-block">
            <h2>Masterful Integration</h2>
            <div className="level-indicator">Level 18</div>
            <p>Choose one of the following upgrades for your exoskeleton:</p>
            <p>Permanent Integration: Your exoskeleton no longer has an active time limit and enhances your physical capabilities. You gain a +4 bonus to Strength and a +2 bonus to Dexterity. Features that specify limited uses or specific time limits still apply and are recharged as specified.</p>
            <p>Temporal Core: Your exoskeleton integrates advanced temporal manipulation, permanently granting you the effects of the haste spell. Additionally, as an action, you can activate a Temporal Field for 1 minute, centered on you with a 30-foot radius. Within this field, you and allies gain the effects of haste, while enemies must succeed on a Wisdom saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) at the start of their turn or be affected by the slow spell for that turn. You can use this ability a number of times equal to your proficiency bonus, recharging after a long rest.</p>
            <p>Second Suit Deployment: You gain the ability to create and maintain a second exoskeleton suit, which can be worn by another party member or piloted remotely by you within a 1-mile range. This suit has the following features and mechanics:</p>
            <p>Customization and Reprogramming: During a long rest, you can reprogram the second suit, altering its abilities to suit the current needs of the party. The suit can take on the functionality of the Path of the Exoskeleton, gaining the following benefits:</p>
            <ul>
              <li>The 3rd-level abilities of path of the Exoskeleton</li>
              <li>One 5th-level ability of path of the Exoskeleton</li>
              <li>One 9th-level ability of path of the Exoskeleton</li>
            </ul>
            <p>This secondary suit is a marvel of engineering and arcane craftsmanship, visually distinct from your own. When worn by another, it enhances their combat or utility performance, glowing faintly with your magical energy. When piloted remotely, the suit moves with mechanical precision, acting as an extension of your will and responding to commands instantly.</p>
          </div>

          <div className="content-block">
            <h2>Second Exoskeleton Stat Block</h2>
            <p>Armor Class: 17 (Reinforced Plating)</p>
            <p>Hit Points: 4 × your Artificer level</p>
            <p>Speed: Fly 30 ft. (hover)</p>
            <p>Ability Scores:</p>
            <p>STR: 10 (+0)</p>
            <p>DEX: 14 (+2)</p>
            <p>CON: 12 (+1)</p>
            <p>INT: 10 (+0)</p>
            <p>WIS: 10 (+0)</p>
            <p>CHA: 6 (-2)</p>
            <p>Saving Throws: Dexterity +4, Constitution +3</p>
            <p>Skills: Perception +2</p>
            <p>Damage Immunities: Poison</p>
            <p>Condition Immunities: Charmed, Exhaustion, Poisoned</p>
            <p>Senses: Darkvision 60 ft., Passive Perception 12</p>
            <p>Languages: Understands the languages you speak but cannot speak</p>
            <p>Actions:</p>
            <p>Multiattack: The drone makes two attacks: one with its Ranged Weapon Form and one with its Melee Weapon Form.</p>
            <p>Ranged Weapon Form: Energy Bolt. Ranged Weapon Attack: Your spell attack modifier to hit, range 60 ft., one target. Hit: 1d8 + Intelligence modifier force damage.</p>
            <p>Melee Weapon Form: Energy Blade. Melee Weapon Attack: Your spell attack modifier to hit, reach 5 ft., one target. Hit: 1d10 + Intelligence modifier force damage.</p>
            <p>Features:</p>
            <p>Tactical Adaptation: Switch between Ranged and Melee Weapon Forms as a bonus action.</p>
            <p>Integrated Targeting Systems: Add proficiency bonus to attack rolls and damage calculations.</p>
            <p>Energy Efficiency: Recharge all hit points during a short or long rest.</p>
            <p>Self-Stabilizing Hover: Hover up to 10 feet above the ground, ignoring difficult terrain.</p>
            <p>Remote Control: Obey verbal or mental commands within 120 feet.</p>
          </div>
        </div>

        {/* Path of the Weaponized Drone */}
        <div className="path-section">
          <h2 className="path-title">Path of the Weaponized Drone</h2>
          
          <div className="content-block">
            <h2>Path of the Weaponized Drone</h2>
            <p>The weaponized drone is a versatile construct designed for both precision and destruction, seamlessly merging arcane and mechanical ingenuity. Once activated, the drone becomes a permanent fixture of your arsenal, requiring only occasional maintenance during downtime to remain operational. Shifting fluidly between combat roles, it can be a ranged, melee weapon, or a flying weaponized drone. Each enhancing your effectiveness in battle. Its sleek, adaptive design allows it to dynamically respond to your commands, with glowing energy conduits and intricate mechanisms radiating a blend of artistry and lethality. Whether wielding it as a weapon or deploying it as an autonomous combatant, the drone exemplifies your mastery of arcane engineering.</p>
          </div>

          <div className="content-block">
            <h2>Weaponized Drone Creation</h2>
            <div className="level-indicator">Level 3rd</div>
            <p>Choose between:</p>
            <p>Ranged Weapon Form: The drone transforms into a physical ranged weapon of your choice, such as a pistol, rifle, or crossbow, tailored to your preferences and designed for precision. With advanced targeting systems and arcane energy capacitors, it delivers 1d8 energy damage within a 60-foot range. Each shot crackles with magical energy, illuminating the path of the projectile with a faint glow. Whether a sleek firearm or a magically enhanced crossbow, this weapon is ideal for calculated strikes from afar.</p>
            <p>Energy Weapon Form: The drone transforms into a powerful melee weapon, channeling pure energy into a devastating close-range attack. With advanced energy conduits and arcane amplifiers, it delivers 1d10 energy damage within a 5-foot range. Each strike crackles with magical energy, illuminating the target with a faint glow. Whether a sleek energy blade or a brutal energy maul, this weapon is ideal for precise and powerful strikes in close combat.</p>
            <p>Flying Weaponized Drone: The drone transforms into a lethal aerial combatant, equipped with advanced propulsion systems and precision targeting. With its sleek design and glowing energy conduits, it soars through the air, delivering devastating strikes from above. Whether equipped with energy cannons or precision lasers, this drone is ideal for aerial superiority and tactical strikes, providing unmatched versatility in both offense and reconnaissance.</p>
          </div>

          <div className="content-block">
            <h2>Weaponized Drone Stat Block</h2>
            <p>Armor Class: 17 (Reinforced Plating)</p>
            <p>Hit Points: (4 × your level)</p>
            <p>Speed: Fly 30 ft. (hover)</p>
            <p>STR: 10 (+0)</p>
            <p>DEX: 14 (+2)</p>
            <p>CON: 12 (+1)</p>
            <p>INT: 10 (+0)</p>
            <p>WIS: 10 (+0)</p>
            <p>CHA: 6 (-2)</p>
            <p>Saving Throws: Dexterity +4, Constitution +3 (add proficiency bonus as applicable)</p>
            <p>Skills: Perception +2</p>
            <p>Damage Immunities: Poison</p>
            <p>Condition Immunities: Charmed, Exhaustion, Poisoned</p>
            <p>Senses: Darkvision 60 ft., Passive Perception 12</p>
            <p>Languages: Understands the languages you speak but cannot speak</p>
            <h3>Actions</h3>
            <p>Multiattack: The drone makes two attacks: one with its Ranged Weapon Form and one with its Melee Weapon Form.</p>
            <p>Ranged Weapon Form: Energy Bolt. Ranged Weapon Attack: Your spell attack modifier to hit, range 60 ft., one target. Hit: 1d8 + Intelligence modifier force damage.</p>
            <p>Melee Weapon Form: Energy Blade. Melee Weapon Attack: Your spell attack modifier to hit, reach 5 ft., one target. Hit: 1d10 + Intelligence modifier force damage.</p>
            <h3>Features</h3>
            <p>Tactical Adaptation: The drone can switch between its Ranged and Melee Weapon Forms as a bonus action on your turn.</p>
            <p>Integrated Targeting Systems: Add your proficiency bonus to the drone's attack rolls and damage calculations.</p>
            <p>Energy Efficiency: The drone recharges all hit points during a short or long rest.</p>
            <p>Self-Stabilizing Hover: The drone hovers up to 10 feet above the ground, ignoring difficult terrain.</p>
            <p>Remote Control: The drone obeys your verbal or mental commands within a 120-foot range. Commands are issued as a bonus action.</p>
          </div>

          <div className="content-block">
            <h2>Weaponized Drone Upgrades</h2>
            <div className="level-indicator">Level 5</div>
            <p>Shotgun Blast: Your drone's ranged weapon form can now unleash a powerful blast of energy in a 15-foot cone as an action. Each creature within the cone must make a Constitution saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus). On a failed save, a creature takes 2d6 force damage and is stunned until the end of its next turn. On a successful save, a creature takes half as much damage and is not stunned. This ability uses your drone's ranged weapon form and does not require it to be in that form to use, but if used while in ranged form, the drone forgoes its normal ranged attack for that turn. Once used, the drone must complete a short or long rest before it can be used again.</p>
            <p>Melee Discharge: The melee weapon form can change to any melee weapon you are proficient with. The chosen weapon's damage die is used, and on a critical hit, it releases a 5-foot burst of energy, forcing creatures within the burst to make a Dexterity saving throw or take 1d6 energy damage.</p>
            <p>Aerial Bombardment: The drone can execute an area-of-effect attack, targeting a 10x10 area within 30 feet. Creatures in the area must make a Dexterity saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) or take 1d6 damage, halved on success.</p>
          </div>

          <div className="content-block">
            <h2>Level 9 Abilities</h2>
            <div className="level-indicator">Level 9</div>
            <p>Choose one of the following upgrades:</p>
            <p>Adaptive Energy Matrix: Add necrotic and radiant damage to available energy types. The drone can now switch its damage type for all its attacks as a bonus action. Choose from: acid, cold, fire, lightning, necrotic, thunder or radiant.</p>
            <p>Gain access to an additional drone form; Bonus Action: The user can expend their bonus action to have their drone assume their second form.</p>
            <p>Targeting Beacon: As a bonus action, you can mark a target within 60 feet with a tracking beacon that lasts for 1 minute or until you lose concentration (as if concentrating on a spell). While the target is marked, you have advantage on all attack rolls made with your drone against the marked target. Additionally, if the marked target attempts to move out of your line of sight, your drone can use its reaction to move up to its full movement speed to maintain line of sight, even if it means provoking opportunity attacks. You can use this ability a number of times equal to your proficiency bonus, regaining all expended uses after a long rest.</p>
          </div>

          <div className="content-block">
            <h2>Level 15 Abilities</h2>
            <div className="level-indicator">Level 15</div>
            <p>Choose one of the following upgrades:</p>
            <p>Dual Mode: Your drone's adaptive systems reach new heights, allowing it to fluidly shift between its ranged and melee weapon forms as a bonus action. This advanced integration of arcane and mechanical engineering ensures that your drone is always ready to respond to the ever-changing dynamics of battle, embodying the perfect blend of versatility and lethality.</p>
            <p>Gain a Second Drone: Your expertise in drone crafting allows you to create and command a second drone, each a marvel of arcane engineering. Choose its form and one ability from the Level 5 and Level 9 lists at creation. This second drone operates independently, showcasing your mastery over technological innovation and enhancing your tactical prowess on the battlefield. Whether used for offense, defense, or support, this second drone is a testament to your growing arsenal of arcane constructs.</p>
            <p>Absorption Drone Matrix: Your drone's energy systems are enhanced with advanced absorption technology. As a reaction, when either the wielder of your drone or the drone itself takes damage from an elemental source (acid, cold, fire, lightning, or thunder), the drone can absorb that energy. The next time the drone makes an attack before the end of your next turn, it deals additional damage equal to the amount of damage absorbed, of the same damage type. This feature can be used a number of times equal to your proficiency bonus per long rest, turning the enemy's own elemental power against them and showcasing the drone's adaptive and resilient nature.</p>
          </div>

          <div className="content-block">
            <h2>Level 18 Abilities</h2>
            <div className="level-indicator">Level 18</div>
            <p>Choose one of the following upgrades:</p>
            <p>Ranged Form: Arcane Siege Mode: Your drone permanently upgrades to function as a devastating ranged weapon. While in this mode, the drone can make ranged weapon attacks. Each attack uses your spell attack modifier for the attack roll, has a range of 300 feet, and deals 4d12 force damage on a hit. These attacks ignore all cover.</p>
            <p>Melee Form: Titan Strike Mode: Your drone enhances your melee capabilities, creating an energy-infused weapon of pure destructive force. For 1 minute, your melee weapon attacks deal an additional 3d10 force damage and cause creatures hit to be pushed back 10 feet. Additionally, on a critical hit, the target must succeed on a Strength saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) or be knocked prone. This ability can be used a number of times equal to your proficiency bonus, recharging all uses after a long rest.</p>
            <p>Autonomous Form: War Machine Mode: Your drone activates into a fully autonomous war construct. For 1 minute, it gains an additional 30 hit points, can make two attacks per turn, and its energy attacks deal 2d10 force damage with an additional 10-foot-radius explosion that deals 1d10 damage to all creatures within the area. Recharges after a long rest.</p>
          </div>
        </div>

        {/* Path of the Toolkit Drone */}
        <div className="path-section">
          <h2 className="path-title">Path of the Toolkit Drone</h2>
          
          <div className="content-block">
            <h2>Path of the Toolkit Drone</h2>
            <p>The Toolkit Drone specialization transforms your mechanical companion into a versatile and indispensable ally, embodying the pinnacle of utility and adaptability. This drone functions as a support companion, a mobile repair station, and a healer, while also providing assistance in crafting and problem-solving. Designed to act as an extension of your ingenuity, the Toolkit Drone seamlessly integrates into both combat and exploration scenarios. Whether mending wounds, creating essential tools, or shielding allies from harm, this path exemplifies the boundless potential of combining arcane and technological mastery into a single, multifunctional device.</p>
          </div>

          <div className="content-block">
            <h2>Mechanics: Kit Points</h2>
            <p>At the beginning of each long rest, you gain a pool of Kit Points equal to your proficiency bonus. These points are spent to activate your drone's abilities, which scale in cost and power as you level up.</p>
            <h3>Abilities and Kit Point Costs</h3>
            <p>Level 3 Abilities (1 Kit Point per use): Basic functions such as minor healing, minor item creation, or skill assistance.</p>
            <p>Level 5 Abilities (2 Kit Points per use): Improved support functions such as enhanced repairs or granting temporary bonuses to allies.</p>
            <p>Level 9 Abilities (3 Kit Points per use): Advanced features such as area-of-effect healing or utility effects like removing conditions.</p>
            <p>Level 15 Abilities (4 Kit Points per use): Powerful augmentations, including multi-target effects or simultaneous module activations. You can select and use up to two of these abilities per long rest.</p>
            <p>Kit Points represent the energy and resources required to keep your drone running efficiently. They refresh at the end of a long rest, ensuring you can adapt to challenges each day with renewed capacity. This system ensures strategic use of higher-level abilities, balancing power with versatility.</p>
          </div>

          <div className="content-block">
            <h2>Toolkit Drone Creation</h2>
            <div className="level-indicator">Level 3</div>
            <p>At 3rd level, your drone becomes an invaluable companion, capable of adapting to a variety of challenges. Through its advanced modular design, it integrates seamlessly into your adventures, offering a range of transformative capabilities. These starting features highlight the balance of cutting-edge technology and arcane ingenuity, ensuring your drone can aid you in exploration, combat, and problem-solving alike.</p>
            <h3>Level 3: Modular Adaptations</h3>
            <ul>
              <li>Hydro-Adaptive Suit: A sleek, breathable tech suit that enables the wearer to breathe underwater and swim at an enhanced speed of 30 feet, adapting seamlessly to aquatic environments. Duration: Up to 1 hour per use.</li>
              <li>Buoyancy Stabilizer Boots: High-tech boots that manipulate surface tension, allowing the wearer to walk on water as if it were solid ground. Duration: Up to 1 hour per activation.</li>
              <li>Spatial Compression Storage Unit: A compact backpack utilizing spatial compression technology to store up to 500 pounds of items (but not exceeding 64 cubic feet in volume), while weighing only 15 pounds. No duration limit.</li>
              <li>Bio-Stabilizer Implant: A wearable tech device that regulates and fortifies the user's biology, granting immunity to disease and neutralizing poisons within 1 minute of exposure. No duration limit.</li>
              <li>Portable Water Generator: A moisture-capture device that produces clean, unlimited water at adjustable flow rates (fountain, stream, or geyser), useful for survival or extinguishing fires. Duration: Continuous while activated.</li>
              <li>Escape Assistance Device: A drone-integrated module that automatically activates to aid the wearer in escaping restraints or bindings, granting advantage on checks made to escape or avoid being grappled. Usable once per hour.</li>
              <li>Luck Optimization Module: A subtle enhancement chip embedded in the drone that improves reaction timing and decision-making, granting a +1 bonus to all ability checks and saving throws. Continuous effect while active.</li>
              <li>Stealth Field Enhancers: Noise-dampening tech boots that enhance stealth by granting advantage on Dexterity (Stealth) checks and reducing detection risk in low-light environments. Continuous effect while worn.</li>
              <li>Thermo-Insulated Boots: Advanced footwear with temperature-regulating technology, providing resistance to cold damage and advantage on checks to traverse icy or snowy terrain. Continuous effect while worn.</li>
              <li>Climbing Assist Modules: Mechanized gloves with retractable grips and stabilizers that grant a climbing speed equal to your walking speed, allowing effortless scaling of vertical surfaces. Continuous effect while worn.</li>
              <li>Telescopic Vision Lenses: Goggles with integrated zoom and clarity enhancements, granting advantage on Wisdom (Perception) checks relying on sight and the ability to spot details up to 1 mile away in clear conditions. Usable for up to 10 minutes per activation.</li>
              <li>Low-Light Optics: Enhanced visual goggles that amplify ambient light and simulate darkvision up to a range of 60 feet, useful for navigating dim or dark environments. Continuous effect while worn.</li>
              <li>Revealing Scanner Light: A portable scanning lamp integrated into the drone, which highlights cloaked or hidden entities and objects within a 30-foot radius when activated. Duration: 10 minutes per activation.</li>
              <li>Atmospheric Filtration Unit: A wearable mask or necklace that filters air and adjusts to extreme environments, granting immunity to harmful gases and breathable air in otherwise uninhabitable conditions. Continuous effect while worn.</li>
              <li>Sonic Pest Controller: A sonic emitter mounted on the drone that attracts or repels specific creatures (such as rats or insects) within a 30-foot radius, controllable by the user. Usable for up to 1 hour per activation.</li>
              <li>Gravitational Stabilizer Device: A wearable tech module that negates fall impact by slowing descent, functioning as a continuous feather fall effect. Continuous effect while worn.</li>
              <li>Speed Boosters: High-performance boosters integrated into mounts or vehicles, enhancing their speed by 20 feet for up to 1 hour per activation. Cooldown: 1 hour between uses.</li>
              <li>Hydro-Breathing Apparatus: A lightweight, helmet-mounted system that allows the wearer to breathe underwater for up to 1 hour per use. Cooldown: 1 hour between uses.</li>
              <li>Holo-Projector Module: A wearable drone attachment that generates a realistic holographic disguise, enabling the wearer to appear as another creature or object for up to 1 hour. Cooldown: 1 hour between uses.</li>
              <li>Fog Emission Unit: A drone-mounted device that creates a dense fog cloud in a 20-foot radius, lasting for 10 minutes. Cooldown: 1 hour between uses.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Level 5: Modular Adaptations</h2>
            <div className="level-indicator">Level 5</div>
            <ul>
              <li>Bio-Stabilizer Implant: A wearable tech device that sets the wearer's Constitution to 19, fortifying their health and resilience. Duration: Continuous effect while worn.</li>
              <li>Strength Assist Belt: A tech-enhanced belt that grants advantage on Strength (Athletics) checks for grappling, lifting, and shoving. Duration: Continuous effect while worn.</li>
              <li>Aero-Lift Boots: Boots equipped with retractable thrusters, enabling short-term flight up to a height of 30 feet with a speed of 60 feet. Duration: Up to 10 minutes per activation. Cooldown: 1 hour between uses.</li>
              <li>Auto-Climbing Cable: A grappling cable integrated into the drone, allowing effortless climbing and anchoring. Duration: Up to 1 hour per use. Cooldown: 1 hour between uses.</li>
              <li>Kinetic Enhancement Boots: Mobility-enhancing boots that negate movement penalties and triple the wearer's normal jump distance. Duration: Continuous effect while worn.</li>
              <li>Resonance Unlocker: A compact sonic vibration tool that bypasses mundane locks and disables nonmagical traps. Usable 3 times per long rest.</li>
              <li>Adhesive Neutralizer: A spray that dissolves adhesives, including magical ones, freeing creatures or objects from entanglement or traps. Usable up to 5 times before refueling. Duration: Instantaneous.</li>
              <li>Charisma Boost Lenses: Tech-enhanced lenses granting advantage on Charisma (Persuasion) checks and a single use of charm person per day. Duration: Continuous effect for skill bonuses. Cooldown: 24 hours for charm person.</li>
              <li>Cognitive Enhancement Interface: A wearable device that sets the wearer's Intelligence to 19, sharpening their mental acuity. Duration: Continuous effect while worn.</li>
              <li>Linguistic Decoding Module: A device that enables the wearer to understand all spoken languages and read any written text. Duration: Continuous effect while worn.</li>
              <li>Energy Reclamation Module: A compact system that allows recovery of one expended 1st-level spell slot. Usable once per long rest.</li>
              <li>Sonic Manipulator: A sound-emission device capable of summoning a phantom steed for travel or reconnaissance. Duration: Up to 1 hour per use. Cooldown: 24 hours between uses.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Level 9: Modular Adaptations</h2>
            <div className="level-indicator">Level 9</div>
            <ul>
              <li>Privacy Shield Module: A wearable device that prevents magical tracking and divination, shielding the user's location from detection. Duration: Continuous effect while worn.</li>
              <li>Hover Stabilizer Boots: Tech boots that allow controlled vertical movement up to 20 feet, providing enhanced mobility for exploration or escape. Duration: Up to 10 minutes per activation. Cooldown: 1 hour between uses.</li>
              <li>Spatial Compression Disk: A portable disk that creates an extra-dimensional storage space capable of holding up to 500 pounds or small creatures. Duration: Continuous while activated.</li>
              <li>Aero-Lift Carpet: A lightweight, hover-capable rug capable of carrying up to six Medium creatures at a speed of 60 feet. Duration: Continuous while in use.</li>
              <li>Nocturnal Flight Cloak: A tech-enhanced cloak enabling stealthy flight and transformation into a bat-like form. Duration: Up to 1 hour per activation. Cooldown: 1 hour between uses.</li>
              <li>Visionary Analyzer: A device that allows remote viewing of distant locations, people, or events. Duration: Up to 10 minutes per use. Cooldown: 24 hours between uses.</li>
              <li>Nano-Nutrient Orb: A floating device that eliminates the need for food and drink by supplying essential nutrients directly to the user. Duration: Continuous effect while active.</li>
              <li>Alertness Enhancement Orb: A floating sphere that eliminates surprise conditions and boosts vigilance, granting advantage on initiative rolls. Duration: Continuous effect while active.</li>
              <li>Defensive Buffer Orb: A floating device projecting an energy shield that grants a +1 bonus to AC. Duration: Continuous effect while active.</li>
              <li>Stellar Attunement Cloak: A garment granting limited flight, enhanced mobility, and a +1 bonus to AC, as well as shedding bright light in a 30-foot radius. Duration: Continuous effect while worn.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Level 15: Modular Adaptations</h2>
            <div className="level-indicator">Level 15</div>
            <ul>
              <li>Plane Travel Module: A sophisticated device enabling the wearer to cast plane shift once per day, allowing instantaneous travel across planes of existence. Cooldown: 24 hours between uses.</li>
              <li>Enhanced Strength Regulator: A wearable device that sets the wearer's Strength score to 25, enabling feats of extraordinary physical power. Duration: Continuous effect while worn.</li>
              <li>Speed Optimization Unit: Advanced tech boots that allow the wearer to take the Dash action as a bonus action, doubling their speed. Duration: Up to 10 minutes per activation. Cooldown: 1 hour between uses.</li>
              <li>Stealth Integration Cloak: A high-tech cloak that renders the wearer invisible while worn, providing unparalleled stealth capabilities. Duration: Up to 1 hour per activation. Cooldown: 1 hour between uses.</li>
              <li>Force Barrier Generator: A compact device that creates a cube-shaped barrier to block physical and magical attacks. Duration: Up to 10 minutes per use. Cooldown: 1 hour between uses.</li>
              <li>Spatial Relocation Helm: A wearable helm enabling the wearer to cast teleport once per day, allowing instantaneous travel within the same plane. Cooldown: 24 hours between uses.</li>
              <li>Energy Storage Orb: A floating device capable of absorbing spells cast at the wearer, storing their energy for later use. Usable up to 3 times per day.</li>
              <li>Leadership Enhancement Orb: A sphere that hovers around the user, granting a +2 bonus to Charisma checks and inspiring nearby allies, giving them advantage on saving throws against being frightened. Duration: Continuous effect while active.</li>
            </ul>
          </div>

          <div className="content-block">
            <h2>Level 18: Dual Drone Deployment</h2>
            <div className="level-indicator">Level 18</div>
            <p>At 18th level, your mastery over technological adaptation reaches its pinnacle, allowing you to integrate a second drone into your arsenal. This feature grants unparalleled flexibility and utility, elevating your role as a battlefield strategist and problem-solver.</p>
            <ul>
              <li>Dual Drone Deployment: You can now deploy and control two drones simultaneously. Each drone functions as a fully independent entity, and you can transform both into separate items from your available list, assign one or both to allies, or keep both for your own use. This allows for maximum adaptability in diverse scenarios.</li>
              <li>Independent Transformation: Each drone can be transformed into a different tech-based item at the same time. The transformation of one drone does not affect the other, providing the flexibility to cover multiple needs within the same encounter or exploration scenario.</li>
              <li>Action Economy: Controlling two drones does not require additional action expenditure. You can command both drones as part of the same bonus action or interaction (as relevant to their current form).</li>
              <li>Resource Sharing: Both drones draw from your shared tech point pool. Deploying two drones simultaneously requires careful resource management, as each transformation or activation consumes tech points from the same reservoir.</li>
              <li>Tactical Coordination: Your drones can operate independently within a range of 60 feet, enabling you to strategically position them for optimal effect. Whether enhancing allies, creating utility effects, or supporting with unique transformations, the dual-drone system ensures you are always prepared for any situation.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying Tips for the Dronewright Artificer</h2>
          
          <h3>Character Concepts</h3>
          
          <h4>The Innovative Tinkerer:</h4>
          <p>Background: Fascinated by the intersection of magic and technology, you spent your youth tinkering with mechanical devices and experimenting with arcane energies.</p>
          <p>Motivation: Driven to create something revolutionary that can change the world for the better.</p>
          <p>Roleplaying: Always looking for new materials and ideas to improve your drones. You might be eccentric, often lost in thought, but your creations speak for themselves.</p>

          <h4>The Battlefield Engineer:</h4>
          <p>Background: From a military family, you've seen the devastation of war and decided to use your skills to create tools that protect and aid your allies.</p>
          <p>Motivation: Committed to making the battlefield a safer place for your comrades. Your drones enhance combat capabilities and provide crucial support.</p>
          <p>Roleplaying: Strategic and calculated, always thinking several steps ahead. You are the one your party turns to for tactical advice and innovative solutions.</p>

          <h4>The Arcane Scholar:</h4>
          <p>Background: Dedicated your life to studying arcane energies and their application in technology, believing that the fusion of magic and machinery holds the key to unlocking new possibilities.</p>
          <p>Motivation: Seek to uncover the secrets of the universe and use that knowledge to create powerful and versatile drones.</p>
          <p>Roleplaying: Analytical and methodical, always seeking to understand the underlying principles of your creations. You might come across as aloof, but your passion for knowledge is undeniable.</p>

          <h3>Interacting with the Party</h3>

          <h4>The Supportive Ally:</h4>
          <p>Role: The backbone of the party, providing essential support and utility with your drones. Whether it's healing, enhancing abilities, or creating useful tools, you are always there to lend a helping hand.</p>
          <p>Interactions: Empathetic and caring, always looking out for the well-being of your companions. You might offer words of encouragement or use your drones to lighten the mood during tough times.</p>

          <h4>The Strategic Planner:</h4>
          <p>Role: The tactician of the group, using your drones to scout ahead, gather information, and plan the best course of action.</p>
          <p>Interactions: Decisive and confident, often taking the lead in discussions about the next steps. You value input from your party members and work collaboratively to achieve the best outcomes.</p>

          <h4>The Creative Problem-Solver:</h4>
          <p>Role: The one who thinks outside the box, using your drones to come up with unconventional solutions to complex problems.</p>
          <p>Interactions: Enthusiastic and optimistic, always seeing the potential in every situation. You encourage your party to think creatively and embrace new ideas.</p>

          <h3>Interacting with NPCs</h3>

          <h4>The Curious Inventor:</h4>
          <p>Approach: Eager to learn from others and share your knowledge. You might seek out other inventors, scholars, or anyone with unique insights into magic and technology.</p>
          <p>Interactions: Open and friendly, willing to engage in deep conversations about your passions. You might offer to show your drones or ask about their own creations and experiences.</p>

          <h4>The Resourceful Negotiator:</h4>
          <p>Approach: Understand the value of your creations and are not afraid to negotiate for the resources and materials you need. You might barter your services or drones in exchange for rare components or information.</p>
          <p>Interactions: Assertive and persuasive, using your charm and intelligence to strike the best deals. You are fair but firm, ensuring that both parties benefit from the exchange.</p>

          <h4>The Protective Guardian:</h4>
          <p>Approach: Use your drones to protect and aid those in need, whether it's defending a village from bandits or helping a farmer with a difficult harvest.</p>
          <p>Interactions: Compassionate and generous, always looking for ways to make a positive impact. You might offer your assistance freely, expecting nothing in return, but appreciating any gestures of gratitude.</p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={dronewrightImage} 
                alt="Dronewright"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={dronewrightImage}
                download="Dronewright.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesDronewright; 