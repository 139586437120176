import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Winter Witch/winterwitch.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesWinterWitch = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Winter Witch" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Winter Witch</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>              
                <Link to="/species/SpeciesSkinWalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Skinwalker
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Winter Witches first appeared during the first winter solstice after the Shattering, a time when a devastating winter storm swept across the Velum. This storm traveled with the devastating changing of seasons, leaving no part of the realm untouched. It brought icy destruction to lush lands and blanketed even the warmest regions in frost. In the coldest and harshest reaches, this unnatural storm gave birth to new beings—the Winter Witches. Emerging from the darkest caves and endless tundra, they were both natural protectors and predators, shaped by the storm's relentless power.
          </p>
        </div>

        <div className="content-block">
          <p>
            Winter Witches are said to originate from a sacred mountain range known as The Three Daughters, consisting of three massive peaks: The Mother, The Matron, and The Lady. These mountains are veiled in perpetual frost and are the seat of Queen Mab's domain. Legends speak of ancient storms and powerful winter magic emanating from these peaks. Each mountain symbolizes an aspect of winter—creation, preservation, and destruction. Though few have ventured there and returned, these peaks are believed to be the birthplace of the Winter Witches, a place where the original storm's power still lingers.
          </p>
        </div>

        <div className="content-block">
          <p>
            Unlike traditional societies, Winter Witches do not form structured communities or large gatherings. Their nature drives them to solitude or small, temporary covens. If not overseeing a domain of winter, they are driven by an innate compulsion to seek one. This search is not aimless; Winter Witches are drawn to regions they deem necessary for winter's presence. Once claimed, they dedicate themselves to maintaining the balance of the natural cycle, ensuring decay and renewal occur as intended.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <h3>Icy Visage</h3>
          <p>
            Winter Witches exude an unnerving aura, marked by cold, calculating beauty. Their movements are precise, and their presence evokes the stillness of a winter storm—a calm that hides potent power. They are known for an almost supernatural stillness; they can remain as motionless as death itself, only to seemingly appear right in front of you in the blink of an eye, as though the frost carried them forward. Their eyes are piercing and seem to reflect the frost-laden landscapes they protect, occasionally shimmering with a faint, icy glow that betrays the depth of their magical connection.
          </p>
          <h3>Haunting Pallor</h3>
          <p>
            Their skin is unnaturally pale, often with grey or blue undertones that highlight their unyielding connection to the cold. Subtle markings, resembling cracked ice or frostbite scars, shimmer as though a thin layer of frost covers their flesh. When touched, their skin feels unnervingly tough and icy, more akin to polished marble than living tissue. These scars sometimes shift or grow faintly colder when the Witch draws upon her magic.
          </p>
          <h3>Prehensile Hair</h3>
          <p>
            Their hair is a tangled mass, appearing more like dark, frozen vines than typical strands. It writhes and twists with an eerie vitality, crackling faintly with blue energy. Colors range from ashen whites and icy blues to deep purples and midnight blacks. It is unkempt and unruly, its appearance dictated by its arcane use rather than aesthetics. In moments of heightened magic, their hair may move autonomously, frost trailing behind its strands, giving them an otherworldly and intimidating aura.
          </p>
        </div>

        <div className="content-block">
          <h2>Winter Witch Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Choose two ability scores to increase by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Winter Witches mature at a slower pace than humans, reaching adulthood around the age of 25 and living for approximately 200 years.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>They tend towards lawful neutral, driven by a need for order and strategy above all else.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium. Winter Witches vary in height and build, but most are between 5 feet and 6 feet tall.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Features</h3>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frigid Assault</h3>
            </div>
            <p>Your connection to the cold allows you to wield it with precision. You know the ray of frost cantrip. Additionally, you can cast the ice knife spell a number of times equal to your proficiency bonus per long rest without requiring material components. After casting either spell, you can choose for the damage type to be piercing or cold. Starting at 5th level, when you score a critical hit with ray of frost or ice knife, the target's movement speed is reduced to 0 until the end of their next turn.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Prehensile Hair Mastery</h3>
            </div>
            <p>Your hair acts as an extension of your will. You can manipulate objects, open doors, or disarm traps with your hair as if using the mage hand cantrip. Your hair has a reach of 15 feet and can carry up to 5 pounds. As a bonus action, you can use your hair to make a number of melee spell attacks equal to your proficiency bonus against different targets, using your spellcasting modifier for attack and damage rolls. These attacks deal 1d4 cold damage on a hit. Starting at 8th level, these attacks also deal an additional 1d4 necrotic damage. As an action, you can use your hair as if casting mage hand.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Winter's Resilience</h3>
            </div>
            <p>You are resistant to cold damage. As a reaction, when you take damage, you can emit a blast of freezing air, inflicting your level in cold damage on the attacker and reducing their movement speed by 10 feet until the end of their next turn. You may use this reaction a number of times equal to your proficiency bonus per long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frostbound Perception</h3>
            </div>
            <p>You have proficiency in the Insight and Survival skills. Additionally, you have advantage on Wisdom (Insight) and Wisdom (Survival) checks that rely on logic, pattern recognition, or inconsistencies in social or environmental interactions.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common, Sylvan, and one other language of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h2>Roleplaying a Winter Witch</h2>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Cultural Notes</h3>
            </div>
            <p><strong>Guardians of the Frost:</strong> Winter Witches see themselves as stewards of winter, dedicated to claiming and preserving domains they deem vital for the natural balance. Each Witch feels a personal connection to the land they protect, treating it as both their sanctuary and their responsibility. Once a domain is claimed, a Winter Witch will fiercely defend it from threats, whether they are natural, magical, or mortal.</p>
            <p><strong>The Call to Claim:</strong> If a Winter Witch loses their domain or is born without one, they are drawn to find a new one. This compulsion to seek and protect aligns with their belief in the necessity of winter's role in the cycle of decay and renewal. These Witches often roam the Shattered Realm until they find an area that resonates with their purpose.</p>
            <p><strong>The Three Daughters:</strong> Legends of The Mother, The Matron, and The Lady are deeply tied to Winter Witch culture. These mountains are said to embody the essence of winter itself and serve as the seat of Queen Mab's domain. Many Witches make pilgrimages to these peaks to meditate, hone their powers, or seek guidance from the eternal frost. Some believe the mountains grant visions or blessings to those deemed worthy, while others see them as the source of winter's balance.</p>
            <p><strong>Silent Stalkers:</strong> Winter Witches are known for their unnerving ability to remain utterly still, blending seamlessly into their icy surroundings. They can remain as motionless as death itself, only to appear unnervingly close when one looks away. This ability reinforces their mystique as ghostly protectors of winter's domain and makes them formidable against intruders.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Motivations</h3>
            </div>
            <p>Winter Witches are driven by their connection to the natural cycle of life and death, seeing themselves as enforcers of decay and balance. They often seek knowledge and power to better understand and uphold this cycle, and their actions are guided by a cold, calculating pragmatism.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Interactions</h3>
            </div>
            <p>Winter Witches approach social interactions with strategic precision. They rarely waste words or actions on frivolity, often appearing aloof or detached. Their respect is earned through competence and understanding rather than charm or sentimentality.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Quirks</h3>
            </div>
            <p>Winter Witches may exhibit unique behaviors tied to their icy nature. They might leave behind faint frost on surfaces they touch or have a faint, chilling aura that cools the air around them. Their speech is often deliberate, with pauses as if calculating the impact of each word.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Roleplaying Tips</h3>
            </div>
            <p><strong>Embrace Precision:</strong> Portray your Winter Witch character as methodical and exacting. Describe their actions with a deliberate grace, reflecting their calculated nature.</p>
            <p><strong>Leverage Cold Authority:</strong> Highlight your character's commanding presence, using their icy demeanor to influence and intimidate.</p>
            <p><strong>Explore the Cycle:</strong> Integrate your character's philosophy of balance and decay into their decisions and actions. Show how this belief shapes their worldview and motivations.</p>
            <p><strong>Enhance Atmosphere:</strong> Add flavor to your roleplay by describing the subtle effects of their presence, such as frosty breath, icy footprints, or the quiet crackle of frozen air.</p>
            <p><strong>Seek the Domain:</strong> Roleplay the internal drive to find and claim a domain of winter. Let this pursuit guide your character's goals, whether it involves defending an already claimed sanctuary or searching for a place that feels like home.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Winter Witch"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="WinterWitch.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesWinterWitch; 