import React, { useState, useRef, useEffect } from 'react';
import sovereignZealotImage from '../../assets/images/subclasses/Sovereign/sovereignzealot.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesSovereignZealot = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={sovereignZealotImage} 
          alt="Sovereign Zealot" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Sovereign Zealot</h1>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Sovereign Zealots are the unwavering vanguards of Humanity's resurgence, skilled in the arts of warfare and persuasion. Clad in heavy armor and wielding martial weapons, they stand as bulwarks against the chaos of the Shattered Realm. Their mastery of rhetoric and unwavering faith fuel their drive to elevate humanity above all others, securing their dominion over the realm.
          </p>
        </div>

        <div className="content-block">
          <h2>Domain Spells</h2>
          <div className="level-indicator">Level 1st</div>
          <p>You gain domain spells at the cleric levels listed in the Sovereign Zealot Domain Spells table. See the Divine Domain class feature for how domain spells work.</p>
          <div className="trait">
            <div className="trait-header">
              <h3>1st Level</h3>
            </div>
            <p>Command, Heroism</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>3rd Level</h3>
            </div>
            <p>Enthrall, Branding Smite</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>5th Level</h3>
            </div>
            <p>Crusader's Mantle, Fear</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>7th Level</h3>
            </div>
            <p>Compulsion, Dominate Beast</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>9th Level</h3>
            </div>
            <p>Dominate Person, Geas</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Channel Divinity: Inspiring Conviction</h2>
          <div className="level-indicator">Level 2nd</div>
          <p>
            As an action, you can channel divine energy to bolster your words and ignite the flames of human exceptionalism. For the next 10 minutes, you gain advantage on all Charisma (Persuasion) and Charisma (Deception) checks when interacting with others to promote human unity, inspire loyalty, and strengthen their resolve to reclaim humanity's rightful place as the Shattered Realm's leaders.
          </p>
        </div>

        <div className="content-block">
          <h2>Tactical Precision</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            Your combat training and unwavering focus grant you the ability to exploit enemy vulnerabilities with deadly accuracy. You gain the Sneak Attack feature, as a Rogue. You deal an extra 1d6 damage to a creature you hit with an attack if you have advantage on the attack roll. The attack must use a finesse or ranged weapon. The extra damage increases by 1d6 at 10th and 14th level.
          </p>
        </div>

        <div className="content-block">
          <h2>Deflecting Maneuver</h2>
          <div className="level-indicator">Level 8th</div>
          <p>
            Your battlefield experience and understanding of combat tactics allow you to deftly avoid harm. When you are targeted by an attack, you can use your reaction to impose disadvantage on the attack roll. You can use this feature a number of times equal to your Wisdom modifier (minimum of once), and you regain all expended uses after a long rest.
          </p>
        </div>

        <div className="content-block">
          <h2>Paragon of Humanity</h2>
          <div className="level-indicator">Level 17th</div>
          <p>At 17th level, your commitment to humanity's cause elevates you to a living legend. You gain the following benefits:</p>
          <p>Humanity's Legacy: Your physical form embodies the ideals of human dominance. Your skin takes on a golden hue, and you grow a pair of pure white wings, granting you a flying speed of 30 feet.</p>
          <p>Voice of Authority: Your words carry the weight of human history and the promise of a glorious future. You gain advantage on Charisma (Persuasion), Charisma (Intimidation), and Charisma (Deception) checks.</p>
          <p>Unbreakable Will: Your mind is a fortress. You gain resistance to psychic damage and advantage on saving throws against being charmed or frightened.</p>
          <p>Dominion Mandate: Once per long rest, you can issue a command that compels obedience. Any creature that hears your command must make a Wisdom saving throw against your Cleric spell save DC or follow the command for the next minute, provided the command is not self-harmful.</p>
        </div>

        <div className="menu-container">
          <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Classes
              <ChevronDown size={20} />
            </h2>
            <div className="pantheon-links">
              <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                ← Return to Classes
              </Link>              
              <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Circle of the Burning Seed
              </Link>
              <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Circle of the Glacial Heart
              </Link>
              <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Dronewright
              </Link>
              <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Oath of the Seraphic Guardian
              </Link>
              <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Path of Wrath
              </Link>
              <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Pyromancer
              </Link>
              <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Way of the Lifedrinker
              </Link>
              <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                Winterborn Knight
              </Link>              
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={sovereignZealotImage} 
                alt="Sovereign Zealot"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={sovereignZealotImage}
                download="SovereignZealot.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesSovereignZealot; 