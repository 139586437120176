import React, { useState, useRef, useEffect } from 'react';
import seraphicGuardianImage from '../../assets/images/subclasses/Seraphic/oathoftheseraphicguardian.png';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesSeraphicGuardian = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={seraphicGuardianImage} 
          alt="Oath of the Seraphic Guardian" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Oath of the Seraphic Guardian</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Paladins who swear the Oath of the Seraphic Guardian dedicate themselves to embodying the essence of Valdís: justice, protection, law, and the strength found in the unity of community. These guardians are known for their unshakable honesty, refusing to lie or engage in deceit even under the gravest circumstances. Their commitment to truth is absolute, viewing dishonesty as a form of corruption that weakens the foundations of a just society.
          </p>
          <p>
            Each Seraphic Guardian lives as a beacon of Valdís's ideals, holding themselves to the highest standard of morality and transparency. They believe that truth fosters trust, and trust is the cornerstone of unity and peace.
          </p>
        </div>

        <div className="content-block">
          <h2>Features</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Tenets of the Seraphic Guardian</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>Paladins who swear this oath must adhere to the following tenets:</p>
            <p><strong>Justice as a Shield:</strong> Defend the innocent and the oppressed above all. Your strength exists to protect those who cannot protect themselves.</p>
            <p><strong>Unity in Diversity:</strong> Seek to unify all creatures under the principles of justice and cooperation. Strive to eliminate divisions caused by prejudice or hatred.</p>
            <p><strong>Mercy's Light:</strong> Grant mercy to those who seek redemption. Justice demands compassion, offering the penitent a path to amend their wrongs.</p>
            <p><strong>Eternal Vigilance:</strong> Stand unwavering against the forces of darkness. Always be prepared to confront corruption and despair.</p>
            <p><strong>Beacon of Hope:</strong> Act as a source of inspiration and courage. Your actions should inspire others to fight for what is just, even in the darkest times.</p>
            <p><strong>Truth Above All:</strong> Never lie or engage in deceit. Speak with clarity and honesty, for your words must reflect the ideals you uphold.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Oath Spells</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>You gain oath spells at the paladin levels listed:</p>
            <div className="trait">
              <div className="trait-header">
                <h3>3rd Level</h3>
              </div>
              <p>Shield, Command</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>5th Level</h3>
              </div>
              <p>Warding Bond, Zone of Truth</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>9th Level</h3>
              </div>
              <p>Beacon of Hope, Dispel Magic</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>13th Level</h3>
              </div>
              <p>Guardian of Faith, Freedom of Movement</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>17th Level</h3>
              </div>
              <p>Geas, Flame Strike</p>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Channel Divinity</h3>
              <span className="level-tag">Level 3rd</span>
            </div>
            <p>At 3rd level, you gain the following Channel Divinity options:</p>
            
            <h4>Mantle of Celestial Law</h4>
            <p>
              As an action, you can invoke Valdís's divine authority to envelop you and your allies within 10 feet in a Mantle of Celestial Law for 1 minute. This mantle manifests as a golden, shimmering light, providing the following benefits:
            </p>
            <p><strong>Protective Grace:</strong> You and your allies within 10 feet gain a bonus to AC equal to half your Charisma modifier (rounded up, minimum of +1).</p>
            <p><strong>Guardian's Retribution:</strong> When a hostile creature successfully attacks an ally within the mantle's range with a melee attack, the mantle emits a retaliatory burst of radiant light, inflicting radiant damage to the attacker equal to your Charisma modifier. This effect can trigger once per round.</p>
            <p><strong>Lawful Command:</strong> Once during the Mantle's duration, you can issue a divine command that aligns with Valdís's principles of protection, justice, or law, without expending a spell slot.</p>

            <h4>Turn the Profane</h4>
            <p>
              You can use your Channel Divinity to speak a prayer censuring fiends and undead. Each fiend or undead within 30 feet that can hear you must make a Wisdom saving throw. On a failure, it is turned for 1 minute or until it takes damage.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Aura of Seraphic Warding</h3>
              <span className="level-tag">Level 7th</span>
            </div>
            <p>
              You and friendly creatures within 10 feet of you gain resistance to necrotic and radiant damage. Additionally, whenever an ally within the aura is reduced to 0 hit points but not killed outright, they are instead reduced to 1 hit point. This effect can trigger once per creature per long rest. At 18th level, the range of this aura increases to 30 feet.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Divine Wings</h3>
              <span className="level-tag">Level 15th</span>
            </div>
            <p>
              You permanently gain radiant wings made of metallic golden feathers. You can summon or dismiss these wings as a bonus action. While your wings are active, you have a flying speed equal to your current walking speed.
            </p>
            <p>
              In addition, your wings can be used as melee weapons. As an attack, you can make a melee weapon attack with your wings against a creature within 5 feet. On a hit, the wings deal 1d8 radiant damage and 1d8 necrotic damage, plus your Charisma modifier. These attacks count as magical for the purpose of overcoming resistance and immunity to nonmagical attacks and damage.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Seraphic Champion</h3>
              <span className="level-tag">Level 20th</span>
            </div>
            <p>You can use your action to assume the form of a Seraphic Guardian for 1 minute. While in this form, you gain the following benefits:</p>
            <ul>
              <li>You emit bright light in a 30-foot radius and dim light for an additional 30 feet.</li>
              <li>Once on each of your turns, you can deal extra radiant damage to one target when you hit it with a weapon attack. This extra damage equals 10 + your Charisma modifier.</li>
              <li>You have advantage on all saving throws.</li>
              <li>You can use this transformation once per long rest.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>Role Playing</h2>
          <p>
            As a Seraphic Guardian paladin, you embody the ideals of justice, protection, law, and community. You are a beacon of hope, a defender of the innocent, and a champion of the oppressed. Your actions are guided by your unwavering faith in Valdís and your commitment to upholding her sacred tenets.
          </p>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Altruistic:</strong> You prioritize the needs of others before your own.</p>
            <p><strong>Loyal:</strong> You are fiercely loyal to your allies and your cause.</p>
            <p><strong>Disciplined:</strong> You adhere to a strict code of conduct.</p>
            <p><strong>Compassionate:</strong> You show mercy to the repentant.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Justice:</strong> Fairness and equality guide your actions.</p>
            <p><strong>Protection:</strong> You dedicate yourself to safeguarding the innocent.</p>
            <p><strong>Community:</strong> Unity is strength; you foster cooperation.</p>
            <p><strong>Law:</strong> Order and stability are the foundation of society.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>Divine Mandate:</strong> You feel a strong sense of duty to fulfill Valdís's will.</p>
            <p><strong>Sacred Oath:</strong> You are bound by your vows to protect and uphold justice.</p>
            <p><strong>Fellow Paladins:</strong> You share a unique camaraderie with other paladins.</p>
            <p><strong>Community:</strong> You work tirelessly to protect those you serve.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Righteous Fury:</strong> Your dedication to justice can lead to impulsive actions.</p>
            <p><strong>Unwavering Conviction:</strong> Your rigidity can hinder compromise.</p>
            <p><strong>Overprotective:</strong> Your desire to protect can stifle independence.</p>
            <p><strong>Self-Sacrificing:</strong> You risk yourself to protect others, sometimes recklessly.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Equipment</h2>
          <p>As a Seraphic Guardian paladin, your equipment reflects your divine purpose:</p>
          <ul>
            <li>A holy symbol of Valdís, crafted from gold or silver</li>
            <li>Fine armor representing your status as a champion of justice</li>
            <li>A healing kit for tending to the wounded</li>
            <li>A book of laws and sacred texts</li>
            <li>A banner bearing Valdís's symbol</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Multiclassing</h2>
          <p>To enhance your capabilities as a Seraphic Guardian, consider multiclassing with:</p>
          <div className="trait">
            <p><strong>Cleric (Life Domain):</strong> Enhance your healing and protective abilities.</p>
            <p><strong>Warlock (Celestial):</strong> Gain additional radiant damage and celestial spells.</p>
            <p><strong>Bard (Valor):</strong> Inspire allies while maintaining combat effectiveness.</p>
            <p><strong>Fighter (Battle Master):</strong> Add tactical options to your combat repertoire.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={seraphicGuardianImage} 
                alt="Oath of the Seraphic Guardian"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={seraphicGuardianImage}
                download="SeraphicGuardian.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesSeraphicGuardian; 