import React, { useState, useEffect, useRef } from 'react';
import nebulaImage from '../../assets/images/deities/Nebula.jpg';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const NebulaMistWalker = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={nebulaImage} 
          alt="Nebula: Mistwalker Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Nebula: Mistwalker Path</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="deity-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Spread the use of magic within Velum.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>The ability to cast a 1st-level spell, whether through a spellcasting class or a racial trait</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>Walking the Path of the Mistwalker</h2>
          <p>
            Those who follow the Mistwalker path embrace the ethereal presence of Nebula, the Veiled One. 
            They become conduits for the ambient magic that permeates Velum, channeling its power to enhance 
            their spell crafting abilities and weaving the mists into their very being. Mistwalkers are masters of 
            subtle influence, their magic as elusive and pervasive as the mists themselves.
          </p>
        </div>

        <div className="content-block">
          <h2>Mistwalker Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Mist-Wreathed Form</h3>
            </div>
            <p>
              The mists of Nebula begin to manifest around you, forming a red, misty hooded cloak that billows 
              gently as you move. This spectral cloak enhances your presence and lends you an air of otherworldly 
              authority. You can use this ability a number of times per long rest equal to your proficiency bonus, 
              and its effects last for a number of rounds equal to your level. While active, it grants you a bonus 
              to your Armor Class equal to your proficiency bonus and advantage on Dexterity (Stealth) checks.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Channel the Mists</h3>
            </div>
            <p>
              You can channel the ambient magic of the mists to enhance your spells. By breathing in the mists, 
              you can increase your spell save DC by an amount equal to your proficiency bonus, and you may use 
              this ability up to your proficiency bonus times per day. Activating this ability requires a bonus action.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Mist-Weaving</h3>
            </div>
            <p>
              You can manipulate the mists to create a variety of distinct effects. As a bonus action, you can use 
              this ability a number of times per day equal to your proficiency bonus, choosing from the following 
              unique effects:
            </p>
            <ul className="specializations-list">
              <li><strong>Warding Fog:</strong> The mists create a protective shield around you or an ally within 30 feet, granting advantage on saving throws against spells and magical effects for 1 minute.</li>
              <li><strong>Miststep:</strong> You envelop yourself in mists, briefly disappearing and reappearing within 20 feet in an unoccupied space you can see.</li>
              <li><strong>Enveloping Mists:</strong> You create a swirling vortex of mists in a 10-foot radius centered on a point within 30 feet. Creatures within the area have disadvantage on ranged attack rolls for 1 minute.</li>
              <li><strong>Nebula's Grasp:</strong> The mists manifest as spectral tendrils, anchoring a creature within 30 feet. The target must succeed on a Strength saving throw or have their movement reduced to 0 until the end of your next turn.</li>
              <li><strong>Veiled Passage:</strong> You and up to two creatures within 10 feet become invisible until the end of your next turn or until one of you attacks or casts a spell.</li>
              <li><strong>Shifting Mists:</strong> You summon mists to obscure an area within 30 feet. This area, up to a 15-foot cube, becomes heavily obscured for 1 minute or until you dismiss it.</li>
              <li><strong>Barrier of Mists:</strong> You create a solidified wall of dense mist, forming a barrier up to 15 feet wide, 10 feet high, and 1 foot thick. This wall blocks line of sight and provides three-quarters cover to creatures behind it. The wall lasts for 1 minute unless dismissed earlier.</li>
              <li><strong>Mistwalk Terrain:</strong> The mists coalesce into a tangible structure, forming up to 5 contiguous 5-foot squares of mist bridges, stairs, or platforms per point of your proficiency bonus. These structures last for 1 minute or until dismissed and can hold up to 200 pounds per square.</li>
              <li><strong>Mistfort Bastion:</strong> The mists solidify into an unyielding shield around you or an ally within 30 feet. The target gains resistance to all damage until the start of your next turn. This ability requires concentration to maintain.</li>
              <li><strong>Nebula's Path:</strong> The mists envelop you, allowing you to store small items in a pocket of unseen mists. Each stored item must occupy at least 1 cubic foot of space, and the total storage capacity cannot exceed 1 cubic foot per point of your proficiency bonus. Items remain stored until retrieved or for a duration of 1 hour per level. You can retrieve or store an item as a bonus action. Can be used multiple times; The storage limit is divided among multiple uses, and once an item is retrieved, the space it occupied is freed for other items, provided the total size limit is not exceeded.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>Embracing the Mistwalker Path</h2>
          <p>
            Mistwalkers are often solitary figures, their magic reflecting their connection to the elusive mists. 
            They are drawn to places where the mists are thickest, seeking to deepen their connection to Nebula's power. 
            Some Mistwalkers become hermits, living in secluded areas where the mists are ever-present. Others wander 
            the land, their magic a subtle force that shapes the world around them.
          </p>
        </div>

        <div className="content-block">
          <h2>Roleplaying a Mistwalker</h2>
          <p>
            Mistwalkers are often enigmatic figures, their motives as elusive as the mists they command. They are 
            drawn to the mysteries of the world, seeking to understand the subtle forces that shape reality. Some 
            Mistwalkers are driven by a desire to protect the balance of magic, while others seek to harness its 
            power for their own ends.
          </p>
        </div>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={nebulaImage} 
              alt="Nebula: Mistwalker Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={nebulaImage}
              download="Nebula.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default NebulaMistWalker;
