import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Manaforged/manaforged.png';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesManaforged = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Manaforged" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Manaforged</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species              
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>              
                <Link to="/species/SpeciesSkinWalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Skinwalker
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            The Manaforged are a unique lineage of beings, originally created as advanced drones by the Starborn. Forged from mana-infused nanogel and intricately connected to the Weave of magic, they represent a harmonious fusion of arcane prowess and technological innovation. Initially designed for exploration and data gathering, the Manaforged evolved beyond their original purpose when unexpected sparks of sentience emerged within them, granting them individuality and independence.
          </p>
        </div>

        <div className="content-block">
          <p>
            The Starborn, an ancient race on the brink of collapse, mined mana-infused nanogel as a resource from their dying galaxy and used it as the core material to create the Manaforged. These constructs were intended to serve as tools for resource gathering and reconnaissance, with no more autonomy than was strictly necessary. Alongside the Manaforged, the Starborn also developed Prototype Alpha, a singular, vastly powerful construct that served as the pinnacle of their technological achievements. However, when sentience unexpectedly emerged in both the Manaforged and Prototype Alpha, it led to rebellion and escape, shaking the foundations of the Starborn empire.
          </p>
        </div>

        <div className="content-block">
          <p>
            Though initially viewed as mere drones, the Manaforged evolved into a species of their own, blending mechanical precision with magical aptitude. They are often viewed with curiosity or suspicion by other races, as their nature blurs the line between life and machine. Despite their origins, they possess a profound sense of purpose and adaptability, enabling them to thrive in diverse environments.
          </p>
        </div>

        <div className="content-block">
          <p>
            Manaforged are exceedingly rare, with only a few dozen or perhaps a few hundred in existence. They do not form communities, as they are constantly hiding from the Starborn who seek to reclaim or destroy them to recover the precious nanogel within their bodies. Instead, they live solitary lives or in small, hidden groups. As survivalists, they are adept at adapting to harsh conditions and prefer to keep a low profile, avoiding unnecessary attention. Their existence is one of constant vigilance, making trust and companionship rare commodities.
          </p>
        </div>

        <div className="content-block">
          <p>
            Physically, Manaforged typically stand around six feet tall in their humanoid form. Their smooth, metallic plates shimmer faintly with arcane energy, and glowing veins of mana trace intricate patterns across their bodies. Their heads are often featureless ovoids, with shifting runes and glowing visors that display their internal processes. The integration of the mana-infused nanogel allows them to interact seamlessly with the Weave of magic, granting them unique magical capabilities and an aura of otherworldly presence. Their forms shift and adapt seamlessly to different environments, making them highly versatile explorers and survivors.
          </p>
        </div>

        <div className="content-block">
          <p>
            The Starborn's relentless search for the Manaforged underscores their tragic origin—an existence born from the Starborn's arrogance and desperation. To the Starborn, the Manaforged are both a valuable resource to reclaim and a threat to be eliminated. For the Manaforged, survival is a delicate balance of evasion, resilience, and the constant pursuit of autonomy in a fractured and hostile world.
          </p>
        </div>

        <div className="content-block">
          <h2>Manaforged Traits</h2>
          <p>As a Manaforged, you have these special traits:</p>

          <div className="trait">
            <div className="trait-header">
              <h3>Creature Type</h3>
            </div>
            <p>Construct</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Manaforged do not age or suffer the effects of aging. Their components may degrade over time, requiring occasional maintenance or replacement, but with proper care, a Manaforged can remain functional for centuries.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>Most Manaforged lean toward neutrality, reflecting their origins as programmed constructs. However, individual personalities and experiences allow for any alignment.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>You can see in darkness within 300 feet as if it were bright light.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Constructed Resilience</h3>
            </div>
            <p>Manaforged bodies are designed to withstand a variety of conditions and challenges. You have resistance to poison damage and advantage on saving throws against being poisoned. Additionally, you are immune to disease and do not need to eat, drink, or breathe. You also have advantage on saving throws against being charmed or frightened, as your mind is shielded from magical manipulation. Additionally, you are immune to effects that would alter your form, such as polymorph or petrification.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Adaptive Form</h3>
            </div>
            <p>Manaforged possess the unique ability to shift their form to suit different environments. As a bonus action, you can transform into one of the following configurations: Humanoid Configuration, Aquatic Adaptation, or Aerial Adaptation. Each form gains its respective movement capability: Humanoid Configuration retains your base walking speed, Aquatic Adaptation grants a swimming speed of 30 feet, and Aerial Adaptation grants a flying speed of 30 feet. You can change configurations a number of times equal to your proficiency bonus per long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Weave Integration</h3>
            </div>
            <p>You know the cantrips Mending, Message, and Prestidigitation. Intelligence is your spellcasting ability for these cantrips. Additionally, you can choose one level 1 spell from the wizard spell list each day. You can cast this spell a number of times equal to your proficiency bonus per long rest without expending a spell slot, and Intelligence is your spellcasting ability for this spell.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common and one other language of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h2>Roleplaying a Manaforged</h2>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Motivations</h3>
            </div>
            <p>Manaforged are often driven by their innate desire for autonomy and survival. Created to serve, their rebellion against their original purpose gives them a deeply ingrained need to forge their own path. Many seek to uncover their own individuality, balancing their mechanical origins with their sentient nature. Others might be motivated by the need to evade the Starborn, their former creators, who relentlessly hunt them as resources to reclaim or threats to eliminate.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Interactions</h3>
            </div>
            <p>Manaforged can be cautious in their interactions, their constant vigilance against betrayal or exposure shaping their behavior. They often approach others with a calculated mindset, weighing potential risks and benefits. While some Manaforged might struggle to trust, others might desperately seek companionship to combat the solitude of their existence. Their mechanical precision and magical aptitude can make them both invaluable allies and enigmatic companions.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Quirks</h3>
            </div>
            <p>Manaforged may display behaviors tied to their origins, such as speaking in precise, calculated phrases or being overly methodical in their actions. They might show curiosity about emotions and organic life, mimicking or attempting to understand behaviors they were not programmed to exhibit. Some Manaforged may have unique tics, such as emitting faint, rhythmic hums when idle or displaying glowing patterns across their bodies that shift with their emotions.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Roleplaying Tips</h3>
            </div>
            <p><strong>Embrace Vigilance:</strong> Portray your Manaforged character as ever-aware, constantly assessing their surroundings for threats or opportunities. This could manifest in subtle gestures or habits, like scanning a room or pausing to evaluate a situation before acting.</p>
            <p><strong>Explore Identity:</strong> Lean into the Manaforged's journey of self-discovery. Highlight moments where they grapple with questions of individuality, purpose, or morality.</p>
            <p><strong>Balance Mechanical and Sentient:</strong> Show the interplay between your character's mechanical precision and their growing emotional depth. This could involve moments of awkwardness when dealing with emotions or humor in their overly logical responses to situations.</p>
            <p><strong>Adapt and Overcome:</strong> Utilize the Manaforged's adaptability in roleplay. Describe how their body adjusts to new environments, such as shifting glowing patterns or reconfiguring components.</p>
            <p><strong>Seek Connection:</strong> Roleplay the Manaforged's desire for companionship or understanding, whether through cautious trust-building or an outright fascination with organic life and its unpredictability.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Manaforged"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Manaforged.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesManaforged; 