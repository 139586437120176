import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Starborn/starborn.png';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesStarborn = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Starborn" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Starborn</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Species
                <ChevronDown size={20} />
              </h2>
              <div className="speciespage-links">
                <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Species              
                </Link>
                <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Gravewalker
                </Link>
                <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Humanity
                </Link>
                <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Lifedrinker
                </Link>
                <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Manaforged
                </Link>
                <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Starborn
                </Link>
                <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Seraphiend
                </Link>
                <Link to="/species/SpeciesScytherai" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Scytherai
                </Link>                            
                <Link to="/species/SpeciesSkinWalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Skinwalker
                </Link>
                <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Sunseed
                </Link>
                <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Vermis Corpus
                </Link>
                <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                  Winter Witch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Starborn are a race of ancient beings from a galaxy that faced total collapse. Descended from elven ancestors, their graceful features and slender physiques belie their immense technological and magical prowess. Their survival depended on a unique resource—a mana-infused nanogel that they mined relentlessly from their galaxy. This nanogel sustained their society but at the cost of depleting their environment and driving their civilization to desperation. As their galaxy's resources dwindled, the Starborn turned to the Shattered Realm to reclaim what they lost, viewing its resources and inhabitants as means to their survival. Infused with the nanogel, their pale, luminous skin, flowing silver hair, and starlit eyes reflect their connection to the cosmos and the arcane. They pursue the retrieval of lost nanogel and other energy sources like the Manaforged and Prototype Alpha—constructs created by their own technology—which they aim to dismantle for their dwindling reserves.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality</h2>
          <p>
            Starborn are contemplative, calculating, and inherently arrogant. Their understanding of the universe's vastness shapes their belief in their own superiority. This arrogance manifests in their interactions with other races, whom they often view as tools, obstacles, or irrelevant beings. While they value knowledge and exploration, their pursuits are rarely altruistic, driven instead by the desire to bolster their own power and survival. Diplomatic in appearance, they are fiercely protective of their heritage and dwindling resources. Their inner sense of urgency and entitlement drives them to take what they believe is theirs, regardless of the cost to others.
          </p>
        </div>

        <div className="content-block">
          <h2>Society</h2>
          <p>
            The Starborn's society, before its collapse, was highly advanced and meritocratic. Individuals were valued for their skills and contributions to the expansion and preservation of their empire. However, with their galaxy dying and new births becoming exceedingly rare, their focus shifted to survival at any cost. They turned to scavenging their own creations, including the Manaforged and Prototype Alpha, for resources, and pursued the leviathan as a potential energy source. This desperation led them to the Shattered Realm at the moment of the Shattering. Caught in the chaos, the Starborn were scattered, their empire reduced to fragmented enclaves. Despite their diminished state, their singular purpose remains: reclaiming their lost technology and securing their place as masters of their destiny.
          </p>
        </div>

        <div className="content-block">
          <h2>Claim to the Moon</h2>
          <p>
            Upon their arrival in the Shattered Realm, the Starborn claimed the largest moon, naming it 'All Father.' This outpost serves as their primary base and a stark reminder of their former glory. From the moon, they orchestrate their operations and maintain their presence across the Shattered Realm. In the early years, their envoys faced hostility from several kingdoms. Insults or attacks against these envoys were met with annihilation. Entire kingdoms were razed, leaving no trace of life or structure. Two immense eyes of white light appeared on 'All Father,' obliterating these defiant realms in beams of celestial fire. This act of retribution cemented the Starborn as figures of fear and hatred. Now, rulers bow to their envoys, aware of the devastating consequences of defiance.
          </p>
        </div>

        <div className="content-block">
          <h2>Physical Description</h2>
          <p>
            Starborn are tall and slender, standing between 5'8" and 6'4", with an elegance that reflects their elven ancestry. Their pale blue, silver, or golden skin emits a subtle glow, more pronounced in darkness, due to the nanogel coursing through their veins. Their hair, often silver, white, or pale blonde, shimmers faintly as though lit from within. Their eyes appear as vibrant pools of blue, violet, or silver, containing swirling nebulae or distant galaxies.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>+2 to one ability score of your choice, and +1 to another ability score of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Starborn reach adulthood around 100 years old and can live over a thousand years. Upon death, their bodies dissipate into stardust, returning to the cosmos.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>Starborn are often lawful neutral or lawful evil, prioritizing order, control, and self-preservation.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium. Starborn typically range from 5'8" to 6'4" in height.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Celestial Resistance</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You have resistance to radiant damage and advantage on saving throws against being charmed.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Starlight Legacy</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You know the Light cantrip.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Techno-Arcane Integration</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You gain proficiency in the Arcana skill. Additionally, gain proficiency with one type of artisan's tools of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>You can speak, read, and write Common, Elvish, and Celestial.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Starborn"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Starborn.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesStarborn; 