import React, { useState, useRef, useEffect } from 'react';
import seraphicGuardianImage from '../../assets/images/subclasses/Seraphic/oathoftheseraphicguardian.png';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesSeraphicGuardian = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={seraphicGuardianImage} 
          alt="Oath of the Seraphic Guardian" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Oath of the Seraphic Guardian</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Paladins who swear the Oath of the Seraphic Guardian dedicate themselves to embodying the essence of Valdís: justice, protection, law, and the strength found in the unity of community. These guardians stand as beacons of hope, defending the innocent with the fervor of their divine mandate.
          </p>
        </div>

        <div className="content-block">
          <h2>Tenets of the Seraphic Guardian</h2>
          <div className="level-indicator">Level 1st</div>
          <p>Justice as a Shield: Prioritize the defense of the innocent and the oppressed. Your might is their shelter.</p>
          <p>Unity in Diversity: Cherish the strength that comes from the harmonious unity of all creatures. Seek to unite, not divide.</p>
          <p>Mercy's Light: Extend mercy to the repentant. True justice is tempered with compassion and the opportunity for redemption.</p>
          <p>Eternal Vigilance: Guard against the darkness that seeks to engulf the world. Your vigilance is the bulwark against despair.</p>
          <p>Beacon of Hope: Shine as a light in the darkest times. Let your deeds inspire courage in the face of overwhelming odds.</p>
        </div>

        <div className="content-block">
          <h2>Oath Spells</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>You gain oath spells at the paladin levels listed:</p>
          <div className="trait">
            <div className="trait-header">
              <h3>3rd Level</h3>
            </div>
            <p>Shield, Command</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>5th Level</h3>
            </div>
            <p>Warding Bond, Zone of Truth</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>9th Level</h3>
            </div>
            <p>Beacon of Hope, Dispel Magic</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>13th Level</h3>
            </div>
            <p>Guardian of Faith, Freedom of Movement</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>17th Level</h3>
            </div>
            <p>Geas, Flame Strike</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Channel Divinity</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>At 3rd level, you gain the following Channel Divinity options:</p>
          
          <h3>Mantle of Celestial Law</h3>
          <p>
            As an action, you can invoke Valdís's divine authority to envelop you and your allies within 10 feet in a Mantle of Celestial Law for 1 minute. This mantle manifests as a golden, shimmering light, providing the following benefits:
          </p>
          <p>Protective Grace: You and your allies within 10 feet gain a bonus to AC equal to half your Charisma modifier (rounded up, minimum of +1).</p>
          <p>Guardian's Retribution: When a hostile creature successfully attacks an ally within the mantle's range with a melee attack, the mantle emits a retaliatory burst of radiant light, inflicting radiant damage to the attacker equal to your Charisma modifier. This effect can trigger once per round.</p>
          <p>Lawful Command: Once during the Mantle's duration, you can issue a divine command that aligns with Valdís's principles of protection, justice, or law, without expending a spell slot. This command affects one creature you can see within 30 feet and must succeed on a Wisdom saving throw or follow the command on its next turn. This effect mirrors the command spell, with the stipulation that the command must reflect Valdís's ideals.</p>

          <h3>Turn the Profane</h3>
          <p>
            You can use your Channel Divinity to speak a prayer censuring fiends and undead, using your holy symbol as a focus. Each fiend or undead that can see or hear you within 30 feet must make a Wisdom saving throw. If the creature fails its saving throw, it is turned for 1 minute or until it takes damage.
          </p>
        </div>

        <div className="content-block">
          <h2>Aura of Seraphic Warding</h2>
          <div className="level-indicator">Level 7th</div>
          <p>
            Beginning at 7th level, you and friendly creatures within 10 feet of you gain resistance to necrotic or radiant damage (your choice upon gaining this feature). At 18th level, the range of this aura increases to 30 feet.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Wings</h2>
          <div className="level-indicator">Level 15th</div>
          <p>
            At 15th level, you permanently gain radiant wings made of metallic golden feathers. You can summon or dismiss these wings as a bonus action. While your wings are active, you have a flying speed equal to your current walking speed.
          </p>
          <p>
            In addition, your wings can be used as melee weapons. As an attack, you can make a melee weapon attack with your wings against a creature within 5 feet. On a hit, the wings deal 1d8 radiant damage and 1d8 necrotic damage, plus your Charisma modifier. These attacks count as magical for the purpose of overcoming resistance and immunity to nonmagical attacks and damage.
          </p>
        </div>

        <div className="content-block">
          <h2>Seraphic Champion</h2>
          <div className="level-indicator">Level 20th</div>
          <p>At 20th level, you permanently assume the form of a Seraphic Guardian, embodying divine justice. While in this form, you gain the following benefits:</p>
          <ul>
            <li>You emit bright light in a 30-foot radius and dim light for an additional 30 feet.</li>
            <li>Once on each of your turns, you can deal extra radiant damage to one target when you hit it with a weapon attack. This extra damage equals 10 + your Charisma modifier.</li>
            <li>You have advantage on all saving throws.</li>
            <li>You gain immunity to being frightened and charmed.</li>
            <li>When a creature within 10 feet of you makes a saving throw, you can use your reaction to grant them advantage on the roll.</li>
          </ul>
          <p>Your presence radiates an unyielding aura of hope and law, marking you as a true avatar of Valdís's will.</p>
        </div>

        <div className="content-block">
          <h2>Roleplaying an Oath of the Seraphic Guardian Paladin</h2>
          <p>
            As a paladin of the Seraphic Guardian, you embody the ideals of justice, unity, and protection. Your every action is driven by a commitment to uphold Valdís's principles, serving as a beacon of hope in a dark world. Your connection to divine justice makes you a symbol of unwavering strength and compassion.
          </p>

          <h3>Personality Traits</h3>
          <ul>
            <li>Inspiring: Your words and actions uplift those around you, instilling courage in the face of fear.</li>
            <li>Resolute: You remain steadfast in your convictions, refusing to waver in the face of adversity.</li>
            <li>Empathetic: You strive to understand the struggles of others, ensuring your justice is fair and compassionate.</li>
            <li>Dutiful: You take your divine mandate seriously, prioritizing the protection of others above personal gain.</li>
          </ul>

          <h3>Ideals</h3>
          <ul>
            <li>Justice: True justice balances law and compassion, ensuring fairness for all.</li>
            <li>Unity: Strength lies in unity, and you seek to bring people together to overcome challenges.</li>
            <li>Hope: In even the darkest times, you believe in the possibility of redemption and renewal.</li>
            <li>Vigilance: Evil thrives in complacency, and you are ever-watchful for its return.</li>
          </ul>

          <h3>Bonds</h3>
          <ul>
            <li>Divine Mandate: Your bond to Valdís drives your every action, and you feel their guidance in your heart.</li>
            <li>Community Protector: You have sworn to defend a specific group, village, or organization.</li>
            <li>Lost Redemption: You seek to atone for a past failure, dedicating your life to protecting others.</li>
            <li>Symbol of Light: You carry a holy relic or artifact tied to Valdís, which serves as your source of strength and inspiration.</li>
          </ul>

          <h3>Flaws</h3>
          <ul>
            <li>Unyielding: Your strict adherence to justice can make you inflexible and dismissive of alternative viewpoints.</li>
            <li>Self-Sacrificing: You often place yourself in harm's way to protect others, even to your own detriment.</li>
            <li>Judgmental: You may struggle to forgive those who have erred, believing in punishment over second chances.</li>
            <li>Burdened: The weight of your responsibility sometimes causes you to doubt your ability to carry on.</li>
          </ul>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={seraphicGuardianImage} 
                alt="Oath of the Seraphic Guardian"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={seraphicGuardianImage}
                download="SeraphicGuardian.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesSeraphicGuardian; 