import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import valdisImage from '../../assets/images/deities/valdis.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ValdisJustice = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={valdisImage} 
          alt="Valdís: Justice Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Valdís: The Path of Justice</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/nebula/mistwalker" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Nebula: Mistwalker
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="race-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Deliver justice tempered with mercy, ensuring the innocent are shielded from harm and the scales of fairness are balanced.</li>
              <li>Balance punishment with understanding, seeking to reform rather than destroy whenever possible.</li>
              <li>Act selflessly as a protector for those in need, sacrificing for the greater good when necessary.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>Offer aid and comfort to those who have suffered injustice, providing them with sanctuary and support.</li>
              <li>Never hesitate to defend the innocent, even if it means putting yourself in harm's way.</li>
              <li>Never lie or deceive, as honesty and transparency are the cornerstones of true justice.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <p>
            Following the Path of Justice grants practitioners the ability to balance the scales of law and compassion. Valdís empowers her followers to protect the innocent, unify the broken, and deliver righteous judgment. Practitioners wield the radiant light of justice, tempered with mercy, to heal and shield allies while delivering divine retribution to the wicked.
          </p>
        </div>

        <div className="content-block">
          <h2>Valdís: The Shield of Justice</h2>
          <p>
            Valdís stands as the essence of protection, sanctuary, self-sacrifice, retribution, and vengeance. Her domain is a raging storm of reflections—all the unresolved and unbalanced wrongs of the world. It is said that the storm will only calm on the day after the world ends, symbolizing her eternal dedication to justice and balance.
            Valdís stands as the ultimate arbiter of fairness and protection. Followers of Valdís see her as the embodiment of law and compassion, teaching that true justice requires both accountability and understanding.
            Legends tell of Valdís forging the Radiant Shield, an artifact said to hold the light of the first sunrise, symbolizing her unyielding commitment to protecting the innocent and punishing the guilty. Her followers strive to emulate her unwavering sense of duty, becoming living embodiments of her divine principles.
          </p>
        </div>

        <div className="content-block">
          <h2>Valdís's Domain</h2>
          <p>
            Valdís's realm is a turbulent expanse where golden lightning streaks across darkened skies, illuminating towering spires of light and shadow. At its heart churns the Eternal Storm, a manifestation of every injustice left unresolved, howling with the echoes of those who seek retribution. The Hall of Judgments stands unwavering amidst the chaos, a sanctum of balance where the weight of every decision is measured and made clear.
            Despite its tempestuous nature, the domain emanates an unshakable resolve, offering solace to those who seek redemption and striking fear into those who evade accountability. It is a place where every act is seen, every deed is weighed, and where Valdís herself wields the power to restore equilibrium to a fractured world.
          </p>
        </div>

        <div className="content-block">
          <h2>Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Radiant Shield</h3>
            </div>
            <p>
              Up to your proficiency bonus times per day, you can summon a shield of golden light that grants an ally the effects of the Sanctuary spell and heals them for 1 hit point at the start of their turn. The shield lasts for 1 round per level or they attack or act in an aggressive manner.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Scales of Judgment</h3>
            </div>
            <p>
              You can choose up to a number of allies equal to your proficiency bonus who are bonded to you until the next long rest. If you are within 30 feet of a bonded ally, you may use your reaction to teleport to their location, taking the damage they would have received. The attacker suffers half of the damage as divine radiant energy, bypassing resistances or immunities.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Casting Judgment</h3>
            </div>
            <p>
              Up to your proficiency bonus times per long rest, you may declare judgment on a creature. While under judgment, you gain a bonus to attack and damage rolls against that creature equal to your proficiency bonus. This effect lasts for a number of minutes equal to your level.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Judgment of Mercy</h3>
            </div>
            <p>
              If you are defending an ally, they receive an injury and you are able to reach their location within a number of rounds equal to your level, you can use a healing hands ability to restore hit points equal to your level + your proficiency bonus. If you then attack in protection of the healed ally, you can add the same amount of damage to your next attack. This ability can be used once per short rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Aura of Unity</h3>
            </div>
            <p>
              Allies within 10 feet of you gain a bonus to saving throws equal to your Charisma modifier. If an ally within this aura would be reduced to 0 hit points, they drop to 1 hit point instead. This ability can trigger an amount equal to your proficiency bonus per long rest and once per ally.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying the Path of Justice</h2>
          <p>
            Followers of Valdís are defined by their unwavering commitment to fairness and protection. They act as shields for the vulnerable and as swords of accountability for the wicked. Valdís' followers understand that true justice is not blind but sees with clarity, balancing accountability with compassion.
            Choosing this path is a commitment to uphold the principles of law, fairness, and community. Practitioners of the Path of Justice embody the radiant light of Valdís, guiding others toward harmony and delivering judgment with both strength and grace.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={valdisImage} 
              alt="Valdís: Justice Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={valdisImage}
              download="Valdis.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ValdisJustice;