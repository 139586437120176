import React from 'react';
import { Link } from 'react-router-dom';
import './Gallery.css';

const Gallery = () => {
  const mainFolders = [
    { path: 'races', name: 'Races' },
    { path: 'deities', name: 'Deities' },
    { path: 'subclasses', name: 'Subclasses' },
    { path: 'NPC', name: 'NPCs' },
    { path: 'bestiary', name: 'Bestiary' }
  ];

  return (
    <div className="gallery-page">
      <div className="gallery-container">
        <h1>Gallery</h1>
        
        <div className="folder-grid">
          {mainFolders.map((folder) => (
            <Link 
              to={`/gallery/${folder.path}`} 
              key={folder.path}
              className="folder-card"
            >
              <div className="folder-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path 
                    d="M20 6h-8l-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <span className="folder-name">{folder.name}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery; 