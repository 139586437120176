import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Layout from './components/Layout';
import Home from './pages/Home';
import History from './pages/History';
import HouseRules from './pages/HouseRules';
import Races from './pages/Races';
import Classes from './pages/Classes';
import Deities from './pages/Deities';
import Subclasses from './pages/Subclasses';
import Gallery from './pages/Gallery';
import GalleryFolder from './pages/GalleryFolder';
import WinterWitch from './pages/races/WinterWitch';
import Gravewalker from './pages/races/Gravewalker';
import Humanity from './pages/races/Humanity';
import Lifedrinker from './pages/races/Lifedrinker';
import Manaforged from './pages/races/Manaforged';
import Seraphiend from './pages/races/Seraphiend';
import Scytherai from './pages/races/Scytherai';
import Starborn from './pages/races/Starborn';
import VermisCorpus from './pages/races/VermisCorpus';
import Sunseed from './pages/races/Sunseed';
import Pyromancer from './pages/classes/Pyromancer';
import BurningSeed from './pages/classes/BurningSeed';
import Dronewright from './pages/classes/Dronewright';
import GlacialHeart from './pages/classes/GlacialHeart';
import SeraphicGuardian from './pages/classes/SeraphicGuardian';
import PathOfWrath from './pages/classes/PathOfWrath';
import SovereignZealot from './pages/classes/SovereignZealot';
import WayOfTheLifedrinker from './pages/classes/WayOfTheLifedrinker';
import WinterbornKnight from './pages/classes/WinterbornKnight';
import Caelumbris from './pages/deities/Caelumbris';
import Calanthar from './pages/deities/Calanthar';
import Libris from './pages/deities/Libris';
import QueenMab from './pages/deities/QueenMab';
import QueenTitania from './pages/deities/QueenTitania';
import Sacrathor from './pages/deities/Sacrathor';
import Firstborn from './pages/deities/Firstborn';
import Leviathan from './pages/deities/Leviathan';
import Valdis from './pages/deities/Valdis';
import Wildscar from './pages/deities/Wildscar';
import './App.css';
import './styles/global.css';
import './pages/races/RacePage.css';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/house-rules" element={<HouseRules />} />
          <Route path="/races" element={<Races />} />
          <Route path="/races/WinterWitch" element={<WinterWitch />} />
          <Route path="/races/Gravewalker" element={<Gravewalker />} />
          <Route path="/races/Humanity" element={<Humanity />} />
          <Route path="/races/Lifedrinker" element={<Lifedrinker />} />
          <Route path="/races/Manaforged" element={<Manaforged />} />
          <Route path="/races/Seraphiend" element={<Seraphiend />} />
          <Route path="/races/Scytherai" element={<Scytherai />} />
          <Route path="/races/Starborn" element={<Starborn />} />
          <Route path="/races/VermisCorpus" element={<VermisCorpus />} />
          <Route path="/races/Sunseed" element={<Sunseed />} />
          <Route path="/deities" element={<Deities />} />
          <Route path="/classes" element={<Classes />} />
          <Route path="/subclasses" element={<Subclasses />} />
          <Route path="/classes/burningseed" element={<BurningSeed />} />
          <Route path="/classes/glacialheart" element={<GlacialHeart />} />
          <Route path="/classes/dronewright" element={<Dronewright />} />
          <Route path="/classes/seraphicguardian" element={<SeraphicGuardian />} />
          <Route path="/classes/pathofwrath" element={<PathOfWrath />} />
          <Route path="/classes/pyromancer" element={<Pyromancer />} />
          <Route path="/classes/sovereignzealot" element={<SovereignZealot />} />
          <Route path="/classes/lifedrinker" element={<WayOfTheLifedrinker />} />
          <Route path="/classes/winterbornknight" element={<WinterbornKnight />} />
          <Route path="/deities/caelumbris" element={<Caelumbris />} />
          <Route path="/deities/calanthar" element={<Calanthar />} />
          <Route path="/deities/libris" element={<Libris />} />
          <Route path="/deities/queenmab" element={<QueenMab />} />
          <Route path="/deities/queen-titania" element={<QueenTitania />} />
          <Route path="/deities/sacrathor" element={<Sacrathor />} />
          <Route path="/deities/firstborn" element={<Firstborn />} />
          <Route path="/deities/leviathan" element={<Leviathan />} />
          <Route path="/deities/valdis" element={<Valdis />} />
          <Route path="/deities/wildscar" element={<Wildscar />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/:folder" element={<GalleryFolder />} />
          <Route path="/gallery/:folder/:subfolder" element={<GalleryFolder />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
}

export default App;
