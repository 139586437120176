import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Scytherai/scytherai.png';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesScytherai = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Scytherai: Echoes of the Hive" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Scytherai: Echoes of the Hive</h1>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            The Scytherai are a haunting paradox: a race of insectoid humanoids born from a collective consciousness, now fractured into a mosaic of individual beings. Once extensions of the Leviathan, a malevolent entity dwelling within the Shadow Dimension, they were severed from the hive mind during a cataclysmic event known as the বিচ্ছিন্নতা (bisshinnota - 'separation'). This abrupt sundering shattered their shared consciousness, casting them adrift in the Shattered Realm as individuals for the first time. Their past, a swirling vortex of fragmented memories, paints a disturbing picture of existence within the hive: a ceaseless, যন্ত্রবত (jantrobot - 'mechanical' or 'robotic') servitude to the Leviathan's will, devoid of individual thought or emotion. These fragmented recollections, more akin to nightmares than memories, fuel a deep-seated internal conflict. Some Scytherai are repulsed by these visions, embracing their newfound individuality as a liberation from a horrific past. Others, however, feel a disturbing pull towards the lost unity of the hive, viewing their current state as incomplete and weak.
          </p>
        </div>

        <div className="content-block">
          <h2>Description</h2>
          <p>
            Scytherai are imposing figures, their bodies a testament to their engineered purpose within the hive.
          </p>
        </div>

        <div className="content-block">
          <h2>Exoskeleton</h2>
          <p>
            A durable, segmented exoskeleton provides natural protection, its color ranging from earthy browns and greens to deep blacks, sometimes with subtle, iridescent sheens.
          </p>
        </div>

        <div className="content-block">
          <h2>Limbs</h2>
          <p>
            Two pairs of arms – a primary humanoid pair for delicate manipulation and a secondary pair, often larger and more powerful, tipped with sharp, blade-like protrusions or specialized tools, depending on their caste.
          </p>
        </div>

        <div className="content-block">
          <h2>Heads</h2>
          <p>
            Insectile heads feature large, multifaceted eyes that grant exceptional vision, sensitive antennae, and powerful mandibles.
          </p>
        </div>

        <div className="content-block">
          <h2>Wings</h2>
          <p>
            All Scytherai possess a set of wings. While typically not large enough to grant true flight in their natural forms, they can be enhanced through magic or specialized training, reflecting the potential within their genetic code.
          </p>
        </div>

        <div className="content-block">
          <h2>A World of Whispers and Fear</h2>
          <p>
            The Scytherai's physical forms, a stark reminder of their otherworldly origins, often evoke fear and mistrust. Their chitinous exoskeletons, multifaceted eyes, and insectoid limbs are viewed with suspicion by most other races. This prejudice, coupled with the Scytherai's own internal struggles, has led many to adopt solitary lives on the fringes of society. The Wall of Death, an imposing barrier erected by powerful magic, stands as a constant, agonizing reminder of their severance from the hive, an uncrossable boundary between their present and the life they vaguely remember.
          </p>
        </div>

        <div className="content-block">
          <h2>Roleplaying a Scytherai</h2>
          <p>
            Playing a Scytherai offers a unique opportunity to explore the complexities of newfound individuality in the face of a haunting past. Consider how the বিচ্ছিন্নতা (bisshinnota) and the fragmented memories of the hive have shaped your character's personality, motivations, and worldview. Are they driven by a desire for connection or independence? Do they embrace their insectoid nature, or do they strive to transcend it? The Scytherai are a race defined by their internal conflicts and their struggle to forge a new destiny in a world that often views them with fear and misunderstanding. They are survivors, forever searching for their place amidst the shattered remnants of a reality they barely comprehend.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase one ability score of your choice by 2, and increase a different ability score of your choice by 1.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Scytherai mature rapidly, reaching adulthood by the age of 5. Their lifespan is relatively short, averaging around 50 years.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>The shock of separation from the hive mind has left many Scytherai struggling to define their own moral compass. Most tend toward neutral alignments as they adapt to their newfound individuality, but exceptions exist in all directions.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium. Scytherai typically stand between 5 and 6 feet tall.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Exoskeletal Armor</h3>
            </div>
            <p>Your chitinous exoskeleton is naturally resilient. When you aren't wearing armor, your AC is 16 + your Dexterity modifier. You can use your natural armor to determine your AC if the armor you wear would leave you with a lower AC. A shield's benefits apply as normal while you use your natural armor.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>You can see in dim light within 120 feet of you as if it were bright light, and in darkness as if it were dim light. You can't discern color in darkness, only shades of gray.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Multifaceted Eyes</h3>
            </div>
            <p>Your compound eyes grant you superior visual acuity. You have proficiency in the Perception skill, and you have advantage on saving throws against being blinded.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can understand and communicate in Scytherai, a complex language of clicks, chirps, and subtle body movements. Due to the unique structure of their mouthparts, Scytherai are physically incapable of speaking other languages, though they can learn to understand them.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Hive Mind Echoes</h3>
            </div>
            <p>You can communicate telepathically with any creature within 60 feet that you can see and that understands at least one language. This telepathy allows you to convey basic messages, emotions, or images, but it is not suitable for complex or nuanced communication. It is a vestige of the hive mind, a faint echo of their former connection. This telepathic communication is often accompanied by faint clicking or chirping sounds audible only to those receiving the message. You also have advantage on saving throws against effects that would read your thoughts or control you telepathically.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Subraces</h2>
          
          <div className="trait">
            <div className="trait-header">
              <h3>Builder Caste</h3>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Artisan's Expertise</h3>
                <span className="level-tag">Level 1st</span>
              </div>
              <p>You gain proficiency with one set of artisan's tools of your choice. At 5th level, you gain expertise with those tools, doubling your proficiency bonus for any ability check you make that uses them.</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Extra Limb Utility</h3>
                <span className="level-tag">Level 1st</span>
              </div>
              <p>You have four arms, allowing you to multitask with ease. You can interact with an additional object or feature of the environment for free during your turn. Additionally, you gain a bonus action on each of your turns that can be used to make an attack with a light melee weapon held in your extra arms, use an object, or perform a skill action that involves your hands.</p>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Soldier Caste</h3>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Enhanced Natural Weaponry</h3>
                <span className="level-tag">Level 1st</span>
              </div>
              <p>Your secondary arms end in sharp, blade-like protrusions that can be used to make melee attacks. These natural weapons deal 1d8 piercing damage on a hit.</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Bonus Attacks</h3>
                <span className="level-tag">Level 1st</span>
              </div>
              <p>You can make a melee attack with one of your extra arms as a bonus action on each of your turns. At 5th level, you can make two such attacks as a bonus action, provided you take the Attack action on that turn.</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Exoskeletal Armor</h3>
                <span className="level-tag">Level 1st</span>
              </div>
              <p>Your chitinous exoskeleton provides you with natural armor. When you are not wearing armor, your AC is 16 + your Dexterity modifier. You cannot benefit from wearing a shield.</p>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Drone Caste</h3>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Advanced Winged Flight</h3>
                <span className="level-tag">Level 1st</span>
              </div>
              <p>You have a pair of thin, insect-like wings that grant you a flying speed of 30 feet. You can hover, and if you are not wearing armor or are only wearing light armor, your flying speed increases to 40 feet.</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Silent Wings</h3>
                <span className="level-tag">Level 1st</span>
              </div>
              <p>Your wings are naturally silent, allowing you to move through the air without drawing unwanted attention. When flying, you make virtually no sound and have advantage on Dexterity (Stealth) checks related to moving silently.</p>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Static Camouflage</h3>
                <span className="level-tag">Level 1st</span>
              </div>
              <p>As an action, you can blend into your surroundings as long as you remain motionless. While using this ability, you become invisible until you move, attack, or cast a spell. This ability lasts for up to 10 minutes. You regain the ability to use this feature after you finish a long rest.</p>
            </div>
          </div>
        </div>

        <div className="menu-container">
          <div ref={menuRef} className={`speciespage-menu ${menuOpen ? 'active' : ''}`}>
            <h2 onClick={toggleMenu}>
              Species
              <ChevronDown size={20} />
            </h2>
            <div className="speciespage-links">
              <Link to="/species" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                ← Return to Species              
              </Link>
              <Link to="/species/SpeciesGravewalker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Gravewalker
              </Link>
              <Link to="/species/SpeciesHumanity" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Humanity
              </Link>
              <Link to="/species/SpeciesLifedrinker" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Lifedrinker
              </Link>
              <Link to="/species/SpeciesManaforged" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Manaforged
              </Link>
              <Link to="/species/SpeciesSeraphiend" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Seraphiend
              </Link>
              <Link to="/species/SpeciesStarborn" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Starborn
              </Link>
              <Link to="/species/SpeciesVermisCorpus" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Vermis Corpus
              </Link>
              <Link to="/species/SpeciesSunseed" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Sunseed
              </Link>
              <Link to="/species/SpeciesWinterWitch" className="speciespage-link" onClick={() => setMenuOpen(false)}>
                Winter Witch
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Scytherai: Echoes of the Hive"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Scytherai.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesScytherai; 