import React, { useState, useRef, useEffect } from 'react';
import seraphicGuardianImage from '../../assets/images/subclasses/Seraphic/oathoftheseraphicguardian.png';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesSeraphicGuardian = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={seraphicGuardianImage} 
          alt="Oath of the Seraphic Guardian" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Oath of the Seraphic Guardian</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Paladins who swear the Oath of the Seraphic Guardian dedicate themselves to embodying the essence of Valdís: justice, protection, law, and the strength found in the unity of community. These guardians are known for their unshakable honesty, refusing to lie or engage in deceit even under the gravest circumstances. Their commitment to truth is absolute, viewing dishonesty as a form of corruption that weakens the foundations of a just society.
          </p>
          <p>
            Each Seraphic Guardian lives as a beacon of Valdís's ideals, holding themselves to the highest standard of morality and transparency. They believe that truth fosters trust, and trust is the cornerstone of unity and peace. This strict adherence often leads them to serve as mediators in disputes, investigators uncovering hidden corruption, or advisors to those in power. Their counsel, free from falsehood, is valued and respected across the realms.
          </p>
          <p>
            The bond between a Seraphic Guardian and Valdís is deeply spiritual. They feel her divine presence as a source of guidance and fortitude, inspiring them to confront even the most daunting challenges. This connection not only empowers them to resist temptation but also bolsters their resolve to protect the vulnerable and confront injustice wherever it festers.
          </p>
          <p>
            Through their actions, Seraphic Guardians illuminate the path of integrity and justice. Their oaths are not just promises but the very core of their existence, shaping their every decision. Whether standing against tyrants, exposing deceit, or providing solace to the oppressed, these paladins embody the principle that unwavering truth is the greatest weapon against the shadows of the world.
          </p>
        </div>

        <div className="content-block">
          <h2>Tenets of the Seraphic Guardian</h2>
          <div className="level-indicator">Level 1st</div>
          <p>Paladins who swear this oath must adhere to the following tenets:</p>
          <p>Justice as a Shield: Defend the innocent and the oppressed above all. Your strength exists to protect those who cannot protect themselves.</p>
          <p>Unity in Diversity: Seek to unify all creatures under the principles of justice and cooperation. Strive to eliminate divisions caused by prejudice or hatred.</p>
          <p>Mercy's Light: Grant mercy to those who seek redemption. Justice demands compassion, offering the penitent a path to amend their wrongs.</p>
          <p>Eternal Vigilance: Stand unwavering against the forces of darkness. Always be prepared to confront corruption and despair.</p>
          <p>Beacon of Hope: Act as a source of inspiration and courage. Your actions should inspire others to fight for what is just, even in the darkest times.</p>
          <p>Truth Above All: Never lie or engage in deceit. Speak with clarity and honesty, for your words must reflect the ideals you uphold.</p>
        </div>

        <div className="content-block">
          <h2>Oath Spells</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>You gain oath spells at the paladin levels listed:</p>
          <div className="trait">
            <div className="trait-header">
              <h3>3rd Level</h3>
            </div>
            <p>Shield, Command</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>5th Level</h3>
            </div>
            <p>Warding Bond, Zone of Truth</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>9th Level</h3>
            </div>
            <p>Beacon of Hope, Dispel Magic</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>13th Level</h3>
            </div>
            <p>Guardian of Faith, Freedom of Movement</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>17th Level</h3>
            </div>
            <p>Geas, Flame Strike</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Channel Divinity</h2>
          <div className="level-indicator">Level 3rd</div>
          <p>At 3rd level, you gain the following Channel Divinity options:</p>
          
          <h3>Mantle of Celestial Law</h3>
          <p>
            As an action, you can invoke Valdís's divine authority to envelop you and your allies within 10 feet in a Mantle of Celestial Law for 1 minute. This mantle manifests as a golden, shimmering light, providing the following benefits:
          </p>
          <p>Protective Grace: You and your allies within 10 feet gain a bonus to AC equal to half your Charisma modifier (rounded up, minimum of +1).</p>
          <p>Guardian's Retribution: When a hostile creature successfully attacks an ally within the mantle's range with a melee attack, the mantle emits a retaliatory burst of radiant light, inflicting radiant damage to the attacker equal to your Charisma modifier. This effect can trigger once per round.</p>
          <p>Lawful Command: Once during the Mantle's duration, you can issue a divine command that aligns with Valdís's principles of protection, justice, or law, without expending a spell slot. This command affects one creature you can see within 30 feet and must succeed on a Wisdom saving throw or follow the command on its next turn. This effect mirrors the command spell, with the stipulation that the command must reflect Valdís's ideals.</p>

          <h3>Turn the Profane</h3>
          <p>
            You can use your Channel Divinity to speak a prayer censuring fiends and undead. Each fiend or undead within 30 feet that can hear you must make a Wisdom saving throw. On a failure, it is turned for 1 minute or until it takes damage.
          </p>
        </div>

        <div className="content-block">
          <h2>Aura of Seraphic Warding</h2>
          <div className="level-indicator">Level 7th</div>
          <p>
            You and friendly creatures within 10 feet of you gain resistance to necrotic and radiant damage. Additionally, whenever an ally within the aura is reduced to 0 hit points but not killed outright, they are instead reduced to 1 hit point. This effect can trigger once per creature per long rest. At 18th level, the range of this aura increases to 30 feet.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Wings</h2>
          <div className="level-indicator">Level 15th</div>
          <p>
            You permanently gain radiant wings made of metallic golden feathers. You can summon or dismiss these wings as a bonus action. While your wings are active, you have a flying speed equal to your current walking speed.
          </p>
          <p>
            In addition, your wings can be used as melee weapons. As an attack, you can make a melee weapon attack with your wings against a creature within 5 feet. On a hit, the wings deal 1d8 radiant damage and 1d8 necrotic damage, plus your Charisma modifier. These attacks count as magical for the purpose of overcoming resistance and immunity to nonmagical attacks and damage.
          </p>
        </div>

        <div className="content-block">
          <h2>Seraphic Champion</h2>
          <div className="level-indicator">Level 20th</div>
          <p>You can use your action to assume the form of a Seraphic Guardian for 1 minute. While in this form, you gain the following benefits:</p>
          <ul>
            <li>You emit bright light in a 30-foot radius and dim light for an additional 30 feet.</li>
            <li>Once on each of your turns, you can deal extra radiant damage to one target when you hit it with a weapon attack. This extra damage equals 10 + your Charisma modifier.</li>
            <li>You have advantage on all saving throws.</li>
            <li>You can use this transformation once per long rest.</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Roleplaying a Seraphic Guardian Paladin</h2>
          <p>
            As a Seraphic Guardian paladin, you embody the ideals of justice, protection, law, and community. You are a beacon of hope, a defender of the innocent, and a champion of the oppressed. Your actions are guided by your unwavering faith in Valdís and your commitment to upholding her sacred tenets.
          </p>

          <h3>Personality Traits</h3>
          <ul>
            <li>Altruistic: You prioritize the needs of others, putting their well-being before your own.</li>
            <li>Loyal: You are fiercely loyal to your allies and your cause, standing by them through thick and thin.</li>
            <li>Disciplined: You adhere to a strict code of conduct, always striving to do what is right.</li>
            <li>Compassionate: You show mercy to the repentant and offer redemption to those who seek it.</li>
          </ul>

          <h3>Ideals</h3>
          <ul>
            <li>Justice: Fairness and equality guide your actions.</li>
            <li>Protection: You dedicate yourself to safeguarding the innocent.</li>
            <li>Community: Unity is strength; you work to foster cooperation and understanding.</li>
            <li>Law: Order and stability are the foundation of a just society.</li>
          </ul>

          <h3>Bonds</h3>
          <ul>
            <li>Divine Mandate: You feel a strong sense of duty to fulfill Valdís's will.</li>
            <li>Sacred Oath: You are bound by your sacred vows to protect the innocent and uphold justice.</li>
            <li>Fellow Paladins: You share a unique camaraderie with other paladins.</li>
            <li>Community: You work tirelessly to protect the communities you serve.</li>
          </ul>

          <h3>Flaws</h3>
          <ul>
            <li>Righteous Fury: Your dedication to justice can lead to impulsive actions.</li>
            <li>Unwavering Conviction: Your rigidity can hinder compromise or adaptability.</li>
            <li>Overprotective: Your desire to protect others can stifle their independence.</li>
            <li>Self-Sacrificing: You risk your own well-being to protect others, sometimes recklessly.</li>
          </ul>

          <h3>Equipment</h3>
          <p>As a Seraphic Guardian paladin, your equipment reflects your divine purpose:</p>
          <ul>
            <li>A holy symbol of Valdís, often crafted from gold or silver.</li>
            <li>Fine clothes or armor representing your status as a champion of justice.</li>
            <li>Tools such as a healing kit, a book of laws, or sacred texts.</li>
          </ul>

          <h3>Multiclassing Options</h3>
          <p>To enhance your capabilities as a Seraphic Guardian, consider multiclassing with:</p>
          <ul>
            <li>Cleric (Life Domain): Enhance healing and protective abilities.</li>
            <li>Warlock (Celestial Patron): Amplify radiant damage and gain celestial spells.</li>
            <li>Bard (College of Valor): Inspire allies and control the battlefield.</li>
            <li>Fighter (Battle Master): Gain superior tactical options for combat.</li>
          </ul>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={seraphicGuardianImage} 
                alt="Oath of the Seraphic Guardian"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={seraphicGuardianImage}
                download="SeraphicGuardian.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesSeraphicGuardian; 