import React from 'react';
import { motion } from 'framer-motion';
import sunseedImage from '../../assets/images/races/Sunseed/sunseed.jpg';
import sunseedData from '../../assets/data/sunseed.json';
import './RacePage.css';

const Sunseed = () => {
  return (
    <div className="race-page">
      <div className="race-hero">
        <img src={sunseedImage} alt="Sunseed" className="race-background" />
        <div className="race-content">
          <h1>{sunseedData.title}</h1>
          <p className="race-brief">{sunseedData.brief}</p>
        </div>
      </div>

      <div className="race-details">
        {sunseedData.content.map((section, index) => (
          <div key={index} className="content-section">
            <h2>{section.title}</h2>
            <p>{section.description}</p>
          </div>
        ))}

        <div className="features-section">
          {sunseedData.features.map((feature, index) => (
            <div key={index} className="feature-group">
              {feature.traits && feature.traits.map((trait, idx) => (
                <div key={idx} className="trait">
                  <div className="trait-header">
                    <h3>{trait.title}</h3>
                    {trait.level && <span className="level">Level {trait.level}</span>}
                  </div>
                  <p>{trait.description}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sunseed; 