import React from 'react';
import { motion } from 'framer-motion';
import burningseedImage from '../../assets/images/subclasses/Burning/circleoftheburningseed.png';
import burningseedData from '../../assets/data/burningseed.json';
import '../races/RacePage.css';

const BurningSeed = () => {
  return (
    <div className="race-page">
      <div className="race-hero">
        <img src={burningseedImage} alt="Circle of the Burning Seed" className="race-background" />
        <div className="race-content">
          <h1>{burningseedData.title}</h1>
        </div>
      </div>

      <div className="race-details">
        <div className="content-section">
          <p>{burningseedData.content}</p>
        </div>

        <div className="features-section">
          {burningseedData.features.map((feature, index) => (
            <div key={index} className="feature-group">
              <div className="trait">
                <div className="trait-header">
                  <h3>{feature.title}</h3>
                  {feature.level && <span className="level">Level {feature.level}</span>}
                </div>
                <p>{feature.description}</p>
                {feature.spells && (
                  <div className="spell-list">
                    {feature.spells.map((levelSpells, idx) => (
                      <div key={idx} className="spell-level">
                        <span className="level-number">{levelSpells.level}:</span> {levelSpells.spells.join(", ")}
                      </div>
                    ))}
                  </div>
                )}
                {feature.additional && <p className="additional">{feature.additional}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BurningSeed;