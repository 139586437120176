import React, { useState, useEffect, useRef } from 'react';
import queenTitaniaImage from '../../assets/images/deities/queentitania.png';
import './PantheonPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const PantheonQueenTitania = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img
          src={queenTitaniaImage}
          alt="Queen Titania: The Radiant Sovereign of Summer"
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
        />
        <div className="pantheon-title">
          <h1>Queen Titania: The Radiant Sovereign of Summer</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Pantheon
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/Pantheon" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Pantheon
                </Link>
                <Link to="/deities/PantheonCaelumbris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris
                </Link>
                <Link to="/deities/PantheonCalanthar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Calanthar
                </Link>
                <Link to="/deities/PantheonFirstborn" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Firstborn
                </Link>
                <Link to="/deities/PantheonLibris" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.
                </Link>
                <Link to="/deities/PantheonNebula" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Nebula
                </Link>
                <Link to="/deities/PantheonQueenMab" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab
                </Link>
                <Link to="/deities/PantheonSacrathor" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor
                </Link>
                <Link to="/deities/PantheonValdis" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Valdís
                </Link>
                <Link to="/deities/PantheonWildscar" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Wildscar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            Queen Titania embodies the radiance and vitality of summer, her presence evoking warmth and flourishing life. Her dominion is a realm of perpetual sunlight, where gardens bloom with otherworldly vibrancy and flowers glow softly as if kissed by the sun itself. The air hums with the mingling scents of blooming flora, the drone of bees, and the rustle of leaves, creating an immersive tapestry that invigorates the spirits of all who enter. The Seelie Court, which she presides over, is a sanctuary of unbridled artistic splendor and vibrant energy.
          </p>
        </div>

        <div className="content-block">
          <h2>Description</h2>
          <p>
            Titania represents summer in its most unbound form—a relentless force of creativity and abundant growth. Her beauty inspires awe, while her charisma draws followers to her court, compelling artists to craft their finest works and leaders to seek her wisdom in pivotal moments. Under her influence, bards compose enduring melodies, and architects design grand tributes that celebrate her radiance. However, her untamed nature mirrors the duality of summer's warmth: nurturing yet overwhelming when unchecked. Her reign reflects the delicate balance between flourishing vitality and chaotic overgrowth.
          </p>
        </div>

        <div className="content-block">
          <h2>Origins and Mythos</h2>
          <p>
            Titania's tale is one of luminous creation and boundless growth. Revered as a beacon of life and renewal, her influence has long shaped the realms of summer. Her actions inspire beauty and innovation, fostering an unyielding drive to create and flourish. Though her ambitions occasionally push the boundaries of natural order, they are celebrated as the essence of her vibrant and dynamic spirit.
          </p>
        </div>

        <div className="content-block">
          <h2>Influence and Teachings</h2>
          <p>
            Titania's teachings celebrate the cycles of growth and renewal, encouraging her followers to embrace the joys of life, creativity, and passion. She advocates for unrestrained creation, fostering incredible beauty and innovation but also risking the chaos of unchecked growth. Followers are taught to pursue their passions with abandon, often walking a fine line between flourishing life and destructive excess. This excess may manifest in overambition, resource depletion, or creations so overwhelming they destabilize the balance of the natural world. Her influence is both a gift and a challenge, inviting her followers to explore their potential while navigating the unpredictability of unrestrained energy. Titania's philosophy emphasizes the importance of creativity tempered by awareness of its consequences.
          </p>
        </div>

        <div className="content-block">
          <h2>Worship and Clergy</h2>
          <p>
            The worship of Titania is an exuberant expression of life's vibrancy. Her rituals are joyous celebrations filled with music, dance, and the vibrant colors of summer. Her clergy, clad in radiant gold and green robes, act as custodians of her teachings and guides to her followers. They celebrate life's flourishing beauty and encourage creative endeavors, though they often wrestle with channeling Titania's wild energies into constructive paths. Through their devotion, they seek to honor her vitality while mitigating the potential chaos of unbound growth.
          </p>
        </div>

        <div className="content-block">
          <h2>Role in the Shattered Realm</h2>
          <p>
            Titania's chaotic energy has made her a force of unbridled creation within the Shattered Realm, driving waves of wild growth that ripple across adjacent domains. These expansions challenge neighboring territories, forcing them to adapt to sudden influxes of life or risk being overwhelmed by relentless flora and energy. Her influence reshapes the Shattered Realm, creating new opportunities for life to flourish but often disrupting established harmony. While Titania's power inspires and uplifts, it also pushes the boundaries of balance, reshaping ecosystems in unpredictable ways. Her actions underscore her role as a catalyst for transformation—both celebrated and feared for the changes she brings to the natural order.
          </p>
        </div>

        <div className="content-block">
          <h2>Powers and Abilities</h2>
          <p>
            Titania wields the forces of light and growth with unparalleled mastery. She can inspire rapid flourishing of flora, imbue her followers with extraordinary charisma, and harness the sun's energy as both a nurturing warmth and a consuming blaze. Her beauty and charm are tools of persuasion and inspiration, driving artistic and creative achievements that ripple through the Seelie Court and the Shattered Realm. Her presence shapes her court into a haven of artistry and passion, while her influence extends beyond her borders, inspiring awe and action among those who seek her favor. Titania's abilities exemplify the boundless potential of creation—a source of beauty and transformation that defines her role as the Radiant Sovereign of Summer.
          </p>
        </div>

        <div className="content-block">
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>Empress of the Seelie Court, The Sovereign of Summer's Wild Glory</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Light, Growth, Passion, Art</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>Seelie Court</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A sunburst entwined with blooming flora</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={queenTitaniaImage} 
                alt="Queen Titania: The Radiant Sovereign of Summer"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={queenTitaniaImage}
                download="QueenTitania.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonQueenTitania; 