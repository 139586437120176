import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import titaniamImage from '../../assets/images/deities/queentitania.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const QueenTitaniaWildgrowth = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={titaniamImage} 
          alt="Queen Titania: Wildgrowth Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Queen Titania: The Wildgrowth Path</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="race-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Embrace the boundless energy of life, recognizing that growth and change are essential, even if they bring chaos.</li>
              <li>Use nature's power to defend the weak and reclaim corrupted or blighted lands.</li>
              <li>Channel the energy of summer in both creation and destruction, recognizing the cyclical nature of life and renewal.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>Followers must nurture and channel wild growth, embracing unrestrained passion or creation that mirrors the untamed energy of summer.</li>
              <li>They may not touch ice, use cold spells, or harness the energy of winter in any form.</li>
              <li>Express your emotions freely and authentically, allowing your passions to guide your actions.</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <p>
            Following the Wildgrowth Path of Queen Titania imbues practitioners with the raw, untamed energy of nature at its peak. They embody the dynamic power of growth and the searing intensity of summer, channeling Titania's vitality to create and destroy in equal measure.
          </p>
        </div>

        <div className="content-block">
          <h2>Queen Titania: The Summer Queen</h2>
          <p>
            Queen Titania reigns as a deity of unrelenting growth and passionate energy. Her domain is an endless expanse of vibrant meadows, ancient forests, and sun-drenched hills, teeming with life and brimming with untamed magic. Where Titania's power touches, nature thrives—sometimes to the brink of chaos.
            Titania's rule radiates boundless energy and untamed love, a reflection of nature's vibrant force at its peak. Her followers see her as the embodiment of creation's joy tempered by nature's destructive cycles. Her court is a kaleidoscope of endless celebration and flourishing beauty, where wild dances and blazing bursts of magic echo her exuberant reign. Through her, practitioners learn to embrace the raw, transformative energy of summer.
          </p>
        </div>

        <div className="content-block">
          <h2>Queen Titania's Domain</h2>
          <p>
            Titania's domain is a realm of boundless vitality and radiant splendor. Vast fields of wildflowers stretch as far as the eye can see, their vivid colors shimmering like liquid sunlight. Towering trees form sprawling forests where the air hums with life, and streams of golden water twist and turn through fertile valleys.
            The sun never sets in Titania's realm, its golden light bathing the land in eternal warmth. Beneath this unyielding sun, life flourishes in exuberant abundance. Yet, the wild energy that drives growth also courts chaos. Unchecked vines choke the tallest oaks, and fields erupt in spontaneous bursts of flame. This is the paradox of Titania's domain: a place of breathtaking beauty where nature's passion teeters on the edge of destruction.
          </p>
        </div>

        <div className="content-block">
          <h2>Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Chaotic Bloom</h3>
            </div>
            <p>
              Plant life grows constantly around you, creating an aura of vitality and chaos. Plants grow by 1 foot per level in the area surrounding you, mimicking the effects of the Plant Growth spell. Additionally, your connection to this untamed energy grants you a +1 bonus to your Charisma score.
            </p>
            <p>
              As an action, you unleash a burst of wild, chaotic energy in a 30-foot radius. All creatures in the area must roll on the Summer Fey Chaos Table to determine the effect they experience. Each creature must also make a Dexterity saving throw (DC = 8 + your Charisma modifier + your proficiency bonus). On a failed save, they take 1d4 fire damage. You can use this ability a number of times equal to your proficiency bonus per long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Nature's Rebirth</h3>
            </div>
            <p>
              When reduced to 0 hit points, you explode in a burst of chaotic energy. All creatures within 20 feet take fire damage equal to twice your Charisma modifier. You are immediately restored to 1 hit point and can move up to 15 feet without provoking opportunity attacks. This ability can be used once per long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Summer Fey Chaos Table (1d10)</h3>
            </div>
            <ul className="specializations-list">
              {/* List all the effects here */}
            </ul>
          </div>
        </div>

        <div className="content-block">
          <h2>Roleplaying the Path of Summer</h2>
          <p>
            Followers of Queen Titania are defined by their untamed passion and boundless energy. They thrive in chaos, wielding the vibrant intensity of summer to reshape the world around them. As living extensions of Titania's will, they revel in both creation and destruction, finding beauty in the wild, unrestrained power of nature.
            Choosing this path is a commitment to embrace life in its fullest, wildest form. Practitioners of the Summer Path embody the warmth and ferocity of the sun, spreading Titania's chaotic bloom wherever they go and leaving trails of radiant growth and fiery renewal in their wake.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={titaniamImage} 
              alt="Queen Titania: Wildgrowth Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={titaniamImage}
              download="Titania.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueenTitaniaWildgrowth;