import React, { useState, useRef, useEffect } from 'react';
import sovereignZealotImage from '../../assets/images/subclasses/Sovereign/sovereignzealot.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesSovereignZealot = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={sovereignZealotImage} 
          alt="Sovereign Zealot" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Sovereign Zealot</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <div className="requirement">Requirement: Must be the Humanity race</div>
          <p>
            Sovereign Zealots are the unwavering vanguards of Humanity's resurgence, skilled in the arts of warfare and persuasion. Clad in heavy armor and wielding martial weapons, they stand as bulwarks against the chaos of the world of Velum. Their mastery of rhetoric and unwavering faith fuel their drive to elevate humanity above all others, securing their dominion over the realm.
          </p>
          <p>
            In the unforgiving realm of Velum, where the very existence of humanity teeters on the precipice of oblivion, the Sovereign Zealots emerge as unwavering beacons of hope. These are not mere warriors, but paragons of unwavering dedication to humanity's resurgence, their resolve as unyielding as the armor they wear. Masters of both warfare and diplomacy, they wield not only martial weapons but also the potent force of rhetoric, inspiring allies and confounding adversaries with their eloquence.
          </p>
          <p>
            Clad in heavy armor and wielding an array of martial weapons, they stand as living bulwarks against the encroaching chaos, their presence on the battlefield a source of both courage and terror. Their unwavering faith in humanity's potential fuels their every action, a relentless pursuit of dominion driven by the conviction that humanity's ascension is not only inevitable but necessary for the well-being of Velum.
          </p>
          <p>
            The Sovereign Zealots are more than mere warriors; they are the guardians of humanity's legacy, the vanguards of its future. With unwavering zeal, they strive to secure humanity's supremacy, to etch its accomplishments into the annals of history, and to ensure that its flame never flickers in the face of adversity. They are the champions of humanity's supremacy, the zealous guardians of its legacy, and the unwavering vanguards of its inevitable resurgence.
          </p>
        </div>

        <div className="content-block">
          <h2>Domain Spells</h2>
          <div className="level-indicator">Level 1st</div>
          <p>You gain domain spells at the cleric levels listed in the Sovereign Zealot Domain Spells table. See the Divine Domain class feature for how domain spells work.</p>
          <div className="trait">
            <div className="trait-header">
              <h3>1st Level</h3>
            </div>
            <p>Command, Heroism</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>3rd Level</h3>
            </div>
            <p>Enthrall, Branding Smite</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>5th Level</h3>
            </div>
            <p>Crusader's Mantle, Fear</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>7th Level</h3>
            </div>
            <p>Compulsion, Dominate Beast</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>9th Level</h3>
            </div>
            <p>Dominate Person, Geas</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Channel Divinity: Inspiring Conviction</h2>
          <div className="level-indicator">Level 2nd</div>
          <p>
            As an action, you can channel divine energy to bolster your words and ignite the flames of human exceptionalism. For the next 10 minutes, you gain advantage on all Charisma (Persuasion) and Charisma (Deception) checks when interacting with others to promote human unity, inspire loyalty, and strengthen their resolve to reclaim humanity's rightful place as the Shattered Realm's leaders.
          </p>
        </div>

        <div className="content-block">
          <h2>Tactical Precision</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            Your combat training and unwavering focus grant you the ability to exploit enemy vulnerabilities with deadly accuracy. You gain the Sneak Attack feature, as a Rogue. You deal an extra 1d6 damage to a creature you hit with an attack if you have advantage on the attack roll. The attack must use a finesse or ranged weapon. The extra damage increases by 1d6 at 10th and 14th level.
          </p>
        </div>

        <div className="content-block">
          <h2>Deflecting Maneuver</h2>
          <div className="level-indicator">Level 8th</div>
          <p>
            Your battlefield experience and understanding of combat tactics allow you to deftly avoid harm. When you are targeted by an attack, you can use your reaction to impose disadvantage on the attack roll. You can use this feature a number of times equal to your Wisdom modifier (minimum of once), and you regain all expended uses after a long rest.
          </p>
        </div>

        <div className="content-block">
          <h2>Paragon of Humanity</h2>
          <div className="level-indicator">Level 17th</div>
          <p>At 17th level, your commitment to humanity's cause elevates you to a living legend. You gain the following benefits:</p>
          <p>Humanity's Legacy: Your physical form embodies the ideals of human dominance. Your skin takes on a golden hue, and you grow a pair of pure white wings, granting you a flying speed of 30 feet.</p>
          <p>Voice of Authority: Your words carry the weight of human history and the promise of a glorious future. You gain advantage on Charisma (Persuasion), Charisma (Intimidation), and Charisma (Deception) checks.</p>
          <p>Unbreakable Will: Your mind is a fortress. You gain resistance to psychic damage and advantage on saving throws against being charmed or frightened.</p>
          <p>Dominion Mandate: You can issue a command that compels obedience a number of times equal to your proficiency bonus. Any creature that hears your command must make a Wisdom saving throw against your Cleric spell save DC (8 + your proficiency bonus + your Wisdom modifier) or follow the command for the next minute, provided the command is not self-harmful.</p>
        </div>

        <div className="content-block">
          <h2>Roleplaying a Sovereign Zealot</h2>
          <p>
            As a Sovereign Zealot, you are a beacon of humanity's resurgence, a champion of their supremacy, and a skilled warrior and orator. Your unwavering faith in humanity's potential and your dedication to Sacrator's teachings drive you to lead and inspire, to forge a new era where humanity stands above all others.
          </p>

          <h3>Personality Traits</h3>
          <ul>
            <li>Disciplined: You embody the order and structure that Sacrator demands, adhering to a strict code of conduct and expecting the same from others.</li>
            <li>Zealous: Your belief in humanity's superiority is absolute, and you preach their divine right to rule with unwavering conviction.</li>
            <li>Charismatic: You inspire others with your words and actions, rallying them to your cause and forging unity through your commanding presence.</li>
            <li>Ambitious: You strive for greatness, both for yourself and for humanity, seeking to elevate their status and secure their dominion over the realm.</li>
          </ul>

          <h3>Ideals</h3>
          <ul>
            <li>Order: You believe in the importance of structure and hierarchy, seeing them as the foundations for a prosperous and unified society.</li>
            <li>Dominion: Humanity is the pinnacle of creation, destined to rule over all others.</li>
            <li>Progress: Technological advancement is the key to humanity's future, and you embrace innovation as a means to achieve their goals.</li>
            <li>Purity: You uphold the sanctity of humanity's bloodline, seeing their blue blood as a mark of their divine right to rule.</li>
          </ul>

          <h3>Bonds</h3>
          <ul>
            <li>Sacerdotian Devotion: You are deeply devoted to Sacrator, believing in his divine mandate and striving to fulfill his will.</li>
            <li>Humanity's Advocate: You see yourself as a champion of humanity, fighting for their rights and ensuring their dominance over the realm.</li>
            <li>Technological Legacy: You are proud of humanity's technological achievements and see them as a testament to their superiority.</li>
            <li>Community Leader: You inspire and guide your community, working to ensure their prosperity and unity.</li>
          </ul>

          <h3>Flaws</h3>
          <ul>
            <li>Fanaticism: Your unwavering belief in humanity's supremacy can blind you to their flaws and lead you to justify their actions, even when morally questionable.</li>
            <li>Intolerance: You have little patience for those who oppose humanity's rule or challenge their authority.</li>
            <li>Arrogance: Your belief in humanity's superiority can make you dismissive or condescending towards other races.</li>
            <li>Ruthlessness: You are willing to use any means necessary to achieve your goals, even if it means resorting to violence or manipulation.</li>
          </ul>

          <h3>Equipment</h3>
          <p>As a Sovereign Zealot, your equipment reflects your role as a warrior and leader. You might carry:</p>
          <ul>
            <li>A holy symbol of Sacrator, crafted from gold or platinum and adorned with symbols of humanity's power.</li>
            <li>A set of masterfully crafted armor, symbolizing your status as a champion of humanity.</li>
            <li>A backpack containing a bedroll, a mess kit, a set of tools for repairing armor and weapons, and a collection of Sacrator's teachings.</li>
            <li>A belt pouch containing 20 gold pieces.</li>
            <li>A banner or flag bearing the symbol of Sacrator and the colors of humanity, used to rally your allies and inspire them to fight for their cause.</li>
          </ul>

          <h3>Multiclassing</h3>
          <p>While humans cannot cast spells or use magical items, they can still multiclass with other classes to create unique and powerful characters. Here are a few multiclassing options that might be suitable for a Sovereign Zealot, keeping in mind the restriction on magic use:</p>
          <ul>
            <li>Fighter: A multiclass with Fighter allows you to further enhance your martial prowess and combat versatility. The Battle Master archetype, for example, grants you access to combat maneuvers that can disrupt and control your enemies, while the Champion archetype focuses on raw power and resilience.</li>
            <li>Rogue: While some Rogue subclasses rely on magic, others, like the Assassin or Thief, focus on stealth, agility, and cunning. These subclasses can complement the Sovereign Zealot's martial abilities, allowing you to strike from the shadows or infiltrate enemy strongholds with ease.</li>
            <li>Ranger: The Ranger class offers a blend of martial prowess and wilderness expertise. Subclasses like the Hunter or Beast Master can enhance your combat abilities and provide you with a loyal animal companion, further solidifying your role as a leader and protector of humanity.</li>
          </ul>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={sovereignZealotImage} 
                alt="Sovereign Zealot"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={sovereignZealotImage}
                download="SovereignZealot.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesSovereignZealot; 