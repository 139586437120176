// src/pages/Deities.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Crown } from 'lucide-react';
import './Deities.css';

const Deities = () => {
  return (
    <div className="welcome-page" style={{ background: '#0a0a0a' }}>
      <motion.div 
        className="welcome-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Crown size={48} className="welcome-icon" style={{ color: '#4a9eff', marginBottom: '1rem' }} />
        <h1>Whispers of Divine Power</h1>
        <p className="welcome-subtitle">
          Choose your divine patron
        </p>
      </motion.div>
    </div>
  );
};

export default Deities;