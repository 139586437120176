import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Layout from './components/Layout';
import Home from './pages/Home';
import History from './pages/History';
import HouseRules from './pages/HouseRules';
import Classes from './pages/Classes';
import Species from './pages/species';
import Gallery from './pages/Gallery';
import GalleryFolder from './pages/GalleryFolder';
import WinterWitch from './pages/species/SpeciesWinterWitch';
import Gravewalker from './pages/species/SpeciesGravewalker';
import Humanity from './pages/species/SpeciesHumanity';
import Lifedrinker from './pages/species/SpeciesLifedrinker';
import Manaforged from './pages/species/SpeciesManaforged';
import Seraphiend from './pages/species/SpeciesSeraphiend';
import Scytherai from './pages/species/SpeciesScytherai';
import Starborn from './pages/species/SpeciesStarborn';
import VermisCorpus from './pages/species/SpeciesVermisCorpus';
import Sunseed from './pages/species/SpeciesSunseed';
import Skinwalker from './pages/species/SpeciesSkinWalker';
import Pyromancer from './pages/classes/ClassesPyromancer';
import BurningSeed from './pages/classes/ClassesBurningSeed';
import Dronewright from './pages/classes/ClassesDronewright';
import GlacialHeart from './pages/classes/ClassesGlacialHeart';
import SeraphicGuardian from './pages/classes/ClassesSeraphicGuardian';
import PathOfWrath from './pages/classes/ClassesPathOfWrath';
import SovereignZealot from './pages/classes/ClassesSovereignZealot';
import UmbralInfiltrator from './pages/classes/ClassesUmbralInfiltrator';
import WayOfTheLifedrinker from './pages/classes/ClassesWayOfTheLifedrinker';
import WinterbornKnight from './pages/classes/ClassesWinterbornKnight';
import PantheonCaelumbris from './pages/deities/PantheonCaelumbris';
import PantheonCalanthar from './pages/deities/PantheonCalanthar';
import PantheonLibris from './pages/deities/PantheonLibris';
import PantheonQueenMab from './pages/deities/PantheonQueenMab';
import PantheonQueenTitania from './pages/deities/PantheonQueenTitania';
import PantheonSacrathor from './pages/deities/PantheonSacrathor';
import PantheonFirstborn from './pages/deities/PantheonFirstborn';
import PantheonLeviathan from './pages/deities/PantheonLeviathan';
import PantheonValdis from './pages/deities/PantheonValdis';
import PantheonWildscar from './pages/deities/PantheonWildscar';
import VeiledAscension from './pages/VeiledAscension';
import CaelumbrisShadowed from './pages/deities/CaelumbrisShadowed';
import FirstbornSoulbound from './pages/deities/FirstbornSoulbound';
import LibrisKnowledge from './pages/deities/LibrisKnowledge';
import QueenMabFrozenVeil from './pages/deities/QueenMabFrozenVeil';
import QueenTitaniaWildgrowth from './pages/deities/QueenTitaniaWildgrowth';
import SacrathoDominion from './pages/deities/SacrathorDominion';
import ValdisJustice from './pages/deities/ValdisJustice';
import WildscarEmberFury from './pages/deities/WildscarEmberFury';
import LIBRISNeuralInterface from './pages/deities/useragreement';
import LibrisKnowledgeTrue from './pages/deities/LibrisKnowledgeTrue';
import Pantheon from './pages/Pantheon';
import Prologue from './pages/Prologue';
import './App.css';
import './styles/global.css';
import './pages/species/SpeciesPage.css';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/house-rules" element={<HouseRules />} />
          <Route path="/prologue" element={<Prologue />} />
          <Route path="/veiled-ascension" element={<VeiledAscension />} />
          
          <Route path="/species" element={<Species />} />
          <Route path="/species/SpeciesWinterWitch" element={<WinterWitch />} />
          <Route path="/species/SpeciesGravewalker" element={<Gravewalker />} />
          <Route path="/species/SpeciesHumanity" element={<Humanity />} />
          <Route path="/species/SpeciesLifedrinker" element={<Lifedrinker />} />
          <Route path="/species/SpeciesManaforged" element={<Manaforged />} />
          <Route path="/species/SpeciesSeraphiend" element={<Seraphiend />} />
          <Route path="/species/SpeciesScytherai" element={<Scytherai />} />
          <Route path="/species/SpeciesStarborn" element={<Starborn />} />
          <Route path="/species/SpeciesVermisCorpus" element={<VermisCorpus />} />
          <Route path="/species/SpeciesSunseed" element={<Sunseed />} />
          <Route path="/species/SpeciesSkinWalker" element={<Skinwalker />} />
          <Route path="/Pantheon" element={<Pantheon />} />
          <Route path="/classes" element={<Classes />} />
          
          <Route path="/classes/ClassesBurningSeed" element={<BurningSeed />} />
          <Route path="/classes/ClassesGlacialHeart" element={<GlacialHeart />} />
          <Route path="/classes/ClassesDronewright" element={<Dronewright />} />
          <Route path="/classes/ClassesSeraphicGuardian" element={<SeraphicGuardian />} />
          <Route path="/classes/ClassesPathOfWrath" element={<PathOfWrath />} />
          <Route path="/classes/ClassesPyromancer" element={<Pyromancer />} />
          <Route path="/classes/ClassesSovereignZealot" element={<SovereignZealot />} />
          <Route path="/classes/ClassesWayOfTheLifedrinker" element={<WayOfTheLifedrinker />} />
          <Route path="/classes/ClassesWinterbornKnight" element={<WinterbornKnight />} />
          <Route path="/classes/ClassesUmbralInfiltrator" element={<UmbralInfiltrator />} />
          <Route path="/deities/PantheonCaelumbris" element={<PantheonCaelumbris />} />
          <Route path="/deities/PantheonCalanthar" element={<PantheonCalanthar />} />
          <Route path="/deities/PantheonLibris" element={<PantheonLibris />} />
          <Route path="/deities/PantheonQueenMab" element={<PantheonQueenMab />} />
          <Route path="/deities/PantheonQueenTitania" element={<PantheonQueenTitania />} />
          <Route path="/deities/PantheonSacrathor" element={<PantheonSacrathor />} />
          <Route path="/deities/PantheonFirstborn" element={<PantheonFirstborn />} />
          <Route path="/deities/PantheonLeviathan" element={<PantheonLeviathan />} />
          <Route path="/deities/PantheonValdis" element={<PantheonValdis />} />
          <Route path="/deities/PantheonWildscar" element={<PantheonWildscar />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/:folder" element={<GalleryFolder />} />
          <Route path="/gallery/:folder/:subfolder" element={<GalleryFolder />} />
          <Route path="/veiled-ascension" element={<VeiledAscension />} />
          <Route path="/deities/caelumbris/shadowed" element={<CaelumbrisShadowed />} />
          <Route path="/deities/firstborn/soulbound" element={<FirstbornSoulbound />} />
          <Route path="/deities/libris/knowledge" element={<LibrisKnowledge />} />
          <Route path="/deities/queenmab/frozenveil" element={<QueenMabFrozenVeil />} />
          <Route path="/deities/queen-titania/wildgrowth" element={<QueenTitaniaWildgrowth />} />
          <Route path="/deities/sacrathor/dominion" element={<SacrathoDominion />} />
          <Route path="/deities/valdis/justice" element={<ValdisJustice />} />
          <Route path="/deities/wildscar/emberfury" element={<WildscarEmberFury />} />
          <Route path="/deities/libris/useragreement" element={<LIBRISNeuralInterface />} />
          <Route path="/deities/libris/knowledge/true" element={<LibrisKnowledgeTrue />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
}

export default App;
