import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import backgroundImage from '../assets/images/intro.jpg';
import './Home.css';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mainContainer">
      <div 
        className="contentContainer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="contentOverlay">
          <div className="sectionContent" style={{ background: 'rgba(0, 0, 0, 0.7)', padding: '1rem', borderRadius: '8px' }}>
            <h1 style={{ textAlign: 'center' }}>Welcome to The First Veil</h1>
            <div className={`veiled-paths ${menuOpen ? 'active' : ''}`} ref={menuRef}>
              <h2 onClick={toggleMenu}>
                Menu
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/history" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  History
                </Link>
                <Link to="/house-rules" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  House Rules
                </Link>
                <Link to="/prologue" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Prologue
                </Link>
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Veiled Ascension
                </Link>
              </div>
            </div>
            <p>
              In the dim, receding light of a dying galaxy, a race known as the Starborn, astral elves of immense age and dwindling hope, turned their gaze towards a new frontier: Calanthar's galaxy. They were not conquerors in the traditional sense, but desperate refugees, driven by the gnawing hunger for survival and the allure of a unique substance – a mana-infused nanogel, the lifeblood of their advanced but failing civilization. Like locusts descending upon fertile fields, they arrived, their actions a brutal ballet of strip-mining and exploitation. Worlds wept under their touch, resources plundered, and native populations deemed insignificant in the face of the Starborn's desperate plight. Arrogance, born of desperation and ancient lineage, blinded them to the devastation they wrought.
            </p>
            
            <p>
              Amidst this cosmic drama, a mortal man, Calanthar, embarked on a journey of his own, a quest not for dominion, but for understanding. Imbued with immeasurable intellect and an insatiable thirst for knowledge, he sought to transcend the limitations of mortality, to weave his consciousness with every echo of himself across the infinite expanse of the multiverse. He succeeded, in a feat of will and arcane mastery that defied comprehension. He became something more, something vast, a nexus of infinite possibilities. Yet, this monumental achievement came at a terrible price. The weight of countless lives, the echoes of a billion realities, pressed upon him, fracturing his sanity, driving him into a profound and isolating madness.
            </p>
            
            <p>
              He retreated from the vibrant tapestry of existence, seeking solace in seclusion, wandering through landscapes forged of his own fractured mind. For centuries, he wrestled with the cacophony of his multiversal consciousness, his physical form mirroring the ethereal nature of his transformed being. Slowly, agonizingly, he wrestled back control, not of his sanity perhaps, but of his very essence, emerging as something beyond human, a living universe contained within a single form.
            </p>
            
            <p>
              From the shadows, Calanthar, now the Silent Architect, observed humanity, his own creation, flourishing under his unseen hand. For millennia, he had been their spectral guide, whispering inspiration into the minds of inventors, subtly steering the course of their technological and societal evolution. He remained a ghost in their world, a spectral benefactor, forever separated from the very humanity he sought to uplift, a guardian disconnected from those he yearned to protect.
            </p>
            
            <p>
              Then, a cryptic signal echoed from the cosmos, a whisper from beyond the veil of known realities. Humanity, in their burgeoning faith and self-proclaimed chosen status, interpreted it as a divine mandate, a test from their god. Religious fervor ignited, fueled by the mystery of the signal and the whispers of destiny. Calanthar, in his boundless wisdom, understood the true nature of the signal – a chilling harbinger, a warning of the approaching Leviathan, a cosmic entity of unimaginable hunger and power, a threat to all existence.
            </p>
            
            <p>
              Driven by a desperate need to avert the looming cataclysm, Calanthar conceived of the Dimensional Net, a masterwork of multiversal engineering, a device to ensnare the cosmic entity. Humanity, caught in the grip of religious fervor, mobilized their resources, their efforts tragically misdirected, believing they were preparing for a holy war, not a desperate act of cosmic defense. They built machines of war, fueled by fanaticism, while Calanthar, the silent architect, toiled in the shadows, weaving science and sorcery to create a shield against the encroaching doom.
            </p>
            
            <p>
              Unbeknownst to humanity, the Starborn, in their relentless pursuit of resources, were hurtling towards their own abyss. Their home galaxy, bled dry by centuries of relentless exploitation, teetered on the brink of collapse. Extinction loomed, and their desperate search for salvation intensified, their covetous gaze fixated on the lost Prototype Alpha, a unique Manaforged drone, a creation they now saw as both a threat and a potential key to their survival.
            </p>
            
            <p>
              As the Leviathan drew closer, casting its shadow across the stars, Calanthar, in a desperate act of cosmic defiance, activated the Dimensional Net. In that blinding instant, as raw power surged through the device, reality itself fractured. It was during this cataclysmic activation, in the heart of the shattering, that Calanthar merged with the Realm itself, his consciousness expanding to encompass the infinite fragments of reality, becoming the Universal Embodiment, the living soul of the First Veil. The Shattering occurred, birthing the First Veil, a universe forged from the fragments of countless realities, a chaotic mosaic of broken worlds.
            </p>
            
            <p>
              In the wake of the Shattering, Calanthar ascended beyond mortality, becoming the Universal Embodiment, the living heart and soul of this fractured new reality. His home world, now known as Velum, became the nexus of the Shattered Realm, a focal point in the chaotic tapestry of existence.
            </p>
            
            <p>
              Fifty years after the Shattering, the Leviathan breached the south pole of Velum, tearing a festering wound in reality and unleashing the ravenous Scytherai and a tide of unlife. To halt this encroaching chaos, a colossal wall of raw power erupted from the planet's surface, a jagged scar against the void. This wall, a bulwark against the encroaching chaos, physically halted the Scytherai advance, preventing their relentless spread across Velum's surface. Erected by a mysterious, nascent deity known only as Nebula, it stood as a testament to Nebula's desperate defiance, channeling immense power to preserve what remained of the realm.
            </p>
            
            <p>
              In this new era of chaos and uncertainty, Humanity, now the Anointed Remnant, underwent a radical transformation under the influence of Sacrathor, their self-proclaimed deity. Infused with nanites, their blood turned azure, granting them technological mastery but severing their connection to the Weave, the source of magic. Fueled by fanatical devotion, they declared a holy war, their legions marching across Velum, seeking dominion over all who dared defy their technologically enforced order.
            </p>
            
            <p>
              The Firstborn, the god of death and pacts, watches from his ethereal island, a melancholic arbiter of cosmic balance. And in the shadows of Velum, within the gleaming spires of L.I.B.R.I.S., the City of Knowledge, the pursuit of understanding continues, a flickering beacon of enlightenment in a universe forever fractured.
            </p>
            
            <p>
              But the Leviathan's shadow still stretches across Velum, a silent, ever-present threat. Seeking to contain this cosmic horror and stabilize the fractured realities, a divine summit was convened, orchestrated by the enigmatic being known as Nebula – the architect of the First Veil, the globe-spanning wall that held back the Leviathan and its reality-devouring minions. Nebula revealed a desperate plan: each of the surviving deities would be bound to a Domain, a pocket dimension anchored to the Leviathan's fractured reality.
            </p>
            
            <p>
              Thus, the Domains were created, each a unique reflection of the deity bound to it. Gateways to Queen Mab's Frozen Veil can be found in the icy north, a realm of calculated precision and wintry dominion. Portals to Queen Titania's Wildgrowth are hidden around the equator and in jungle areas of immense growth and untamed lands. Entrances to Caelumbris's Shadow Domain are veiled in the darkest places on Velum, a realm of unseen influence and subtle manipulation. The Eternal Wanderer, The Firstborn's floating island, became his domain, a bastion of solemn order and unbreakable pacts. L.I.B.R.I.S., the City of Knowledge, is a coastal city in a natural protected cove, by Velum's tallest mountain range, known for the three peaks named after the winter court: The Matron, The Queen, and The Lady, each reaching far into the heavens and eternally capped in ice. Just outside of the cove's natural protective reef is an eternal whirlpool. Sacrathor's golden Technological city sits in the reflections of Humanity's capital city (Urbs) skyscrapers. Valdis' Domain is said to be a maelstrom of all unbalanced wrongs of the world, thought to exist in the destructive maelstroms that populate the distant skies of Velum. Wildscar's realm is seen in the reflections from the blood spilled on a battlefield. Nebula is whispered to be the mists that cover Velum after the sun vanishes beyond Velum's horizon.
            </p>
            
            <p>
              These Domains – realms of shadow, frost, knowledge, justice, and untamed summer – would act as cosmic anchors, drawing power from the Leviathan, channeling it back into Velum through divine magic, a desperate act of cosmic acupuncture to mend the shattered fabric of existence. While offering stability and a bulwark against the encroaching void, these Domains became a constant drain on the deities bound to them, their power tethered to the containment of the Leviathan. The threat, though contained, was far from extinguished, a cosmic sword of Damocles hanging over Velum.
            </p>
            
            <p>
              The Scytherai, fragmented and adrift, echoes of a once unified hive mind, now wandered Velum as individuals, their insectoid forms a constant reminder of the Leviathan's lingering influence. Some, driven by instinctual memory, sought to reunite, to rebuild their shattered connection. Others, embracing their newfound individuality, carved their own paths in this chaotic world, their destinies as uncertain and fragmented as the realm itself.
            </p>
            
            <p>
              Now, two centuries after the Shattering, the First Veil stands at a crossroads. Gods and mortals, angels and demons, navigate this fractured reality, their destinies intertwined in the chaotic tapestry of existence. Humanity, empowered and zealous, marches across Velum, their technological might and unwavering faith in Sacrathor a force to be reckoned with.
            </p>
            
            <p>
              In this world of shattered realities, of zealous empires and ancient deities, of looming cosmic threats and flickering beacons of hope, your story begins. Velum awaits, a land of infinite possibilities and untold dangers. What path will you choose? What legacy will you carve into the tapestry of the First Veil?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;