import React, { createContext, useRef, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MainHeader from './MainHeader';

export const SubMenuContext = createContext({
  onSubMenuClick: { current: () => {} }
});

const Layout = () => {
  const onSubMenuClick = useRef((item) => {
    console.log('Default handler:', item);
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to top whenever location changes
  }, [location]);

  return (
    <SubMenuContext.Provider value={{ onSubMenuClick }}>
      <div className="layout-main-container">
        <MainHeader />
        <div className="page-content">
          <Outlet />
        </div>
      </div>
    </SubMenuContext.Provider>
  );
};

export default Layout; 