import React, { useState, useRef, useEffect } from 'react';
import winterbornKnightImage from '../../assets/images/subclasses/Winterborn/winterbornknight.jpg';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesWinterbornKnight = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={winterbornKnightImage} 
          alt="Winterborn Knight" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Winterborn Knight</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesGlacialHeart" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Glacial Heart
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesUmbralInfiltrator" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Umbral Infiltrator
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Description</h2>
          <p>
            Knights sworn to the Frost Monarch serve the ruler of the coldest, most forbidding realms of the fey world. These warriors harness the biting frost, fierce blizzards, and silent deadliness of the harshest winters. As enforcers of their icy patron's will, they manipulate the cold to vanquish their foes and protect the balance of life that winter brings.
          </p>
        </div>

        <div className="content-block">
          <h2>Features</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Expanded Spell List</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>The Winterborn Knight lets you choose from an expanded list of spells when you learn a warlock spell. The following spells are added to the warlock spell list for you:</p>
            <p><strong>3rd Level:</strong> Command, Ice Knife</p>
            <p><strong>5th Level:</strong> Rime's Binding Ice, Hold Person</p>
            <p><strong>7th Level:</strong> Sleet Storm, Tidal Wave</p>
            <p><strong>9th Level:</strong> Ice Storm, Otiluke's Resilient Sphere</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frostforged Weapon</h3>
              <span className="level-tag">Level 1st</span>
            </div>
            <p>
              As an action, you can summon any melee weapon made of pure ice that deals 1d12 damage on a hit, using your spell attack modifier for attack rolls. The weapon remains as long as you hold it but melts away if it leaves your hand for more than 1 minute. It cannot be ranged or have any special properties beyond its cold damage type.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Icy Thorns</h3>
              <span className="level-tag">Level 5th</span>
            </div>
            <p>
              Whenever you hit a creature with the Frostforged Weapon, the target takes additional cold damage equal to your Charisma modifier.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frost Armor and Winter's Cloak</h3>
              <span className="level-tag">Level 6th</span>
            </div>
            <p>
              Activate this ability as an action to envelop yourself in shimmering frost armor. This sets your Armor Class to 16 (if not higher), grants resistance to cold damage, dims light by one level for a 10-foot radius around you, and provides darkvision up to 60 feet, including the ability to see in magical darkness. Additionally, you can cast darkness a number of times per day equal to your proficiency bonus without needing material components.
            </p>
            <p>
              While in darkness or dim light, you can use your reaction to become invisible until the start of your next turn or until you attack, cast a spell, or turn on a light. This ability can be used a number of times equal to your proficiency bonus, resetting after a long rest.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ice Shield</h3>
              <span className="level-tag">Level 10th</span>
            </div>
            <p>
              You can conjure a shield of solid ice at a moment's notice with a reaction or bonus action. This shield not only grants a +2 bonus to AC but also impedes the freedom of movement, allowing you to glide across the battlefield unimpeded. The shield dims light by one level for a 20-foot radius around you and casts an aura of deeper darkness in a 10-foot radius around you, which can be dismissed at your will.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Winter's Grasp</h3>
              <span className="level-tag">Level 11th</span>
            </div>
            <p>
              Hits with the Frostforged Weapon now slow the target, reducing their speed by 10 feet until the end of their next turn. If you hit the same target with your Frostforged Weapon in two consecutive rounds, the target becomes frozen (restrained). The target remains frozen as long as it is hit by your weapon each round. The target can make a Strength saving throw at the end of each of its turns to break free from the frozen condition.
            </p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Winter's Dominion</h3>
              <span className="level-tag">Level 14th</span>
            </div>
            <p>
              Once per long rest, as an action, you can invoke a powerful blizzard centered on yourself that extends in a 60-foot radius. This blizzard moves with you and lasts for 1 minute, creating heavily obscured and difficult terrain. It deafens all within its range and forces creatures to make a Constitution saving throw at the start of their turns; on a failed save, they take 4d6 cold damage (this damage bypasses cold resistance and immunity). At the start of the blizzard, choose a number of creatures up to your Charisma modifier to be unaffected by its effects.
            </p>
          </div>
        </div>

        <div className="content-block">
          <h2>Role Playing</h2>
          <p>As a Winterborn Knight, you are a master of winter's icy power, a warrior sworn to uphold the balance of nature through the chilling embrace of frost. You are a force of calculated destruction and unwavering loyalty, wielding the magic of winter to vanquish your foes and protect the delicate balance of life.</p>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Disciplined:</strong> You embody the strategic mind and controlled power of winter, approaching challenges with calculated precision.</p>
            <p><strong>Resilient:</strong> Like the unyielding ice and snow, you endure hardship without complaint, your resolve as strong as a blizzard's fury.</p>
            <p><strong>Loyal:</strong> Your dedication to Queen Mab and the Winter Court is absolute, your word as binding as the ice that encases your foes.</p>
            <p><strong>Perceptive:</strong> You see the world with a clarity that pierces deception, your senses honed by the stillness and silence of winter's embrace.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Balance:</strong> Winter is not merely a season of death but a time of preparation and renewal, and you strive to maintain its vital role in the natural cycle.</p>
            <p><strong>Strategy:</strong> True power lies in the calculated mind, in planning and precision, not brute force.</p>
            <p><strong>Protection:</strong> You are a shield against the chaos of the world, defending the innocent and upholding the order that winter brings.</p>
            <p><strong>Endurance:</strong> The harshest winters forge the strongest spirits, and you embrace hardship as a test of your resilience.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>Winter's Embrace:</strong> You feel a deep connection to the icy landscapes and creatures of winter, drawing strength and inspiration from their resilience.</p>
            <p><strong>Queen Mab's Champion:</strong> You are devoted to serving Queen Mab, upholding her authority and carrying out her will with unwavering loyalty.</p>
            <p><strong>Frozen Heart:</strong> A personal loss or betrayal has hardened your heart, leaving you with a cold determination to protect others from suffering the same fate.</p>
            <p><strong>Legacy of Winter:</strong> You seek to leave your mark on the world, ensuring that the power and beauty of winter are never forgotten.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Cold Demeanor:</strong> Your icy nature can make you seem distant or unfeeling, hindering your ability to connect with others.</p>
            <p><strong>Ruthlessness:</strong> Your dedication to your cause can lead you to make difficult choices, sacrificing compassion for the sake of the greater good.</p>
            <p><strong>Isolation:</strong> You find solace in solitude, often withdrawing from the company of others to commune with the icy forces that empower you.</p>
            <p><strong>Vengeance:</strong> You harbor a deep grudge against those who have wronged you or those you protect, and you seek retribution with an icy fury.</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Equipment</h2>
          <p>As a Winterborn Knight, your equipment reflects your connection to the icy forces of winter and your role as a warrior and protector. You might carry:</p>
          <ul>
            <li>A symbol of Queen Mab, such as a frozen shard or a snowflake pendant, worn as a badge of your loyalty.</li>
            <li>Armor crafted from ice or infused with the essence of winter, providing both protection and a chilling aura.</li>
            <li>A cloak or furs that help you withstand the harshest winter conditions, often adorned with symbols of frost and ice.</li>
            <li>A pouch containing a collection of rare winter herbs and crystals, used in rituals and to enhance your connection to the icy forces.</li>
            <li>A journal or book filled with tactical notes and observations on the movements of your enemies and the ever-shifting balance of nature.</li>
          </ul>
        </div>

        <div className="content-block">
          <h2>Multiclassing</h2>
          <p>If you choose to multiclass, you can combine your warlock abilities with those of another class to create a unique and versatile character. Some multiclassing options might include:</p>
          <div className="trait">
            <p><strong>Fighter (Battle Master):</strong> The fighter's martial prowess and tactical maneuvers complement your winter-infused combat style, making you a more formidable warrior.</p>
            <p><strong>Rogue (Assassin):</strong> The rogue's stealth and agility enhance your ability to strike from the shadows, delivering deadly blows with icy precision.</p>
            <p><strong>Wizard (School of Abjuration or Conjuration):</strong> The wizard's arcane knowledge and spellcasting abilities can further enhance your control over winter's magic, granting you access to powerful spells that manipulate the elements and protect your allies.</p>
            <p><strong>Druid (Circle of the Glacial Heart):</strong> The druid's connection to nature and mastery of winter's power complements your own, granting you access to a wider range of spells and abilities that harness the icy forces of the world.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={winterbornKnightImage} 
                alt="Winterborn Knight"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={winterbornKnightImage}
                download="WinterbornKnight.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesWinterbornKnight; 