import React, { useState, useRef, useEffect } from 'react';
import glacialHeartImage from '../../assets/images/subclasses/Glacial/circleoftheglacialheart.png';
import './ClassesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const ClassesGlacialHeart = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={glacialHeartImage} 
          alt="Circle of the Glacial Heart" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-title">
          <h1>Circle of the Glacial Heart</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`pantheons ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Classes
                <ChevronDown size={20} />
              </h2>
              <div className="pantheon-links">
                <Link to="/classes" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Classes
                </Link>              
                <Link to="/classes/ClassesBurningSeed" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Circle of the Burning Seed
                </Link>
                <Link to="/classes/ClassesDronewright" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Dronewright
                </Link>
                <Link to="/classes/ClassesSeraphicGuardian" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Oath of the Seraphic Guardian
                </Link>
                <Link to="/classes/ClassesPathOfWrath" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Path of Wrath
                </Link>
                <Link to="/classes/ClassesPyromancer" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Pyromancer
                </Link>
                <Link to="/classes/ClassesSovereignZealot" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Sovereign Zealot
                </Link>
                <Link to="/classes/ClassesWayOfTheLifedrinker" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Way of the Lifedrinker
                </Link>
                <Link to="/classes/ClassesWinterbornKnight" className="pantheon-link" onClick={() => setMenuOpen(false)}>
                  Winterborn Knight
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pantheon-details">
        <div className="content-block">
          <h2>Overview</h2>
          <p>
            Druids of the Circle of the Glacial Heart are masters of cold and control. They channel the power of winter to freeze their enemies, manipulate the battlefield, and strike from the shadows. Their magic is precise and calculated, reflecting a deep understanding of strategy and the natural order. These druids are often seen as stoic and reserved, but their hearts burn with a fierce determination to protect the balance of nature.
          </p>
        </div>

        <div className="content-block">
          <h2>Circle Spells</h2>
          <div className="level-indicator">Level 2nd</div>
          <p>Your connection to the power of winter grants you access to certain spells. You always have the following spells prepared, and they don't count against the number of spells you can prepare each day.</p>
          <div className="trait">
            <div className="trait-header">
              <h3>3rd Level</h3>
            </div>
            <p>Snilloc's Snowball Swarm, Rime's Binding Ice</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>5th Level</h3>
            </div>
            <p>Sleet Storm, Hold Person</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>7th Level</h3>
            </div>
            <p>Ice Storm, Elemental Bane</p>
          </div>
          <div className="trait">
            <div className="trait-header">
              <h3>9th Level</h3>
            </div>
            <p>Cone of Cold, Control Winds</p>
          </div>
        </div>

        <div className="content-block">
          <h2>Glacial Burst</h2>
          <div className="level-indicator">Level 2nd</div>
          <p>
            While in your Wild Shape, you can expend a spell slot as a bonus action to unleash a burst of freezing energy. Each creature of your choice within 10 feet of you must make a Dexterity saving throw against your spell save DC. On a failed save, a creature takes 1d4 cold damage per level of the spell slot expended and falls prone. On a successful save, a creature takes half as much damage and does not fall prone.
          </p>
        </div>

        <div className="content-block">
          <h2>Frostbite</h2>
          <div className="level-indicator">Level 6th</div>
          <p>
            Your mastery of winter enhances the lethality of your Wild Shape. While in Wild Shape, your melee weapon attacks deal an additional 1d4 cold damage and count as magical for the purpose of overcoming resistance and immunity to nonmagical attacks and damage. The cold damage increases to 1d6 when you reach 10th level and 1d8 when you reach 14th level in this class.
          </p>
        </div>

        <div className="content-block">
          <h2>Glacier Stance</h2>
          <div className="level-indicator">Level 10th</div>
          <p>
            As a reaction to being attacked while in Wild Shape, you can create a wall of ice around yourself, granting you +4 AC until the end of your next turn. You can use this feature a number of times equal to your proficiency bonus (minimum of once). You regain all expended uses when you finish a long rest.
          </p>
        </div>

        <div className="content-block">
          <h2>Winter's Embrace</h2>
          <div className="level-indicator">Level 14th</div>
          <p>
            As an action while in Wild Shape, you can create a 15-foot cone of freezing air. Each creature in the cone must make a Constitution saving throw against your spell save DC. On a failed save, a creature takes 4d6 cold damage and is slowed until the end of your next turn. On a successful save, a creature takes half damage and is not slowed. Once you use this feature, you can't do so again until you finish a long rest.
          </p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={glacialHeartImage} 
                alt="Circle of the Glacial Heart"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={glacialHeartImage}
                download="GlacialHeart.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesGlacialHeart; 