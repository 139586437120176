/**
 * Utility function to dynamically import images using webpack's require.context
 */

// Cache for the webpack context and loaded images
const imageContext = require.context('../assets/images', true, /\.(png|jpe?g|webp)$/);
const imageCache = new Map();

// Function to normalize path for different environments
function normalizePath(path) {
  // Remove any leading slashes
  path = path.replace(/^\/+/, '');
  // Handle both development and production paths
  const baseUrl = process.env.PUBLIC_URL || '';
  return `${baseUrl}/assets/images/${path}`;
}

// Function to get a single image
export function getImage(path) {
  try {
    if (imageCache.has(path)) {
      return imageCache.get(path);
    }
    // Try to get the image using webpack first
    try {
      const image = require(`../assets/images/${path}`);
      imageCache.set(path, image);
      return image;
    } catch {
      // If webpack fails, use the normalized path
      const normalizedPath = normalizePath(path);
      imageCache.set(path, normalizedPath);
      return normalizedPath;
    }
  } catch (error) {
    console.error(`Failed to load image: ${path}`, error);
    return null;
  }
}

// Function to get all images from a folder
export function getAllImagesInFolder(folderPath) {
  try {
    const images = {};
    
    imageContext.keys().forEach(key => {
      // Remove the leading './' from the key
      const path = key.slice(2);
      // Check if this image is in the requested folder
      if (path.startsWith(folderPath)) {
        try {
          if (imageCache.has(path)) {
            images[path] = imageCache.get(path);
          } else {
            const image = getImage(path);
            if (image) {
              images[path] = image;
              imageCache.set(path, image);
            }
          }
        } catch (error) {
          console.error(`Failed to load image: ${path}`, error);
        }
      }
    });
    
    return images;
  } catch (error) {
    console.error('Failed to load images:', error);
    return {};
  }
}

// Function to get all subfolders in a folder
export function getSubfolders(folderPath) {
  try {
    const subfolders = new Set();
    
    imageContext.keys().forEach(key => {
      // Remove the leading './' from the key
      const path = key.slice(2);
      // Check if this path is in the requested folder
      if (path.startsWith(folderPath)) {
        // Get the next segment of the path after the folderPath
        const remainingPath = path.slice(folderPath.length + 1);
        const nextFolder = remainingPath.split('/')[0];
        if (nextFolder && !nextFolder.includes('.')) {  // Ensure it's a folder, not a file
          subfolders.add(nextFolder);
        }
      }
    });
    
    return Array.from(subfolders).sort();
  } catch (error) {
    console.error('Failed to get subfolders:', error);
    return [];
  }
}

// Function to preload images for better performance
export function preloadImages(paths) {
  return Promise.all(
    paths.map(path => {
      if (imageCache.has(path)) {
        return Promise.resolve(imageCache.get(path));
      }
      return new Promise((resolve, reject) => {
        try {
          const image = getImage(path);
          if (image) {
            imageCache.set(path, image);
            resolve(image);
          } else {
            reject(new Error(`Failed to load image: ${path}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    })
  );
} 